import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  public isPageAvailable: boolean;

  constructor(private router: Router) {
  }

  public goToAccessPage(): void {
    this.isPageAvailable = true;

    this.router.navigate(['pages/access']).then(() => {
      this.isPageAvailable = false;
    });
  }
}
