import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { environment } from '@env';

import * as _ from 'lodash';
import moment from 'moment';
import { awaitHandler } from '@utils/awaitHandler';

export class ICommsResponse {
  status: number;
  statusText: string;
  reqStatus: string;
  reqStatusText: string;
  result: null | any;
}

@Injectable({
  providedIn: 'root'
})
export class CommsService {

  subscriberID = environment.data.subscriberID;
  backendHost = environment.data.endpoint;
  initialServicePath = 'scripts/corvex.cgi';
  servicePath = environment.data.servicePath;
  uploadPath = `${this.servicePath}/uploadObject.cgi`;
  serviceHost: any;
  serviceURL: any = `${environment.data.endpoint}${environment.data.servicePath}/corvex.cgi`;
  networkAvailable = true;
  noAuthHandler: any;
  messageID = 0;
  usingGateway: boolean;
  usingDirect: boolean;
  usingZeroconf: boolean;
  localsubs: any;
  subscriber: any;
  lastServiceURL: any;
  lastServiceHost: any;
  lastServicePath: any;
  lastIPAddr: any;     // when we know our IP address, put it in here
  lastSubnet: any;
  lastNetworkType: any;
  discoverGateway: boolean;
  mySubnet: any;
  version: string;
  token = null;
  myAddress = null;
  gatewayWaitSeconds = 10;
  // message queue
  mQueue: any = [];
  // active requests
  activeRequests: any = {};
  waiting = false;
  zcInitialized = false;
  private noCacheCommands = [
    // 'getAvailableResponses'
  ];
  private tzOffset: any;
  private initialized = false;

  constructor(
    private logger: NGXLogger,
  ) {
  }

  public async sendMessage(message: any, canQueue = false, showLoading = false, retryCounter = 5): Promise<ICommsResponse> {
    if (canQueue === undefined) {
      canQueue = false;
    }
    if (showLoading === undefined) {
      showLoading = false;
    }

    if (showLoading) {
      this.logger.warn('Called with spinner');
    }
    const messageID = this.messageID++;
    const d: any = new Promise(async (resolve, reject) => {

      if (message && 'object' === typeof (message)) {
        // populate the message with metadata
        if (!message.hasOwnProperty('sendTime')) {
          message.sendTime = Date.now();
        }
        if (!message.token) {
          if (this.token) {
            message.token = this.token;
          }
        }

        if (message.cmd === 'initialize') {
          // delete message.subscriberID;    // initialize should never be sent a subscriberID
        } else if (!message.subscriberID && environment.data.subscriberID) {
          message.subscriberID = environment.data.subscriberID;
        }

        if (message.cmd === 'getUsers') {
          this.logger.log('called getUsers');
        }

        message.commsMsgID = messageID;
        if (this.networkAvailable) {
          this.serviceURL = this.serviceURL.replace(/([^:]\/)\/+/g, '$1');
          const props = {
            url: this.serviceURL + '/' + message.cmd,
            method: 'POST',
            data: message,
            style: 'FORM',
            parse: true
          };

          // we have an open request
          this.waiting = true;

          let completed = false;
          let data;
          let theError;

          while (!completed && retryCounter) {
            // start the request
            [data, theError] = await awaitHandler(this._fetch(props));

            if (!theError) {

            // it completed
            delete this.activeRequests[message.commsMsgID];
            if (Object.keys(this.activeRequests).length === 0) {
              this.waiting = false;
            }
            // this.logger.log('response received: ' + JSON.stringify(data));
            if (this.noAuthHandler) {
              // look at the response...
              if (data.reqStatus === 'NoAuth') {
                this.logger.log('User not authenticated and we have a noAuth handler');
                this.noAuthHandler(data);
                completed = true;
                reject('NoAuth');
              } else {
                completed = true;
                resolve(data);
              }
            } else {
              completed = true;
            }
          }

          if (theError) {
            retryCounter--;
            if (retryCounter) {
              this.logger.log('Send failed: ' + JSON.stringify(theError) + '; original message was ' + JSON.stringify(message) + ': RETRYING');
              await this.sleep(_.random(1000, 5000));
            } else {
              this.logger.error('Send failed: ' + JSON.stringify(theError) + '; original message was ' + JSON.stringify(message) );
              delete this.activeRequests[message.commsMsgID];
              if (Object.keys(this.activeRequests).length === 0) {
                this.waiting = false;
              }
            }
          }
        }
        if (theError) {
          reject(theError);
        } else if (data) {
          resolve(data);
        }
        } else {
          reject('noNetwork');
        }
      } else {
        reject('noMessage');
      }
    });

    d.messageID = messageID;

    return d;
  }

  private _fetch(props): Promise<any> {
    'use strict';
    if (props.method === null || props.method === undefined) {
      props.method = 'GET';
    }
    if (props.parse === null || props.parse === undefined) {
      props.parse = true;
    }
    if (props.headers === null || props.headers === undefined) {
      props.headers = [];
    }
    if (props.style === null || props.style === undefined) {
      props.style = 'JSON';
    }
    if (props.style === 'FORM') {

      _.forEach(Object.keys(props.data).sort(), (key) => {
        if (_.isNull(props.data[key])) {
          delete props.data[key];
        }
        if (typeof (props.data[key]) === 'object') {
          // this.logger.debug(`Encoding object ${key} into JSON`);
          props.data[key] = JSON.stringify(props.data[key]);
        }
      });
    }

    let xhr;
    const d: any = new Promise((resolve, reject) => {

      xhr = new XMLHttpRequest();

      // this gets returned when the request completes
      const resp = {
        headers: null,
        status: 0,
        statusText: '',
        body: null,
        text: '',
        reqStatusText: '',
        reqStatus: '',
        result: null,
        xhr
      };

      let theTimer = null;

      if (props.timeout) {
        theTimer = window.setTimeout(() => {
          theTimer = null;
          xhr.abort();
          reject('timeout');
        }, props.timeout);
      }
      let url = props.url;
      if (props.method === 'GET' && props.data) {
        // we need to translate the data into args
        // xhr.send($.param(props.data));
        let f = '';
        if (_.has(props.data, 'cmd')) {
          f += 'cmd=' + encodeURIComponent(props.data.cmd) + '&';
        }

        _.forEach(Object.keys(props.data).sort(), (key) => {
          // skip sendTime; it screws up caching in the service worker
          if (key !== 'cmd' && key !== 'commsMsgID' && key !== 'sendTime' && props.data[key] != null) {
            f += encodeURIComponent(key) + '=' + encodeURIComponent(props.data[key]) + '&';
          }
        });
        if (f !== '') {
          url += '?' + f;
        }
        props.data = null;
      }

      xhr.open(props.method, url);

      // headers?
      _.forEach(props.headers, (ref) => {
        xhr.setRequestHeader(ref[0], ref[1]);
      });

      // if (props.data !== null && props.data !== undefined && _.has(props.data, 'cmd')) {
      //   if ( _.indexOf(this.noCacheCommands, props.data.cmd) !== -1) {
      //     xhr.setRequestHeader('ngsw-bypass', '1');
      //   }
      // }
      xhr.setRequestHeader('ngsw-bypass', 'true');

      if (props.parse) {
        // xhr.responseType = 'json';
      }

      if (props.style === 'FORM') {
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      }

      xhr.onerror = function () {
        if (theTimer) {
          window.clearTimeout(theTimer);
          theTimer = null;
        }
        if (this.status) {
          resp.status = this.status;
          resp.statusText = xhr.statusText;
        } else if (this.status === 0) {
          resp.status = 0;
          resp.statusText = 'No Response';
        }
        reject(resp);
      };

      xhr.onload = function () {
        if (theTimer) {
          window.clearTimeout(theTimer);
          theTimer = null;
        }
        resp.status = this.status;
        if (this.status >= 200 && this.status < 300) {
          // return the raw text of the response
          if (!props.parse) {
            resp.text = xhr.response;
          } else {
            let data;

            if (typeof (xhr.response) === 'string') {
              try {
                data = JSON.parse(xhr.response); // as IE doesn't support json as a responseType
              } catch (e) {
                data = xhr.response;
              }
            } else {
              data = xhr.response;
            }

            // we have it; what is it?
            if (data && data.result) {
              resp.result = data.result;
            } else {
              resp.result = data;
            }
            if (data && data.status) {
              resp.reqStatus = data.status;
              resp.reqStatusText = data.statusText;
              if (resp.reqStatus !== 'OK') {
                if (resp.reqStatus === 'CACHEVALID') {
                  // this.logger.log('CACHEVALID: Request was ', props, '; Server says ', data);
                } else {
                  console.log('ERROR: Request was ', props, '; Server says ', data);
                }
              }
            }
          }
          resolve(resp);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };

      if (props.data !== null && props.data !== undefined) {
        if (_.has(props.data, 'commsMsgID')) {
          // remember the request in the promise object so we can abort it later
          this.activeRequests[props.data.commsMsgID] = xhr;
        }
        if ('object' === typeof (props.data)) {
          if (props.style === 'JSON') {
            xhr.send(JSON.stringify(props.data));
          } else if (props.style === 'FORM') {
            let f = '';
            if (_.has(props.data, 'cmd')) {
              f += 'cmd=' + encodeURIComponent(props.data.cmd) + '&';
            }
            _.forEach(Object.keys(props.data).sort(), (key) => {
              if (key !== 'cmd' && props.data[key] != null) {
                f += encodeURIComponent(key) + '=' + encodeURIComponent(props.data[key]) + '&';
              }
            });
            xhr.send(f);
          }
        } else if ('function' === typeof (props.data)) {
          xhr.send(props.data());
        } else if ('string' === typeof (props.data)) {
          xhr.send(props.data);
        }
      } else {
        xhr.send();
      }
    });
    d.theRequest = xhr;
    return d;
  }

  /**
   * Returns a string that can be used as a URI to retrieve the object
   *
   * @param objectID - the object to request
   * @param thumbnail - optional indication that a thumbnail version should be returned (only if the object is an image).
   * @param timestamp - optional time parameter
   * @param oldStyle - optional time parameter
   * @param cached - uses cached object url
   *
   */

  objectURI(objectID: string | number, thumbnail: boolean, oldStyle: boolean = false, cached = true): string {
    let cmd = '';
    const token = this.token || 'none';
    let ret = '';

    if (oldStyle) {
      cmd = this.serviceURL.replace('corvex.cgi', 'getObject.cgi');

      ret = cmd +
        '?cmd=getObject&token=' + this.token +
        '&subscriberID=' + this.subscriberID +
        '&objectID=' + objectID;

      if (thumbnail) {
        ret += '&thumb=1';
      }
    } else {
      cmd = this.serviceURL.replace('api/v2', '');
      ret = `${cmd}objects/${this.subscriberID}/${token}/${objectID}`;
      if (thumbnail) {
        ret += '/thumb';
      }
    }

    if (!cached) {
      ret += `?t=${moment().valueOf()}`;
    }

    return ret;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public init(params: any = {}): void {
    if (params) {
      if (params.noAuth) {
        // function to call when there is an authentication problem
        this.noAuthHandler = params.noAuth;
      }
      if (params.servicePath) {
        this.servicePath = params.servicePath;
      }
      if (params.endpoint && params.endpoint !== '') {
        this.backendHost = params.endpoint;
      }
      // this.logMessages = _.get(params, "debug", false);
    }
    // ensure parameters are set to match environment if it changed
    this.subscriberID = environment.data.subscriberID;
    this.backendHost = environment.data.endpoint;
    this.servicePath = environment.data.servicePath;
    this.uploadPath = `${this.servicePath}/uploadObject.cgi`;
    this.serviceURL = `${environment.data.endpoint}${environment.data.servicePath}/api/v2`;
    this.initialized = true;
  }

  private sleep(time: number = 0): Promise<void> {
    if (!time) {
      Promise.resolve();
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }

}

