<div *ngIf="buttonList?.length || selectList?.length" [appPermissionCanView]="'supervisor'"  [appAccountCanViewExcept]="['viewer']" class="detail-controls-as-viewer">
  <div class="button-block">
    <div class="buttons">
      <ion-button *ngFor="let butts of buttonList; trackBy: trackBy"
                  (click)="butts.click(oid, this.headerIsHidden)"
                  [class]="butts.class"
                  [color]="butts.color">{{butts.name | translate}}</ion-button>
    </div>

    <div class="actions" *ngIf="selectList.length">
      <ng-select *ngFor="let select of selectList"
                 (change)="onSelectChange($event, select)"
                 [class]="select.class"
                 [(ngModel)]="select.value"
                 [placeholder]="select.name | translate"
                 [clearable]="false"
                 [closeOnSelect]="true"
                 [multiple]="false"
                 [searchable]="false" class="custom-select select-actions">
        <ng-option *ngFor="let value of select.values" [value]="value">
          <span class="select-value">{{ value.text | translate }}</span>
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="clearfix"></div>
</div>
