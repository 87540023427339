import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CopyLinkComponent } from './components/copy-link/copy-link.component';
import { CopyModalPopupComponent } from './view-modal/copy-modal-popup/copy-modal-popup.component';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    CopyLinkComponent,
    CopyModalPopupComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    IonicModule
  ],
  exports: [
    CopyLinkComponent
  ],
  entryComponents: [
    CopyModalPopupComponent
  ]
})
export class CopyLinkModule {}
