import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { UserdataService } from '@services';

import * as _ from 'lodash';

@Directive({
  selector: '[appAccountCanViewExcept]'
})
export class AccountCanViewExceptDirective implements OnInit {

  @Input('appAccountCanViewExcept') appAccountCanViewExcept: string[];

  constructor(
    private elementRef: ElementRef,
    private userDataService: UserdataService
  ) {
  }

  ngOnInit(): void {
    if (_.includes(this.appAccountCanViewExcept, this.userDataService.type)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}
