import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ObjectItem, ObjectsService } from '@services';
import { ContentPickerComponent } from './../content-picker/content-picker.component';
import { ReorderList, ReorderListItem } from '@shared/components/reorder-list/reorder-list.interfaces';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface ContentListItem extends ReorderListItem, ObjectItem {
  iconName?: string;
  contentCategoryName?: string;
}

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
})
export class ContentListComponent extends CustomFormComponent implements OnInit, OnDestroy {
  @ViewChild('contentTypeRef', { static: true }) contentTypeRef: TemplateRef<HTMLElement>;
  @ViewChild('contentNameRef', { static: true }) contentNameRef: TemplateRef<HTMLElement>;
  @ViewChild('contentRemoveRef', { static: true }) contentRemoveRef: TemplateRef<HTMLElement>;
  @Input() draggable: boolean = true;

  public onParentIdsChange: Observable<number[]>;
  public prompt: string;
  public parentIds: number[] = [];
  public contentItems: ContentListItem[] = [];
  public parentContentItems: ContentListItem[] = [];
  public panelConfig = {
    count: 0,
    title: '',
    removeTitle: this.translateService.instant('MGMT_DETAILS.Remove_Content'),
    subTitle: this.translateService.instant('MGMT_DETAILS.Content_Items')
  };
  public orderByObject: string[] = ['mediaType', 'description'];
  public currentOrderByObject: { [key: string]: string[] } = {
    parentOrder: this.orderByObject,
    order: this.orderByObject
  };
  public reorderConfig: ReorderList<ContentListItem> = {
    draggable: this.draggable,
    enableCheckbox: true,
    items: [],
    columns: []
  };

  private subscriptionDestroy: Subject<number[]> = new Subject<number[]>();

  constructor(
    private objectsService: ObjectsService,
    private popoverController: PopoverController,
    private translateService: TranslateService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.defineItems();
    this.initParentIdsSubscriber();
    this.initReorderConfig();
  }

  ngOnDestroy() {
    this.subscriptionDestroy.next([]);
    this.subscriptionDestroy.complete();
  }

  public doReorder(): void {
    this.setContentItems(this.contentItems);
  }

  public toggleSelection(): void {
    const contentItems: number[] = _.map(_.filter(this.contentItems, 'selected'), 'objectID');
    this.setRemovalCount(contentItems.length);
  }

  public onRemove() {
    this.setRemovalCount(0);
    this.setContentItems(_.reject(this.contentItems, 'selected'));
  }

  public removeById(objectID: number): void {
    this.setContentItems(_.reject(this.contentItems, {objectID}));
  }

  public addContent() {
    this.popoverController.create(<any>{
      component: ContentPickerComponent,
      animated: false,
      componentProps: {
        rejectedIds: _.map([...this.contentItems, ...this.parentContentItems], 'objectID')
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event) => {
        const selectedIds = _.get(event, 'data.ids');
        const contentObjects = _.map(selectedIds, (id) => this.objectsService.getCachedObjectById(id));
        this.prepareListItems(contentObjects);

        if (contentObjects.length) {
          this.setContentItems([
            ...this.contentItems,
            ...contentObjects
          ]);
        }
      });
    });
  }

  public openDetailPage(content: ContentListItem) {
    const object = this.objectsService.getCachedObjectById(content.objectID, 'content', true);
    this.router.navigate(['pages/management/content/view/detail', {id: object.objectID}]);
  }

  private defineItems(): void {
    this.initContentItems();
    this.initParentContentItems(this.parentIds);
    this.checkDuplicate();

    if (this.reorderConfig.draggable) {
      this.currentOrderByObject.order = [];
    }

    this.reorderConfig.items = this.contentItems;
  }

  private prepareListItems(items): void {
    _.each(items, (item) => {
      item.iconName = this.objectsService.getIconByType(item.mediaType);
    });
  }

  private setRemovalCount(count: number): void {
    this.panelConfig.count = count;
    this.panelConfig.title = `${this.panelConfig.count} ${this.translateService.instant('SHARED.Selected')}`;
  }

  private setContentItems(items: ContentListItem[]) {
    this.contentItems = items;
    this.formValue = _.map(this.contentItems, 'objectID');
    this.reorderConfig.items = this.contentItems;
  }

  private initParentIdsSubscriber() {
    if (this.onParentIdsChange) {
      this.onParentIdsChange.pipe(
        takeUntil(this.subscriptionDestroy)
      ).subscribe((ids: number[]) => {
        this.initContentItems();
        this.initParentContentItems(ids);
        this.checkDuplicate();
      });
    }
  }

  private checkDuplicate() {
    const parentIds: number[] = _.map(this.parentContentItems, 'objectID');
    this.contentItems = _.reject(this.contentItems, (item) => _.includes(parentIds, item.objectID));
  }

  private initContentItems() {
    this.contentItems = this.objectsService.getCachedObjectByIds(this.formValue, true);
    this.prepareListItems(this.contentItems);
  }

  private initParentContentItems(ids: number[]) {
    this.parentContentItems = this.objectsService.getCachedObjectByIds(ids, true);
    this.prepareListItems(this.parentContentItems);
  }

  private initReorderConfig() {
    this.reorderConfig.draggable = this.draggable;

    this.reorderConfig.columns = [
      {
        title: 'SHARED.Type',
        class: 'type-field',
        template: this.contentTypeRef
      },
      {
        title: 'SHARED.NAME',
        class: 'name-field',
        template: this.contentNameRef
      }
    ];

    if (!this.draggable) {
      this.reorderConfig.columns.push({
        title: '',
        class: 'remove-button',
        template: this.contentRemoveRef
      });
    }
  }
}
