import { Injectable } from '@angular/core';

import { ObservationService, UtilsService } from '@services';

import palette from 'google-palette';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private utils: UtilsService,
              private observationService: ObservationService) {
  }

  /***
   * accumulate data into a dataset per secondary with a bucket per primary
   */
  public graphdataBySecondary(opts: any, rowRefs: any, keys: any, reportFieldOptions, reportColumnOptions): any {
    const graphData: any = {
      labels: [],
      datasets: []
    };

    const pRef: any = _.find(reportFieldOptions, {id: opts.primary});
    const sRef: any = _.find(reportFieldOptions, {id: opts.secondary});
    const gRef: any = _.find(reportFieldOptions, {id: opts.graphPrimary});
    const gsRef: any = _.find(reportColumnOptions, {id: opts.graphSecondary}) || {};

    if (gRef) {
      if (opts.graphType === 'pie' || opts.graphType === 'pareto') {
        graphData.datasets[0] = {data: [], label: gRef.label, backgroundColor: [], borderWidth: 2, borderColor: []};
      }
    }

    // probably want a legend for each data set

    // we are organizing by primary
    const pLabels = keys.primary;
    // pLabels = _.sortBy(pLabels);


    const sLabels = keys.secondary;
    if (gsRef) {
      graphData.yaxisLabel = gsRef.units;
    }

    const colorCounter = 0;
    const colorMap = {};
    // now we have a complete collection of the possible values.  Sort them and populate with colors
    // sLabels = _.sortBy(sLabels);

    let colorCount = sLabels.length;
    if (opts.graphType === 'pie') {
      // we have a LOT of labels
      colorCount = pLabels.length * sLabels.length;
    }

    let colorSet = null;

    // how many colors do we need?
    if (colorCount < 65) {
      colorSet = palette('mpn65', colorCount);
    } else {
      colorSet = palette('tol-rainbow', colorCount);
    }

    // there will be one dataset per unique label
    const dataSets = {};

    // column names
    _.each(pLabels, (label) => {
      graphData.labels.push(label);
    });

    // map in the colors

    if (opts.graphType === 'pie') {
      let counter = 0;
      _.each(pLabels, (pLabel) => {
        _.each(sLabels, (sLabel) => {
          const label = pLabel + ' - ' + sLabel;
          // in this case, we want a data set per period and then accumulate the data for the intervals in that dataset
          colorMap[label] = colorSet[counter];
          counter++;
        });
      });

    } else {
      _.each(sLabels, (label, counter) => {
        // in this case, we want a data set per period and then accumulate the data for the intervals in that dataset
        colorMap[label] = colorSet[counter];
        if (opts.graphType === 'line') {
          dataSets[label] = {
            label,
            data: [],
            borderWidth: 2,
            borderColor: '#' + colorSet[counter],
            backgroundColor: this.utils.makeRGBa(colorSet[counter], 0.5)
          };
        } else if (opts.graphType === 'lineNoFill') {
          dataSets[label] = {
            label,
            data: [],
            fill: false,
            borderWidth: 2,
            borderColor: '#' + colorSet[counter],
            backgroundColor: this.utils.makeRGBa(colorSet[counter], 0.5)
          };
        } else {
          dataSets[label] = {
            label,
            data: [],
            borderWidth: 2,
            borderColor: '#' + colorSet[counter],
            backgroundColor: this.utils.makeRGBa(colorSet[counter], 2 * 0.5)
          };
        }
      });
      if (opts.graphType === 'lineNoFill') {
        opts.graphType = 'line';
      }
    }
    // if there is an also organize by, and we are NOT doing pareto nor pie, then let's have a secondary collection
    if (opts.graphType !== 'pie') {
      _.each(pLabels, (primary) => {
        _.each(sLabels, (secondary, index) => {
          const i = {};
          i[pRef.fieldName] = primary;
          i[`secondary_${gRef.fieldName}`] = secondary;

          if (!dataSets[secondary].labelsMap) {
            dataSets[secondary].labelsMap = [];
          }
          const r = _.find(rowRefs, i);
          if (r) {
            const secondary = r[`secondary_${r.secondFieldName}`];
            if (gsRef && r.hasOwnProperty('total' + gsRef.id)) {
              dataSets[secondary].data.push(r['total' + gsRef.id] || 0);
              dataSets[secondary].labelsMap.push({primary: r[r.fieldName], secondary, count: r['total' + gsRef.id]});
            } else {
              dataSets[secondary].data.push(0);
              dataSets[secondary].labelsMap.push({primary: r[r.fieldName], secondary, count: 0});
            }
            // this one exists - push the value into the dataSet
          } else {
            // there was no match
            dataSets[secondary].data.push(null);
            dataSets[secondary].labelsMap.push({primary, secondary, count: 0});
          }
        });
      });
      graphData.datasets = [];
      _.each(sLabels, (label) => {
        graphData.datasets.push(dataSets[label]);
      });
    } else if (opts.graphType === 'oldpareto') {
      // this is a pareto or a pie chart; just roll up the s
      _.each(sLabels, (theLabel) => {
        const theRow = _.find(rowRefs, [sRef.fieldName, theLabel]);
        const p = 'total' + gsRef.id;
        if (theRow && theRow.hasOwnProperty(p)) {
          graphData.datasets[0].data.push(theRow[p] || 0);
        } else {
          graphData.datasets[0].data.push(0);
        }
        if (opts.graphType === 'pareto') {
          graphData.datasets[0].borderColor.push(this.utils.makeRGBa(colorMap[theLabel], 1.0));
          graphData.datasets[0].backgroundColor.push(this.utils.makeRGBa(colorMap[theLabel], 2 * 0.5));
        } else {
          graphData.datasets[0].backgroundColor.push('#' + colorMap[theLabel]);
        }
      });

    } else if (opts.graphType === 'pie') {
      graphData.labels = [];  // we are going to create our labels on the fly
      let colorCount = 0;
      _.each(pLabels, (primary) => {
        _.each(sLabels, (secondary) => {
          const i = {};
          i[pRef.fieldName] = primary;
          i[`secondary_${gRef.fieldName}`] = secondary;
          const label = primary + ' - ' + secondary;

          const theRow = _.find(rowRefs, i);
          const p = 'total' + gsRef.id;
          if (theRow && theRow.hasOwnProperty(p)) {
            graphData.labels.push(label);
            graphData.datasets[0].data.push(theRow[p] || 0);
            if (!graphData.datasets[0].labelsMap) {
              graphData.datasets[0].labelsMap = [];
            }
            graphData.datasets[0].labelsMap.push({
              primary: theRow[theRow.fieldName],
              secondary: theRow[`secondary_${theRow.secondFieldName}`],
              count: theRow[p]
            });
            colorCount++;
          }
        });
      });
      let colors;
      if (colorCount < 65) {
        colors = palette('mpn65', colorCount);
      } else {
        colors = palette('tol-rainbow', colorCount);
      }
      _.each(colors, (thecolor) => {
        graphData.datasets[0].backgroundColor.push('#' + thecolor);
      });
    }
    return graphData;
  }

  public graphdataByPeriod(opts: any, intervals: any, rowRefs: any, keys: any, reportFieldOptions, reportColumnOptions): any {
    const graphData: any = {
      labels: [],
      datasets: []
    };
    const pRef: any = _.find(reportFieldOptions, {id: opts.primary});
    const sRef: any = _.find(reportFieldOptions, {id: opts.secondary});

    // populate the labels
    _.each(intervals.intervals, (iref) => {
      graphData.labels.push(iref.label);
    });

    let fName;
    let labels;
    if (sRef) {
      labels = keys.secondary;
      fName = `secondary_${sRef.fieldName}`;
    } else {
      labels = keys.primary;
      fName = pRef.fieldName;
    }

    const colorMap = {};
    // now we have a complete collection of the possible values.  Sort them and populate with colors
    // labels = _.sortBy(labels);

    let colorSet = [];
    // how many colors do we need?
    if (fName === 'type') {
      // lets try to get colors from labels for observation types
      _.each(labels, label => {
        const c = this.observationService.typeColorByLabel(label);
        if (c) {
          colorSet.push(c);
        }
      });
      if (colorSet.length !== labels.length) {
        colorSet = [];
      }
    }

    // how many colors do we need?
    if (!colorSet.length) {
      if (labels.length < 65) {
        colorSet = palette('mpn65', labels.length);
      } else {
        colorSet = palette('tol-rainbow', labels.length);
      }
    }

    // there will be one dataset per unique label
    const dataSets = {};

    // map in the colors

    _.each(labels, (label, counter) => {
      // in this case, we want a data set per period and then accumulate the data for the intervals in that dataset
      colorMap[label] = colorSet[counter];
      if (opts.graphType === 'lineNoFill') {
        dataSets[label] = {
          label,
          fill: false,
          data: [],
          borderWidth: 2,
          borderColor: '#' + colorSet[counter],
          backgroundColor: this.utils.makeRGBa(colorSet[counter], 0.5)
        };
      } else {
        dataSets[label] = {
          label,
          data: [],
          borderWidth: 2,
          borderColor: '#' + colorSet[counter],
          backgroundColor: this.utils.makeRGBa(colorSet[counter], 0.5)
        };
      }
    });
    if (opts.graphType === 'lineNoFill') {
      opts.graphType = 'line';
    }

    // in the case of graphing by period, we always use the "include in graph" to decide what to populate with
    const gsRef: any = _.find(reportColumnOptions, {id: opts.graphSecondary}) || {};


    // pareto and pie charts can't have multiple levels of data; we can only use the primary accumulator

    if (gsRef) {
      graphData.yaxisLabel = gsRef.units;
    }

    if (opts.graphType === 'pie' || opts.graphType === 'pareto') {
      if (gsRef) {
        graphData.datasets[0] = {data: [], label: gsRef.label, backgroundColor: [], borderWidth: 2, borderColor: []};
      }
      _.each(labels, (theLabel) => {
        // graph by period is different
        const theRow: any = _.find(rowRefs, [fName, theLabel]);
        if (!graphData.datasets[0].labelsMap) {
          graphData.datasets[0].labelsMap = [];
        }
        _.each(intervals.intervals, (iref, idx) => {
          const p = 'p' + idx + gsRef.id;
          const secondary = theRow[`secondary_${theRow.secondFieldName}`];
          if (theRow && theRow.hasOwnProperty(p)) {
            if (opts.graphType === 'pie') {
              graphData.datasets[0].backgroundColor.push('#' + colorMap[theLabel]);
              graphData.datasets[0].data.push(theRow[p] || 0);
            } else if (opts.graphType === 'pareto') {
              graphData.datasets[0].borderColor.push(this.utils.makeRGBa(colorMap[theLabel], 1.0));
              graphData.datasets[0].backgroundColor.push(this.utils.makeRGBa(colorMap[theLabel], 2 * 0.5));
              graphData.datasets[0].data.push(theRow[p] || 0);
            }
            graphData.datasets[0].labelsMap.push({primary: theRow[theRow.fieldName], secondary, count: theRow[p]});
          } else {
            if (opts.graphType === 'pie') {
              graphData.datasets[0].backgroundColor.push('#' + colorMap[theLabel]);
              graphData.datasets[0].data.push(0);
            } else if (opts.graphType === 'pareto') {
              graphData.datasets[0].borderColor.push(this.utils.makeRGBa(colorMap[theLabel], 1.0));
              graphData.datasets[0].backgroundColor.push(this.utils.makeRGBa(colorMap[theLabel], 2 * 0.5));
              graphData.datasets[0].data.push(0);
            }
            graphData.datasets[0].labelsMap.push({primary: theRow[theRow.fieldName], secondary, count: 0});
          }
        });
      });
    } else {
      // this is some other sort of graph.   Do we have a secondary field?
      _.each(labels, (label) => {
        // graph by period is different
        if (opts.graphPrimary === 'period') {
          const rows = _.filter(rowRefs, [fName, label]);
          if (!dataSets[label].labelsMap) {
            dataSets[label].labelsMap = [];
          }

          _.each(intervals.intervals, (iref, idx) => {
            _.each(rows, (theRow: any) => {
              const periodKey = `p${idx}${gsRef.id}`;
              const rowValueByPeriod = _.get(theRow, `${periodKey}`, 0);
              const secondary = theRow[`secondary_${theRow.secondFieldName}`];

              if (!dataSets[label].data[idx]) {
                dataSets[label].data[idx] = 0;
              }

              dataSets[label].data[idx] += rowValueByPeriod || 0;
              dataSets[label].labelsMap.push({primary: theRow[theRow.fieldName], secondary, count: rowValueByPeriod});
            });
          });
        }
      });
      graphData.datasets = [];
      _.each(labels, (label) => {
        graphData.datasets.push(dataSets[label]);
      });
    }

    return graphData;
  }

  public graphdataByPrimary(opts: any, rowRefs: any, keys: any, reportFieldOptions, reportColumnOptions): any {
    const graphData: any = {
      labels: [],
      datasets: []
    };

    const pRef: any = _.find(reportFieldOptions, {id: opts.primary});
    const labels = keys.primary;
    const fName = pRef.fieldName;

    // go through the report data and find the bits to present
    // we are not organizing by period; use the primaryKeys for the labels
    _.each(labels, (key) => {
      graphData.labels.push(key);
    });
    const gRef: any = _.find(reportFieldOptions, {id: opts.graphPrimary});
    const gsRef: any = _.find(reportColumnOptions, {id: opts.graphSecondary}) || {};

    if (gRef) {
      if (opts.graphType === 'pie' || opts.graphType === 'pareto') {
        graphData.datasets[0] = {data: [], label: gRef.label, backgroundColor: [], borderWidth: 2, borderColor: []};
      } else {
        // it never makes sense to have more than one dataset in a pie graph
        graphData.datasets[0] = {data: [], backgroundColor: [], borderWidth: 2, borderColor: []};
      }
    }

    if (gsRef) {
      graphData.yaxisLabel = gsRef.units;
    }

    const colorMap = {};
    // now we have a complete collection of the possible values.  Sort them and populate with colors
    // labels = _.sortBy(labels);

    let colorSet = [];
    // how many colors do we need?
    if (gRef.fieldName === 'type') {
      // lets try to get colors from labels for observation types
      _.each(labels, label => {
        const c = this.observationService.typeColorByLabel(label);
        if (c) {
          colorSet.push(c);
        }
      });
      if (colorSet.length !== labels.length) {
        colorSet = [];
      }
    }

    if (!colorSet.length) {
      if (labels.length < 65) {
        colorSet = palette('mpn65', labels.length);
      } else {
        colorSet = palette('tol-rainbow', labels.length);
      }
    }

    // there will be one dataset per unique label
    const dataSets = {};

    // map in the colors

    _.each(labels, (label, counter) => {
      // in this case, we want a data set per period and then accumulate the data for the intervals in that dataset
      colorMap[label] = colorSet[counter];
      if (opts.graphType === 'line') {
        dataSets[label] = {
          label,
          data: [],
          borderWidth: 2,
          borderColor: '#' + colorSet[counter],
          backgroundColor: this.utils.makeRGBa(colorSet[counter], 0.5)
        };
      } else if (opts.graphType === 'lineNoFill') {
        dataSets[label] = {
          label,
          fill: false,
          data: [],
          borderWidth: 2,
          borderColor: '#' + colorSet[counter],
          backgroundColor: this.utils.makeRGBa(colorSet[counter], 0.5)
        };
      } else {
        dataSets[label] = {
          label,
          data: [],
          borderWidth: 2,
          borderColor: '#' + colorSet[counter],
          backgroundColor: this.utils.makeRGBa(colorSet[counter], 2 * 0.5)
        };
      }
    });
    if (opts.graphType === 'lineNoFill') {
      opts.graphType = 'line';
    }

    // if there is an also organize by, and we are NOT doing pareto nor pie, then let's have a secondary collection
    // we ONLY have a primary
    _.each(labels, (theLabel) => {
      const theRow: any = _.find(rowRefs, [fName, theLabel]);
      const p = 'total' + gsRef.id;
      if (!graphData.datasets[0].labelsMap) {
        graphData.datasets[0].labelsMap = [];
      }
      if (theRow && theRow.hasOwnProperty(p)) {
        const primaryID = _.flatten([_.get(theRow, `items[0].${opts.primary}`)])[0];
        graphData.datasets[0].data.push(_.sum(_.map(_.filter(rowRefs, [fName, theLabel]), (item) => _.get(item, p) || 0)));
        graphData.datasets[0].labelsMap.push({primary: theRow[theRow.fieldName], count: theRow[p], primaryID: primaryID});
      } else {
        graphData.datasets[0].data.push(0);
        graphData.datasets[0].labelsMap.push({primary: theRow[theRow.fieldName], count: 0});
      }
      if (opts.graphType === 'pareto') {
        graphData.datasets[0].borderColor.push(this.utils.makeRGBa(colorMap[theLabel], 1.0));
        graphData.datasets[0].backgroundColor.push(this.utils.makeRGBa(colorMap[theLabel], 2 * 0.5));
      } else {
        graphData.datasets[0].backgroundColor.push('#' + colorMap[theLabel]);
      }

      graphData.datasets[0].fill = false;
    });
    opts.customLegend = true;

    return graphData;
  }

}
