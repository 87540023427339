import * as CryptoJS from 'crypto-js';

export default class CryptoHelper {
  static encryptByteArray(bytes: number[], key: string): string {
    const hex = this.bytesToHex(bytes);
    const wordArray = CryptoJS.enc.Hex.parse(hex);

    return CryptoHelper.encrypt(wordArray, key);
  }

  static decryptByteArray(data: string, key: string): Uint8Array {
    const wordArray2 = CryptoJS.AES.decrypt(data, key);
    const hex2 = CryptoJS.enc.Hex.stringify(wordArray2);

    return this.hexToBytes(hex2);
  }

  static encrypt(data: string, key: string): string {
    return "" + CryptoJS.AES.encrypt(data, key);
  }

  static decrypt(data: string, key: string): string {
    if (!data) {
      return
    }
    let result = '';
    try {
      const bytes = CryptoJS.AES.decrypt(data, key);
      result = bytes.toString(CryptoJS.enc.Utf8);
    } catch(e) {
      console.warn('Decrypt error: ', data?.slice(0, 128), '...');
    }

    return result;
  }

  static hexToBytes(hex): Uint8Array {
    for (var bytes = [], c = 0; c < hex.length; c += 2) {
      bytes.push(parseInt(hex.substr(c, 2), 16));
    }

    return new Uint8Array(bytes);
  }

  static bytesToHex(bytes): string {
    for (var hex = [], i = 0; i < bytes.length; i++) {
      var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
      hex.push((current >>> 4).toString(16));
      hex.push((current & 0xF).toString(16));
    }
    return hex.join('');
  }
}
