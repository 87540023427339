import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { CommsService, LoadingService, ObservationService } from '@services';
import { QualityFieldsComponent } from '@modules/observation/components';

import * as _ from 'lodash';

@Component({
  selector: 'app-edit-quality-fields',
  templateUrl: './edit-quality-fields.page.html',
  styleUrls: ['./edit-quality-fields.page.scss'],
})
export class EditQualityFieldsPage {

  @ViewChild(QualityFieldsComponent) qualityFieldsComponent: QualityFieldsComponent;

  private isLoading: boolean;

  constructor(
    private location: Location,
    private observationsService: ObservationService,
    private commsService: CommsService,
    private loadingService: LoadingService,
  ) {
  }

  public back(): void {
    this.location.back();
  }

  public save(): void {
    const qualityVal = _.map(this.qualityFieldsComponent.customQualityField.data, data => {
      if (data.value !== '') {
        return {
          name: data.name,
          uuid: data.uuid,
          value: data.value
        };
      }
    });
    const jsonVal = JSON.stringify(qualityVal);
    const params: any = {
      cmd: 'updateObservation',
      observationID: this.qualityFieldsComponent.observationId,
      value: jsonVal
    };

    this.setLoadingState(true);
    this.commsService.sendMessage(params).then((res) => {
      this.observationsService.observations.data[this.qualityFieldsComponent.observationId] = res.result;
      this.setLoadingState(false);
      this.back();
    }).catch(() => {
      this.setLoadingState(false);
    });
  }

  private setLoadingState(isEnabled: boolean): void {
    this.isLoading = isEnabled;
    isEnabled ? this.loadingService.enable() : this.loadingService.disable();
  }

}
