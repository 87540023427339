import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-closing-details',
  templateUrl: './closing-details.component.html',
  styleUrls: ['./closing-details.component.scss'],
})
export class ClosingDetailsComponent {
  @Input() scrollRef: HTMLElement;
  @Input() closingData: any;

  constructor(private router: Router) {
  }

  public scrollTo() {
    this.scrollRef?.scrollIntoView && this.scrollRef.scrollIntoView({ behavior: 'smooth' });
  }

  public editDetails(): void {
    this.router.navigate(['pages/dashboard/close-observation', {id: this.closingData.observationID}]);
  }

  public addNote(): void {
    this.router.navigate(['pages/dashboard/closing-note', {id: this.closingData.observationID}]);
  }

}
