import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService, CommsService } from '@services';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FullscreenLeaderboardGuard implements CanActivate {

  constructor(
    private router: Router,
    private commsService: CommsService,
    private authService: AuthService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token: string = next.queryParamMap.get('token');

    if (this.authService.isUserLogged()) {
      return true;
    } else {
      if (token) {
        return this.commsService.sendMessage({cmd: 'checkAccess', readOnly: 1, token}).then((response: any) => {
          if (_.get(response, 'result.status') === 'OK') {
            this.commsService.token = token;
            return true;
          } else {
            return this.goToAccessPage();
          }
        }).catch(() => this.goToAccessPage());
      } else {
        return this.goToAccessPage();
      }
    }
  }

  private goToAccessPage(): boolean {
    this.router.navigate(['/pages/access']);
    return false;
  }

}
