import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { UtilsService } from '@services/utils/utils.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RolesService extends BaseService {

  public filterObject: any = {};

  public roles = {
    lastRequest: 0,
    lastHash: null,
    data: []
  };

  constructor(
    private logger: NGXLogger,
    protected comms: CommsService,
    private utils: UtilsService,
    private events: Events
  ) {
    super(comms);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.roles.lastRequest) {
      this.logger.log(`local roles cache already up to date: ${updated}, ${this.roles.lastRequest}`);
      return Promise.resolve(this.roles.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.comms.sendMessage({
          cmd: 'getRoles',
          includeDisabled: 1,
          lastHash: this.roles.lastHash,
          lastRequest: this.roles.lastRequest,
          sendTime: when
        }, false, false)
          .then((data) => {
            if (data && data.reqStatus === 'OK') {
              this.updateCache(data);
              this.roles.lastHash = data.result.datahash;
            }
            resolve(this.roles.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  public updateCache(data) {
    this.roles.lastRequest = data.lastRequest;
    this.roles.data = data.result.roles;
    this.utils.sortArray(this.roles.data, [
      {name: 'name', function: this.utils.toLowerCase},
      {name: 'description', function: this.utils.toLowerCase}
    ]);
    this.events.publish('ccs:rolesUpdate');
  }

  public clearCache() {
    this.roles.lastHash = null;
    this.roles.lastRequest = null;
    this.roles.data = null;
  }

  public roleName(roleItem) {
    if (roleItem) {
      return roleItem.name;
    } else {
      return '';
    }
  }

  public handleAddRole(fData) {
    fData.cmd = 'addRole';
    fData.sendTime = Date.now();
    return this.handleRequest(this.encodeRole(fData));
  }

  public encodeRole(roleData) {
    const r: any = {};
    Object.keys(roleData).forEach((key) => {
      if (key === 'gearAdds' || key === 'gearExceptions') {
        const v = roleData[key];
        const o = [];
        if (Array.isArray(v)) {
          $.each(v, (i, item) => {
            o.push(parseInt(item));
          });
        } else {
          o[0] = parseInt(v);
        }
        r[key] = JSON.stringify(o);
      } else {
        r[key] = roleData[key];
      }
    });
    r.exclusive = r.exclusive || 0;

    return r;
  }

  public handleUpdateRole(fData) {
    fData.cmd = 'updateRole';
    fData.sendTime = Date.now();
    return this.handleRequest(this.encodeRole(fData));
  }

  public handleDeleteRole(fData) {
    fData.cmd = 'deleteRole';
    return this.handleRequest(fData);
  }

  public getRoleById(id: number): any {
    return _.find(this.roles.data, <any>{roleID: id});
  }

  public getRoleByName(name: string): any {
    return _.find(this.roles.data, <any>{name});
  }

}
