<div [hidden]="!showFilterBody" class="filter-container-wrapper">
  <div class="filter-container-wrapper-inner">
    <div *ngFor="let filter of filterComps" class="filter-container">
      <div class="grid-box-text">{{mapLabel(filter.name)}}</div>
      <select2 (valueChanged)="dropDownChanged($event, filter.name)" [data]="filter.dropDownOptions" [options]="filter.options" [value]="filter.value"
               appSelect2Parent appSelect2TextClearing
               class="dashboard-dropdown" id="{{filter.id}}">
      </select2>
    </div>
  </div>
  <div class="hr-dashed"></div>
  <div class="filter-buttons">
    <div (click)="clearAll()" class="filter-button clear-button">{{ "SHARED.Clear_All" | translate }}</div>
    <div (click)="closeFilter()" class="filter-button done-button"> &nbsp;{{closeText}}&nbsp;</div>
  </div>
</div>

<div [class.active-filter]="filterValuesAvailable()"  class="current-filter">
  <div [hidden]="showFilterBody" *ngIf="filterValuesAvailable()" class="filter-label">
    <span [hidden]="!filterValuesAvailable()"
          class="body-copy label-object">{{ title || "DASHBOARD.FILTER_labelObject" | translate }} </span>
    <ng-template ngFor let-filter [ngForOf]="filterComps">
      <div *ngFor="let item of filter.value" class="active-filter-object">
        <span *ngIf="filter.id !== 'sl'" class="active-filter-inner">
          <span (click)="removeThis(filter.id, item)" *ngIf="!disableRemoval" class="active-filter-close"
                data-label="location">×</span>
          {{ findFilterLabel(item, filter.dropDownOptions) | translate }}
        </span>
        <span *ngIf="filter.id==='sl'">
          <span (click)="removeThis(filter.id, item)" *ngIf="!disableRemoval" class="active-filter-close"
                data-label="location">×</span>
          <span>
            <img [src]="findFilterLabel(item, filter.dropDownOptions)" alt="Label"
                 class="image-spacer obs-dot"> {{ findColorLabelName(item) }}
          </span>
        </span>
      </div>
    </ng-template>

    <div (click)="clearAll()" *ngIf="!disableRemoval" [hidden]="!filterValuesAvailable()"
         class="body-link clear-link">{{ "SHARED.Clear_All" | translate }}</div>
    <div class="body-link clear-link">
      <ng-content select="[customLinkContent]"></ng-content>
    </div>
  </div>
</div>
