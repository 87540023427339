<div class="form-modal">
  <div class="modal-title form-divider-title headline-medium">{{ title }}</div>
  <div class="modal-subtitle">{{ subTitle }}</div>
  <div class="form-group">
    <div class="selected-filters" id="selectFiltersModal">
      <app-item-list
        (onSelected)="onItemsSelected()"
        [idProperty]="'name'"
        [isInfinityScrollEnabled]="false"
        [isMultiple]="true"
        [isSearchable]="false"
        [items]="options"></app-item-list>
      <div class="btn-group">
        <ion-button (click)="close()" class="page-button" color="light">{{"SHARED.Cancel" | translate}}</ion-button>
        <ion-button (click)="applyForm()" [disabled]="!selectedItems || !selectedItems.length" class="page-button apply-btn"
                    color="secondary">{{"SHARED.Add_Filters" | translate}}</ion-button>
      </div>
    </div>
  </div>
</div>
