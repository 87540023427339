<div *ngFor="let field of customQualityField.data; let i = index">
  <div *ngIf="field.needsLabel" class="field-block-wrapper">
    <div class="field-block">
      <span class="form-label">{{field.name}}</span>
      <span *ngIf="field.required" class="required">*</span>
    </div>
    <input (ngModelChange)="modelCheck()" [(ngModel)]="field.value" autocomplete="off" id="{{'in-' + i}}" type="text">
  </div>
  <div *ngIf="!field.needsLabel" class="field-block-wrapper">
    <input (ngModelChange)="modelCheck()" [(ngModel)]="field.value" autocomplete="off" class="without-label" id="{{'in-' + i}}"
           type="text">
  </div>
  <!-- For BAR CODE  -->

  <div class="add-more-block">
    <ion-button (click)="addMore($event)" *ngIf="field.canAdd" [attr.data-index]="i" class="page-button"
                color="light">{{ "SHARED.Add_Another_Part" | translate }}</ion-button>
  </div>
</div>
