<ng-container *ngIf="config?.items?.length">
  <div class="header" [class.checkbox-padding]="config.enableCheckbox">
    <div class="column {{column.class}}" *ngFor="let column of config.columns">{{column.title | translate}}</div>
  </div>
  <ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!config.draggable">
    <div class="reorder-item" (click)="onClick.emit(item)" *ngFor="let item of config.items">
      <div class="reorder-cells">
        <ion-checkbox (click)="$event.stopPropagation()"
                      (ionChange)="toggleSelection()"
                      *ngIf="config.enableCheckbox"
                      [(ngModel)]="item.selected" class="reorder-cell" slot="start"></ion-checkbox>
        <div class="row-cells">
          <div *ngFor="let column of config.columns" [class]="column.class">
            <span *ngIf="column.value">{{column.value(item)}}</span>
            <ng-container *ngIf="!column.value && column.template">
              <ng-template [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{ item, disabled }"></ng-template>
            </ng-container>
          </div>
        </div>
      </div>
      <ion-reorder *ngIf="config.draggable" class="reorder-cell">
        <div class="reorder-icon"></div>
      </ion-reorder>
    </div>
  </ion-reorder-group>
</ng-container>
