<ion-header no-border>
  <app-close-button [backUrl]="backNavigateUrl"></app-close-button>
</ion-header>

<ion-content class="{{itemState}}">
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>
    <app-detail-controls [headerData]="headerData"></app-detail-controls>
    <app-observation-tag-list #tagListRef [ids]="tagsConfig.ids"
                              [removalHandler]="tagsConfig.removeHandler"></app-observation-tag-list>
    <hr *ngIf="tagListRef.tags.length" class="hr-dashed">
    <app-opportunity-feedback-requests [parentID]="poolItemID"
                                       parentType="externalData"></app-opportunity-feedback-requests>
    <div class="headline-medium margin-top margin-bottom">{{ 'SHARED.Reference' | translate }}</div>
    <div (click)="navTOref()" class="body-link margin-bottom">{{reference}}</div>
    <hr class="hr-dashed">
    <div class="headline-medium margin-top margin-bottom">{{'SHARED.Detail_Information' | translate}}</div>
    <div class="body-copy margin-bottom">{{notes}}</div>
  </div>
</ion-content>

<ion-footer *ngIf="footerData" [appAccountCanViewExcept]="['viewer']">
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
