import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLangPipe } from './selectLang.pipe';

@NgModule({
  declarations: [SelectLangPipe],
  imports: [
    CommonModule
  ],
  exports: [SelectLangPipe]
})
export class SelectLangPipeModule {
}
