<ion-content class="table-content">
  <div class="title-block">
    <div class="page-title">{{"SHARED.Communications" | translate}}</div>
  </div>
  <div class="manage-block">
    <app-tabs (currentTabChange)="syncTabs($event)" [(currentTab)]="currentTab" [tabs]="tabs"></app-tabs>
  </div>

  <div class="table-block">
    <div class="table-buttons" [hidden]="currentTab.key !== 'messages'">
      <ion-button (click)="open()" class="page-button new-message-button"
                  color="secondary">{{"COMMUNICATIONS.PAGES_LIST_New_Message" | translate}}</ion-button>
      <ion-button (click)="downloadThread()" [hidden]="messageThreadHidden" class="page-button page-button-left"
                  color="secondary">{{"COMMUNICATIONS.PAGES_LIST_Worker_Messages" | translate}}</ion-button>
    </div>
    <div [hidden]="!notifEnabled" class="list">
      <app-notifications (onClicked)="setMenuData($event, 'notification')" (onSelected)="onCheckedNotification($event)"
                         [hidden]="currentTab.key !== 'notifications'"></app-notifications>
    </div>
    <div [hidden]="!messageEnabled" class="list">
      <app-messages (onClicked)="setMenuData($event, 'chat')" (onSelected)="onCheckedMessage($event)"
                    [hidden]="currentTab.key !== 'messages'"></app-messages>
    </div>
  </div>
</ion-content>

<ion-footer *ngIf="currentTab.key === 'messages' && selectedMessagesIds.length || currentTab.key === 'notifications' && selectedNotificationsIds.length"
            no-border>
  <app-notification-removal-panel (onRemove)="remove()" [count]="removalConfig.count"
                                  [type]="removalConfig.type"></app-notification-removal-panel>
</ion-footer>

<app-new-message-menu (onSelected)="createChat($event)"></app-new-message-menu>
<app-chat-menu (refreshTable)="refreshMessageTable()"></app-chat-menu>
<app-notification-menu (refreshTable)="refreshNotificationTable()"></app-notification-menu>
