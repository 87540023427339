import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

interface IParticipant {
  name: string;
  avatarUrl?: string;
}

@Component({
  selector: 'app-participant-view',
  templateUrl: './participant-view.component.html',
  styleUrls: ['./participant-view.component.scss'],
})
export class ParticipantViewComponent {

  @Input() participants: IParticipant[] = [];

  constructor(private popoverCtrl: PopoverController) {
  }

  public close(): void {
    this.popoverCtrl.dismiss();
  }

}
