<div *ngIf="config.type === listMenuViewType.User">
  <app-user-list (onSelected)="emitSelection($event)"
                 [excludeAccounts]="config.excludeAccounts"
                 [excludePermissions]="config.excludePermissions"
                 [isInfinityScrollEnabled]="config.isInfinityScrollEnabled"
                 [isLoggedUserExcluded]="config.isLoggedUserExcluded"
                 [isMultiple]="config.selectMode === listMenuSelectMode.Multiple"
                 [selectedLocationIds]="config.selectedLocationIds">
  </app-user-list>
</div>

<div *ngIf="config.type === listMenuViewType.Team">
  <app-team-list (onSelected)="emitSelection($event)"
                 [isMultiple]="config.selectMode === listMenuSelectMode.Multiple"
                 [selectedLocationIds]="config.selectedLocationIds"></app-team-list>
</div>

<div *ngIf="config.type === listMenuViewType.MultipleUserTeam">
  <app-tabs (currentTabChange)="onChangeTab($event)" [(currentTab)]="activeTab" [tabs]="tabs"></app-tabs>
  <app-user-list (onSelected)="emitSelection($event)"
                 [excludeAccounts]="config.excludeAccounts"
                 [excludePermissions]="config.excludePermissions"
                 [hidden]="activeTab.key === listMenuType.Team"
                 [isSelectAllEnabled]="config.isSelectAllEnabled"
                 [isAssignPopUp]="config.isAssignPopUp"
                 [isInfinityScrollEnabled]="config.isInfinityScrollEnabled"
                 [isLoggedUserExcluded]="config.isLoggedUserExcluded"
                 [isMultiple]="config.selectMode === listMenuSelectMode.Multiple"
                 [selectedLocationIds]="config.selectedLocationIds">
  </app-user-list>
  <app-team-list (onSelected)="emitSelection($event)"
                 [hidden]="activeTab.key === listMenuType.User"
                 [isAssignPopUp]="config.isAssignPopUp"
                 [isMultiple]="config.selectMode === listMenuSelectMode.Multiple"
                 [selectedLocationIds]="config.selectedLocationIds">
  </app-team-list>
</div>
