import { Injectable } from '@angular/core';
import { Feature, PasswordPolicyService, SubscriberService } from '@services';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PolicyErrorHandlerService {

  constructor(
    private subscriberService: SubscriberService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private passwordPolicyService: PasswordPolicyService
  ) {
  }

  public showError() {
    if (this.subscriberService.usesFeature(Feature.Password_Policy)) {
      this.passwordPolicyService.refresh().then((data: any) => this.alertController.create({
        header: this.translateService.instant('MGMT_DETAILS.PASSWORD_PolicyTitle'),
        message: this.getMessageTemplate(data),
        cssClass: 'custom-alert',
        buttons: [{text: this.translateService.instant('SHARED.Try_Again')}]
      }).then((alert: any) => {
        alert.present();
        return alert.onWillDismiss();
      }));
    }
  }

  public getMessageTemplate(policyObj) {
    let message = `<strong>${this.translateService.instant('SHARED.PASSWORD_policyMessage')}</strong>
              <ul class="alert-list-view">`;
    _.each(policyObj, (value, label) => {
      if (!_.includes(label, 'algorithm')) {
        const policyLabel = this.translateService.instant('SHARED.Password_Policy_Msg_' + label);
        message += `<li> ${policyLabel} ${value}</li>`;
      }
    });
    message += `</ul>`;
    return message;
  }
}

