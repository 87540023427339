import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { UserService } from '@services/user/user.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { GearService } from '@services/gear/gear.service';
import { CertificationsService } from '@services/certifications/certifications.service';
import { UserdataService } from '@services/userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  public complianceInfo: any = {};
  public complianceSelection: any = {
    period: 'day',
    details: null,
    subitem: null,
    lastRequest: null
  };

  constructor(
    private commsService: CommsService,
    private userService: UserService,
    private accountsService: AccountsService,
    private gearService: GearService,
    private certificationsService: CertificationsService,
    private userDataService: UserdataService
  ) {
  }

  public getComplianceInfo(startTime: number, endTime: number, filter?: any) {
    let msg: any = {};
    if (filter) {
      msg = filter;
    }
    msg.cmd = 'getComplianceInfo';
    if (startTime === null && endTime === null) {
      msg.current = 1;
      delete msg.userevents;
    } else {
      if (startTime === undefined || startTime === null) {
        const date: Date = new Date();
        date.setHours(0, 0, 0, 0);
        startTime = date.getTime();
      }
      if (endTime === undefined || endTime === null) {
        endTime = Date.now();
      }
      msg.startTime = startTime;
      msg.endTime = endTime;
    }
    if (this.complianceSelection.lastRequest) {
      msg.lastRequest = this.complianceSelection.lastRequest;
    } else {
      msg.lastRequest = 0;
    }
    msg.locID = JSON.stringify(this.userDataService.locations);
    return new Promise((resolve) => {
      const now: number = Date.now();
      this.commsService.sendMessage(msg, false, true)
        .then((data) => {
          if (data.reqStatus === 'OK') {
            if (filter === undefined) {
              this.complianceInfo = data.result;
              this.complianceSelection.startTime = startTime;
              this.complianceSelection.endTime = endTime;
              this.complianceSelection.lastRequest = now;
              resolve(this.complianceInfo);
            } else {
              resolve(data.result);
            }
          } else if (data.reqStatus === 'ERROR') {
            this.complianceInfo = {};
            resolve(this.complianceInfo);
          }
        });
    });
  }
}
