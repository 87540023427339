import { ActivatedRoute } from '@angular/router';
import { ElementRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { IPopoverConfig } from '@shared/components';
import { FormBuilderService, LoadingService, PopoverService } from '@services';
import { CustomValidate } from './validation';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';

interface IDetailPopoverConfig extends IPopoverConfig {
  disable?: boolean;
}

@Injectable()
export abstract class BaseDetailsPage implements OnInit, OnDestroy {

  public messageID: string;
  public formId: string;
  public abstract title: string;
  protected copyMode: string;
  protected onFormRender: () => void;
  protected routeIdParameterName?: string;
  protected skipPopup?: boolean;
  protected customValidate: CustomValidate;
  protected addPopoverConfig?: IPopoverConfig;
  protected updatePopoverConfig: IDetailPopoverConfig = {
    title: this.translate.instant('SHARED.Update_Category'),
    description: this.translate.instant('MGMT_DETAILS.ABSTRACT_description0'),
    onSave: () => this.save()
  };
  protected duplicateErrorPopoverConfig: IPopoverConfig = {
    title: this.translate.instant('MGMT_DETAILS.ABSTRACT_title0'),
    description: this.translate.instant('MGMT_DETAILS.ABSTRACT_description2'),
    hideActions: true
  };

  protected failedErrorPopoverConfig: IPopoverConfig = {
    title: this.translate.instant('SHARED.Add_Failed'),
    description: '',
    hideActions: true
  };
  protected isRefreshed: boolean;
  protected disableBackNavigation?: boolean;
  protected abstract formConfig: any;
  protected editForm?: any;
  protected deletePopoverConfig: IDetailPopoverConfig = {
    title: this.translate.instant('SHARED.Delete_Category'),
    description: this.translate.instant('MGMT_DETAILS.ABSTRACT_description1'),
    onSave: () => {
      const formData = this.formBuilderService.getFormData($(`#${this.editForm.id}`));
      this.loadingService.enable();
      this.deleteHandler(formData).then((response) => {
        this.formBuilderService.clearFormChanges(`#${this.editForm.id}`);
        this.loadingService.disable();
        response?.backNavigationHandler ? response.backNavigationHandler() : this.location.back();
      });
    }
  };
  protected newForm?: any;
  protected disableUpdatingDataWhileBack?: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected formBuilderService: FormBuilderService,
    protected elementRef: ElementRef,
    protected popoverService: PopoverService,
    protected location: Location,
    protected loadingService: LoadingService,
    protected translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.messageID = this.route.snapshot.paramMap.get('messageID') ||
      _.get(this.route.snapshot.queryParams, 'messageID') ||
      this.route.snapshot.paramMap.get(this.routeIdParameterName);
    this.copyMode = this.route.snapshot.paramMap.get('copyMode');

    if (this.editForm && this.newForm) {
      this.formId = this.messageID && !this.copyMode ? this.editForm.id : this.newForm.id;
    }
    this.prepareFormConfig && this.prepareFormConfig();
  }

  ngOnDestroy() {
    this.destroyCustomElements();
  }

  ionViewWillEnter() {
    this.loadingService.enable();
  }

  ionViewDidEnter() {
    if (!this.isRefreshed && this.disableUpdatingDataWhileBack || !this.disableUpdatingDataWhileBack) {
      this.isRefreshed = true;
      this.customValidate = new CustomValidate(this.elementRef, this.translate, this.popoverService);
      const prepareDataResolver: Promise<void> = this.prepareDataAsync ? this.prepareDataAsync() : Promise.resolve();

      prepareDataResolver.then(() => {
        if (this.messageID && this.copyMode) {
          this.formConfig.del = null;

          const data: any = this.getData();
          this.formBuilderService.showForm(`#${this.newForm.id}`, this.formConfig, data);

          this.elementRef.nativeElement.querySelector(`#${this.newForm.cancelButton}`)
            .addEventListener('click', () => this.cancel());

          this.elementRef.nativeElement.querySelector(`#${this.newForm.saveButton}`)
            .addEventListener('click', () => this.save());

        } else if (this.messageID) {
          if (_.isUndefined(this.formConfig.enableChangeDetectionMode)) {
            this.formConfig.enableChangeDetectionMode = true;
          }
          this.failedErrorPopoverConfig.title = 'Update Failed';
          this.formConfig.hidden = {messageID: this.messageID};

          const renderForm = () => {
            const data: any = this.getData();
            this.formBuilderService.showForm(`#${this.editForm.id}`, this.formConfig, data);
            if (this.onFormRender) {
              this.onFormRender();
            }

            const sb = this.elementRef.nativeElement.querySelector(`#${this.editForm.saveButton}`);
            if (sb) {
              sb.addEventListener('click', () => {
                if (this.isValid()) {
                  if (!this.skipPopup) {
                    if (this.updatePopoverConfig.disable) {
                      this.updatePopoverConfig.onSave();
                    } else {
                      this.popoverService.show(this.updatePopoverConfig);
                    }
                  } else {
                    this.save();
                  }
                } else {
                  this.customValidate.validateFunction(this.formConfig);
                }
              });
            }

            const cb = this.elementRef.nativeElement.querySelector(`#${this.editForm.cancelButton}`);
            if (cb) {
              cb.addEventListener('click', () => {
                if (this.formConfig.enableChangeDetectionMode) {
                  const formId: string = this.messageID && !this.copyMode ? this.editForm.id : this.newForm.id;
                  if (this.formBuilderService.isFormChanged(`#${formId}`)) {
                    this.formConfig.resetForm();

                    setTimeout(() => {
                      this.customValidate.animateActivityFun(this.formConfig);
                    });
                  } else {
                    this.cancel();
                  }
                } else {
                  this.cancel();
                }
              });
            }

            if (this.editForm.deleteButton) {
              this.elementRef.nativeElement.querySelector(`#${this.editForm.deleteButton}`)
                .addEventListener('click', () => {
                  if (this.deletePopoverConfig.disable) {
                    this.deletePopoverConfig.onSave();
                  } else {
                    this.popoverService.show(this.deletePopoverConfig)
                  }
                });
            }
          };

          if (this.formConfig.enableChangeDetectionMode) {
            this.destroyCustomElements();

            this.formConfig.resetForm = async () => {
              this.onResetForm && this.onResetForm();
              await this.loadingService.enable();
              this.destroyCustomElements();
              renderForm();
              this.initInputEvents();
              this.onFinish && this.onFinish();
              await this.loadingService.disable();
            };
          }

          renderForm();
        } else {
          this.formConfig.enableChangeDetectionMode = this.formConfig.bottomControls ? true : false;
          this.formConfig.del = null;

          const data: any = this.getData();
          this.formBuilderService.showForm(`#${this.newForm.id}`, this.formConfig, data);

          this.elementRef.nativeElement.querySelector(`#${this.newForm.cancelButton}`)
            .addEventListener('click', () => this.cancel());
          if (this.newForm.saveButton) {
            this.elementRef.nativeElement.querySelector(`#${this.newForm.saveButton}`)
              .addEventListener('click', () => {
                if (this.addPopoverConfig) {
                  if (this.isValid()) {
                    this.popoverService.show(this.addPopoverConfig);
                  } else {
                    this.customValidate.validateFunction(this.formConfig);
                  }
                } else {
                  this.save();
                }
              });
          }
        }

        this.initInputEvents();
        this.onFinish && this.onFinish();
        this.loadingService.disable();
      });
    } else {
      this.loadingService.disable();
    }
  }

  public cancel(): void {
    this.location.back();
  }

  public isValid(): boolean {
    const form: any = this.messageID && !this.copyMode ? $(`#${this.editForm.id}`) : $(`#${this.newForm.id}`);
    return form.valid();
  }

  public isFormChanged(): boolean {
    const formId: string = this.messageID && !this.copyMode ? this.editForm.id : this.newForm.id;
    return this.formBuilderService.isFormChanged(`#${formId}`);
  }

  public toggleFormControls(value: boolean = true): void {
    const buttonId: string = this.messageID && !this.copyMode ? this.editForm.saveButton : this.newForm.saveButton;
    const element = this.elementRef.nativeElement.querySelector(`#${buttonId}`);
    value ? element?.classList.remove('inactive') : element?.classList.add('inactive');
  }

  protected abstract getData(): any;

  protected prepareFormConfig?(): void;

  protected deleteHandler?(formData: any);

  protected addHandler?(formData: any);

  protected updateHandler?(formData: any);

  protected refreshHandler?();

  protected prepareDataAsync?(): Promise<any>;

  protected onFinish?();

  protected onComplete?(data?: any);

  protected saveErrorHandler?(response: any);

  protected onResetForm?();

  protected save(): void {
    if (this.isValid()) {
      if (this.elementRef.nativeElement.querySelector(`#startSlideError`) != null) {
        this.elementRef.nativeElement.querySelector(`#startSlideError`).remove();
      }

      // start the spinner

      // NOTE THAT THIS ONE WILL NOT TIMEOUT ON ITS OWN
      this.loadingService.enable(null, 0);

      const formId: string = this.messageID && !this.copyMode ? this.editForm.id : this.newForm.id;
      const formData: any = this.formBuilderService.getFormData($(`#${formId}`));
      const queryHandler = this.messageID && !this.copyMode ? this.updateHandler(formData) : this.addHandler(formData);
      queryHandler
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            const result: any = _.get(data, 'result');
            this.formBuilderService.clearFormChanges(`#${formId}`);
            // just in case the update handler cleared it
            if (this.refreshHandler) {
              this.loadingService.enable();
              this.refreshHandler()
                .then(() => this.onSaveFinish(result))
                .catch(() => this.onSaveFinish());
            } else {
              this.onSaveFinish(result);
            }
          } else if (data && data.reqStatus !== 'OK') {
            // the request status was NOT okay - how should we handle it
            if (this.saveErrorHandler) {
              this.saveErrorHandler(data);
            } else if (this.duplicateErrorPopoverConfig.title) {
              this.customValidate.validateServerFunction(this.formConfig, this.duplicateErrorPopoverConfig, _.get(_.find(this.formConfig.fields, 'unique'), 'name'));
            }
            this.loadingService.disable();
          } else {
            this.failedErrorPopoverConfig.description = this.translate.instant('SHARED.ERROR_COMM') + ' ' + this.translate.instant('SHARED.Unknown');
            this.popoverService.show(this.failedErrorPopoverConfig);
            this.loadingService.disable();
          }
        }).catch((errorMessage: string) => {
        this.failedErrorPopoverConfig.description = this.translate.instant('SHARED.ERROR_COMM') + ` ${errorMessage}`;
        this.popoverService.show(this.failedErrorPopoverConfig);
        this.loadingService.disable();
      });

      this.formConfig.fields.filter((obj) => obj.required).map((obj) => {
        if (obj.type === 'text') {
          const ref = this.elementRef.nativeElement.querySelector(`input[name="${obj.name}"]`);
          if (ref && ref.value !== '') {
            ref.closest('.ui-field-contain').classList.remove('custom-error');
          }
        } else if (obj.type === 'selectmenu') {
          const ref = this.elementRef.nativeElement.querySelector(`select[name="${obj.name}"]`);
          if (ref && ref.value !== '') {
            ref.closest('.ui-field-contain').classList.remove('custom-error');
          }
        }
      });

    } else {
      this.customValidate.validateFunction(this.formConfig);
    }
  }

  protected replaceMessageIdWith(nameProperty: string, formData: any): void {
    formData[nameProperty] = this.messageID;
    delete formData.messageID;
  }

  private initInputEvents(): void {
    if (this.elementRef.nativeElement.querySelector(`textarea`) != null) {
      this.elementRef.nativeElement.querySelector(`textarea`)
        .addEventListener('keypress', (e) => {
          if (e.which === 32 && !this.elementRef.nativeElement.querySelector(`textarea`).value.length) {
            e.preventDefault();
          }
        });

      this.elementRef.nativeElement.querySelector(`textarea`)
        .addEventListener('keyup', () => {
          if (!this.elementRef.nativeElement.querySelector(`textarea`).value.trim().length) {
            this.elementRef.nativeElement.querySelector(`textarea`).value = '';
          }
        });
    }
    _.each(this.elementRef.nativeElement.querySelectorAll(`input[type="text"]`), (userItem) => {
      userItem.addEventListener('keypress', (e) => {
        if (e.which === 32 && !userItem.value.length) {
          e.preventDefault();
        }
      });
      userItem.addEventListener('keyup', () => {
        if (!userItem.value.trim().length) {
          userItem.value = '';
        }
      });
    });
  }

  private onSaveFinish(data?: any): void {
    if (!this.disableBackNavigation) {
      this.location.back();
    }
    this.onComplete && this.onComplete(data);
    this.loadingService.disable();
  }

  private destroyCustomElements() {
    if (_.find(this.formConfig.fields, {type: 'customElement'})) {
      this.formBuilderService.destroyCustomElements(`#${this.formId}`);
    }
  }

}
