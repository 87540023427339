<ion-grid>
  <ion-row (click)="locationDetail(site.locationID)" *ngFor="let site of locationsHealth" class="site-health-box">
    <ion-col class="col-first" size="3">
      <div>
        <div class="descriptor column-label">{{ "SHARED.LOCATION" | translate }}</div>
        <div title="{{site.name}}" class="dash-location-bold column-content ellipsis">{{site.name}}</div>
      </div>
    </ion-col>
    <ion-col [class.disabled]="!(modules.CONDITION | permission: site?.locationID)" class="centered">
      <div>
        <div class="descriptor column-label">{{ "DASHBOARD.SITE_HEALTH_siteLabel1" | translate }}</div>
        <div *ngIf="site.openCount && (modules.CONDITION | permission: site?.locationID)"
             class="dash-location-bold column-content">
          {{site.openCount}}
        </div>
      </div>
    </ion-col>
    <ion-col [class.disabled]="!(modules.CONDITION | permission: site?.locationID)" class="centered">
      <div>
        <div class="descriptor column-label">{{ "DASHBOARD.SITE_HEALTH_siteLabel2" | translate }}</div>
        <div *ngIf="site.avgTime && (modules.CONDITION | permission: site?.locationID)"
             class="dash-location-bold column-content">
          {{site.avgTime}}
        </div>
      </div>
    </ion-col>
    <ion-col [class.disabled]="!(modules.CONDITION | permission: site?.locationID)" class="centered risk-column-wrap">
      <div class="risk-column">
        <div class="descriptor column-label">{{ "SHARED.SITE_HEALTH_siteLabel3" | translate }}</div>
        <div *ngIf="site.riskIndex && (modules.CONDITION | permission: site?.locationID)" [ngClass]="site.riskClass"
             class="dash-location-bold column-content">
          {{site.riskIndex}}
        </div>
      </div>
    </ion-col>
    <ion-col [class.disabled]="!(modules.QUALITY | permission)" class="centered">
      <div>
        <div class="descriptor column-label">{{ "DASHBOARD.SITE_HEALTH_siteLabel4" | translate }}</div>
        <div *ngIf="site.qualityCount && (modules.QUALITY | permission)"
             class="dash-location-bold column-content">{{site.qualityCount}}</div>
      </div>
    </ion-col>
    <ion-col [class.disabled]="!(modules.PROCESS_IMPROVEMENT | permission)" class="centered">
      <div>
        <div class="descriptor column-label">{{ "DASHBOARD.SITE_HEALTH_siteLabel5" | translate }}</div>
        <div *ngIf="site.waitingTime && (modules.PROCESS_IMPROVEMENT | permission)"
             class="dash-location-bold column-content">{{site.waitingTime}}</div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
