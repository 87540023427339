import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SubscriberService, Feature, ObservationService } from '@services';
import { ObservationTypes, ObservationSubtypes } from '@services/observations/observation.interfaces';
import { TranslateService } from '@ngx-translate/core';

export interface ISubtype {
  id: string;
  description: string;
}

@Component({
  selector: 'app-create-observation-popup',
  templateUrl: './create-popup.component.html',
  styleUrls: ['./create-popup.component.scss'],
})
export class CreatePopupComponent implements OnInit {
  @Input() observationType: ObservationTypes;

  public observationSubtype = '';

  public subtypes: ISubtype[] = [];

  constructor(private popOver: PopoverController,
              private subscriber: SubscriberService,
              private observationService: ObservationService,
              private translate: TranslateService,
              private router: Router) {
  }

  ngOnInit() {
    // look at the observation type to make a list of subtypes
    if (this.observationType === ObservationTypes.Quality) {
      // what quality options are supported
      if (this.subscriber.usesFeature(Feature.QUALITY_PRODUCTION)) {
        this.subtypes.push({
          id: ObservationSubtypes.QualityProduction,
          description: this.observationService.getTitle(ObservationTypes.Quality, ObservationSubtypes.QualityProduction)
        });
      }
      if (this.subscriber.usesFeature(Feature.QUALITY_RECEIVING)) {
        this.subtypes.push({
          id: ObservationSubtypes.QualityReceiving,
          description: this.observationService.getTitle(ObservationTypes.Quality, ObservationSubtypes.QualityReceiving)
        });
      }
      // there is a subtype that will help with this
      if (this.subscriber.usesFeature(Feature.QUALITY_RMA)) {
        this.subtypes.push({
          id: ObservationSubtypes.QualityRMA,
          description: this.observationService.getTitle(ObservationTypes.Quality, ObservationSubtypes.QualityRMA)
        });
      }
      if (this.subtypes.length === 1) {
        this.observationSubtype = this.subtypes[0].id;
        this.startCreate();
      }
    }
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public isFormInvalid(): boolean {
    return this.observationSubtype === '';
  }

  public selectSubtype(changeEvent: CustomEvent): void {
    if (changeEvent?.detail?.value) {
      this.observationSubtype = changeEvent.detail.value;
    } else {
      this.observationSubtype = '';
    }
  }

  startCreate() {
    this.popOver.dismiss();
    // which subtype is selected
    this.router.navigate([`/pages/dashboard/create-observation`, { type: this.observationType, subtype: this.observationSubtype}]);
  }


}
