import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DynamicComponentCreatorService, FeedbackService, TableService, UserService } from '@services';
import { TimeAgoComponent } from '@shared/components';

import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-opportunity-feedback-requests',
  templateUrl: './feedback-responses.component.html',
  styleUrls: ['./feedback-responses.component.scss'],
})
export class OpportunityFeedbackRequestsComponent {

  public tableId: string = uuid.v4();
  public data: any[] = [];

  private tableAttr = {
    rowvaluefrom: 'requestID',
    columns: [
      {
        id: 'question',
        title: 'SHARED.Question',
        func: (title, row) => this.userService.translateItem(row, 'question'),
        headerClass: 'table-header'
      },
      {
        id: 'lastUpdate',
        title: 'MGMT_LIST.LAST_ACTIVITY',
        cellprop: 'data-order',
        cellval: 'lastUpdate',
        func: (val) => this.dynamicComponentCreatorService.create(TimeAgoComponent, (componentInstance: TimeAgoComponent) => {
          componentInstance.time = val;
        }),
        headerClass: 'table-header',
        nosort: true
      },
      {
        id: 'replies',
        title: '',
        func: (replies, row) => {
          let htmlOutput: JQuery<HTMLElement> | string = `<div>${this.translate.instant('SHARED.No_Responses')}</div>`;

          if (replies?.length) {
            htmlOutput = $(`<div class="row-link">${this.translate.instant('SHARED.View_Responses')} (${replies.length})</div>`);
            htmlOutput.on('click', () => {
              this.router.navigate([`/pages/dashboard/opportunities/view-responses/${row.requestID}`]);
            });
          }

          return htmlOutput;
        },
        headerClass: 'table-header'
      }
    ]
  };

  private tableDef = {
    scrollX: false,
    paging: false,
    searching: false,
    info: false,
    stateSave: true,
    scrollY: false,
    scrollCollapse: true,
    order: [[1, 'desc']],
    disableAutoResizeHandler: true,
    columnDefs: [
      {targets: [0, 1, 2], orderable: false},
      {targets: 0, width: '60%'},
      {targets: 1, width: '20%'},
      {targets: 2, width: '20%'}
    ]
  };
  private theType = 'observation';
  private theID: number;

  constructor(
    private dynamicComponentCreatorService: DynamicComponentCreatorService,
    private translate: TranslateService,
    private tableService: TableService,
    private feedbackService: FeedbackService,
    private router: Router,
    private userService: UserService
  ) {
  }

  @Input() set parentType(type: string) {
    if (type) {
      this.theType = type;
    }
  }

  @Input() set parentID(id: number) {
    if (id) {
      this.theID = id;
      this.initTable();
    }
  }

  public initTable() {
    if (this.theID) {
      this.data = this.feedbackService.getFeedbackByParentId(this.theID, this.theType);

      if (this.data.length) {
        setTimeout(() => {
          this.tableService.showTable(`#${this.tableId}`, this.tableAttr, this.data, this.tableDef);
        });
      }
    }
  }

}
