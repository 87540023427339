import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordPolicyService extends BaseService {

  policy: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private events: Events
  ) {
    super(commsService);
  }

  public async getPolicy() {
    return await this.policy.data;
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.policy.lastRequest) {
      this.logger.log(`local password policy cache already up to date: ${updated}, ${this.policy.lastRequest}`);
      return Promise.resolve(this.policy.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getPasswordPolicy',
          lastRequest: this.policy.lastRequest,
          lastHash: this.policy.lastHash,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
            this.policy.lastHash = data.result.datahash;
          }

          resolve(this.policy.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public clearCache() {
    this.policy.lastHash = null;
    this.policy.lastRequest = null;
    this.policy.data = null;
  }

  public updateCache(data) {
    this.policy.lastRequest = data.result.timestamp;
    this.policy.data = data.result.policy ? data.result.policy : {length: '0'};
    this.events.publish('ccs:policyUpdate');
  }

  public handleUpdatePolicy(fData) {
    delete fData.messageID;
    const requestObject: any = {
      cmd: 'setPasswordPolicy',
      sendTime: Date.now(),
      policy: JSON.stringify(fData)
    };
    return this.handleRequest(requestObject);
  }
}
