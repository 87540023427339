import { ElementRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { MessagesService, TableService, UserdataService, UtilsService } from '@services';

import * as _ from 'lodash';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  @Output() onClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  private tableAttr = {
    class: 'viewNotification',
    lookupFunc: (id) => this.messagesService.getMessage(id),
    checkbox: true,
    rowprop: 'data-mid',
    rowvaluefrom: 'messageID',
    columns: [
      {
        id: 'created',
        title: this.translate.instant('COMMUNICATIONS.Notifications_SENT'),
        fromProperty: 'created',
        func: (unixTime: any, none: any) => this.utils.dateTimeFormat(unixTime, none),
        cellprop: 'data-order',
        cellval: 'created',
        headerClass: 'table-header'
      },
      {
        id: 'message',
        title: this.translate.instant('SHARED.Messages_MESSAGE'),
        headerClass: 'table-header',
        class: 'markdown',
        func: (message, ref) => {
          const ret = this.markdown.compile(message);
          return ret;
        }
      }
    ],
    onClickRow: (id): void => {
      this.onClicked.emit(id);

      this.menu.isEnabled('notification').then((isEnabled: boolean) => {
        if (!isEnabled) {
          this.menu.enable(true, 'notification');
        }
        this.menu.toggle('notification');
      });
    },
    onChecked: () => this.emitSelectedNotifications(),
    onCheckedAll: () => this.emitSelectedNotifications(),
    getTable: () => $('#messagesNotificationsTable').DataTable(),
    rowClassFunc: (message) => {
      const isUnread = this.messagesService.isUnreadThread(message.messageID, this.userData.userID);
      return isUnread ? 'unread-class' : 'read-class';
    },
  };
  private elementRef: ElementRef = new ElementRef(document);

  constructor(
    private tableService: TableService,
    private messagesService: MessagesService,
    private utils: UtilsService,
    private menu: MenuController,
    private translate: TranslateService,
    private userData: UserdataService,
    private markdown: MarkdownService
  ) {
  }

  ngOnInit() {
    this.update();
  }

  public update(): void {
    if ($('#messagesNotificationsTable thead').length) {
      $(`#messagesNotificationsTable`).DataTable().destroy();
    }

    this.tableService.showTable(`#messagesNotificationsTable`, this.tableAttr, this.messagesService.notifications);
    if ($('#messagesNotificationsTable thead').length) {
      const table: any = $('#messagesNotificationsTable').DataTable(<any>{
        scrollCollapse: true,
        language: {
          searchPlaceholder: this.translate.instant('SHARED.Search'),
          search: '',
          emptyTable: this.translate.instant('SHARED.emptyTable'),
          sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
        },
        scrollX: true,
        paging: false,
        searching: true,
        info: false,
        retrieve: true,
        stateSave: true, columnDefs: [{
          orderable: false,
          className: 'select-checkbox',
          targets: 0,
          width: '20px'
        }],
        select: {
          style: 'multi',
          selector: 'td:first-child'
        },
        order: [[1, 'desc']],
        dom: 'frtip'
      });

      $('#messagesNotificationsTable').width('100%');

      table.columns.adjust().draw(false);
      this.tableService.handleResize();
    }
    $('#messagesNotificationsTable_filter input').addClass('messagesNotificationsTable_searchinput');
    this.elementRef.nativeElement.querySelector('#messagesNotificationsTable_filter input').addEventListener('input', _.debounce((event) => {
      setTimeout(() => {
        const searchValue = this.tableService.quoteEncoding(event.target.value);
        $('#search_close_messagesNotificationsTable_filter').remove();
        if (searchValue) {
          this.elementRef.nativeElement.querySelector('#messagesNotificationsTable_filter input').insertAdjacentHTML('afterend', `<ion-icon name="close-outline" id="search_close_messagesNotificationsTable_filter" class="custom_close_icon"></ion-icon>`);
          this.elementRef.nativeElement.querySelector('#search_close_messagesNotificationsTable_filter').addEventListener('click', (event) => {
            setTimeout(() => {
              this.clearSearch(event);
            });
          }, 300);
        } else {
          $('#search_close_messagesNotificationsTable_filter').remove();
        }
      });
    }, 300));
  }

  public initSizeTable(): void {
    window.setTimeout(() => {
      $('#messagesNotificationsTable').DataTable().columns.adjust().draw(false);
      this.tableService.handleResize();
    });
  }

  clearSearch(e) {
    $('#messagesNotificationsTable').DataTable().search('').columns().search('').draw();
    this.update();
  }

  private emitSelectedNotifications(): void {
    let ids: number[] = [];
    if ($('#messagesNotificationsTable .selected').length) {
      ids = _.map($('#messagesNotificationsTable .selected'), (selectedElement: any) => +selectedElement.getAttribute('data-mid'));
    }
    this.onSelected.emit(<number[]>_.filter(ids, Boolean));
  }
}
