<ion-header class="modal-page" no-border>
</ion-header>

<ion-content>
  <div class='obtain-consent-content'>
    <div class='page-title'>
      Consent Form
    </div>
    <div class="body-copy text-left">
      <div [innerHTML]='theForm | markdown' class='theForm'></div>
    </div>

    <div class="ui-form-controls ui-grid-a">
      <a (click)="confirmConsent()" class="operation ui-btn button-styled button-taller" color="secondary">{{
        'SHARED.I_Agree' | translate }}</a>
      <a (click)="declineConsent()" class="bg-neutral operation ui-btn button-styled button-taller" color="secondary">{{
        'SHARED.I_Disagree' | translate }}</a>
    </div>
  </div>

</ion-content>
