import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Events } from '@services/events/events.service';
import {
  AccountsService,
  CollectionsService,
  LoadingService,
  PoolService,
  UserService,
  UtilsService
} from '@services';
import { DetailFooterComponent } from '@modules/observation/components';
import { CloseButtonComponent } from '@shared/components';
import { CollectionItemType, ICollectionItem } from '@services/collections/collections.service';
import { OpportunityFeedbackRequestsComponent } from '@modules/observation/pages/insights/pages/detail/components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-external-data-detail',
  templateUrl: './external-data-detail.page.html',
  styleUrls: ['./external-data-detail.page.scss'],
})
export class ExternalDataDetailPage {

  @ViewChild(DetailFooterComponent) detailFooterComponent: DetailFooterComponent;
  @ViewChild(CloseButtonComponent) closeButtonComponent: CloseButtonComponent;
  @ViewChild(OpportunityFeedbackRequestsComponent) opportunityFeedbackRequestsComponent: OpportunityFeedbackRequestsComponent;
  public footerData: any;
  public responsesData: any;
  public fid: number = null;
  public poolItemID: string = null;
  public tagsConfig = {
    ids: [],
    removeHandler: (ids) => {
      const reqObject: any = {
        tags: ids,
        replace: 1,
        items: JSON.stringify([this.poolItemID])
      };
      this.tagsConfig.ids = ids;
      return this.poolService.handleTagItems(reqObject);
    }
  };
  public headerData: any = {
    buttonPanel: true,
    hideNumber: true,
    buttons: [{
      name: 'TOPICS.Add_Tag',
      color: 'secondary',
      class: 'page-button',
      click: () => this.router.navigate([`/pages/management/topics/add-tags/${this.poolItemID}`], {
        queryParams: {
          type: 'poolItem',
          tags: JSON.stringify(this.tagsConfig.ids)
        }
      })
    },
      {
        name: 'TOPICS.Get_Feedback',
        color: 'secondary',
        class: 'page-button',
        module: 'inquiries',
        click: () => {
          this.router.navigate(['/pages/dashboard/feedback/get-feedback'], {
            queryParams: {
              parentID: this.poolItemID,
              parentType: 'externalData'
            }
          });
        }
      },
      {
        name: 'TOPICS.Create_Add_Topic',
        color: 'secondary',
        class: 'page-button',
        click: () => {
          const colItems: ICollectionItem[] = [
            {
              item: this.poolItemID,
              type: CollectionItemType.ExternalItem,
            }
          ];
          this.collectionsService.itemCollection = colItems;
          this.router.navigate(['pages/management/add-topic']);
        },
      },
    ]
  };
  public notes: string = null;
  public poolItem: any;
  public reference = '';
  public closingData: any = {};
  public itemState: string;
  public backNavigateUrl: string;

  constructor(
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private events: Events,
    private poolService: PoolService,
    private router: Router,
    private utils: UtilsService,
    private accountService: AccountsService,
    private userService: UserService,
    private collectionsService: CollectionsService,
    private translate: TranslateService
  ) {
  }

  ionViewDidEnter() {
    this.updateFeedbackData();
    this.events.subscribe('ccs:feedbackUpdate', this.updateFeedbackComp);
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:feedbackUpdate', this.updateFeedbackComp);
  }

  public async updateFeedbackData() {
    const collectionId = this.activatedRoute.snapshot.queryParamMap.get('collectionId') || 0;
    const collectionItemType = this.activatedRoute.snapshot.queryParamMap.get('collectionItemType');
    this.poolItemID = this.activatedRoute.snapshot.paramMap.get('id');
    this.getTopComponentData(this.poolItemID);

    if (collectionItemType || collectionId) {
      this.footerData = await this.collectionsService.getFooterData(+this.poolItemID, +collectionId, collectionItemType);
      this.backNavigateUrl = this.footerData?.nav;
    }

    this.loadingService.disable();
  }

  public back() {
    this.footerData ? this.detailFooterComponent.openMainPage() : this.closeButtonComponent.back();
  }

  public updateFeedbackComp = () => {
    this.opportunityFeedbackRequestsComponent.initTable();
  };

  navTOref() {
    window.open(this.reference, '_blank');
  }

  private async getTopComponentData(poolItemID) {
    // const poolObject = await this.poolService.handleGetPoolItems
    console.log(poolItemID);
    // let poolDetail = poolItemID.split(':');
    const response = await this.poolService.handleGetPoolItems([poolItemID]);
    if (response.reqStatus === 'OK') {
      this.poolItem = response.result.items[0];
      this.reference = this.poolItem.reference;
      this.notes = this.poolItem.notes;
      this.headerData.title = this.poolService.getPoolByID(this.poolItem.poolID).title
        + ' ' + this.translate.instant('SHARED.Detail');
      this.headerData.oid = this.poolItemID;
      this.headerData.uuid = this.poolItemID;
      this.tagsConfig.ids = this.poolItem.tagIDs;
      let locname = '';
      if (this.userService.findLocation(this.poolItem.locationID)) {
        locname += this.userService.findLocation(this.poolItem.locationID).name;
      } else {
        locname = 'N/A';
      }
      this.headerData.headerElements = [
        {name: 'SHARED.Response_ID', data: this.poolItem.uniqueID},
        {name: 'SHARED.When', data: this.utils.dateTimeFormat(this.poolItem.timeStamp)},
        {name: 'SHARED.Who', data: this.accountService.fullname(this.poolItem.userID)},
        {name: 'SHARED.Where', data: locname},
      ];
      // this.headerData = this.feedbackDetailService.getTopComponentData(this.uuid);
      this.opportunityFeedbackRequestsComponent.initTable();
    }
  }
}

