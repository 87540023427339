import Dexie, { Table } from 'dexie';
import { Injectable } from '@angular/core';
import { DBVersion, TableDefs } from '../../db.consts';
import { WebWorkerService } from '@services/web-worker/web-worker.service';
import { awaitHandler } from '@utils/awaitHandler';
import { NGXLogger } from 'ngx-logger';

const dbPrefix = `corvex-`;

@Injectable({
  providedIn: 'root'
})
export class LocalDBService {
  // updateTimes!: Table<IUpdateTime, string>;
  // observations!: Table<IObservationItem, number>;
  // disclaimers!: Table<IDisclaimer, number>;
  // properties!: Table<IProperty, number>;
  // responses!: Table<IResponse, string>;

  public db: Dexie;

  constructor(
    private worker: WebWorkerService,
    private logger: NGXLogger
  ) {}

  public initialize(subscriberID: number, userid: number | string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.db) {
        await this.db.close();
        this.db = null;
      }

      const dbName = `${dbPrefix}-${subscriberID}-${userid}`;
      // try to initialize the web worker - do it first in case it was connected to the old DB
      const [res, err] = await awaitHandler(this.worker.initDB(dbName));
      if (err) {
        this.logger.error(`failed to initialize worker db connection: ${err}`);
      }

      this.logger.debug(`opening connection to database ${dbName}`);
      this.db = new Dexie(dbName);

      this.db.version(DBVersion).stores(TableDefs);
      // block until we KNOW the datayase is ready
      this.db.on('ready', () => {
        this.logger.debug('database reports ready');
        resolve();
      });
      this.db.open();
    });
  }


  public table(tableName: string): Table {
    return this.db.table(tableName);
  }

}
