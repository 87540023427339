<ion-header class="modal-page" no-border>
</ion-header>

<ion-content class="custom-inputs">
  <div class="medium">
    <div class="page-title">Single Sign On Login Handler</div>
    <p>
      This page will close automatically.
    </p>
  </div>
</ion-content>
