import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MenuController } from '@ionic/angular';

import {
  IListMenuConfig,
  IListMenuSelected,
  ListMenuComponent,
  ListMenuSelectMode,
  ListMenuType,
  ListMenuViewType
} from '@shared/components';
import { AccountTypes, Permission, UserdataService } from '@services';

import * as _ from 'lodash';

@Component({
  selector: 'app-new-message-menu',
  templateUrl: './new-message-menu.component.html',
  styleUrls: ['./new-message-menu.component.scss'],
})
export class NewMessageMenuComponent {

  public listConfig: IListMenuConfig = {
    type: ListMenuViewType.MultipleUserTeam,
    selectMode: ListMenuSelectMode.Multiple,
    excludeAccounts: [AccountTypes.Reporting, AccountTypes.Viewer, AccountTypes.Observer, AccountTypes.Observation],
    isLoggedUserExcluded: true,
    excludePermissions: _.get(this.userDataService.Permissions, Permission.Corvex) === 1 ? [] : [Permission.Corvex]
  };

  @ViewChild(ListMenuComponent) listMenuComponent: ListMenuComponent;

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();

  private selectedIds: any = {
    [ListMenuType.User]: [],
    [ListMenuType.Team]: []
  };
  private currentTab: ListMenuType = ListMenuType.User;

  constructor(
    private menu: MenuController,
    private userDataService: UserdataService
  ) {
  }

  public close(): Promise<boolean> {
    return this.menu.close('userSelection');
  }

  public onSelect(selection: IListMenuSelected): void {
    this.selectedIds[selection.type] = selection.items;
  }

  public async createChat() {
    await this.close();

    const params = {
      type: this.currentTab,
      ids: this.currentTab === ListMenuType.Team ? this.selectedIds[ListMenuType.Team] : this.selectedIds[ListMenuType.User]
    };

    this.onSelected.emit(params);
  }

  public isSaveAvailable(): boolean {
    return (this.currentTab === ListMenuType.Team && this.selectedIds[ListMenuType.Team].length)
      || (this.currentTab === ListMenuType.User && this.selectedIds[ListMenuType.User].length);
  }

  public reset(): void {
    this.listMenuComponent.reset();
    this.selectedIds = {
      [ListMenuType.User]: [],
      [ListMenuType.Team]: []
    };
  }

  public onChangeTab(tab: ListMenuType): void {
    this.currentTab = tab;
  }

}
