import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderService, LoadingService, ObservationService, PopupObservationService } from '@services';
import { Location } from '@angular/common';
import { ObservationImageGalleryComponent } from '@modules/observation/components/image-gallery/image-gallery.component';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { ObservationFlow, ObservationSubtypes, ObservationTypes } from '@services/observations/observation.interfaces';
import { CustomValidate } from '@modules/management/pages/details/abstractDetails/validation';

@Component({
  selector: 'app-mark-fixed',
  templateUrl: './mark-fixed.page.html',
  styleUrls: ['./mark-fixed.page.scss'],
})
export class MarkFixedPage {

  public title: string = this.translate.instant('SHARED.Mark_As_Fixed');
  public formId = 'fixObservationForm';
  protected formConfig: any = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: 'fixObservation',
    del: null,
    // save: this.translate.instant('SHARED.Mark_As_Fixed'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
    ]
  };
  private theObservation: any;
  private observationID: number;
  private formData: any = {
    note: '',
    attachments: []
  };
  private saveButtonId = 'fixObservationSave';
  private extraButtonId = 'fixObservationExtraControl';
  private cancelButtonId = 'fixObservationCancel';
  private customValidate;

  constructor(private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private formBuilderService: FormBuilderService,
              private elementRef: ElementRef,
              private location: Location,
              private popupObservationService: PopupObservationService,
              private loadingService: LoadingService,
              private router: Router,
              private observation: ObservationService,
              private obsDetail: ObservationDetailService) {
  }


  ionViewWillEnter() {
    this.observationID = +this.activatedRoute.snapshot.queryParamMap.get('id');
    this.buildForm();
  }
  ionViewDidEnter() {
    this.observationID = +this.activatedRoute.snapshot.queryParamMap.get('id');
    //alert(this.observationID);
    this.customValidate = new CustomValidate(this.elementRef, this.translate);
    this.formBuilderService.showForm(`#${this.formId}`, this.formConfig, this.formData);
    this.elementRef.nativeElement.querySelector(`#${this.cancelButtonId}`)
      .addEventListener('click', () => {
        if (this.isFormChanged()) {
          this.buildForm();
        } else {
          this.cancel();
        }
      });
    const sb = this.elementRef.nativeElement.querySelector(`#${this.saveButtonId}`);
    if (sb) {
      sb.addEventListener('click', () => {
        if (this.isValid()) {
          this.update();
        } else {
          this.customValidate.validateFunction(this.formConfig);
        }
      });
    }
    const eb = this.elementRef.nativeElement.querySelector(`#${this.extraButtonId}`);
    if (eb) {
      eb.addEventListener('click', () => {
        if (this.isValid()) {
          this.update('dropped');
        } else {
          this.customValidate.validateFunction(this.formConfig);
        }
      });
    }
  }

  public isFormChanged(): boolean {
    return this.formBuilderService.isFormChanged(`#${this.formId}`);
  }

  public isValid(): boolean {
    const form: any = $(`#${this.formId}`);
    return form.valid();
  }

  private buildForm(): void {
    this.theObservation = this.observation.getObservationById(this.observationID);
    const settings = this.observation.getSettingsByID(this.observationID);
    const fields = [];
    this.formConfig.save = this.translate.instant('SHARED.Mark_As_Fixed');
    if (this.theObservation) {
      if ( !settings?.flow?.fixPicture || settings?.flow?.fixPicture !== ObservationFlow.Disabled) {
        const required = settings?.flow?.fixPicture === ObservationFlow.Required ? true : false;
        // this should be conditional
        fields.push(
          {
            containerClass: 'image-gallery',
            title: 'DASHPAGES.Creation_Add_Photo',
            name: 'attachments',
            type: 'customElement',
            required,
            component: ObservationImageGalleryComponent,
            inputs: {
              observationId: this.observationID,
              subtype: 'fixed'
            },
          }
        );
      }
      if (!settings?.flow?.fixNote || settings?.flow?.fixNotes !== ObservationFlow.Disabled) {
        const required = settings?.flow?.fixNotes === ObservationFlow.Required ? true : false;
        fields.push(
          {
            containerClass: 'observation-note',
            title: 'SHARED.Fix_Notes',
            name: 'note',
            required,
            type: 'textarea',
            maxlength: 1980,
            size: 20
          },
        );
      }
      if (this.theObservation.type === ObservationTypes.Quality && this.theObservation.subtype === ObservationSubtypes.QualityProduction) {
        // this is a production issue; it has different fix buttons that do different things
        let added = false;
        if (settings?.flow?.fixScrap === ObservationFlow.Required) {
          // add a scrap button
          this.formConfig.extraControl = 'SHARED.Scrap_Product';
          added = true;
        } else {
          this.formConfig.extraControl = null;
        }
        if (settings?.flow?.fixRepair === ObservationFlow.Required) {
          // add a Repair button
          this.formConfig.save = 'SHARED.Repair_Product';
          added = true;
        } else {
          this.formConfig.save = null;
        }
        if (!added) {
          this.formConfig.extraControl = null;
        }
      }
      this.formConfig.fields = fields;
    }
  }

  private update(action: string = 'fixed'): void {
    // this.popoverService.show(this.popoverUpdateConfig);

    const formData: any = this.formBuilderService.getFormData($(`#${this.formId}`));
    const requestObject: any = {
      cmd: 'updateObservation',
      observationID: this.observationID,
      state: action,
      note: JSON.stringify({
        type: 'text',
        value: formData.note,
        subtype: 'fixed'
      }),
      attachments: JSON.stringify(formData.attachments)
    };
    this.loadingService.enable().then(() => {
      this.popupObservationService.closeObs(requestObject).then((data: any) => {
        this.loadingService.disable();
        this.location.back();
      }
      ).catch(err => {
        this.loadingService.disable();
      });
    });
  }

  private cancel(): void {
    this.location.back();
  }

}
