import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { IQuestionFormGroup, QuestionFormGroupComponent } from './../question-form-group/question-form-group.component';
import { BulkOptionsModalComponent } from './../bulk-options-modal/bulk-options-modal.component';
import { FormBuilderService } from '@services';

import * as _ from 'lodash';

@Component({
  selector: 'app-question-form-group-list',
  templateUrl: './question-form-group-list.component.html',
  styleUrls: ['./question-form-group-list.component.scss'],
})
export class QuestionFormGroupListComponent implements OnInit {

  @ViewChildren(QuestionFormGroupComponent) questionFormGroupComponentList: QueryList<QuestionFormGroupComponent>;

  @Output() onNewGroup: EventEmitter<void> = new EventEmitter();
  @Output() onFinish: EventEmitter<void> = new EventEmitter();
  @Output() onReorder: EventEmitter<void> = new EventEmitter();
  @Output() onItemRemoval: EventEmitter<void> = new EventEmitter();

  public addButtonName = 'Add';
  public title = 'Group';
  public disableNewGroup: boolean;
  public disableReorder: boolean;
  public enableRemoval: boolean;
  public enableEmptyList: boolean;
  public enableBulkAdding: boolean;
  public isReorderAvailable: boolean;
  public isRemovalAvailable: boolean;
  public groups: IQuestionFormGroup[] = [];
  public formConfig: any;

  constructor(
    private formBuilderService: FormBuilderService,
    private popoverController: PopoverController
  ) {
  }

  ngOnInit() {
    this.defineGroups();
    this.checkGroupActionsAvailability();
  }

  public getFormListData(): any[] {
    return _.filter(_.map(this.groups, (group) => {
      const formComponent: QuestionFormGroupComponent = this.questionFormGroupComponentList.find((component) => component.group.formConfig.prefix === group.formConfig.prefix);

      const formElement: JQuery<Element> = $(formComponent.formElement.nativeElement);
      const formData: any = this.formBuilderService.getFormData(formElement);
      const optionID = _.get(formComponent, 'group.formData.optionID');

      if (optionID) {
        formData.optionID = optionID;
      }

      return formData;
    }));
  }

  public addGroup(silent?: boolean) {
    this.groups.push(this.createGroupConfig());
    this.checkGroupActionsAvailability();

    if (!silent) {
      this.onNewGroup.emit();
    }
  }

  public onItemReorder(event: CustomEvent) {
    const draggedGroup = this.groups.splice(event.detail.from, 1)[0];
    this.groups.splice(event.detail.to, 0, draggedGroup);
    event.detail.complete();
    this.onReorder.emit();
  }

  public getGroupElementRefByFormId(id: string) {
    const groupComponent = this.questionFormGroupComponentList.find((questionFormGroupComponent: QuestionFormGroupComponent) => questionFormGroupComponent.formId === id);

    return _.get(groupComponent, 'elementRef');
  }

  public onGroupFinish(index: number) {
    this.groups[index].finished = true;

    if (_.every(this.groups, 'finished')) {
      this.onFinish.emit();
    }
  }

  public removeItemByIndex(index: number) {
    this.groups.splice(index, 1);
    this.onItemRemoval.emit();
    this.checkGroupActionsAvailability();

    _.each(this.groups, (group: IQuestionFormGroup, index: number) => {
      group.title = `${this.title} ${index + 1}`;
    });
  }

  public showBulkAdding() {
    this.popoverController.create(<any>{
      component: BulkOptionsModalComponent,
      animated: false,
      componentProps: {
        selectedData: _.filter(_.map(this.getFormListData(), 'label'))
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event) => {
        if (event.data) {
          _.each(event.data, (label) => this.groups.push(this.createGroupConfig({label})));
          this.checkGroupActionsAvailability();
          this.onNewGroup.emit();
        }
      });
    });
  }

  private defineGroups(): void {
    if (_.isEmpty(this.groups) && !this.enableEmptyList) {
      this.addGroup(true);
    }
  }

  private checkGroupActionsAvailability() {
    this.checkReorderAvailability();
    this.checkRemovalAvailability();
  }

  private checkReorderAvailability(): void {
    this.isReorderAvailable = this.groups.length > 1 && !this.disableReorder;
  }

  private checkRemovalAvailability(): void {
    this.isRemovalAvailable = (this.groups.length > 1 || this.enableEmptyList) && this.enableRemoval;
  }

  private createGroupConfig(formData = {}, index: number = this.groups.length) {
    return {
      title: `${this.title} ${index + 1}`,
      formConfig: _.clone(this.formConfig),
      formData
    };
  }

}
