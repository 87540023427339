import { Component, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'report-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss']
})
export class ModalFormComponent {

  @Output() onSaveReport = new EventEmitter();

  @Input() formConfig;
  @Input() newForm;
  @Input() editForm;
  @Input() reportID;
  @Input() onEvent: (event) => void;

  public title: string = this.translate.instant('REPORTING.Save_Report_As');

  constructor(private modalController: ModalController, private translate: TranslateService) {
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalController.dismiss();
  }
}
