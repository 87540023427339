import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import {
  NoteListComponent,
  ObservationNotesComponent,
  OpportunityFeedbackRequestsComponent,
  SubmissionNoteComponent
} from './';
import { ObservationComponentsModule } from '@modules/observation/components/components.module';

import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
  NoteListComponent,
  ObservationNotesComponent,
  SubmissionNoteComponent,
  OpportunityFeedbackRequestsComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    ObservationComponentsModule
  ]
})
export class ObservationDetailComponentsModule {
}
