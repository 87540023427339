import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services/question/question-form.service';
import { AbstractFieldsModel } from '@modules/management/pages/details/check/models/abstractFieldsModel';
import { QuestionFormGroupListComponent } from '@modules/management/pages/details/check/components';
import { FormBuilderService } from '@services';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export class IncrementEntryFieldsModel extends AbstractFieldsModel {

  protected formConfig: any = {
    containers: true,
    prefix: 'incrementEntry',
    fields: [
      {
        containerClass: 'question-field',
        name: 'units',
        title: 'MGMT_DETAILS.Units',
        type: 'selectmenu',
        multiple: false,
        searchable: false,
        canClear: false,
        originalOrder: true,
        default: 'kilograms',
        options: [
          {id: 'inches', description: this.translate.instant('MGMT_DETAILS.Inches')},
          {id: 'feet', description: this.translate.instant('MGMT_DETAILS.Feet')},
          {id: 'mm', description: this.translate.instant('MGMT_DETAILS.MM')},
          {id: 'cm', description: this.translate.instant('MGMT_DETAILS.CM')},
          {id: 'meters', description: this.translate.instant('MGMT_DETAILS.Meters')},
          // { id: 'ounces', description: this.translate.instant('MGMT_DETAILS.Ounces') },
          // { id: 'pounds', description: this.translate.instant('MGMT_DETAILS.Pounds') },
          {id: 'grams', description: this.translate.instant('MGMT_DETAILS.Grams')},
          {id: 'kilograms', description: this.translate.instant('MGMT_DETAILS.Kilograms')},
          // { id: 'fluidOunces', description: this.translate.instant('MGMT_DETAILS.FluidOunces') },
          // { id: 'gallons', description: this.translate.instant('MGMT_DETAILS.Gallons') },
          {id: 'milliliters', description: this.translate.instant('MGMT_DETAILS.Milliliters')},
          {id: 'liters', description: this.translate.instant('MGMT_DETAILS.Liters')},
          {id: 'degrees', description: this.translate.instant('MGMT_DETAILS.Degrees')}
        ],
        onChange: (value: any, $form: JQuery) => {
          if (_.includes(['inches', 'feet'], value)) {
            this.updateFractionIncrementFieldByType(value);
          } else {
            this.syncStartValueFieldStep(false);
          }

          this.markFieldAsChanged($form.attr('id'), 'units', value);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['fraction-increment'],
              valueFunction: (val) => !_.includes(['inches', 'feet'], val)
            },
            {
              hideClasses: ['increment'],
              valueFunction: (val) => _.includes(['inches', 'feet'], val)
            }
          ]
        }
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'startValue',
        title: 'MGMT_DETAILS.Start_Value',
        type: 'number',
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field increment',
        inputtype: 'verbatim',
        name: 'increment',
        title: 'MGMT_DETAILS.Increment',
        type: 'number',
        min: 0,
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field fraction-increment',
        name: 'fractionIncrement',
        title: 'MGMT_DETAILS.Increment',
        type: 'selectmenu',
        multiple: false,
        searchable: false,
        canClear: false,
        originalOrder: true,
        options: [],
        required: true,
        onChange: (value: string) => this.applyFractionIncrementChanges(value)
      }
    ]
  };
  private fields = [
    {
      containerClass: 'question-type-field incrementEntry',
      title: 'MGMT_DETAILS.Increment_Entry_Details',
      type: 'subtitle'
    },
    {
      containerClass: 'question-type-field incrementEntry',
      title: 'MGMT_DETAILS.Increment_Values',
      type: 'subtitle'
    },
    {
      type: 'subtitle',
      containerClass: 'question-type-field incrementEntry form-group-title increment-entry-block'
    }
  ];

  constructor(
    protected questionFormService: QuestionFormService,
    private translate: TranslateService,
    private formBuilderService: FormBuilderService
  ) {
    super(questionFormService);
  }

  public getFields(): any[] {
    return this.fields;
  }

  public getData() {
    const settingsFields: string[] = [
      'units',
      'startValue',
      'increment',
      'fractionIncrement'
    ];

    return this.getFormData(settingsFields);
  }

  public showGroupList() {
    if (this.questionFormService.isModelAvailable('incrementEntry')) {
      const groupElement = this.questionFormService.getElementBy('.increment-entry-block');

      if (groupElement && !this.formGroupComponentRef) {
        const settings = _.get(_.first(this.questionFormService.questionPage.questionOptions), 'settings') || {};
        if (_.includes(['inches', 'feet'], settings.units)) {
          setTimeout(() => {
            this.updateFractionIncrementFieldByType(settings.units, settings.fractionIncrement);
          }, 500);
        }

        this.questionFormService.dynamicComponentCreatorService.create(
          QuestionFormGroupListComponent,
          (component, componentRef) => this.onFormGroupComponentInit(component, componentRef),
          groupElement
        );
      } else {
        const unitsField = _.find(this.formConfig.fields, {name: 'units'});
        this.formBuilderService.hideFieldsOnValue(unitsField, this.formConfig, {});
      }
    }
  }

  public onDestroy() {
    this.formGroupComponentRef && this.formGroupComponentRef.destroy();
    this.formGroupComponentRef = null;
  }

  private onFormGroupComponentInit(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    this.onFormGroupComponentCreate(component, componentRef);

    const option = _.first(_.values(this.questionFormService.questionPage.questionOptions)) || {};
    this.formGroupComponent.disableNewGroup = true;

    this.formGroupComponent.groups = [{
      formConfig: this.formConfig,
      formData: option
    }];
  }

  private updateFractionIncrementFieldByType(type: 'inches' | 'feet', data?): void {
    const labelMap = {
      feet: 'foot',
      inches: 'inch'
    };

    const id = `#${this.formConfig.prefix}fractionIncrement`;
    const value = data || $(id).val();
    const fieldOptions: any = Object.assign(_.find(this.formConfig.fields, {name: 'fractionIncrement'}), {
      options: [
        {id: '1/64', description: '1/64'},
        {id: '1/32', description: '1/32'},
        {id: '1/16', description: '1/16'},
        {id: '1/8', description: '1/8'},
        {id: '1/4', description: '1/4'},
        {id: '1/2', description: '1/2'},
        {id: '1', description: `1 ${labelMap[type]}`}
      ]
    });
    this.formBuilderService.replaceSelectOptionsWith(id, this.formConfig, fieldOptions, {fractionIncrement: value});
    this.applyFractionIncrementChanges(value);
    this.syncStartValueFieldStep();
  }

  private applyFractionIncrementChanges(value: string | number) {
    value = _.isFinite(+value) ? +value : value;
    this.markFieldAsChanged(`${this.formConfig.prefix}Form`, 'fractionIncrement', value);
  }

  private syncStartValueFieldStep(enable: boolean = true) {
    const selector = `#${this.formConfig.prefix}startValue`;
    const element = this.formGroupComponentRef.location.nativeElement.querySelector(selector);

    if (element) {
      element.setAttribute('step', enable ? 1 : null);
    }
  }
}
