<app-close-button (onClicked)="close()"></app-close-button>

<ion-content>
  <div class="view-block">
    <div class="title-block">
      <div class="title">{{ config.title | translate }}</div>
      <div class="count" *ngIf="selectedIds.length">({{selectedIds.length}} {{ 'SHARED.Selected' | translate }})</div>
    </div>
    <div class="tree-block">
      <app-tree-viewer [multiselect]="config.multiselect" (onChange)="onNodeSelected($event)" [nodes]="config.nodes"></app-tree-viewer>
      <span class="empty-list-message" *ngIf="!config?.nodes?.length">{{ 'SHARED.No_Data' | translate }}</span>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <div class="footer-wrapper">
    <ion-button class="page-button" (click)="close()" color="light">
      {{ (config.cancelButtonName || "SHARED.Cancel") | translate }}
    </ion-button>
    <ion-button class="page-button" (click)="save()" color="secondary">
      {{ (config.saveButtonName || "SHARED.Save_Selections") | translate }}
    </ion-button>
  </div>
</ion-footer>
