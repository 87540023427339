import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingService, PopoverService } from '@services';
import { DeploymentService } from '@modules/management/pages/details/check/services/deployment/deployment.service';
import { DeploymentTableComponent } from '@modules/management/pages/details/check/components/deployment-table/deployment-table.component';
import { IPopoverConfig } from '@shared/components';
import { IDeployment } from '@modules/management/pages/details/check/models';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { IObjectStringKeyMap } from '@shared/models';

interface IDeploymentGroup {
  type: string;
  deployments: IDeployment[];
}

@Component({
  selector: 'app-check-deploy',
  templateUrl: './check-deploy.page.html',
  styleUrls: ['./check-deploy.page.scss'],
})
export class CheckDeployPage {

  @ViewChildren(DeploymentTableComponent) deploymentTableComponents: QueryList<DeploymentTableComponent>;

  @Input() checkId: string;
  @Input() isNewDeploymentAvailable = true;

  public deployments: IDeployment[] = [];
  public deploymentGroups: IDeploymentGroup[] = [];
  public removalPanelConfig = {
    count: 0,
    title: '',
    subTitle: this.translate.instant('MGMT_DETAILS.Deployment(s)'),
    removeButtonTitle: this.translate.instant('MGMT_DETAILS.Delete_Deployment')
  };

  private isActivated: boolean;
  private selectedDeployments: IObjectStringKeyMap<string[]> = {};

  constructor(
    private router: Router,
    private deploymentService: DeploymentService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private popoverService: PopoverService
  ) {
  }

  public onActive() {
    if (!this.isActivated) {
      this.isActivated = true;
      this.getDeployments();

      setTimeout(() => {
        this.renderTables();
      });
    }
  }

  public addDeployment(id?: string) {
    const params = _.pickBy({checkId: this.checkId, id}, _.identity);
    this.router.navigate(['/pages/management/checks/view-check/deployment', params]);
  }

  public getDeployments() {
    const periodKeys = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly', 'onDemand', 'once'];

    this.deployments = this.deploymentService.getDeployments(this.checkId, false, true);
    this.deploymentGroups = _.map(_.pick(_.groupBy(this.deployments, 'frequency.type'), periodKeys), (deployments, type) => ({
      type,
      deployments
    }));
  }

  public async updateData() {
    if (!this.isActivated) {
      return;
    }
    this.removalPanelConfig.count = 0;
    this.getDeployments();

    setTimeout(() => {
      this.renderTables();
    });
  }

  public onDeploymentChecked(selectedDeployments): void {
    this.selectedDeployments[selectedDeployments.tableId] = selectedDeployments.ids;
    this.removalPanelConfig.count = _.flatten(_.values(this.selectedDeployments)).length;
    this.removalPanelConfig.title = `${this.removalPanelConfig.count} Selected`;
  }

  public onRemoveDeployment() {
    const popoverConfig: IPopoverConfig = {
      title: this.translate.instant('MGMT_DETAILS.Delete_Deployment_Popover_Title'),
      description: this.translate.instant('MGMT_DETAILS.Delete_Deployment_Popover_Description'),
      onSave: () => this.removeDeployment()
    };
    this.popoverService.show(popoverConfig);
  }

  public trackByFunction(index: number, deploymentGroup: any): number {
    return deploymentGroup.type;
  }

  public async duplicateDeployment() {
    const deploymentId: string = _.first(_.flatten(_.values(this.selectedDeployments)));
    const params = _.pickBy({checkId: this.checkId, id: deploymentId, copyMode: 1}, _.identity);
    this.router.navigate(['/pages/management/checks/view-check/deployment', params]);
    this.selectedDeployments = {};
  }

  private renderTables() {
    this.deploymentTableComponents.forEach((deploymentTableComponent) => {
      deploymentTableComponent.showTable();
    });
  }

  private async removeDeployment() {
    await this.loadingService.enable();
    await this.deploymentService.removeDeployments(_.flatten(_.values(this.selectedDeployments)));
    await this.deploymentService.refresh();
    this.loadingService.disable();
    this.selectedDeployments = {};
  }

}
