import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { SettingsService } from '@services/settings/settings.service';
import { IDeployment } from '@modules/management/pages/details/check/models';
import { Events } from '@services/events/events.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DeploymentService {

  public deployments: { data: { [id: string]: IDeployment }; lastRequest: number } = {
    lastRequest: null,
    data: {}
  };

  private cachedDeployments: { [id: string]: IDeployment } = {};

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private settingsService: SettingsService,
    private events: Events
  ) {
  }

  public getDeployment(id: string): IDeployment {
    return this.deployments.data[id];
  }

  public refresh(updated: Number = 0): Promise<any> {
    if (updated && updated < this.deployments.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.deployments.lastRequest}`);
      return Promise.resolve(this.deployments.data);
    } else {
      const requestParams = {
        cmd: 'getDeployments',
        includeDisabled: 1,
        sendTime: Date.now(),
        lastRequest: this.deployments.lastRequest
      };

      return this.commsService.sendMessage(requestParams, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          this.updateCache(data);
        }
        return this.deployments.data;
      });
    }
  }

  public clearCache() {
    this.deployments.lastRequest = null;
    this.deployments.data = null;
    this.cachedDeployments = {};
  }

  public updateCache(data) {
    this.cachedDeployments = {};
    this.deployments.lastRequest = data.result.timestamp;

    if (_.isEmpty(this.deployments.data)) {
      this.deployments.data = data.result.deployments;
    } else {
      _.each(data.result.deployments, (deployment, id: string) => {
        this.deployments.data[id] = deployment;
      });
    }

    this.events.publish('ccs:deploymentUpdated');
  }

  public getDeployments(checkID: string, activeOnly: boolean = false, rejectDisabled: boolean = false): IDeployment[] {
    const filter: { [key: string]: number | string } = {checkID};

    if (activeOnly) {
      filter.active = 1;
    }

    if (rejectDisabled) {
      filter.disabledAt = 0;
    }

    return _.filter(this.deployments.data, <any>filter);
  }

  public async deployCheck(formData): Promise<any> {
    formData.cmd = 'deployCheck';
    formData.sendTime = Date.now();

    if (_.isUndefined(formData.active)) {
      formData.active = 0;
    }

    return this.commsService.sendMessage(formData);
  }

  public updateDeployment(id: string, params: any): Promise<any> {
    params.cmd = 'updateDeployment';
    params.sendTime = Date.now();
    params.deploymentID = id;

    return this.commsService.sendMessage(params);
  }

  public removeDeployments(ids: string[]): Promise<any> {
    const formData = {
      cmd: 'deleteDeployment',
      deployments: JSON.stringify(ids),
      sendTime: Date.now()
    };

    return this.commsService.sendMessage(formData);
  }

  public getGroupedDeploymentsByChecks(checks: any) {
    const deploymentsGroupedByChecks: any[] = [];
    const deployments = this.deployments.data;
    const checkTypes = this.settingsService.getSettingSync('checkType');
    _.each(checks, (check: any, checkID: string) => {
      const checkType = _.filter(checkTypes, ['messageID', check.checkType]);
      if (checkType.length) {
        const checkTitle: string = checkType[0].messageTitle + ': ' + check.title;
        const currentDeployments: any = _.filter(deployments, ['checkID', checkID]);
        const checkListItem: any = {
          text: checkTitle,
          children: _.map(currentDeployments, (deployment: any) => ({
            id: _.toString(deployment.deploymentID),
            text: deployment.title
          }))
        };
        if (checkListItem.children.length) {
          deploymentsGroupedByChecks.push(checkListItem);
        }
      }
    });
    return deploymentsGroupedByChecks;
  }

}
