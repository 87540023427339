import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services/question/question-form.service';
import { QuestionFormGroupListComponent } from '@modules/management/pages/details/check/components';
import { AbstractFieldsModel } from './abstractFieldsModel';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

export class TextInputFieldsModel extends AbstractFieldsModel {

  protected formConfig: any = {
    containers: true,
    prefix: 'textInput',
    fields: [
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'label',
        title: 'MGMT_DETAILS.Field_Label',
        type: 'text',
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'translation_label',
        title: this.translateService.instant('MGMT_DETAILS.Field_Label_Translations'),
        type: 'textTranslations',
        size: 20,
      },
      {
        containerClass: 'question-field',
        break: true,
        role: 'none',
        title: 'MGMT_DETAILS.Field_Options',
        name: 'fieldOptions',
        type: 'checkbox',
        class: 'inline-checkboxes',
        options: [
          {
            id: 'required',
            description: 'SHARED.Required'
          },
          {
            id: 'barcode',
            description: 'SHARED.Barcode'
          },
          {
            id: 'multiple',
            description: 'SHARED.Multiple'
          }
        ],
        onChange: (value, $form: JQuery) => {
          this.markFieldAsChanged($form.attr('id'), 'fieldOptions', value);
        }
      }
    ]
  };
  private fields = [
    {
      containerClass: 'question-type-field textInput',
      title: 'MGMT_DETAILS.Text_Input_Details',
      type: 'subtitle'
    },
    {
      containerClass: 'question-type-field textInput',
      title: 'MGMT_DETAILS.Text_Input_Fields',
      type: 'subtitle'
    },
    {
      containerClass: 'question-type-field textInput form-group-title text-input-groups',
      type: 'subtitle'
    }
  ];

  constructor(
    protected questionFormService: QuestionFormService,
    private translateService: TranslateService
  ) {
    super(questionFormService);
  }

  public getFields(): any[] {
    return this.fields;
  }

  public showGroupList() {
    if (this.questionFormService.isModelAvailable('textInput')) {
      const groupsElement = this.questionFormService.getElementBy(`.text-input-groups`);

      if (groupsElement && !this.formGroupComponentRef) {
        this.questionFormService.dynamicComponentCreatorService.create(
          QuestionFormGroupListComponent,
          (component, componentRef) => this.onFormGroupComponentInit(component, componentRef),
          groupsElement
        );
      }
    }
  }

  public onDestroy() {
    this.formGroupComponentRef && this.formGroupComponentRef.destroy();
    this.formGroupComponentRef = null;
  }

  public getData(): any[] {
    const settingsHandler = (settingsItem: any) => {
      const fieldOptions: string[] = _.get(settingsItem, 'fieldOptions', []);

      return _.pickBy({
        usesBarcode: _.includes(fieldOptions, 'barcode'),
        required: _.includes(fieldOptions, 'required'),
        canRepeat: _.includes(fieldOptions, 'multiple')
      }, _.identity);
    };

    return this.getFormData(['fieldOptions'], settingsHandler);
  }

  private onFormGroupComponentInit(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    component.title = this.translateService.instant('MGMT_DETAILS.Field');
    component.addButtonName = this.translateService.instant('SHARED.Add_Field');
    component.enableRemoval = true;

    this.onFormGroupComponentCreate(component, componentRef);
  }

}
