import { Injectable } from '@angular/core';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';
import { CommsService } from '@services/comms/comms.service';
import { NGXLogger } from 'ngx-logger';
import * as _ from 'lodash';
import { Events } from '@services/events/events.service';
import { CollectionItemType, ICollectionItemData } from '@services/collections/collections.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { awaitHandler } from '@utils/awaitHandler';

@Injectable({
  providedIn: 'root'
})
export class PoolService extends BaseService {

  public poolItems: any = {
    data: {},
    lastRequest: 0
  };

  public pools: any = {
    data: {},
    lastRequest: 0
  };

  public filterObject: any = {};

  constructor(protected commsService: CommsService,
              private logger: NGXLogger,
              private userData: UserdataService,
              private events: Events) {
    super(commsService);
  }

  public refresh(): Promise<any> {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.commsService.sendMessage({
        cmd: 'getPools',
      }, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          this.updateCache(data);
        }
        resolve(this.pools.data);
      }).catch((err) => {
        reject(err);
      });
    });

  }

  public getPoolItemByID(itemID: number): any {
    return _.find(this.poolItems.data, {itemID}) as any;
  }

  public getPoolByID(poolID: number): any {
    return _.find(this.pools.data, {poolID}) as any;
  }

  public updateCache(data: any): void {
    const collectionData = _.orderBy(data.result.pools, [element => element.poolID], ['asc']);
    this.pools.lastRequest = data.result.timestamp;
    this.pools.data = collectionData;
    this.events.publish('ccs:pools');
  }

  public handleAddPool(fData) {
    fData.cmd = 'addPool';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public async handleGetPools(fData) {
    fData.cmd = 'getPools';
    const poolData = await this.handleRequest(fData);
    this.pools.lastRequest = poolData.result.timestamp;
    this.pools.data = poolData.result.pools;
    return Promise.resolve(this.pools.data);
  }

  public async handleTagItems(fData) {
    fData.cmd = 'tagPoolItems';
    return this.handleRequest(fData);
  }

  public handleUpdatePool(fData) {
    fData.cmd = 'updatePool';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeletePool(fData) {
    fData.cmd = 'deletePool';
    return this.handleRequest(fData);
  }

  public removePools(collectionList: string[]): Promise<any> {
    const cmd = {
      cmd: 'deletePool',
      collections: JSON.stringify(collectionList)
    };
    return this.handleRequest(cmd);
  }

  public async handleGetPoolItems(itemList: number[]) {
    const fData = {
      cmd: 'getPoolItems',
      items: '[]'
    };
    const existing = [];
    const neededItems = [];
    if (itemList.length) {
      // there is an item list - see if the items are already cached locally
      _.each(itemList, theItem => {
        neededItems.push(theItem);
      });
      fData.items = JSON.stringify(neededItems);
    }
    const resp: any = {
      reqStatus: 'OK',
      result: {items: existing}
    };

    if (neededItems.length) {
      let sendResp = null;
      this.logger.debug('requesting required pool items');
      [sendResp] = await awaitHandler(this.handleRequest(fData, () => Promise.resolve(true)));
      if (sendResp && sendResp.reqStatus === 'OK' && sendResp.result?.items) {
        resp.result.items.push(...sendResp.result.items);
        this.logger.debug('captured ' + sendResp.result.items.length + ' items');
      }
    }
    return resp;
  }

  public handleAddPoolItems(fData) {
    fData.cmd = 'addPoolItems';
    return this.handleRequest(fData);
  }

  public handleDeletePoolItems(fData) {
    fData.cmd = 'deletePoolItems';
    return this.handleRequest(fData);
  }

  public async getCollectionData(id: number | number[]): Promise<ICollectionItemData[]> {
    if (_.isInteger(id)) {
      id = [id as number];
    }
    const ret: ICollectionItemData[] = [];

    const resp = await this.handleGetPoolItems(id as number[]);
    if (resp.reqStatus === 'OK') {
      _.each(resp.result.items, theItem => {
        const poolInfo = this.getPoolByID(theItem.poolID);
        if (!poolInfo) {
          this.logger.error(`item ${theItem.itemID} references nonexistent pool ${theItem.poolID}`);
          return;
        }
        const type = 'Ext: ' + this.translateItem(poolInfo, 'title');
        // cache this
        this.poolItems.data[theItem.itemID] = theItem;

        ret.push({
          item: theItem.itemID,
          source: CollectionItemType.External,
          type,
          state: theItem.state,
          zone: theItem.zoneID,
          locationID: theItem.locationID,
          notes: theItem.notes,
          groupID: 0,
          addedAt: theItem.addedAt,
          addedBy: theItem.addedBy,
          created: theItem.timeStamp,
          lastUpdate: theItem.lastUpdate,
          tagIDs: theItem.tagIDs,
          id: 'pool' + ':' + theItem.itemID,
          uniqueID: theItem.uniqueID
        });
      });
    }
    return ret;
  }

  private translateItem(item: { [key: string]: any }, name: string): string {
    const currentLanguage: string = this.userData.getLanguage();
    let translatedValue = _.get(item, name);

    if (currentLanguage) {
      const translations = _.get(item, 'translations');
      const translatedObject: { [key: string]: any } = _.find(_.get(translations, name) || translations, {language: currentLanguage});

      if (translatedObject) {
        translatedValue = translatedObject.value;
      }
    }

    return translatedValue;
  }
}

