<ion-header no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="content">
    <div class="title-block">
      <div class="value">{{ "DASHPAGES.EDIT_QUALITY_FIELDS_value" | translate }}</div>
      <!-- <div class="sign">#</div>
      <div class="id">{{observationId}}</div> -->
    </div>

    <!-- FORM COMPONENTS -->
    <app-quality-fields #qualityFields></app-quality-fields>
    <!-- END FORM COMPONENTS-->

    <div class="buttons">
      <ion-button (click)="back()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
      <ion-button (click)="save()" [disabled]="!qualityFields.nextActivated" class="page-button"
                  color="secondary">{{ "SHARED.Save_Changes" | translate }}</ion-button>
    </div>
  </div>
</ion-content>
