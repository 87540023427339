import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import 'datatables.net';
import { MessagesService } from '@services';
import * as _ from 'lodash';
import { AccountsService } from '@services/accounts/accounts.service';
import { TeamsService } from '@services/teams/teams.service';
import { UtilsService } from '@services/utils/utils.service';
import { TableExport } from 'tableexport';
import * as moment from 'moment';
import { CommsService } from '@services/comms/comms.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message-thread',
  templateUrl: './message-thread.page.html',
  styleUrls: ['./message-thread.page.scss'],
})
export class MessageThreadPage implements OnInit {

  public startDate: any = '';
  public endDate: any = '';
  public dataSet = [];

  constructor(private location: Location,
              private messageService: MessagesService,
              private accountService: AccountsService,
              private teamService: TeamsService,
              private utils: UtilsService,
              private comms: CommsService,
              private translate: TranslateService) {
  }

  ngOnInit() {
  }

  public async save() {
    await this.prepareDataSet();
    this.generateTable();
    this.exportExcel();

  }

  public back(): void {
    this.location.back();
  }

  public nextActive() {
    return (this.startDate && this.endDate);
  }

  public exportExcel(): void {
    $('.buttons-excel').click();
  }

  private async prepareDataSet() {


    // filter here
    const timeStart = moment(this.startDate);
    timeStart.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const timeEnd = moment(this.endDate);
    timeEnd.set({hour: 24, minute: 59, second: 59, millisecond: 9});
    const uStartTime = moment(timeStart).unix();
    const uEndTime = moment(timeEnd).unix();

    const params: any = {
      cmd: 'getMessages',
      startTime: uStartTime,
      endTime: uEndTime,
      recipients: JSON.stringify([0]),
      types: JSON.stringify(['message']),
      useCreated: 1
    };

    const data = await this.comms.sendMessage(params, false, false);
    if (data && data.reqStatus === 'OK') {
      _.each(data.result.messages, msgObj => {
        if (msgObj.message) {
          this.dataSet.push(msgObj);
        }
      });
    }
    return this.dataSet;
  }

  private generateTable() {
    let o = 0;
    if ($('.table-container:visible').offset()) {
      o = $('.table-container:visible').offset().top;
    }
    const h = $(window).height() - o - 150;  // sets height of the datatble
    const t: any = $('#messageTable').DataTable(<any>{
      data: this.dataSet,
      stateSave: true, // saves the state, selected column, ordered column etc..
      destroy: true,  // kills table and draws new when new data set comes in
      paging: false,  // imp for virtual scroll
      info: false,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'excel', 'pdf'
      ],
      language: {
        searchPlaceholder: this.translate.instant('SHARED.Search'),
        search: '',
        emptyTable: this.translate.instant('SHARED.emptyTable'),
        sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
      },
      columns: this.buildColumn(),
    });
  }

  private buildColumn() {
    const retArr = [];
    retArr.push(
      {
        title: this.translate.instant('COMMUNICATIONS.Sender') + '<span class="sort-icon"></span>',
        data: data => this.accountService.fullname(data.userID)
      },
      {
        title: this.translate.instant('COMMUNICATIONS.Recipients') + '<span class="sort-icon"></span>',
        data: data => data,
        render: (data, type, row, meta) => {
          let pString = '';
          let expString = '';
          if (!_.isEmpty(data.groups)) {
            _.each(data.groups, gid => {
              // tslint:disable-next-line:max-line-length
              pString += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${this.teamService.teamNameByID(gid)}</div>`;
              expString += '&bull; ' + this.teamService.teamNameByID(gid) + '&#8232;';
            });
          } else {
            _.each(data.recipients, (part: any) => {
              // tslint:disable-next-line:max-line-length
              pString += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${this.accountService.fullname(part.userID)}</div>`;
              expString += '&bull; ' + this.accountService.fullname(part.userID) + '&#8232;';
            });
          }

          if (type === 'display' || type === 'filter') {
            return pString;
          } else {
            return expString;
          }
        }
      },
      {
        title: this.translate.instant('SHARED.Timestamp') + '<span class="sort-icon"></span>',
        data: data => this.utils.dateTimeFormat(data.created, null, false)
      },
      {
        title: this.translate.instant('SHARED.Message') + '<span class="sort-icon"></span>',
        data: 'message'
      },
    );


    return retArr;
  }

}
