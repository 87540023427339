<div *ngIf="config?.isDetailView; else fullView">
  <div [class.selected]="config?.value" class="cell-list">
    <div *ngFor="let cell of cells" [class.selected-cell]="config?.value === cell.value"
         class="cell {{ cell.class }}-cell"></div>
  </div>
  <div class="title">{{ config?.title }}</div>
</div>

<ng-template #fullView>
  <div class="title">{{ config?.title }}</div>
  <div [class.selected]="config?.value" class="cell-list">
    <div (click)="onSelectCell(cell.value)"
         *ngFor="let cell of cells"
         [class.selected-cell]="config?.value === cell.value"
         class="cell {{ cell.class }}-cell">{{ cell.class[0] }}</div>
  </div>
  <div class="y-axis">
    <div class="axis-title">
      <span>{{ "SHARED.Severity" | translate }}</span>
    </div>
  </div>
  <div class="axis-title">
    <span>{{ "SHARED.Likelihood" | translate }}</span>
  </div>
</ng-template>
