import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-auto-page-refresher',
  templateUrl: './auto-page-refresher.component.html',
  styleUrls: ['./auto-page-refresher.component.scss'],
})
export class AutoPageRefresherComponent {

  @Input() frequency: number = 1000 * 60; // 5 minutes
  @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();

  public isContentAvailable = true;
  private intervalListener: any;

  public start(): void {
    this.stop();

    this.intervalListener = setInterval(() => {
      this.onUpdate.emit();
    }, this.frequency);
  }

  public stop(): void {
    clearInterval(this.intervalListener);
  }

  public setContentAvailability(isContentAvailable: boolean): void {
    this.isContentAvailable = isContentAvailable;
    this.isContentAvailable ? this.start() : this.stop();
  }

}
