<div class="date-range-picker-field">
  <ng-container *ngTemplateOutlet="labelRef; context: { labelValue: startLabel }"></ng-container>
  <input #startDate (input)="onChangedValue()" class="input-date js-date-popup-start-date start-date" type="text" [placeholder]="startPlaceholder">
</div>

<div *ngIf="!singleDatePicker" class="date-range-picker-field">
  <ng-container *ngTemplateOutlet="labelRef; context: { labelValue: endLabel }"></ng-container>
  <input #endDate (input)="onChangedValue()" class="input-date js-date-popup-end-date end-date" type="text" [placeholder]="endPlaceholder">
</div>

<ng-template #labelRef let-labelValue="labelValue">
  <label *ngIf="!noLabel">
    {{ labelValue | translate }}
    <span *ngIf="required" class="required">*</span>
  </label>
</ng-template>
