import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderService, PopupObservationService } from '@services';
import { Location } from '@angular/common';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';

@Component({
  selector: 'app-mark-resolved',
  templateUrl: './mark-resolved.page.html',
  styleUrls: ['./mark-resolved.page.scss'],
})
export class MarkResolvedPage implements OnInit {

  public title: string = this.translate.instant('SHARED.Mark_As_Resolved');
  public formId = 'resolveObservationForm';
  protected formConfig: any = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: 'resolveObservation',
    del: null,
    save: this.translate.instant('SHARED.Mark_As_Resolved'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      {
        title: this.translate.instant('SHARED.Resolved_Notes'),
        containerClass: 'observation-note',
        name: 'note',
        type: 'textarea',
        maxlength: 1980,
        size: 20
      }
    ]
  };
  private observationID: number;
  private formData: any = {
    note: ''
  };
  private saveButtonId = 'resolveObservationSave';
  private deleteButtonId: string = null;
  private cancelButtonId = 'resolveObservationCancel';

  constructor(private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private formBuilderService: FormBuilderService,
              private elementRef: ElementRef,
              private location: Location,
              private popupObservationService: PopupObservationService,
              private router: Router,
              private obsDetail: ObservationDetailService) {
  }


  ngOnInit() {

  }

  ionViewDidEnter() {
    this.observationID = +this.activatedRoute.snapshot.queryParamMap.get('id');
    //alert(this.observationID);
    this.formBuilderService.showForm(`#${this.formId}`, this.formConfig, this.formData);
    this.elementRef.nativeElement.querySelector(`#${this.saveButtonId}`)
      .addEventListener('click', (event: Event) => this.update());
    this.elementRef.nativeElement.querySelector(`#${this.cancelButtonId}`)
      .addEventListener('click', () => {
        if (this.isFormChanged()) {
          this.ngOnInit();
        } else {
          this.cancel();
        }
      });
  }

  public isFormChanged(): boolean {
    return this.formBuilderService.isFormChanged(`#${this.formId}`);
  }

  private update(): void {
    // this.popoverService.show(this.popoverUpdateConfig);
    const formData: any = this.formBuilderService.getFormData($(`#${this.formId}`));
    const requestObject: any = {
      cmd: 'updateObservation',
      observationID: this.observationID,
      state: 'resolved',
      note: JSON.stringify({
        type: 'text',
        value: formData.note,
        subtype: 'closingNotes'
      })
    };
    this.popupObservationService.closeObs(requestObject).then((data: any) => {
        // we have to make sure we go back where we came from, check resolveFromSupersior to see if we landed into detail from supervisor task board.
        if (this.obsDetail.resolveFromSupervisor) {
          this.obsDetail.resolveFromSupervisor = false;
          this.router.navigate(['pages/dashboard/supervisor-dashboard']);
        } else if (this.obsDetail.resolveToResultTable) {
          this.obsDetail.resolveToResultTable = false;
          this.obsDetail.resolveFromCheckResultTable();
          this.obsDetail.resolveFromCheckResultTable = () => null;
        } else {
          this.router.navigate(['pages/dashboard/ca-table']);
        }
      }
    );
  }

  private cancel(): void {
    this.location.back();
  }

}
