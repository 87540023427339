import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services/question/question-form.service';
import { QuestionFormGroupListComponent } from '@modules/management/pages/details/check/components';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

export class PassFailFieldsModel {

  private fields = [
    {
      title: 'MGMT_DETAILS.Pass_Fail_Details',
      type: 'subtitle',
      containerClass: 'question-type-field passFail'
    },
    {
      title: 'MGMT_DETAILS.Pass_Fail_Responses',
      type: 'subtitle',
      containerClass: 'question-type-field passFail'
    },
    {
      type: 'subtitle',
      containerClass: 'question-field question-type-field passFail form-group-title pass-fail-choices-groups'
    }
  ];

  private formGroupComponent: QuestionFormGroupListComponent;
  private formGroupComponentRef: ComponentRef<QuestionFormGroupListComponent>;
  private options: any[] = [];

  private passFormConfig: any = {
    containers: true,
    prefix: 'passChoice',
    fields: [
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'label',
        title: 'MGMT_DETAILS.Pass_Label',
        type: 'text',
        required: true
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'translation_label',
        title: this.translate.instant('MGMT_DETAILS.Pass_Label_Translation'),
        type: 'textTranslations'
      },
      {
        title: 'MGMT_DETAILS.Choice_Validation',
        type: 'subtitle',
        containerClass: 'question-type-field'
      },
      {
        containerClass: 'question-field',
        title: 'MGMT_DETAILS.Validation_Notes',
        name: 'notes_validation',
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        default: 0,
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('notes_validation', value, this.options[0]);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['form-group-checkbox-pass', 'required-validation-field-divider-pass'],
              valueFunction: (val) => !val || !this.getValidationValue('pass', 'photo')
            },
            {
              hideClasses: ['issue-type-pass'],
              valueFunction: (val) => !val && !this.getValidationValue('pass', 'photo')
            }
          ]
        }
      },
      {
        containerClass: 'question-field',
        title: 'MGMT_DETAILS.Photo_Validation',
        name: 'photo_validation',
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        default: 0,
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('photo_validation', value, this.options[0]);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['form-group-checkbox-pass', 'required-validation-field-divider-pass'],
              valueFunction: (val) => !val || !this.getValidationValue()
            }
          ]
        }
      },
      {
        containerClass: 'question-field required-validation-field-divider-pass',
        type: 'divider'
      },
      {
        containerClass: 'question-field form-group-checkbox form-group-checkbox-pass',
        name: 'required_validation_field',
        title: '',
        type: 'checkbox',
        options: {
          checked: {
            description: 'MGMT_DETAILS.Validation_Type_Above',
            id: 1
          }
        },
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('required_validation_field', value, this.options[0]);
        }
      }
    ]
  };

  private failFormConfig: any = {
    containers: true,
    prefix: 'failChoice',
    del: null,
    save: null,
    cancel: null,
    fields: [
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'label',
        title: 'MGMT_DETAILS.Fail_Label',
        type: 'text',
        required: true
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'translation_label',
        title: this.translate.instant('MGMT_DETAILS.Fail_Label_Translation'),
        type: 'textTranslations'
      },
      {
        containerClass: 'question-type-field',
        title: 'MGMT_DETAILS.Choice_Validation',
        type: 'subtitle'
      },
      {
        containerClass: 'question-field',
        title: 'MGMT_DETAILS.Validation_Notes',
        name: 'notes_validation',
        type: 'flipswitch',
        value: 1,
        default: 0,
        onChange: (value, formPrefix) => {
          const getValue = (!value || !this.getValidationValue('fail', 'photo'));
          const elementRef = this.formGroupComponent.getGroupElementRefByFormId(`${formPrefix}Form`);
          this.questionFormService.failChoiceValidation(elementRef.nativeElement, 'required_validation_field', getValue, this.options[1]);
          this.questionFormService.markFieldAsChanged('notes_validation', value, this.options[1]);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['issue-type-fail', 'required-validation-field-divider-fail'],
              valueFunction: (val) => !val && !this.getValidationValue('fail', 'photo')
            }
          ]
        }
      },
      {
        containerClass: 'question-field',
        title: 'MGMT_DETAILS.Photo_Validation',
        name: 'photo_validation',
        type: 'flipswitch',
        value: 1,
        default: 0,
        onChange: (value, formPrefix) => {
          const getValue = !value || !this.getValidationValue('fail');
          const elementRef = this.formGroupComponent.getGroupElementRefByFormId(`${formPrefix}Form`);
          this.questionFormService.failChoiceValidation(elementRef.nativeElement, 'required_validation_field', getValue, this.options[1]);
          this.questionFormService.markFieldAsChanged('photo_validation', value, this.options[1]);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['issue-type-fail', 'required-validation-field-divider-fail'],
              valueFunction: (val) => !val && !this.getValidationValue('fail')
            }
          ]
        }
      },
      {
        containerClass: 'question-field form-group-checkbox form-group-checkbox-fail',
        name: 'required_validation_field',
        disabled: true,
        title: '',
        type: 'checkbox',
        options: {
          checked: {
            description: 'MGMT_DETAILS.Validation_Type_Above',
            id: 1
          }
        },
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('required_validation_field', value, this.options[1]);
        }
      },
      {
        containerClass: 'question-field required-validation-field-divider-fail',
        type: 'divider'
      },
      {
        containerClass: 'question-field issue-type-fail',
        title: 'MGMT_DETAILS.Documentation_Options',
        type: 'subtitle'
      },
      _.merge({}, this.questionFormService.sharedFields.attachCommentOption, {
        containerClass: 'question-field issue-type-fail',
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('issueTypeFail_Comment', value, this.options[1]);
        }
      }),
      _.merge({}, this.questionFormService.sharedFields.correctiveOption, {
        containerClass: 'question-field issue-type-fail',
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('issueTypeFail_Corrective', value, this.options[1]);
        }
      })
    ]
  };

  constructor(
    private questionFormService: QuestionFormService,
    private translate: TranslateService
  ) {
  }

  public isValid(): boolean {
    return this.questionFormService.validFormGroups(this.formGroupComponent.groups);
  }

  public showGroupList() {
    if (this.questionFormService.isModelAvailable('passFail')) {
      const choicesGroupsElement = this.questionFormService.getElementBy(`.pass-fail-choices-groups`);

      if (choicesGroupsElement && !this.formGroupComponentRef) {
        this.questionFormService.dynamicComponentCreatorService.create(
          QuestionFormGroupListComponent,
          (component, componentRef) => this.onFormGroupComponentInit(component, componentRef),
          choicesGroupsElement
        );
      }
    }
  }

  public getFields(): any[] {
    return this.fields;
  }

  public onDestroy() {
    this.formGroupComponentRef && this.formGroupComponentRef.destroy();
    this.formGroupComponentRef = null;
  }

  public getData(): any[] {
    return _.map(this.formGroupComponent.getFormListData(), (formData, index: number) => {
      (<any>this.questionFormService).utils.encodeTranslations(formData, ['translation_label'], ['label']);
      const labelTranslateKey: string = index ? 'Fail' : 'Pass';
      let issueType = '';

      if (formData.issueTypeFail_Comment && formData.issueTypeFail_Corrective) {
        issueType = '2';
      } else if (formData.issueTypeFail_Corrective) {
        issueType = '1';
      } else if (formData.issueTypeFail_Comment) {
        issueType = '0';
      }

      const data = {
        optionID: formData.optionID,
        label: formData.label || this.translate.instant(`MGMT_DETAILS.${labelTranslateKey}`),
        settings: _.pickBy({
          usesNote: formData.notes_validation ? 2 : 0,
          usesPhoto: formData.photo_validation ? 2 : 0,
          verificationRequired: formData.required_validation_field,
          issueType: formData.issueType || issueType
        }, _.identity),
        translations: formData.translations
      };

      if (data.settings.verificationRequired) {
        data.settings.usesNote = 1;
        data.settings.usesPhoto = 1;
      }

      return _.assign({}, data, {
        settings: JSON.stringify(data.settings)
      });
    });
  }

  private onFormGroupComponentInit(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    this.formGroupComponent = component;
    this.formGroupComponentRef = componentRef;

    this.formGroupComponent.disableReorder = true;
    this.formGroupComponent.disableNewGroup = true;

    this.options = _.values(this.questionFormService.questionPage.questionOptions);
    const passFormData = this.options[0] || {};
    const failFormData = this.options[1] || {};

    this.formGroupComponent.groups = [
      {title: this.translate.instant('MGMT_DETAILS.Pass'), formConfig: this.passFormConfig, formData: passFormData},
      {title: this.translate.instant('MGMT_DETAILS.Fail'), formConfig: this.failFormConfig, formData: failFormData}
    ];
  }

  private getValidationValue(type: 'pass' | 'fail' = 'pass', field: 'notes' | 'photo' = 'notes'): number {
    const typeMap = {pass: 0, fail: 1};
    const fieldConfig: any = _.find(this.formGroupComponent.groups[typeMap[type]].formConfig.fields, {name: `${field}_validation`});
    return fieldConfig && fieldConfig.getValue();
  }

}
