import * as StoreActions from './store.actions';
import { IAppState } from './IAppState';

import * as _ from 'lodash';
import { Action, createReducer, on } from '@ngrx/store';
import { ILocationsData } from '@services';

const initialState: IAppState = {
};

export interface StateAction extends Action {
  state: IAppState;
}

export interface UpdateLocationsAction extends Action {
  locations: ILocationsData;
}

export interface UpdateUserLocationsAction extends Action {
  locations: number[];
}

const reducer = createReducer(
  initialState,
  on(StoreActions.updateStore, (state: IAppState, action: StateAction) => {
    return _.assignIn({}, state, action.state);
  }),
  on(StoreActions.updateLocations, (state: IAppState, action: UpdateLocationsAction) => {
    return _.assignIn({}, state, {
      locations: action.locations
    });
  }),
  on(StoreActions.updateUserLocations, (state: IAppState, action: UpdateUserLocationsAction) => {
    return _.assignIn({}, state, {
      userInfo: {
        locations: action.locations
      }
    });
  }),
  on(StoreActions.resetStore, () => ({}))
);

export function AppStateReducer(state = initialState, action: Action) {
  return reducer(state, action);
}


