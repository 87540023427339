<ion-content [hidden]="!dashboardHomeData" [ngClass]="isValid ?'': 'display-none'" class="z-index">
  <app-dashboard-header [headerSetting]="headerSetting"></app-dashboard-header>
  <div class="overview-board">
    <div class="board-header">
      <div class="board-title">
        <p *ngIf="usingREI"
           class="headline-medium no-margins">{{ "DASHPAGES.OBSERVATION_HOME_descriptor1" | translate }}</p>
        <div *ngIf="!pieDetail && usingREI" class="board-title-info">
          <div class="safety-number-box-small">
            <div *ngIf="dashboardHomeData && dashboardHomeData.subscriberRisk" [ngClass]="dashboardHomeData.subscriberRisk.class"
                 class="risk-index-medium">
              {{dashboardHomeData.subscriberRisk.safetyIndex}}
            </div>
          </div>
        </div>
      </div>

      <div class="board-container">
        <span class="descriptor">{{ "SHARED.RANGE" | translate }}</span>
        <select2 (valueChanged)="rangeSorter($event)" [data]="rangeOptions | selectLangPipe" [options]="options"
                 [value]="selectedRange" appSelect2Parent class="table-content dashboard-dropdown"></select2>
        <app-view-switcher (onSelect)="menuSelected($event)" [config]="viewSwitcherConfig"></app-view-switcher>
      </div>

    </div>

    <div [hidden]="!pieDetail" class="board-info">

      <div *ngIf="usingREI" class="board-info-inner">
        <div class="safety-number-box">
          <div *ngIf="dashboardHomeData && dashboardHomeData.subscriberRisk" [ngClass]="dashboardHomeData.subscriberRisk.class"
               class="risk-index-large">
            {{dashboardHomeData.subscriberRisk.safetyIndex}}
          </div>
        </div>
        <div class="caption">
          {{ "DASHPAGES.OBSERVATION_HOME_descriptor2" | translate }}
        </div>
      </div>

      <div class="dotted-border-right"></div>

      <div class="chart-block">
        <div class="chart-block-inner">
          <canvas #barCanvas [hidden]="!pieDataAvailable" id="barCanvas"></canvas>
          <img [hidden]="pieDataAvailable" alt="" src="assets/images/pieNoData.svg">
        </div>

        <div class="chart-block-info">
          <p class="headline-medium">{{ "SHARED.Observation_Log" | translate }}</p>
          <div #globalLegend id="globalLegend"></div>
          <ul class="pie-label-wrapper">
            <li *ngFor="let label of pieChartLabel" [ngClass]="label.class">
              <span class="checkbox-label-crossbrowser">
                <input (click)="pieChartLegend(label.index)" [checked]="!label.unchecked" [value]="label.label"
                       type="checkbox">
                <label></label>
              </span>
              <span [ngStyle]="{'border-color': label.borderColor, 'background-color': label.backgroundColor}"
                    class="checkbox-color-filled"></span>
              <span class="body-copy-bold">{{label.label}}</span>
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div [hidden]="pieDetail" class="chart-container">
      <div class="chart-container-inner">
        <canvas #lineCanvas [hidden]="!lineDataAvailable" id="lineCanvas"></canvas>
        <p [hidden]="lineDataAvailable" class="no-data no-data-available">{{ "SHARED.No_Data" | translate }}</p>
      </div>
      <div class="chart-container-info" id="trendLegend">
        <div class="label-wrapper">
          <span *ngFor="let label of lineChartLabel" class="linechart-label">
            <span class="checkbox-label-crossbrowser">
              <input (click)="lineChartLegend(label.index)" [checked]="!label.unchecked" [value]="label.label"
                     type="checkbox">
              <label></label>
            </span>
          <span [ngStyle]="{'border-color': label.borderColor, 'background-color': label.backgroundColor}"
                class="checkbox-color-filled"></span>
          <span class="body-copy-bold">{{label.label}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="usingREI" class="board-page standart-spacing">
    <div class="board-page-inner">
      <div class="headline-small">{{ "DASHPAGES.OBSERVATION_HOME_descriptor3" | translate }}</div>
      <div class="board-page-sort">
        <span class="descriptor">{{ "DASHPAGES.OBSERVATION_HOME_descriptor4" | translate }}</span>
        <ng-select (change)="siteSorter($event)"
                   [(ngModel)]="selectedSorter"
                   [clearable]="false"
                   [closeOnSelect]="true"
                   [multiple]="false"
                   [searchable]="false"
                   class="custom-select">
          <ng-option *ngFor="let statusValue of siteSorterData" [value]="statusValue.id">
            <span class="select-value {{ statusValue.key }}">{{ statusValue.text | translate }}</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="clearfix"></div>
    </div>

    <app-site-health *ngIf="dashboardHomeData && dashboardHomeData.locationHealth"
                     [locationsHealth]="dashboardHomeData.locationHealth"></app-site-health>
  </div>
</ion-content>
<!--<div class="curtain-loader" *ngIf="isValid" style="z-index: 9999"></div>-->
