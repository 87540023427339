import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { TableService } from '@services';

import * as _ from 'lodash';

export interface ModalTableConfig {
  title: string;
  data: { id: string; title: string }[];
  columns: { id: string; title: string }[];
}

@Component({
  selector: 'app-table',
  templateUrl: './modal-table.component.html',
  styleUrls: ['./modal-table.component.scss'],
})
export class ModalTableComponent {

  @ViewChild('listTable') listTableElement: ElementRef<HTMLElement>;
  @Input() tableService: TableService;
  public title: string;
  private tableAttributes = {columns: []};
  private readonly dataTableOptions = {
    paging: false,
    searching: false,
    info: false,
    scrollCollapse: true,
    scrollY: ''
  };

  constructor(private popoverCtrl: PopoverController) {
  }

  @Input('config')
  set config({title, data, columns}: ModalTableConfig) {
    this.title = title;
    this.tableAttributes.columns = _.map(columns, (column) => ({
      id: column.id,
      title: column.title,
      headerClass: 'table-header'
    }));

    setTimeout(() => {
      this.showTable(data);
    });
  }

  public close(): void {
    this.popoverCtrl.dismiss();
  }

  private showTable(data: any[] = []): void {
    this.tableService.showTable(this.listTableElement.nativeElement, this.tableAttributes, data);
    this.dataTableOptions.scrollY = `${this.getTableHeight()}px`;
    $(this.listTableElement.nativeElement).DataTable(this.dataTableOptions).draw();
  }

  private getTableHeight(): number {
    const offset: number = $(this.listTableElement.nativeElement).offset().top;
    return $(window).height() - offset - 125;
  }

}
