import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import cssVars from 'css-vars-ponyfill';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

cssVars({
  watch: true,
  silent: true
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
