import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { AbstractModalCloseHandler } from '@shared/components/abstractModalCloseHandler/abstractModalCloseHandler';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent extends AbstractModalCloseHandler {

  @Input() backUrl: string;
  @Input() isModal: boolean;
  @Output() onClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private location: Location,
    private modalController: ModalController,
    private router: Router,
    protected elementRef: ElementRef
  ) {
    super(elementRef);
  }

  public back(): void {
    if (this.isModal) {
      this.modalController.dismiss();
    } else {
      this.onClicked.observers.length ? this.onClicked.emit() : this.navigateBack();
    }
  }

  private navigateBack() {
    this.backUrl ? this.router.navigate([this.backUrl]) : this.location.back();
  }

}
