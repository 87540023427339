<ion-menu class="custom" contentId="main-content" menuId="notification" side="end" swipeGesture="false" type="overlay">
  <ion-header no-border>
    <div (click)="close()" class="close-icon"></div>
  </ion-header>

  <ion-content>
    <div class="notification-menu-content">
      <img [src]="src" alt="">
      <div class="notification-date">{{notification.created | amFromUnix | amTimeAgo}}</div>
      <div class="notification-message" [innerHTML]="notification.message | markdown"></div>
      <ion-button (click)="openObservationDetail()" *ngIf="showButton" class="page-button"
                  color="secondary">{{ buttonName | translate}}</ion-button>
    </div>
  </ion-content>

  <ion-footer no-border>
    <ion-button (click)="remove()" class="page-button"
                color="danger">{{"COMMUNICATIONS.Delete_Notification" | translate}}</ion-button>
  </ion-footer>
</ion-menu>
