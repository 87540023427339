import { Component, Input, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ImageGalleryComponent, IGaleryImage } from '../image-gallery/image-gallery.component';

import * as _ from 'lodash';

@Component({
  selector: 'image-gallery-modal',
  templateUrl: 'image-gallery-modal.component.html',
  styleUrls: ['./image-gallery-modal.component.scss']
})
export class ImageGalleryModalComponent {

  public images: IGaleryImage[] = [];

  constructor(public modalController: ModalController) {
  }

  @Input()
  set inputImages(images: IGaleryImage[]) {
    this.images = _.map(images, (image: IGaleryImage | any) => ({
      url: image.imgSrc.replace('/thumb', ''),
      time: image.time,
      title: image.name,
      thumbnailUrl: image.imgSrc,
      class: image.class,
      mediaType: image.mediaType,
      fullName: image.fullName,
      isPending: image.isPending
    }));
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalController.getTop().then((modal) => {
      if (modal) {
        this.modalController.dismiss();
      }
    });
  }

  async presentModal(selectedImage: IGaleryImage) {
    if (selectedImage && !selectedImage.isPending) {
      const filteredImages: IGaleryImage[] = _.reject(this.images, 'isPending');
      const imageIndex: number = _.findIndex(filteredImages, {url: selectedImage.url});

      const modal = await this.modalController.create({
        component: ImageGalleryComponent,
        componentProps: {
          images: filteredImages,
          index: imageIndex
        },
        cssClass: 'image-gallery-modal'
      });
      return await modal.present();
    }
  }
}
