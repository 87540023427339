import { Directive, ElementRef, Input } from '@angular/core';

import * as _ from 'lodash';

@Directive({
  selector: '[appSelect2Parent]'
})
export class Select2ParentDirective {

  private select2Options: { [key: string]: any } = {};

  constructor(private elementRef: ElementRef) {
  }

  get options() {
    return this.select2Options;
  }

  @Input('options')
  set options(options: { [key: string]: any }) {
    if (options) {
      this.select2Options = _.cloneDeep(options);
      const parentElement: any = $(this.elementRef.nativeElement).parent();

      if (parentElement?.length) {
        parentElement.css('position', 'relative');
        this.select2Options.dropdownParent = parentElement;
      }
    }
  }

}
