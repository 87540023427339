import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { Events } from '@services/events/events.service';

import * as moment from 'moment';

@Component({
  selector: 'app-date-picker-popup',
  templateUrl: './date-picker-popup.component.html',
  styleUrls: ['./date-picker-popup.component.scss'],
})
export class DatePickerPopupComponent implements OnInit {

  @Input() filterObject: { startTime: number; endTime: number } = <{ startTime: number; endTime: number }>{};
  @Input() silentMode?: boolean = false;

  public dateRangeConfig: { startDate: number; endDate: number };

  constructor(
    private popOver: PopoverController,
    private event: Events
  ) {
  }

  ngOnInit() {
    this.initDateRange();
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public save(): void {
    this.popOver.dismiss('save');
    this.filterObject.startTime = moment(this.dateRangeConfig.startDate).startOf('day').valueOf();
    this.filterObject.endTime = moment(this.dateRangeConfig.endDate).endOf('day').valueOf();

    if (!this.silentMode) {
      this.event.publish('ccs:filterObject', this.filterObject);
    }
  }

  public onDateChange(dateRange): void {
    this.dateRangeConfig = dateRange;
  }

  private initDateRange() {
    this.dateRangeConfig = {
      startDate: this.filterObject.startTime ? moment(this.filterObject.startTime).startOf('day').valueOf() : moment().startOf('day').valueOf(),
      endDate: this.filterObject.endTime ? moment(this.filterObject.endTime).startOf('day').valueOf() : moment().endOf('day').valueOf()
    };
  }
}
