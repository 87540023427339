import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NativeElementService {

  constructor() {
  }

  public getElementBy(target: HTMLElement | any, selector: string): HTMLElement | any {
    return target.querySelector(selector);
  }

  public getElementsBy(target: HTMLElement | any, selector: string): HTMLElement[] | any[] {
    return target.querySelectorAll(selector);
  }

  public setElementVisibilityBy(target: HTMLElement | any, selector: string, isVisible: boolean) {
    _.each(this.getElementsBy(target, selector), (element) => {
      element.hidden = !isVisible;
    });
  }

  public disableElementsBy(target: HTMLElement | any, selector: string, isDisabled: boolean) {
    _.each(this.getElementsBy(target, selector), (element) => {
      element.disabled = isDisabled;
      if (isDisabled) {
        element.classList.add('disabled');
      } else {
        element.classList.remove('disabled');
      }
    });
  }
}
