import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { Location } from '@angular/common';
import { Events } from '@services/events/events.service';
import { ObjectsService } from '@services';

@Component({
  selector: 'app-check-detail',
  templateUrl: './check-detail.page.html',
  styleUrls: ['./check-detail.page.scss'],
})
export class CheckDetailPage implements OnInit, OnDestroy {

  public headerData: any = {};
  public footerData: any = {};
  public audio: any = null;
  public skipFooter = false;
  public bodyDetail: any = {
    reason: '',
    note: ''
  };
  public unableBody = false;
  public rid = '';

  constructor(
    private checkDetailService: CheckDetailService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private events: Events,
    private objectService: ObjectsService
  ) {}

  ngOnInit() {
    this.events.subscribe('ccs:assignCheckUser', this.assignListener);
    this.rid = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:assignCheckUser', this.assignListener);
  }

  async ionViewWillEnter() {
    this.skipFooter = this.activatedRoute.snapshot.queryParamMap.get('skipFooter') === 'true';
    const destination = this.activatedRoute.snapshot.queryParamMap.get('destination') ?? null;
    this.headerData = await this.checkDetailService.getTopComponentData(this.rid, null, null, destination);
    if (this.headerData && this.headerData.type === 'unable') {
      this.unableBody = true;
      this.bodyDetail = this.headerData.bodyDetail;
      if (this.bodyDetail.audio && this.bodyDetail.audio.value) {
        this.audio = this.objectService.URI(this.bodyDetail.audio.value[0], false);
      }
      this.footerData = await this.checkDetailService.getFooterComponent(this.rid, 'unable');
    } else {
      this.footerData = await this.checkDetailService.getFooterComponent(this.rid, 'available');
    }
    if (this.skipFooter) {
      this.footerData = {};
    }
  }

  superTaskBack(): void {
    if (this.unableBody) {
      if (this.skipFooter) {
        this.location.back();
      } else {
        this.router.navigate([this.footerData.nav], {queryParams: {activeTab: 'skipped'}});
      }
    } else if (this.activatedRoute.snapshot.paramMap.get('backToMain') === 'true') {
      this.router.navigate(['pages/dashboard/main']);
    } else {
      this.location.back();
    }
  }

  private assignListener = (data) => {
    if (data) {
      this.headerData = this.checkDetailService.getTopComponentData(this.rid).then((item) => {
        this.headerData = item;
      }).catch(err => {
        this.headerData = null;
      });
    }
  };

}
