<ion-menu #menu [menuId]="menuId" [ngClass]="currentConfig?.menuClass" contentId="main-content" side="end"
          swipeGesture="false" type="overlay">
  <div (click)="menu.close()" class="menu-arrow"></div>
  <div class="custom-inputs {{ currentConfig?.formClass }}">
    <div class="page-title">{{ currentConfig?.title || 'SHARED.Filters' | translate }}</div>
    <form #formElement></form>

    <div class="action-block">
      <ion-button (click)="clearForm()" class="page-button" title='{{ "SHARED.Clear_All" | translate }}'
                  color="light"><span class="wrap-text">{{ "SHARED.Clear_All" | translate }}</span></ion-button>
      <ion-button (click)="applyFilters()" class="page-button" title='{{ "SHARED.APPLY_CHANGES" | translate }}'
                  color="secondary"><span class="wrap-text">{{ "SHARED.APPLY_CHANGES" | translate }}</span></ion-button>
    </div>
  </div>
</ion-menu>
