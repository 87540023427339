import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services/question/question-form.service';
import { AbstractFieldsModel } from '@modules/management/pages/details/check/models/abstractFieldsModel';
import { QuestionFormGroupListComponent } from '@modules/management/pages/details/check/components';

import { TranslateService } from '@ngx-translate/core';

export class DropdownSelectionModel extends AbstractFieldsModel {

  protected formConfig: any = {
    containers: true,
    prefix: 'dropdownOptions',
    fields: [
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'label',
        title: 'MGMT_DETAILS.Value',
        type: 'text',
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'translation_label',
        title: this.translateService.instant('MGMT_DETAILS.Field_Label_Translations'),
        type: 'textTranslations',
        size: 20,
      }
    ]
  };
  private fields = [
    {
      containerClass: 'question-type-field dropdownSelection',
      title: 'MGMT_DETAILS.Dropdown_Selection_Details',
      type: 'subtitle'
    },
    {
      containerClass: 'question-type-field dropdownSelection',
      title: 'MGMT_DETAILS.Dropdown_Options',
      type: 'subtitle'
    },
    {
      type: 'subtitle',
      containerClass: 'question-type-field dropdownSelection form-group-title dropdown-block'
    }
  ];

  constructor(
    protected questionFormService: QuestionFormService,
    private translateService: TranslateService
  ) {
    super(questionFormService);
  }

  public getFields(): any[] {
    return this.fields;
  }

  public showGroupList() {
    if (this.questionFormService.isModelAvailable('dropdownSelection')) {
      const groupsElement = this.questionFormService.getElementBy(`.dropdown-block`);

      if (groupsElement && !this.formGroupComponentRef) {
        this.questionFormService.dynamicComponentCreatorService.create(
          QuestionFormGroupListComponent,
          (component, componentRef) => this.onFormGroupComponentInit(component, componentRef),
          groupsElement
        );
      }
    }
  }

  public onDestroy() {
    this.formGroupComponentRef && this.formGroupComponentRef.destroy();
    this.formGroupComponentRef = null;
  }

  public getData(): any[] {
    return this.getFormData([]);
  }

  private onFormGroupComponentInit(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    component.title = this.translateService.instant('MGMT_DETAILS.Option');
    component.addButtonName = this.translateService.instant('MGMT_DETAILS.Add_Option');
    component.enableRemoval = true;
    component.enableEmptyList = true;
    component.enableBulkAdding = true;

    this.onFormGroupComponentCreate(component, componentRef);
  }

}
