import { Component, ElementRef, ViewChildren, QueryList, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PopoverController, MenuController, ModalController } from '@ionic/angular';

import {
  AccountsService,
  FormBuilderService,
  LoadingService,
  PopoverService,
  SettingsService,
  UserService,
  ZoneService,
  UserdataService,
  ObservationService
} from '@services';
import { FormGroupComponent } from '../../components/form/form.component';
import { ModalFormComponent } from '../../components/modal-form/modal-form.component';
import { DatePickerPopupComponent } from '@shared/components';
import { BaseDetailsPage } from '../../../management/pages/details/abstractDetails/abstractBaseDetails.page';
import { ReportService } from '../../services/report.service';
import { ReportingFormBuilderService } from '@modules/reporting/services/reporting-form-builder.service';
import { Events } from '@services/events/events.service';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss'],
})
export class EditMenuComponent extends BaseDetailsPage {

  @ViewChildren(FormGroupComponent) formGroupComponent: QueryList<FormGroupComponent>;

  @Input() isAvailable: boolean;
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSelectStep: EventEmitter<void> = new EventEmitter();
  @Output() onSaveAs: EventEmitter<void> = new EventEmitter();
  public formGroups: any[] = [];
  public menuOpen = false;
  public reportData: any;
  public isPreviewReportVisible: boolean = this.menuOpen;
  public title: string = this.translate.instant('SHARED.Add_Report');
  public activeStep = 1;
  public formsData: any = {};
  newFormStep1: any = {
    id: 'reportAddFormStep1',
    cancelButton: 'reportAddStep1Cancel'
  };
  editFormStep1: any = {
    id: 'reportEditFormStep1',
    cancelButton: 'reportEditMenu1Cancel',
    saveButton: 'reportEditMenu1Save'
  };
  newFormStep2: any = {
    id: 'reportAddFormStep2',
    cancelButton: 'reportAddStep2Cancel'
  };
  editFormStep2: any = {
    id: 'reportEditFormStep2',
    cancelButton: 'reportEditMenu2Cancel',
    saveButton: 'reportEditMenu2Save'
  };
  newFormStep3: any = {
    id: 'reportAddFormStep3',
    cancelButton: 'reportAddStep3Cancel',
    saveButton: 'reportAddStep3Save'
  };
  editFormStep3: any = {
    id: 'reportEditFormStep3',
    cancelButton: 'reportEditMenu3Cancel',
    saveButton: 'reportEditMenu3Save'
  };
  newFormModal: any = {
    id: 'reportAddFormModal',
    cancelButton: 'reportAddCancelModal',
    saveButton: 'reportAddSave'
  };
  editFormModal: any = {
    id: 'reportEditFormModal',
    cancelButton: 'reportEditMenuModalCancel',
    saveButton: 'reportEditMenuModalSave'
  };
  formConfigModal: any = {
    step: 'Modal',
    autocomplete: false,
    containers: true,
    prefix: 'reportAddModal',
    save: this.translate.instant('SHARED.Cancel'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      {
        containerClass: 'report-field obsdets team worker zone observation',
        required: true,
        inputtype: 'verbatim',
        name: 'name',
        title: this.translate.instant('REPORTING.EDIT_Report_Name'),
        type: 'text',
        size: 20
      },
      {
        containerClass: 'report-field obsdets team worker zone observation flex-column',
        required: false,
        inputtype: 'verbatim',
        name: 'description',
        title: this.translate.instant('REPORTING.EDIT_Report_Description'),
        type: 'textarea',
        size: 20
      }
    ]
  };
  protected newForm: any = {};
  protected formConfig: any = {};
  private accounts = this.accountsService.getUserlist(this.userdataService.locations);
  private locations = this.userService.getUserLocations(this.userdataService.locations);
  private zones = this.zoneService.getGroupedZonesByLocations(this.userdataService.locations);
  formConfigStep2: any = {
    step: 2,
    autocomplete: false,
    containers: true,
    prefix: 'reportAddStep2',
    cancel: this.translate.instant('SHARED.Cancel'),
    extraControl: this.translate.instant('SHARED.Next_Step'),
    fields: [
      {
        title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
        type: 'divider',
      },
      {
        containerClass: 'report-field obsdets observation',
        title: this.translate.instant('SHARED.Observation_Type'),
        name: 'obstype',
        type: 'selectmenu',
        role: 'none',
        multiple: true,
        placeholder: this.translate.instant('SHARED.Any_Type'),
        options: [
          {
            id: 'condition',
            description: this.translate.instant('SHARED.Unsafe_Condition')
          },
          {
            id: 'behavior',
            description: this.translate.instant('SHARED.Coaching_Opportunity')
          },
          {
            id: 'quality',
            description: this.translate.instant('SHARED.CHANGING_TYPE_TS_quality')
          },
          {
            id: 'pi',
            description: this.translate.instant('REPORTING.EDIT_pi')
          },
          {
            id: 'compliment',
            description: this.translate.instant('SHARED.Thumbs-Up')
          }
        ],
        onChange: (selections) => this.defineObstypeVisibilityByValue(selections)
      },
      {
        containerClass: 'report-field obsdets observation observationType conditionObservation qualityObservation piObservation ',
        title: this.translate.instant('REPORTING.EDIT_Observation_Status'),
        name: 'states',
        type: 'checkbox',
        role: 'none',
        class: 'inline-checkboxes',
        multiple: true,
        placeholder: 'Any Status',
        options: [
          {
            id: 'open',
            description: this.translate.instant('SHARED.Open')
          },
          {
            id: 'unassigned',
            description: this.translate.instant('SHARED.Unassigned')
          },
          {
            id: 'fixed',
            description: this.translate.instant('SHARED.Marked_as_Fixed')
          },
          {
            id: 'closed',
            description: this.translate.instant('SHARED.Closed')
          }
        ],
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets team worker zone',
        title: this.translate.instant('SHARED.Locations'),
        name: 'locations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Locations'),
        multiple: true,
        valueProperty: 'locationID',
        options: this.locations,
        func: (ref) => this.showDeactivated(ref.name, ref),
        onChange: (locationIds) => {
          this.syncFieldsBySelectedLocations(_.map(locationIds, Number));
        }
      },
      {
        containerClass: 'report-field obsdets team worker zone',
        title: this.translate.instant('SHARED.Zones'),
        name: 'zones',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.Any_Zone'),
        options: this.zones,
        func: (ref) => this.showDeactivated(ref.id, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field team worker zone',
        title: this.translate.instant('REPORTING.EDIT_Workers'),
        name: 'users',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
        valueProperty: 'userID',
        options: this.accounts,
        func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets team worker zone',
        title: this.translate.instant('SHARED.Team(s)'),
        name: 'groups',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Team'),
        multiple: true,
        valueProperty: 'groupID',
        options: this.reportingFormBuilderService.getTeamOptions(),
        func: (group) => this.showDeactivated(group.name, group),
        originalOrder: true,
        onChange: (values) => this.changeStep(this.activeStep)
      }
    ]
  };
  formConfigStep3: any = {
    step: 3,
    autocomplete: false,
    containers: true,
    prefix: 'reportAddStep3',
    save: this.translate.instant('SHARED.Save'),
    cancel: this.translate.instant('SHARED.Cancel'),
    extraControl: this.translate.instant('REPORTING.Save_As'),
    fields: [
      {
        title: this.translate.instant('REPORTING.EDIT_Observation_Type_Selectors'),
        type: 'divider'
      },
      {
        containerClass: 'report-field obsdets observation observationType conditionObservation',
        type: 'flipswitch',
        name: 'hasWorkorder',
        title: this.translate.instant('REPORTING.EDIT_Has_Workorder'),
        onText: this.translate.instant('SHARED.Yes'),
        offText: this.translate.instant('SHARED.No'),
        value: 1,
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType conditionObservation',
        title: this.translate.instant('REPORTING.EDIT_Condition_Observation_Categories'),
        name: 'categories',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Category'),
        multiple: true,
        valueProperty: 'messageID',
        options: this.settingsService.getSettingSync('category', this.userdataService.locations),
        func: (ref) => this.showDeactivated(ref.messageTitle, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType conditionObservation color-boxes',
        title: this.translate.instant('SHARED.Severity'),
        name: 'severities',
        type: 'checkbox',
        class: 'inline-checkboxes',
        role: 'none',
        multiple: true,
        options: this.reportService.severities,
        func: (item) => {
          const dot = this.reportService.colorDot(item.id);
          return '<img width=\'24\' src=\'' + dot + '\' />';
        },
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType conditionObservation color-boxes',
        title: this.translate.instant('SHARED.Likelihood'),
        name: 'likelihoods',
        type: 'checkbox',
        multiple: true,
        class: 'inline-checkboxes',
        role: 'none',
        options: this.reportService.likelihoods,
        func: (item) => {
          const dot = this.reportService.colorDot(item.id);
          return '<img width=\'24\' src=\'' + dot + '\' />';
        },
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType behaviorObservation',
        title: this.translate.instant('REPORTING.EDIT_Opportunity_Categories'),
        name: 'behaviors',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Category'),
        multiple: true,
        valueProperty: 'messageID',
        options: this.settingsService.getSettingSync('behavior', this.userdataService.locations),
        func: (ref) => this.showDeactivated(ref.messageTitle, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType behaviorObservation',
        title: this.translate.instant('REPORTING.EDIT_Mitigation_Categories'),
        name: 'mitigations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Category'),
        multiple: true,
        valueProperty: 'messageID',
        options: this.settingsService.getSettingSync('mitigation', this.userdataService.locations),
        func: (ref) => this.showDeactivated(ref.messageTitle, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType qualityObservation',
        title: this.translate.instant('REPORTING.EDIT_Quality_Categories'),
        name: 'qualitycats',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Category'),
        multiple: true,
        valueProperty: 'messageID',
        options: this.settingsService.getSettingSync('quality', this.userdataService.locations),
        func: (ref) => this.showDeactivated(ref.messageTitle, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation observationType complimentObservation',
        title: this.translate.instant('REPORTING.EDIT_Thumbs_Up_Categories'),
        name: 'compliments',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Category'),
        multiple: true,
        valueProperty: 'messageID',
        options: this.settingsService.getSettingSync('compliment', this.userdataService.locations),
        func: (ref) => this.showDeactivated(ref.messageTitle, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets team zone observation',
        title: this.translate.instant('SHARED.Locations'),
        name: 'locations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Locations'),
        multiple: true,
        valueProperty: 'locationID',
        options: this.locations,
        func: (ref) => this.showDeactivated(ref.name, ref),
        onChange: (locationIds) => {
          this.syncFieldsBySelectedLocations(_.map(locationIds, Number));
        }
      },
      {
        containerClass: 'report-field obsdets team zone observation',
        title: this.translate.instant('SHARED.Zones'),
        name: 'zones',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.Any_Zone'),
        options: this.zones,
        func: (ref) => this.showDeactivated(ref.id, ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation',
        title: this.translate.instant('REPORTING.EDIT_Creators'),
        name: 'creators',
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'userID',
        placeholder: this.translate.instant('REPORTING.EDIT_Any_Creator'),
        options: this.accounts,
        func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation',
        title: this.translate.instant('REPORTING.EDIT_Owners'),
        name: 'owners',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('REPORTING.EDIT_Any_Owner'),
        valueProperty: 'userID',
        options: this.accounts,
        func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field team zone',
        title: this.translate.instant('REPORTING.EDIT_Workers'),
        name: 'users',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
        valueProperty: 'userID',
        options: this.accounts,
        func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation team zone',
        title: this.translate.instant('SHARED.Team(s)'),
        name: 'groups',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Team'),
        multiple: true,
        valueProperty: 'groupID',
        options: this.reportingFormBuilderService.getTeamOptions(),
        func: (group) => this.showDeactivated(group.name, group),
        originalOrder: true,
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation compliment',
        title: this.translate.instant('REPORTING.EDIT_Receiving_Workers'),
        name: 'recipients',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
        valueProperty: 'userID',
        options: this.accounts,
        func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets observation compliment',
        title: this.translate.instant('SHARED.Team(s)'),
        name: 'targetGroups',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Any_Team'),
        multiple: true,
        valueProperty: 'groupID',
        options: this.reportingFormBuilderService.getTeamOptions(),
        func: (group) => this.showDeactivated(group.name, group),
        originalOrder: true,
        onChange: (values) => this.changeStep(this.activeStep)
      }
    ]
  };
  private customDateRange: any = {};
  formConfigStep1: any = {
    step: 1,
    autocomplete: false,
    containers: true,
    prefix: 'reportAddStep1',
    cancel: this.translate.instant('SHARED.Cancel'),
    extraControl: this.translate.instant('SHARED.Next_Step'),
    fields: [
      {
        title: this.translate.instant('REPORTING.EDIT_title'),
        type: 'divider',
      },
      // {
      //   containerClass: 'report-field worker',
      //   title: this.translate.instant('REPORTING.EDIT_title2'),
      //   name: 'workerPrimaryField',
      //   type: 'selectmenu',
      //   multiple: false,
      //   required: true,
      //   originalOrder: false,
      //   options: this.reportService.workerReportFieldOptions,
      //   onChange: (val, theForm: any) => this.updateGraphFields('secondary')
      // },
      // {
      //   containerClass: 'report-field worker',
      //   title: this.translate.instant('REPORTING.EDIT_title3'),
      //   name: 'workerSecondaryField',
      //   type: 'selectmenu',
      //   placeholder: this.translate.instant('SHARED.None'),
      //   multiple: false,
      //   required: false,
      //   originalOrder: false,
      //   canClear: true,
      //   options: this.reportService.workerReportFieldOptions,
      //   onChange: (val, theForm: any) => this.updateGraphFields('primary')
      // },
      {
        containerClass: 'report-field obsdets observation',
        title: this.translate.instant('REPORTING.EDIT_title2'),
        name: 'observationPrimaryField',
        type: 'selectmenu',
        multiple: false,
        required: true,
        originalOrder: false,
        // options: this.reportService.observationReportFieldOptions,
        hideFieldsOnValue: {
          fieldNames: ['observationSecondaryField', 'timespan', 'period', 'reportColumns', 'graphType'],
          value: 'none'
        },
        onChange: (val, theForm: any) => this.updateGraphFields('secondary')
      },
      {
        containerClass: 'report-field obsdets observation also-field',
        title: this.translate.instant('REPORTING.EDIT_title3'),
        name: 'observationSecondaryField',
        canClear: true,
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.None'),
        multiple: false,
        originalOrder: false,
        required: false,
        // options: this.reportService.observationReportFieldOptions,
        onChange: (val, theForm: any) => this.updateGraphFields('primary')
      },
      {
        containerClass: 'report-field obsdets observation team worker zone',
        title: this.translate.instant('SHARED.Timespan'),
        name: 'timespan',
        type: 'selectmenu',
        required: true,
        multiple: false,
        options: this.reportService.timespans,
        onChange: (value: any) => {
          if (value === 'custom') {
            this.popover.create(<any>{
              component: DatePickerPopupComponent,
              animated: false,
              componentProps: {
                filterObject: this.customDateRange,
              }
            }).then((element: HTMLIonPopoverElement) => {
              element.present();
            });
          } else {
            const id: string = this.messageID && !this.copyMode ? '#reportEditperiod' : '#reportAddStep1period';
            this.syncPeriodValues(id, value);
          }
        },
        originalOrder: true,
        default: '30days'
      },
      {
        containerClass: 'report-field obsdets observation team worker zone',
        title: this.translate.instant('SHARED.Period'),
        name: 'period',
        type: 'selectmenu',
        required: true,
        multiple: false,
        options: this.reportService.buildPeriodList('30days'),
        originalOrder: true,
        onChange: () => this.updateGraphFields(),
      },
      {
        containerClass: 'report-field obsdets observation',
        title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
        name: 'observationReportColumns',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.None'),
        multiple: true,
        required: true,
        // options: this.reportService.observationReportColumnOptions,
        onChange: (values) => this.syncGraphSecondaryField(values)
      },
      // {
      //   containerClass: 'report-field worker',
      //   title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      //   name: 'workerReportColumns',
      //   type: 'selectmenu',
      //   placeholder: this.translate.instant('SHARED.None'),
      //   multiple: true,
      //   required: true,
      //   options: this.reportService.workerReportColumnOptions,
      //   onChange: (values) => this.syncGraphSecondaryField(values)
      // },
      {
        containerClass: 'report-field obsdets observation team worker zone',
        title: this.translate.instant('REPORTING.EDIT_Type_of_Graph'),
        name: 'graphType',
        type: 'selectmenu',
        multiple: false,
        required: true,
        originalOrder: true,
        options: [
          {
            id: 'none',
            description: this.translate.instant('SHARED.None'),
          },
          {
            id: 'pareto',
            description: this.translate.instant('REPORTING.EDIT_Pareto'),
          },
          {
            id: 'pie',
            description: this.translate.instant('REPORTING.EDIT_Pie_Chart'),
          },
          {
            id: 'line',
            description: this.translate.instant('REPORTING.EDIT_Line_Chart'),
          },
          {
            id: 'lineNoFill',
            description: this.translate.instant('REPORTING.EDIT_Line_Chart_No_Fill'),
          },
          {
            id: 'bar',
            description: this.translate.instant('REPORTING.EDIT_Bar_Graph'),
          },
          {
            id: 'stacked',
            description: this.translate.instant('REPORTING.EDIT_stacked'),
          },
          {
            id: 'hbar',
            description: this.translate.instant('REPORTING.EDIT_hbar'),
          },
          {
            id: 'hstacked',
            description: this.translate.instant('REPORTING.EDIT_hstacked'),
          },
        ],
        hideFieldsOnValue: {
          fieldNames: ['observationGraphPrimary', 'observationGraphSecondary', 'workerGraphPrimary', 'workerGraphSecondary'],
          value: 'none'
        },
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets graphing observation',
        title: this.translate.instant('REPORTING.EDIT_Graph_by'),
        name: 'observationGraphPrimary',
        type: 'selectmenu',
        multiple: false,
        required: false,
        // options: _.unionBy(this.reportService.observationReportFieldOptions, [
        //   { id: 'period', description: this.translate.instant('SHARED.Period') },
        //   // { id: 'timespan', description: "Timespan" }
        // ], 'id'),
        onChange: (values) => this.changeStep(this.activeStep)
      },
      {
        containerClass: 'report-field obsdets graphing observation',
        title: this.translate.instant('REPORTING.EDIT_Include_in_graph'),
        name: 'observationGraphSecondary',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.None'),
        multiple: false,
        required: false,
        // options: this.reportService.observationReportColumnOptions,
        onChange: (values) => this.changeStep(this.activeStep)
      },
      // {
      //   containerClass: 'report-field obsdets graphing worker',
      //   title: this.translate.instant('REPORTING.EDIT_Graph_by'),
      //   name: 'workerGraphPrimary',
      //   type: 'selectmenu',
      //   multiple: false,
      //   required: false,
      //   options: _.unionBy(this.reportService.workerReportFieldOptions, [
      //     { id: 'period', description: this.translate.instant('SHARED.Period') },
      //     // { id: 'timespan', description: "Timespan" }
      //   ], 'id'),
      //   onChange: (values) => this.changeStep(this.activeStep)
      // },
      // {
      //   containerClass: 'report-field obsdets graphing worker',
      //   title: this.translate.instant('REPORTING.EDIT_Include_in_graph'),
      //   name: 'workerGraphSecondary',
      //   type: 'selectmenu',
      //   placeholder: this.translate.instant('SHARED.None'),
      //   multiple: false,
      //   required: false,
      //   options: this.reportService.workerReportColumnOptions,
      //   onChange: (values) => this.changeStep(this.activeStep)
      // },
      {
        containerClass: 'report-field obsdets observation observationType conditionObservation qualityObservation piObservation ',
        // title: this.translate.instant('Include All Results(including "0")'),
        name: 'includingAll',
        type: 'checkbox',
        role: 'none',
        class: 'inline-checkboxes',
        multiple: false,
        placeholder: 'Include All Results',
        options: [
          {
            id: 'include',
            description: this.translate.instant('Include All Results(including "0")')
          }
        ],
        onChange: (values) => this.changeStep(this.activeStep)
      },
    ]
  };
  private additionalFormData: any = {};

  constructor(
    protected route: ActivatedRoute,
    protected formBuilderService: FormBuilderService,
    protected elementRef: ElementRef,
    protected popoverService: PopoverService,
    protected location: Location,
    protected loadingService: LoadingService,
    private reportService: ReportService,
    private userService: UserService,
    private settingsService: SettingsService,
    private accountsService: AccountsService,
    private zoneService: ZoneService,
    protected router: Router,
    private userdataService: UserdataService,
    protected translate: TranslateService,
    private observationService: ObservationService,
    private events: Events,
    private popover: PopoverController,
    private menu: MenuController,
    public modalController: ModalController,
    private reportingFormBuilderService: ReportingFormBuilderService
  ) {
    super(route, formBuilderService, elementRef, popoverService, location, loadingService, translate);
    this.disableUpdatingDataWhileBack = true;

    this.duplicateErrorPopoverConfig.title = this.translate.instant('SHARED.Duplicate_Username');
    this.duplicateErrorPopoverConfig.description = this.translate.instant('SHARED.Duplicate_error');

    if (_.isEmpty(this.observationService.observations.data)) {
      this.loadingService.enable();
      this.events.subscribe('observationUpdate', this.observationListener);
    }
  }

  public cancel(): void {
    if (this.copyMode) {
      this.modalController.dismiss();
      this.router.navigate(['/pages/reporting']);
    } else {
      this.onCancel.emit();
    }
  }

  public changeStep(stepNumber: number): void {
    this.menuOpen = true;
    this.activeStep = stepNumber;
    this.isPreviewReportVisible = true;
    this.onSelectStep.emit();
  }

  public initSteps(formData: any): void {
    if (formData.type === 'observation') {
      this.formGroups = [
        {
          formConfig: this.formConfigStep1,
          newForm: this.newFormStep1,
          editForm: this.editFormStep1
        },
        {
          formConfig: this.formConfigStep2,
          newForm: this.newFormStep2,
          editForm: this.editFormStep2
        },
        {
          formConfig: Object.assign(this.formConfigStep3, {
            extraControl: this.messageID ? this.formConfigStep3.extraControl : null
          }),
          newForm: this.newFormStep3,
          editForm: this.editFormStep3
        }
      ];
    } else {
      this.formGroups = [
        {
          formConfig: this.formConfigStep1,
          newForm: this.newFormStep1,
          editForm: this.editFormStep1
        },
        {
          formConfig: Object.assign(this.formConfigStep2, {
            save: this.translate.instant('SHARED.Save'),
            extraControl: this.messageID ? this.translate.instant('REPORTING.Save_As') : null,
          }),
          newForm: this.newFormStep2,
          editForm: this.editFormStep2
        }
      ];
    }

    _.assign(this.formsData, {
      type: formData.type,
      name: formData.name
    });
  }

  public onFormsLoad() {
    this.changeStep(1);
  }

  public getFormsData(isModal: boolean = false) {
    let formId: string = this.messageID && !this.copyMode ? '#reportEditForm' : '#reportAddForm';

    if (isModal) {
      formId = '#reportEditFormModal';
    }

    this.formGroupComponent.forEach((formGroupComponent: FormGroupComponent) => {
      const formMenuId = this.messageID && !this.copyMode ? formGroupComponent.editForm.id : formGroupComponent.newForm.id;
      Object.assign(this.formsData, this.formBuilderService.getFormData($('#' + formMenuId)));

    });
    Object.assign(this.formsData, this.formBuilderService.getFormData($(formId)));

    this.formsData.selectors = this.reportService._encodeSelectors(this.formsData);

    return this.formsData;
  }

  async onSaveAsReport() {
    this.copyMode = 'true';
    const modal = await this.modalController.create(<any>{
      component: ModalFormComponent,
      componentProps: {
        formConfig: this.formConfigModal,
        newForm: this.newFormModal,
        editForm: this.editFormModal,
        reportID: this.messageID,
        onEvent: () => this.onSaveReport(true)
      },
      cssClass: 'form-group-component'
    });
    return await modal.present();
  }

  public onSaveReport(isModal: boolean): void {
    this.loadingService.enable();
    this.getFormsData(isModal);
    const queryHandler = this.messageID && !this.copyMode ? this.updateHandler(this.formsData) : this.addHandler(this.formsData);
    queryHandler
      .then((data) => {
        if (data && data.reqStatus !== 'OK' && this.duplicateErrorPopoverConfig.title) {
          this.customValidate.validateServerFunction(this.formConfig, this.duplicateErrorPopoverConfig, _.get(_.find(this.formConfig.fields, 'unique'), 'name'));
          this.loadingService.disable();
        } else {
          // just in case the update handler cleared it
          if (this.refreshHandler) {
            this.loadingService.enable();
            this.refreshHandler()
              .then(() => this.onSaveRequestFinish())
              .catch(() => this.onSaveRequestFinish());
          } else {
            this.onSaveRequestFinish();
          }
        }
      }).catch((errorMessage: string) => {
      this.failedErrorPopoverConfig.description = this.translate.instant('SHARED.ERROR_COMM') + ` ${errorMessage}`;
      this.popoverService.show(this.failedErrorPopoverConfig);
      this.loadingService.disable();
    });

    this.formConfigStep1.fields.filter((obj) => obj.required).map((obj) => {
      if (obj.type === 'text') {
        if (this.elementRef.nativeElement.querySelector(`input[name="${obj.name}"]`).value !== '') {
          this.elementRef.nativeElement.querySelector(`input[name="${obj.name}"]`).closest('.ui-field-contain').classList.remove('custom-error');
        }
      } else if (obj.type === 'selectmenu') {
        if (this.elementRef.nativeElement.querySelector(`select[name="${obj.name}"]`).value !== '') {
          this.elementRef.nativeElement.querySelector(`select[name="${obj.name}"]`).closest('.ui-field-contain').classList.remove('custom-error');
        }
      }
    });
  }

  public openAddReports() {
    this.menu.enable(true, 'addReports');
    this.menu.open('addReports');
    this.menuOpen = true;
  }

  public onClose() {
    this.menuOpen = false;
  }

  protected addHandler: any = (formData: any) => this.reportService.add(formData);

  protected updateHandler: any = (formData: any) => {
    this.replaceMessageIdWith('reportID', formData);
    Object.assign(formData, _.pickBy(this.additionalFormData, _.identity));
    if (formData.timespan === 'custom') {
      Object.assign(formData, this.customDateRange);
    }
    return this.reportService.update(formData);
  };

  protected getData(): any {
  }

  private observationListener = () => {
    this.reportData = _.find(_.cloneDeep(this.reportService.reports.data), <any>{reportID: +this.messageID});
    this.events.unsubscribe('observationUpdate', this.observationListener);
    this.loadingService.disable();
  };

  private showDeactivated(value, ref: any): string {
    if (ref.disabledAt || ( _.has(ref, 'active') && !ref.active)) {
      return value + ' (' + this.translate.instant('SHARED.Deactivated') + ')';
    } else {
      return value;
    }
  }

  private onSaveRequestFinish(): void {
    this.onComplete && this.onComplete();
    this.cancel();
    this.loadingService.disable();
  }

  private syncPeriodValues(id, data): void {
    const period = $(id).val();

    if (data === 'custom') {
      data = this.reportService.buildPeriodListByCustomDate(this.customDateRange);
    }

    const fieldOptions: any = Object.assign(_.find(this.formConfigStep1.fields, {name: 'period'}), {
      options: this.reportService.buildPeriodList(data)
    });
    this.formBuilderService.replaceSelectOptionsWith(id, this.formConfigStep1, fieldOptions, {period});
    this.changeStep(this.activeStep);
  }

  private syncGraphSecondaryField(values: any[] = null): void {
    this.reportingFormBuilderService.syncGraphSecondaryField(this.formsData.type, this.formConfigStep1, values);
    this.changeStep(this.activeStep);
  }

  private updateGraphFields(specificFieldName?: string) {
    this.reportingFormBuilderService.updateGraphFields(this.formsData.type, this.formConfigStep1, specificFieldName);
    this.changeStep(this.activeStep);
  }

  private syncFieldsBySelectedLocations(locationIds): void {
    this.reportingFormBuilderService.syncFieldsBySelectedLocations(locationIds, this.formConfigStep3);
    this.changeStep(this.activeStep);
  }

  private defineObstypeVisibilityByValue(types: string[]): void {
    this.reportingFormBuilderService.defineObstypeVisibilityByValue(this.formConfig, types);
    this.changeStep(this.activeStep);
  }

}
