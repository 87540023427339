import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ILocationHealth, Module } from '@services';

@Component({
  selector: 'app-site-health',
  templateUrl: './site-health.component.html',
  styleUrls: ['./site-health.component.scss'],
})
export class SiteHealthComponent {
  @Input() locationsHealth: ILocationHealth[];
  public modules = Module;

  constructor(
    private router: Router
  ) {
  }

  public locationDetail(locationID): void {
    this.router.navigate(['pages/dashboard/location-dashboard'], {queryParams: {locationID}});
  }
}
