import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-bulk-options-modal',
  templateUrl: './bulk-options-modal.component.html',
  styleUrls: ['./bulk-options-modal.component.scss'],
})
export class BulkOptionsModalComponent {

  public selectConfig = {
    options: {
      tags: true,
      multiple: true,
      matcher: () => false
    },
    data: []
  };
  public selectedData: string[] = [];

  constructor(private popoverController: PopoverController) {
  }

  public close() {
    this.popoverController.dismiss();
  }

  public onSelect(data) {
    this.selectedData = data.value || [];
  }

  public addOptions() {
    this.popoverController.dismiss(this.selectedData);
  }

}
