import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService, SubscriberService } from '@services';

@Component({
  selector: 'app-preparing',
  templateUrl: './preparing.page.html',
  styleUrls: ['./preparing.page.scss'],
})
export class PreparingPage implements OnInit {

  public brand = '';
  public loaderImg = '';

  constructor(
    public router: Router,
    public elementRef: ElementRef,
    private loadingService: LoadingService,
    private subscriber: SubscriberService
  ) {
    setTimeout(() => {
      this.loadingService.disable();
    });
  }

  ngOnInit(): void {
    const d = this.subscriber.brandInfo();
    this.brand = d.brand;
    this.loaderImg = d.loaderImg;
  }

  ionViewDidEnter(): void {
    document.querySelector('ion-loading').style.display = 'none';
  }
}
