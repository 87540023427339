import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  CommsService,
  UtilsService,
  UserService,
  SettingsService,
  AccountsService,
  TeamsService,
  GearService,
  ObservationService,
  ShiftService,
  PermissionsService,
  UserdataService,
  SubscriberService
} from '@services';

import * as _ from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { filter, includes, toInteger } from 'lodash';
import {awaitHandler} from '@utils/awaitHandler';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public workerData: any = {};
  public workerEventData: any = {};
  public checkOpts: any;
  public gearOpts: any;
  public PPEReport: any;
  public currentReportChart: any = null;

  public tabs = [
    {
      key: 'favorites',
      value: this.translate.instant('REPORTING.My_Reports'),
      grouped: false
    },
    {
      key: 'all',
      value: this.translate.instant('REPORTING.All_Reports'),
      grouped: true
    },
  ];

  reports: any = {
    data: [],
    lastRequest: null
  };
  // is is important to save custom date range into service
  // to refer later at various places, the current implementation is not very robust
  // and the values go out of the scope.
  public customDateRange: any = {};
  public customDateCreated: any = {};
  public customDateFixed: any = {};
  public customDateClosed: any = {};

  reportTypes = [
    {
      id: 'observation',
      description: this.translate.instant('REPORTING.Service_Observation_Summary'),
      tooltip: this.translate.instant('REPORTING.Service_tooltip')
    },
    {
      id: 'obsDetail',
      description: this.translate.instant('REPORTING.Obs_Detail'),
      tooltip: this.translate.instant('REPORTING.Service_tooltip')
    },
    {
      id: 'check',
      description: this.translate.instant('SHARED.Service_Check_Summary'),
      tooltip: this.translate.instant('REPORTING.Check_Report_tooltip')
    },
    {
      id: 'checkDetail',
      description: this.translate.instant('SHARED.Check_Detail'),
      tooltip: this.translate.instant('REPORTING.Check_Details_Report_tooltip')
    },
    {
      id: 'PPESummary',
      description: this.translate.instant('REPORTING.PPE_Summary'),
      tooltip: this.translate.instant('REPORTING.PPE_Summary_Report_tooltip')
    },
    {
      id: 'worker',
      description: this.translate.instant('REPORTING.Worker_Detail'),
      tooltip: this.translate.instant('REPORTING.Worker_Report_tooltip')
    }
  ];

  public currentPeriods = [];

  timespans = [
    {
      id: '7days',
      description: this.translate.instant('SHARED.7_days'),
      periods: ['none', 'hours', 'days']
    },
    {
      id: '14days',
      description: this.translate.instant('SHARED.14_Days'),
      periods: ['none', 'hours', 'days']
    },
    {
      id: '30days',
      description: this.translate.instant('SHARED.30_days'),
      periods: ['none', 'days', 'weeks']
    },
    {
      id: '90days',
      description: this.translate.instant('SHARED.90_days'),
      periods: ['none', 'days', 'weeks', 'months']
    },
    {
      id: '180days',
      description: this.translate.instant('SHARED.180_days'),
      periods: ['none', 'days', 'weeks', 'months', 'quarters']
    },
    {
      id: '365days',
      description: this.translate.instant('SHARED.365_days'),
      periods: ['none', 'days', 'weeks', 'months', 'quarters']
    },
    {
      id: 'today',
      description: this.translate.instant('SHARED.Today'),
      periods: ['none', 'hours']
    },
    {
      id: 'yesterday',
      description: this.translate.instant('SHARED.Yesterday'),
      periods: ['none', 'hours']
    },
    {
      id: 'thisweek',
      description: this.translate.instant('SHARED.This_Week'),
      periods: ['none', 'hours', 'days']
    },
    {
      id: 'lastweek',
      description: this.translate.instant('SHARED.Last_Week'),
      periods: ['none', 'hours', 'days']
    },
    {
      id: 'thismonth',
      description: this.translate.instant('SHARED.This_Month'),
      periods: ['none', 'days', 'weeks']
    },
    {
      id: 'lastmonth',
      description: this.translate.instant('SHARED.Last_Month'),
      periods: ['none', 'days', 'weeks']
    },
    {
      id: 'thisfiscalquarter',
      description: this.translate.instant('SHARED.This_Fiscal_Quarter'),
      periods: ['none', 'weeks', 'months'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: 'thisquarter',
      description: this.translate.instant('SHARED.This_Quarter'),
      periods: ['none', 'weeks', 'months']
    },
    {
      id: 'lastfiscalquarter',
      description: this.translate.instant('SHARED.Last_Fiscal_Quarter'),
      periods: ['none', 'weeks', 'months'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: 'lastquarter',
      description: this.translate.instant('SHARED.Last_Quarter'),
      periods: ['none', 'weeks', 'months']
    }, {
      id: 'thisfiscalyear',
      description: this.translate.instant('SHARED.This_Fiscal_Year'),
      periods: ['none', 'months', 'quarters'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: 'thisyear',
      description: this.translate.instant('SHARED.This_Year'),
      periods: ['none', 'months', 'quarters']
    },
    {
      id: 'lastfiscalyear',
      description: this.translate.instant('SHARED.Last_Fiscal_Year'),
      periods: ['none', 'months', 'quarters'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: 'lastyear',
      description: this.translate.instant('SHARED.Last_Year'),
      periods: ['none', 'months', 'quarters']
    },
    {
      id: 'custom',
      description: this.translate.instant('SHARED.Custom'),
      periods: ['none']
    }
  ];

  periods = [
    {
      id: 'none',
      description: this.translate.instant('SHARED.None')
    },
    {
      id: 'hours',
      description: this.translate.instant('SHARED.1_Hour')
    },
    {
      id: 'days',
      description: this.translate.instant('SHARED.1_Day')
    },
    {
      id: 'weeks',
      description: this.translate.instant('SHARED.1_Week')
    },
    {
      id: 'months',
      description: this.translate.instant('SHARED.1_Month')
    },
    {
      id: 'quarters',
      description: this.translate.instant('SHARED.1_Quarter')
    },
    {
      id: 'years',
      description: this.translate.instant('SHARED.1_Year')
    }
  ];
  likelihoods = [
    {
      id: 'medium',
      description: this.translate.instant('SHARED.Low'),
      class: 'severity-medium'
    },
    {
      id: 'high',
      description: this.translate.instant('SHARED.Medium'),
      class: 'severity-high'
    },
    {
      id: 'highest',
      description: this.translate.instant('SHARED.High'),
      class: 'severity-highest'
    }
  ];
  severities = [
    {
      id: 'medium',
      description: this.translate.instant('SHARED.Low'),
      class: 'severity-medium'
    },
    {
      id: 'high',
      description: this.translate.instant('SHARED.Medium'),
      class: 'severity-high'
    },
    {
      id: 'highest',
      description: this.translate.instant('SHARED.High'),
      class: 'severity-highest'
    }
  ];

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private utils: UtilsService,
    private settingsService: SettingsService,
    private userService: UserService,
    private accountsService: AccountsService,
    private userdataService: UserdataService,
    private teamsService: TeamsService,
    private gearService: GearService,
    private router: Router,
    private observations: ObservationService,
    private shift: ShiftService,
    private translate: TranslateService,
    private permissions: PermissionsService,
    private subscriber: SubscriberService
  ) {
    this.filterFiscalYearTimeSpans();
  }

  private filterFiscalYearTimeSpans() {
    _.remove(this.timespans, (span) => span.enabled && !span.enabled());
  }

  /**
   *
   * @param theReport an object with all of the report settings.  This assumes encodeSelectors as been called on
   * the selectors already.
   */
  public add(theReport: any): Promise<any> {
    theReport.cmd = 'addReport';
    theReport.sendTime = Date.now();
    theReport.selectors = this._encodeSelectors(theReport);
    if (theReport.access) {
      theReport.access = 'private';
    } else {
      theReport.access = 'shared';
    }

    return this.commsService.sendMessage(this.encode(theReport));
  }

  public update(reportDef: any): Promise<any> {
    reportDef.selectors = this._encodeSelectors(reportDef);

    if (reportDef.active === undefined) {
      reportDef.active = 0;
    }
    if (reportDef.access) {
      reportDef.access = 'private';
    } else {
      reportDef.access = 'shared';
    }
    reportDef.cmd = 'updateReport';
    reportDef.sendTime = Date.now();

    return this.commsService.sendMessage(this.encode(reportDef));
  }

  public copy(reportID): Promise<any> {
    this.logger.log('copying report');
    let f = this.getByID(+reportID);

    f.cmd = 'addReport';
    f.sendTime = Date.now();
    f.name = this.translate.instant('REPORTING.Copy_of') + f.name;
    const p = new Promise((resolve, reject) => {
      this.commsService.sendMessage(this.encode(f))
        .then((data) => {
          this.refresh()
            .then((ret) => {
              resolve(ret);
            });
        })
        .catch(err => {
          reject(err);
        });
    });

    return p;
  }

  public delete(reportID): Promise<any> {
    const f = {
      cmd: 'deleteReport',
      reportID
    };
    const p = new Promise((resolve, reject) => {
      this.commsService.sendMessage(f)
        .then(theResult => {
          this.refresh()
            .then(refreshResult => {
              resolve(refreshResult);
            });
        });
    });

    return p;
  }

  public async deleteReports(IDs: number[]): Promise<any> {
    if (IDs?.length) {
      const requests: Promise<any>[] = _.map(IDs, (reportID: number) => {
        const f = {
          cmd: 'deleteReport',
          reportID,
        };

        return this.commsService.sendMessage(f);
      });
      await awaitHandler(Promise.all(requests));

      return this.refresh().then(refreshResult => Promise.resolve(refreshResult));
    }

    return Promise.resolve();
  }

  public async copyReports(IDs: number[]): Promise<any> {
    if (IDs?.length) {
      const requests: Promise<any>[] = _.map(IDs, (reportID: number) => {
        let f = this.getByID(+reportID);
        f.cmd = 'addReport';
        f.sendTime = Date.now();
        f.name = this.translate.instant('REPORTING.Copy_of') + f.name;

        return this.commsService.sendMessage(this.encode(f));
      });
      await awaitHandler(Promise.all(requests));

      return this.refresh().then(refreshResult => Promise.resolve(refreshResult));
    }

    return Promise.resolve();
  }

  public getWorkerData(params): any {
    const when = Date.now();

    return this.commsService.sendMessage({
      cmd: 'getWorkerData',
      sendTime: when,
      ...params
    }, false, false).then((data) => {
      const result = {};

      if (data && data.reqStatus === 'OK') {
        _.assign(result, {
          users: data.result.users,
          intervals: data.result.intervals || null
        });
      }

      this.workerData = result;

      return result;
    });
  }


  public getWorkerEvents(params): Promise<any> {
    const when = Date.now();
    const f = {
      cmd: 'getWorkerEvents',
      sendTime: when,
      ...params
    };

    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(f, false, false).then((data) => {
        const result = {};
        if (data && data.reqStatus === 'OK') {
          _.assign(result, {
            users: data.result.users,
            intervals: data.result.intervals || null
          });
        }
        this.workerEventData = result;
        resolve(data.result.users);
      });
    });
  }

  /**
   * decodeReport - unpack the JSON for a report
   *
   * @param reportData - a report data object as returned from the backend
   * @returns  a report data object where any stringified JSON is expanded
   */
  decode(reportData) {
    const r = {};
    _.forEach(reportData, function (ref, key) {
      if (typeof reportData[key] === 'string' &&
        (key === 'selectors' || key === 'recipientSelectors')) {
        try {
          if (reportData[key].indexOf('{') !== 0) {
            r[key] = JSON.parse('{ ' + reportData[key] + '}');
          } else {
            r[key] = JSON.parse(reportData[key]);
          }
        } catch (err) {
          this.logger.log(err);
          r[key] = {};
        }
      } else {
        r[key] = reportData[key];
      }
    });
    return r;
  }

  encode(theReport) {
    const r = {};
    Object.keys(theReport).forEach((key) => {
      let v;
      let o;
      // selectors is an
      if ((key === 'selectors' || key === 'recipientSelectors') && typeof theReport[key] !== 'string') {
        // preferences used to be in here too - but those are not managed on the dashboard
        // these are JSON objects that need to be a string
        v = theReport[key];
        o = {};
        if (typeof (v) === 'object') {
          if (Array.isArray(v)) {
            _.forEach(v, (item) => {
              o[item] = 1;
            });
          } else {
            o = v;
          }
        }
        r[key] = JSON.stringify(o);
      } else {
        r[key] = theReport[key];
      }
    });
    return r;
  }

  public _encodeSelectors(fData: any) {
    const r: any = {
      name: fData.name,
      description: fData.description,
      obstype: this.utils.makeArray(fData.obstype),
      states: this.utils.makeArray(fData.states),
      hasWorkorder: (fData.hasWorkorder === undefined || fData.hasWorkorder === '') ? 0 : 1,
      ownershipStatus: this.utils.makeArray(fData.ownershipStatus),
      locations: this.utils.makeArray(fData.locations, parseInt),
      zones: this.utils.makeArray(fData.zones),
      severities: this.utils.makeArray(fData.severities),
      likelihoods: this.utils.makeArray(fData.likelihoods),
      creators: this.utils.makeArray(fData.creators, parseInt),
      categories: this.utils.makeArray(fData.categories, parseInt),
      behaviors: this.utils.makeArray(fData.behaviors, parseInt),
      mitigations: this.utils.makeArray(fData.mitigations, parseInt),
      qualitycats: this.utils.makeArray(fData.qualitycats, parseInt),
      compliments: this.utils.makeArray(fData.compliments, parseInt),
      picats: this.utils.makeArray(fData.picats, parseInt),
      owners: this.utils.makeArray(fData.owners, parseInt),
      users: this.utils.makeArray(fData.users, parseInt),
      creatorGroups: this.utils.makeArray(fData.creatorGroups, parseInt),
      groups: this.utils.makeArray(fData.groups, parseInt),
      tags: this.utils.makeArray(fData.tags, parseInt),
      recipients: this.utils.makeArray(fData.recipients, parseInt),
      targetGroups: this.utils.makeArray(fData.targetGroups, parseInt),
      assigned: this.utils.makeArray(fData.assigned, parseInt),
      assignedTeam: this.utils.makeArray(fData.assignedTeam, parseInt),
      assignedPerms: fData.assignedPerms,
      timespan: fData.timespan,
      period: fData.period,
      graphType: fData.graphType,
      includingAll: fData.includingAll,
      showDataLabels: fData.showDataLabels,
      filtersFields: JSON.parse(fData.filtersFields)
    };
    if (fData.type === 'zone') {
      r.primary = fData.zonePrimaryField;
      r.secondary = fData.zoneSecondaryField || null;
      r.extraColumns = this.utils.makeArray(fData.zoneReportColumns);
    } else if (fData.type === 'worker') {
      r.workerGroups = fData.workerGroups;
      r.userStatus = fData.userStatus;
      r.activityTypes = fData.activityTypes;
      r.primary = fData.workerPrimaryField;
      r.secondary = fData.workerSecondaryField || null;
      r.extraColumns = this.utils.makeArray(fData.workerReportColumns);
      r.permissions = fData.permissions;
      if (fData.graphType !== 'none') {
        // we are graphing.  by what?
        r.graphPrimary = fData.workerGraphPrimary;
        r.graphSecondary = fData.workerGraphSecondary;
      }
    } else if (fData.type === 'observation' || fData.type === 'obsDetail') {
      r.primary = fData.observationPrimaryField;
      r.secondary = fData.observationSecondaryField || null;
      r.categories = this.utils.makeArray(fData.categories, parseInt);
      r.creatorRole = this.utils.makeArray(fData.creatorRole, parseInt);
      r.ownerRole = this.utils.makeArray(fData.ownerRole, parseInt);
      if (fData.graphType !== 'none') {
        // we are graphing.  by what?
        r.graphPrimary = fData.observationGraphPrimary;
        r.graphSecondary = fData.observationGraphSecondary;
      }

      r.dateCreated = fData.dateCreated;
      if (fData.dateCreated === 'custom') {
        r.dateCreatedStart = this.customDateCreated.startTime;
        r.dateCreatedEnd = this.customDateCreated.endTime;
      }
      r.dateFixed = fData.dateFixed;
      if (fData.dateFixed === 'custom') {
        r.dateFixedStart = this.customDateFixed.startTime;
        r.dateFixedEnd = this.customDateFixed.endTime;
      }
      r.dateClosed = fData.dateClosed;
      if (fData.dateClosed === 'custom') {
        r.dateClosedStart = this.customDateClosed.startTime;
        r.dateClosedEnd = this.customDateClosed.endTime;
      }

      if (fData.type === 'obsDetail') {
        r.extraColumns = this.setExtraColumns(fData.obsDetailReportColumns);
      } else {
        r.extraColumns = this.utils.makeArray(fData.observationReportColumns);
      }

    } else if (fData.type === 'check' || fData.type === 'checkDetail') {
      r.checkStatus = this.utils.makeArray(fData.checkStatus);
      r.checkResults = this.utils.makeArray(fData.checkResults);
      r.checkType = this.utils.makeArray(fData.checkType);
      r.deploymentType = this.utils.makeArray(fData.deploymentType);
      r.deploymentName = this.utils.makeArray(fData.deploymentName);
      r.targetLocations = this.utils.makeArray(fData.targetLocations);
      r.targetZones = this.utils.makeArray(fData.targetZones);
      r.targetTeams = this.utils.makeArray(fData.targetTeams);
      r.targetPermissions = this.utils.makeArray(fData.targetPermissions);
      r.targetUsers = this.utils.makeArray(fData.targetUsers);
      r.permissions = this.utils.makeArray(fData.permissions);

      if (fData.type === 'check') {
        r.checkName = this.utils.makeArray(fData.checkName);
        r.primary = fData.checkPrimaryField;
        r.secondary = fData.checkSecondaryField || null;
        r.extraColumns = this.utils.makeArray(fData.checkReportColumns);
        if (fData.graphType !== 'none') {
          // we are graphing.  by what?
          r.graphPrimary = fData.checkGraphPrimary;
          r.graphSecondary = fData.checkGraphSecondary;
        }
      } else {
        r.checkDetailName = fData.checkDetailName;
        // r.primary = fData.checkDetailPrimaryField;
        // r.secondary = fData.checkDetailSecondaryField || null;
        r.extraColumns = this.setExtraColumns(fData.checkDetailReportColumns);
      }
    } else if (fData.type === 'PPESummary') {
      r.primary = fData.PPESummaryPrimaryField;
      r.secondary = fData.PPESummarySecondaryField || null;
      r.extraColumns = this.utils.makeArray(fData.PPESummaryReportColumns);
      r.gearTypes = this.utils.makeArray(fData.gearTypes);
      r.gearNames = this.utils.makeArray(fData.gearNames, toInteger);
      if (fData.graphType !== 'none') {
        r.graphPrimary = fData.PPESummaryGraphPrimary;
        r.graphSecondary = fData.PPESummaryGraphSecondary;
      }
    }

    if (includes(['check', 'checkDetail', 'observation', 'obsDetail'], fData.type)) {
      try {
        r.targetAssets = filter(JSON.parse(fData.targetAssets), (asset) => {
          return asset.assetID || asset.folderID;
        });
      } catch (e) {}
    }

    if (fData.timespan === 'custom') {
      r.startTime = fData.startTime;
      r.endTime = fData.endTime;
    }

    fData.groupID = fData.groupID || 0;
    fData.readOnly = fData.readOnly || 0;

    return r;
  }

  public getByID(reportID): any {
    return _.find(this.reports.data, {reportID});
  }

  /**
   * reportList - get a list of report IDs
   *
   * @param includeDisabled - include the disabled reporting.  Defaults to false.
   *
   */
  public reportList(includeDisabled) {
    const d = new Promise((resolve, reject) => {

      const theList = [];

      this.refresh()
        .then((reporting: any) => {
          _.forEach(reporting, (item: any) => {
            const theID = item.messageID;
            if (!includeDisabled && item.disabledAt) {
              return;
            }
            // we didn't skip this one
            theList.push(theID);
          });
          resolve(theList);
        });
    });
    return d;
  }

  public reportName(item) {
    return item.name;
  }

  public reportNameByID(reportID) {
    let ret = null;
    _.forEach(this.reports.data, (comp) => {
      if (comp.reportID === parseInt(reportID, 10)) {
        ret = comp;
        return false;
      }
    });
    return this.reportName(ret);
  }

  public getReport(reportID) {
    let ret = null;
    _.forEach(this.reports.data, (item) => {
      if (item.reportID === reportID) {
        ret = item;
        return false;
      }
    });
    return this.decode(ret);
  }

  public colorDot(val) {
    return `assets/images/${val}Dot.svg`;
  }

  public refresh() {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.commsService.sendMessage({
        cmd: 'getReports',
        includeDisabled: 0,
        lastRequest: this.reports.lastRequest,
        sendTime: when,
        types: JSON.stringify(['observation', 'obsDetail', 'check', 'checkDetail', 'PPESummary', 'worker'])
      }, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          this.reports.lastRequest = data.result.timestamp;
          this.reports.data = data.result.reports;
          _.forEach(this.reports.data, (item) => {
            item.tabs = ['all'];
            if (item.favorite) {
              item.tabs.push('favorites');
            }
          });
        }
        resolve(this.reports.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  buildPeriodList(timespan) {
    let p: any = _.find(this.timespans, {id: timespan});
    if (!p) {
      p = _.find(this.timespans, {id: '7days'});
    }
    return _.map(p.periods, (p) => _.find(this.periods, {id: p}));
  }

  buildPeriodListByCustomDate(date: any): any {
    let period: string;
    const daysAgo: number = moment(date.endTime).diff(date.startTime, 'days');

    if (daysAgo <= 7) {
      period = '7days';
    } else if (daysAgo <= 14) {
      period = '14days';
    } else if (daysAgo <= 30) {
      period = '30days';
    } else if (daysAgo <= 90) {
      period = '90days';
    } else if (daysAgo <= 180) {
      period = '180days';
    } else {
      period = '365days';
    }

    return period;
  }

  public isEditable(reportID: number): boolean {
    const report: any = _.find(this.reports.data, {reportID}) || {};
    return this.permissions.canView('sadmin') || (report.readOnly && +this.userdataService.userID === report.creatorID || !report.readOnly);
  }

  public setExtraColumns(columns) {
    let colsIds: string[] = this.utils.makeArray(_.filter(_.map(columns, 'id')));
    if (!colsIds.length) {
      colsIds = this.utils.makeArray(columns);
    }
    return colsIds;
  }
}
