export enum WFTypeMap {
  behavior = 'WF2',
  condition = 'WF1',
  compliment = 'WF7',
  opportunity = 'WF8',
  ai = 'WF9'
}
export enum WFSubtypeMap {
  production = 'WF4',
  receiving = 'WF3',
  rma = 'WF10',
  waiting = 'WF5',
  general = 'WF6',
}

export enum WFFieldsTypeMap {

}

export enum WFFieldsSubtypeMap {
  rma = 'qualityRMAFields',
  receiving = 'qualityReceivingFields',
  production = 'qualityProductionFields'
}
