<div class="check-deploy-content">
  <ion-button (click)="addDeployment()" [disabled]="!isNewDeploymentAvailable" class="page-button"
              color="secondary">{{ "MGMT_DETAILS.Add_Deployment" | translate }}</ion-button>

  <div *ngFor="let deploymentGroup of deploymentGroups; let index = index; trackBy: trackByFunction"
       class="deployment-tables">
    <app-deployment-table (onChecked)="onDeploymentChecked($event)"
                          [checkId]="checkId"
                          [deployments]="deploymentGroup.deployments"
                          [type]="deploymentGroup.type">
    </app-deployment-table>
  </div>
</div>

<div *ngIf="removalPanelConfig.count" class="footer-block">
  <app-removal-panel (onRemove)="onRemoveDeployment()" [removeButtonTitle]="removalPanelConfig.removeButtonTitle"
                     [subTitle]="removalPanelConfig.subTitle" [title]="removalPanelConfig.title">
    <ion-button (click)="duplicateDeployment()" [disabled]="removalPanelConfig.count > 1" class="page-button"
                color="secondary">{{ 'MGMT_DETAILS.Add_Layer_Copy' | translate }}</ion-button>
  </app-removal-panel>
</div>
