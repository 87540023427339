import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification-removal-panel',
  templateUrl: './removal-panel.component.html',
  styleUrls: ['./removal-panel.component.scss'],
})
export class RemovalPanelComponent {

  @Input() count = 0;
  @Input() type = 'Items';
  @Output() onRemove: EventEmitter<void> = new EventEmitter();

  public remove(): void {
    this.onRemove.emit();
  }

}
