import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';

@Component({
  selector: 'app-copylink-popup',
  templateUrl: './copylink-popup.component.html',
  styleUrls: ['./copylink-popup.component.scss'],
})

export class CopylinkPopupComponent implements OnInit {
  @Input() observationUUID: string;
  @Input() type: string;

  public fullUrl = '';

  constructor(private popOver: PopoverController,
              private popOps: PopupObservationService) {
  }

  ngOnInit() {
    if (this.type === 'observation') {
      this.fullUrl = this.popOps.shareLink(this.observationUUID);
    } else {
      this.fullUrl = this.popOps.shareExternal(this.observationUUID);
    }
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public copyLink(): void {
    const copyText: any = document.getElementById('deepUrl');
    copyText.select();
    document.execCommand('Copy');
    this.close();
  }
}
