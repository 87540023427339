import { Injectable } from '@angular/core';
import { DeploymentService } from '@modules/management/pages/details/check/services';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountsService,
  AssetsService,
  Feature,
  GearService,
  GeartypesService,
  SettingsService,
  ShiftService,
  SubscriberService,
  TeamsService,
  UserdataService,
  UserService,
  UtilsService
} from '@services';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class WorkerModelService {

  private checkOpts: any;

  private columnOptions = [
    // {
    //   id: 'activityCount',
    //   description: this.translate.instant('REPORTING.Activity_Count'),
    //   label: this.translate.instant('REPORTING.Activity_Count'),
    //   units: 'activityCount',
    //   cellType: 'string',
    //   func: (collection, ref) => {
    //     // to-do, calculate activity count, what is this?
    //     return '12';
    //   }
    // },
    {
      id: 'PPEName',
      description: this.translate.instant('SHARED.PPE_Name'),
      label: this.translate.instant('SHARED.PPE_Name'),
      cellType: 'string',
      func: (collection, ref) => {
        // dereference the name of the PPE
        if (!ref.relatedData) {
          return ' ';
        } else {
          if (ref.relatedTable !== 'gear_beacons') {
            return ' ';
          } else {
            // okay - this is about gear.   Get its name
            const gearInfo = this.gearService.getGearByID(+ref.relatedData.gearid);
            if (!gearInfo) {
              return ' ';
            } else {
              return this.gearService.gearName(gearInfo);
            }
          }
        }
      }
    },
    {
      id: 'PPEType',
      description: this.translate.instant('SHARED.PPE_Type'),
      label: this.translate.instant('SHARED.PPE_Type'),
      cellType: 'string',
      func: (collection, ref) => {
        // dereference the type of the PPE
        if (!ref.relatedData) {
          return ' ';
        } else {
          if (ref.relatedTable !== 'gear_beacons') {
            return ' ';
          } else {
            // okay - this is about gear.   Get its name
            const gearInfo = this.gearService.getGearByID(+ref.relatedData.gearid);
            if (!gearInfo) {
              return ' ';
            } else {
              const typeInfo = this.gearTypeService.info(gearInfo.type);
              if (!typeInfo) {
                return ' ';
              } else {
                return typeInfo.description;
              }
            }
          }
        }
      }
    },
    {
      id: 'PPECost',
      description: this.translate.instant('SHARED.PPE_Cost'),
      label: this.translate.instant('SHARED.PPE_Cost'),
      cellType: 'string',
      func: (collection, ref) => {
        // dereference the type of the PPE
        if (!ref.relatedData) {
          return ' ';
        } else {
          if (ref.relatedTable !== 'gear_beacons') {
            return ' ';
          } else {
            // okay - this is about gear.   Get its name
            if (_.has(ref.relatedData, 'cost')) {
              return ref.relatedData.cost;
            } else {
              return ' ';
            }
          }
        }
      }
    },
    {
      id: 'observationID',
      description: this.translate.instant('DASHPAGES.SUPERVISER_COMP_open_data0'),
      label: this.translate.instant('DASHPAGES.SUPERVISER_COMP_open_data0'),
      units: 'observationID',
      cellType: 'string',
      showWhen: true,
      func: (collection, ref) => {
        // to-do, calculate observation ID
        if (!ref.relatedData) {
          return ' ';
        } else {
          return ref.relatedData.oid;
        }
      }
    },
    {
      id: 'user',
      description: this.translate.instant('SHARED.USER'),
      label: this.translate.instant('SHARED.USER'),
      units: 'user',
      cellType: 'string',
      showWhen: true,
      func: (userID, ref) => this.accountsService.fullname(+ref.userID)
    },
    {
      id: 'location',
      description: this.translate.instant('SHARED.Location'),
      label: this.translate.instant('SHARED.Location'),
      units: 'location',
      cellType: 'string',
      showWhen: true,
      func: (locationID, ref) => {
        // to-do, calculate location
        if (!ref.locationID) {
          return ' ';
        } else {
          const r = this.userService.getLocation(ref.locationID);
          if (r) {
            return r.name;
          } else {
            return ' ';
          }
        }
      }
    },
    {
      id: 'primaryTeam',
      description: this.translate.instant('MGMT_DETAILS.Workers_Primary_Team'),
      label: this.translate.instant('MGMT_DETAILS.Workers_Primary_Team'),
      units: 'primaryTeam',
      cellType: 'string',
      showWhen: true,
      func: (groupID, ref) =>
        // to-do, calculate primary team
        this.teamsService.teamNameByID(ref.groupID)


    },
    {
      id: 'type',
      description: this.translate.instant('SHARED.Type'),
      label: this.translate.instant('SHARED.Type'),
      units: 'type',
      cellType: 'string',
      showWhen: true,
      func: (type) =>
        // to-do, calculate type
        type

    },
    {
      id: 'activity',
      description: this.translate.instant('MGMT_DETAILS.Log_Activity'),
      label: this.translate.instant('MGMT_DETAILS.Log_Activity'),
      units: 'activity',
      cellType: 'string',
      showWhen: true,
      func: (activity) =>
        // to-do, calculate activity
        activity

    },
    {
      id: 'timestamp',
      description: this.translate.instant('SHARED.Timestamp'),
      label: this.translate.instant('SHARED.Timestamp'),
      units: 'timestamp',
      cellType: 'string',
      showWhen: true,
      func: (when, ref) => this.utils.dateTimeFormat(ref.when, null, false)
    },
    {
      id: 'hireDate',
      description: this.translate.instant('SHARED.Hire_Date'),
      label: this.translate.instant('SHARED.Hire_Date'),
      units: 'timestamp',
      cellType: 'string',
      func: (when, ref) => this.utils.dateTimeFormat(ref.when, null, false)
    },
    {
      id: 'terminationDate',
      description: this.translate.instant('SHARED.Termination_Date'),
      label: this.translate.instant('SHARED.Termination_Date'),
      units: 'timestamp',
      cellType: 'string',
      func: (when, ref) => this.utils.dateTimeFormat(ref.when, null, false)
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      units: 'zone',
      cellType: 'string',
      showWhen: true,
      func: (zoneID, ref) => {
        // to-do, calculate zone
        if (ref) {
          const zoneObj = this.userService.findAnyZone(this.userService.getLocation(ref.locationID), ref.zoneID);
          if (zoneObj) {
            return zoneObj.name;
          } else {
            return '';
          }
        }
      }
    },
    {
      id: 'shift',
      description: this.translate.instant('SHARED.Shift'),
      label: this.translate.instant('SHARED.Shift'),
      units: 'shift',
      cellType: 'string',
      showWhen: true,
      func: (collection, ref) => {
        // to-do, calculate shift
        let shift = '';
        if (!ref.relatedData) {
          return ' ';
        }
        const shiftData = this.shift.get(ref.relatedData.creator_shift);
        if (shiftData) {
          const r = this.userService.getLocation(ref.locationID);
          if (r) {
            shift = r.name + ' ' + shiftData.name;
          } else {
            shift = ' ' + shiftData.name;
          }
        }
        return shift;
      }
    },
    {
      id: 'workerPoint',
      description: this.translate.instant('SHARED.Worker_Power_Points'),
      label: this.translate.instant('SHARED.Worker_Power_Points'),
      units: 'workerPoint',
      cellType: 'string',
      showWhen: this.subscriber.usesFeature(Feature.LOYALTY) ? true : false,
      func: (collection, ref) =>
        // to-do, calculate worker point
        ref.value

    }

  ];


  private fieldOptions = [
    {
      id: 'workerID',
      description: this.translate.instant('SHARED.workerID'),
      label: this.translate.instant('SHARED.workerID'),
      fieldName: 'workerID',
      fieldType: 'number',
      fieldRequired: true,
      fieldFunc: (val, ref) => this.accountsService.fullname(ref.userID)
    },
  ];

  constructor(private translate: TranslateService,
              private settingsService: SettingsService,
              private accountsService: AccountsService,
              private userService: UserService,
              private shift: ShiftService,
              private teamsService: TeamsService,
              private assetsService: AssetsService,
              private deployment: DeploymentService,
              private checkDetailService: CheckDetailService,
              private userdataService: UserdataService,
              private subscriber: SubscriberService,
              private gearService: GearService,
              private gearTypeService: GeartypesService,
              private utils: UtilsService
  ) {
  }

  public getColumns() {
    const columns = _.filter(_.cloneDeep(this.columnOptions), (opt) => opt.showWhen);
    return columns;
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }

}
