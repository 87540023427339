import { Injectable } from '@angular/core';

import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';
import { CommsService } from '@services//comms/comms.service';
import { Events } from '@services/events/events.service';
import { IObjectStringKeyMap } from '@shared/models';
import { ITask, SubscriberService, UserdataService } from '@services';

import * as _ from 'lodash';
import * as moment from 'moment/moment';

export interface ICauseType {
  name: string;
  description?: string;
  translations?: IObjectStringKeyMap<any>;

  typeID?: number;
  active?: number;
  addedAt?: number;
  addedBy?: number;
  disabledAt?: number;
  disabledBy?: number;
  lastUpdate?: number;
}

export enum PdcaStatus {
  Draft = 'draft',
  Plan = 'plan',
  Do = 'do',
  Check = 'check',
  Rejected = 'rejected',
  Act = 'act',
  Complete = 'complete'
}

export enum PdcaItemType {
  observation = 'observation'
}

export interface IAddPdcaItem {
  pdcaID: number;
  type: string;
  item?: number;
  value?: string;
  items?: string[];
}

export interface ICauseType {
  name: string;
  description?: string;
  translations?: IObjectStringKeyMap<any>;

  typeID?: number;
  active?: number;
  addedAt?: number;
  addedBy?: number;
  disabledAt?: number;
  disabledBy?: number;
  lastUpdate?: number;
}

export interface IPdca {
  access: string;
  actCompleteAt: number;
  actCompleteBy: number;
  actTasks: ITask[];
  actStartAt: number;
  actStartBy: number;
  askTasks: ITask[];
  addedAt: number;
  addedBy: number;
  causes: ICause[];
  checkCompleteAt: number;
  checkCompleteBy: number;
  checkNote: string;
  checkResult: CheckResult;
  checkStartAt: number;
  checkStartBy: number;
  completedAt: number;
  completedBy: number;
  description: string;
  disabledAt: number;
  disabledBy: number;
  doCompleteAt: number;
  doCompleteBy: number;
  doTasks: IObjectStringKeyMap<ITask[]>;
  checkTasks: ITask[];
  doStartAt: number;
  doStartBy: number;
  folderID: number;
  lastUpdate: number;
  locations: number[];
  nodeSelections: string[];
  owner: number;
  pdcaID: number;
  planActivity: string;
  planAssets: string[];
  planCompleteAt: number;
  planCompleteBy: number;
  planStartAt: number;
  planStartBy: number;
  planUsers: string[];
  planZones: string[];
  previousPDCA: number;
  history: [];
  readOnly: number;
  state: PdcaStatus;
  tagIDs: number[];
  title: string;
  mostImportantCause: number;
  translations: IObjectStringKeyMap<any>;
  items: PDCAItem[];
}

export interface PDCAItem {
  addedAt: number;
  addedBy: number;
  disabledAt: number;
  disabledBy: number;
  item: string;
  itemID: number;
  lastUpdate: number;
  pdcaID: number;
  status: string;
  translations: IObjectStringKeyMap<any>;
  type: PdcaItemType;
  value: string;
}

export interface ICause {
  causeID?: number;
  pdcaID?: string;
  addedAt?: string;
  addedBy?: string;
  type?: string;
  description?: string;
  why?: string[];
  lastUpdate?: string;
  disabledAt?: string;
  disabledBy?: string;
}

export interface IPdcaNote {
  addedAt: number;
  addedBy: number;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  noteID: number;
  pdcaID: number;
  sentiment: null;
  shift: string;
  subtype: string;
  type: string;
  value: string;
}

export enum CheckResult {
  Rejected = 'rejected',
  Approved = 'approved'
}

export enum CopyType {
  blank = 'blank',
  plan = 'plan'
}

@Injectable({
  providedIn: 'root'
})
export class PdcaService extends BaseService {

  private pdcaData = {
    lastRequest: null,
    data: <IObjectStringKeyMap<IPdca>>{}
  };

  private causeTypes = {
    lastRequest: null,
    data: <IObjectStringKeyMap<ICauseType>>{}
  };

  private causes = {
    lastRequest: null,
    data: <IObjectStringKeyMap<ICause>>{}
  };

  private notes = {
    lastRequest: null,
    data: <IObjectStringKeyMap<IPdcaNote>>{}
  };

  private tasks = {
    lastRequest: null,
    data: <IObjectStringKeyMap<ITask>>{}
  };

  constructor(
    protected commsService: CommsService,
    private userdata: UserdataService,
    private subscriber: SubscriberService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh() {
    return this.commsService.sendMessage({
      cmd: 'getPDCAs',
      includeCauses: 1,
      includeItems: 1,
      includeHistory: 1,
      includeTasks: 1,
      sendTime: Date.now(),
      lastRequest: this.pdcaData.lastRequest,
    }, false, false).then((data) => {
      if (data?.reqStatus === 'OK') {
        this.pdcaData.data = data.result.pdcas;
        this.pdcaData.lastRequest = data.result.timestamp;
        this.events.publish('ccs:PDCAUpdate', true);
      }
      return this.pdcaData.data;
    });
  }

  public clearCache() {
    this.pdcaData = {
      lastRequest: null,
      data: {}
    };

    this.causeTypes = {
      lastRequest: null,
      data: {}
    };
  }

  public getById(id: number): IPdca {
    return this.pdcaData.data[id];
  }

  public async getCauseTypeById(typeID: number) {
    if (!this.causeTypes.data[typeID]) {
      await this.getCauseTypes();
    }

    return this.causeTypes.data[typeID];
  }

  public getAll(): IPdca[] {
    return _.values(this.pdcaData.data);
  }

  public async update(formData) {
    formData.cmd = 'updatePDCA';

    return this.handleRequest(formData);
  }

  complete(pdcaID: number) {
    const requestData = {
      cmd: 'completePDCA',
      pdcaID
    };

    return this.handleRequest(requestData);
  }

  public async delete(formData) {
    formData.cmd = 'deletePDCA';

    return this.handleRequest(formData);
  }

  public addCauseType(data: ICauseType) {
    const requestData = {
      cmd: 'addCauseType',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public addPdca(data) {
    const requestData = {
      cmd: 'addPDCA',
      ...data
    };
    return this.handleRequest(requestData);
  }

  public addPdcaItem(data: IAddPdcaItem) {

    const requestData = {
      cmd: 'addPDCAItem',
      ...data
    };
    return this.handleRequest(requestData);
  }

  public getCauseTypes(includeDisabled = false): Promise<IObjectStringKeyMap<ICauseType>> {
    const requestData = {
      cmd: 'getCauseTypes',
      includeDisabled
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.updateCauseTypesCache(response);
      }

      return this.causeTypes.data;
    });
  }

  public updateCauseTypesCache(data) {
    this.causeTypes.lastRequest = data.result.timestamp;
    this.causeTypes.data = data.result.causeTypes;
  }

  public updateCauseType(typeID: number, formData = {}) {
    const requestData = {
      cmd: 'updateCauseType',
      typeID,
      ...formData
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public deleteCauseType(typeID: number) {
    const requestData = {
      cmd: 'deleteCauseType',
      typeID
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public removePDCAItem(id: number, itemsIds: number[]) {
    const requestData = {
      cmd: 'deletePDCAItem',
      pdcaID: id,
      items: JSON.stringify(itemsIds)
    };

    return this.handleRequest(requestData);
  }


  public getCauses(pdcaID?: number, includeDisabled = false): Promise<IObjectStringKeyMap<ICause>> {
    const requestData: any = {
      cmd: 'getCauses',
      includeDisabled
    };

    if (pdcaID) {
      requestData.pdcaID = pdcaID;
    }

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.causes.data = response.result.causes;
      }

      return this.causes.data;
    });
  }

  public getTasks(): Promise<IObjectStringKeyMap<ITask>> {
    const requestData: any = {
      cmd: 'getTasks',
      lastRequest: this.tasks.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.tasks.data = response.result.tasks;
        this.tasks.lastRequest = response.result.timestamp;
      }

      return this.tasks.data;
    });
  }

  public async getTaskById(id: number) {
    if (!this.tasks.data[id]) {
      await this.getTasks();
    }

    return this.tasks.data[id];
  }

  public async getCauseById(causeID: number): Promise<ICause> {
    if (!this.causes.data[causeID]) {
      await this.getCauses();
    }

    return this.causes.data[causeID];
  }

  public addPotentialCause(data) {
    const requestData = {
      cmd: 'addCause',
      ...data
    };
    return this.handleRequest(requestData);
  }

  public updatePotentialCause(causeID: number, formData = {}) {
    const requestData = {
      cmd: 'updateCause',
      causeID,
      ...formData
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public deletePotentialCause(causeID: number) {
    const requestData = {
      cmd: 'deleteCause',
      causeID
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public getPdcaNotes(pdcaID?: number, includeDisabled = false): Promise<IObjectStringKeyMap<IPdcaNote>> {
    const requestData: any = {
      cmd: 'getPDCANotes',
      includeDisabled
    };

    if (pdcaID) {
      requestData.pdcaID = pdcaID;
    }

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.notes.data = response.result.notes;
      }

      return this.notes.data;
    });
  }

  public async getPdcaNoteById(noteID: number): Promise<IPdcaNote> {
    if (!this.notes.data[noteID]) {
      await this.getPdcaNotes();
    }

    return this.notes.data[noteID];
  }

  public addPdcaNote(data) {
    const requestData = {
      cmd: 'addPDCANote',
      ...data
    };
    return this.handleRequest(requestData);
  }

  public updatePdcaNote(noteID: number, formData = {}) {
    const requestData = {
      cmd: 'updatePDCANote',
      noteID,
      ...formData
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public deletePdcaNote(noteID: number) {
    const requestData = {
      cmd: 'deletePDCANote',
      noteID
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  updatePDCAPhase(param: { state: string; pdcaID: number; status?: string; }) {
    const requestData = {
      cmd: 'updatePDCAPhase',
      ...param
    };
    return this.handleRequest(requestData);
  }

  approvePDCACheck(pdcaID: number, note: string) {
    const requestData = {
      cmd: 'approvePDCACheck',
      pdcaID,
      note
    };
    return this.handleRequest(requestData);
  }

  rejectPDCACheck(pdcaID: number, note: string, copyType: CopyType) {
    const requestData = {
      cmd: 'rejectPDCACheck',
      pdcaID,
      note,
      copyType
    };
    return this.handleRequest(requestData);
  }

  startPDCAPhase(id: number, state: PdcaStatus) {
    return this.updatePDCAPhase({
      pdcaID: id,
      state,
      status: 'start'
    });
  }

  public isOwner(pdcaData: IPdca) {
    return pdcaData.owner === +this.userdata.userID;
  }

  public getTotalTime(pdcaID: number): number {
    const pdcaData = this.getById(pdcaID);
    let completedAt = pdcaData?.completedAt ?? 0;

    if (this.isRejected(pdcaID)) {
      completedAt = pdcaData.checkCompleteAt;
    }

    const totalTime = completedAt - (pdcaData?.planStartAt ?? 0);
    return moment().unix() - totalTime;
  }

  public isRejected(pdcaID: number) {
    const pdcaData = this.getById(pdcaID);
    return pdcaData?.checkResult === CheckResult.Rejected;
  }
}
