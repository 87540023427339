<div *ngIf="title" class="page-title">{{ title | translate }}</div>

<ngx-slick-carousel (deferLoad)="initSlider()" [config]="slideConfig">
  <div (click)="onImageClick(image)" *ngFor="let image of viewImages" [class.empty-image-item]="image.isEmpty"
       [class.uploaded]="image.uploaded" class="slide" ngxSlickItem>
    <app-media-file [class]="image.class" [disableOpening]="true" [mediaType]="image.mediaType"
                    [url]="image.src"></app-media-file>

    <div *ngIf="image.uploaded" class="owner-card">
      <div class="owner-name">{{ image.owner }}</div>
      <div class="creation-time">{{ image.time }}</div>
    </div>
  </div>
</ngx-slick-carousel>
