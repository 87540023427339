<ion-footer [hidden]="!changesCount" no-border>
  <div class="panel-content">
    <div class="panel-title">
      <span class="count">{{changesCount}} {{"SHARED.Changes" | translate}} </span>
      <span>{{"SHARED.Have_Been_Made" | translate}}</span>
    </div>

    <ion-button (click)="onSave.emit()" class="page-button"
                color="secondary">{{"SHARED.Save_Changes" | translate}}</ion-button>
    <ion-button (click)="onCancel.emit()" class="page-button"
                color="light">{{"SHARED.Undo_All_Changes" | translate}}</ion-button>
  </div>
</ion-footer>
