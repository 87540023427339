import { Injectable } from '@angular/core';

import { CommsService } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export interface IDisclaimer {
  active: number;
  created: number;
  creator: number;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  title: string;
  disclaimer: string;
  disclaimerID: number;
}

@Injectable({
  providedIn: 'root'
})

export class DisclaimerService {
  private disclaimers = {
    lastRequest: null,
    data: {} as IObjectStringKeyMap<IDisclaimer>
  };

  constructor(
    private commsService: CommsService,
    private events: Events,
    private translate: TranslateService
  ) {}

  public refresh(): Promise<IObjectStringKeyMap<IDisclaimer>> {
    const requestData: any = {
      cmd: 'getDisclaimers',
      lastRequest: this.disclaimers.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.updateCache(response);
      }

      return this.disclaimers.data;
    });
  }

  public updateCache(data) {
        this.disclaimers.data = data.result.disclaimers;
        this.disclaimers.lastRequest = data.result.timestamp;
        this.events.publish('ccs:disclaimersUpdate', true);
  }



  public getDisclaimersAsList(includeInactive: boolean = false, includeDisabled: boolean = false): IDisclaimer[] {
    const theList: IDisclaimer[] = [];

    _.each(this.disclaimers.data, (item) => {
      if (!includeInactive && !item.active) {
        return;
      }
      if (!includeDisabled && item.disabledAt) {
        return;
      }
      theList.push(item);
    });

    return theList;
  }

  public getDisclaimers(): IObjectStringKeyMap<IDisclaimer> {
    return this.disclaimers.data;
  }

  public getDisclaimerById(id: number): IDisclaimer {
    return _.cloneDeep(this.disclaimers.data[id]);
  }

  public addDisclaimer(data: any): Promise<any> {
    const requestData = {
      cmd: 'addDisclaimer',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public updateDisclaimer(data: any): Promise<any> {
    const requestData = {
      cmd: 'updateDisclaimer',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public deleteDisclaimer(ids: number[] | number | string): Promise<any> {
    const requestData = {
      cmd: 'deleteDisclaimer',
      disclaimers: _.flatten([ids])
    };

    return this.commsService.sendMessage(requestData, false, false);
  }
}
