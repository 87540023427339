import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';

import { PopupObservationService, LoadingService, CheckResponseService } from '@services';
import {
  IListMenuConfig,
  IListMenuSelected,
  ListMenuComponent,
  ListMenuSelectMode,
  ListMenuType,
  ListMenuViewType
} from '@shared/components';
import { awaitHandler } from '@utils/awaitHandler';

import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import * as _ from 'lodash';

@Component({
  selector: 'app-assign-popup',
  templateUrl: './assign-popup.component.html',
  styleUrls: ['./assign-popup.component.scss'],
})
export class AssignPopupComponent implements OnInit {

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() observationID: Number;
  @Input() onlyUser: boolean;
  @Input() responseID: String; // for assigning check responses to users.
  @Input() responseIDArr: any; // for bulk-assignment

  @ViewChild(ListMenuComponent) listMenuComponent: ListMenuComponent;

  public listConfig: IListMenuConfig = {
    type: ListMenuViewType.MultipleUserTeam,
    selectMode: ListMenuSelectMode.Single,
    isLoggedUserExcluded: false,
    isInfinityScrollEnabled: true,
    isAssignPopUp: true
  };

  public isListAvailable = true;
  public title = 'SHARED.Assign_Observation';

  private selectedIds: any = {
    [ListMenuType.User]: [],
    [ListMenuType.Team]: []
  };
  private currentTab: ListMenuType = ListMenuType.User;

  constructor(
    private popOver: PopoverController,
    private logger: NGXLogger,
    private popOps: PopupObservationService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private checkResponseService: CheckResponseService
  ) {
  }

  async ngOnInit() {
    if (this.onlyUser || this.responseID) {
      this.listConfig.type = ListMenuViewType.User;
    }

    if (this.responseID) {
      this.title = 'MGMT_DETAILS.Assign_Check';
    }

    await this.defineLocations();
  }

  public close(): void {
    this.popOver.dismiss();

    if ((<any>$.fn.DataTable).isDataTable('#available_table')) {
      const tableInstance: any = $('#available_table').DataTable();
      tableInstance.rows().deselect();
    }
  }

  public isSaveAvailable(): boolean {
    return (this.currentTab === ListMenuType.Team && this.selectedIds[ListMenuType.Team].length) ||
      (this.currentTab === ListMenuType.User && this.selectedIds[ListMenuType.User].length);
  }

  public reset(): void {
    this.listMenuComponent.reset();
    this.selectedIds = {
      [ListMenuType.User]: [],
      [ListMenuType.Team]: []
    };
  }

  public onChangeTab(tab: ListMenuType): void {
    this.currentTab = tab;
  }

  public onSelect(selection: IListMenuSelected): void {
    this.selectedIds[selection.type] = selection.items;
  }

  public async assignObs(): Promise<void> {
    if (this.responseID) {
      const reqObj = {
        cmd: 'updateResponseAssignment',
        responseID: this.responseID,
        assignees: JSON.stringify({users: [this.selectedIds.user[0]]})
      };
      this.loadingService.enable(this.translate.instant('SHARED.Assigned'));

      try {
        this.popOver.dismiss();
        await this.popOps.assignCheckResponse(reqObj);
        this.loadingService.disable();
      } catch (err) {
        this.logger.log(err);
        this.loadingService.disable();
      }
    } else if (this.observationID) {
      const requestBody: any = {
        observationID: this.observationID
      };
      if (this.selectedIds.user.length > 0) {
        // assing to user
        requestBody.ownerID = this.selectedIds.user[0];
      } else if (this.selectedIds.team.length > 0) {
        requestBody.groupID = this.selectedIds.team[0];
      }
      this.popOver.dismiss();
      this.popOps.moveEscalateObservation(requestBody).then();
    } else if (_.isArray(this.responseIDArr)) {
      this.loadingService.enable();
      const reqObj = {
        cmd: 'updateResponseAssignment',
        responses: JSON.stringify(this.responseIDArr),
        assignees: JSON.stringify({users: [this.selectedIds.user[0]]})
      };

      try {
        await this.popOps.assignCheckResponse(reqObj);
        this.popOver.dismiss();
        this.loadingService.disable();
      } catch (err) {
        this.logger.log(err);
        this.loadingService.disable();
      }
    }
  }

  private async defineLocations() {
    if (this.responseIDArr?.length) {
      this.isListAvailable = false;
      await this.loadingService.enable(null, null);

      const checkFilter = {
        responses: JSON.stringify(this.responseIDArr),
        status: '[]'
      };
      const [responses] = await awaitHandler(this.checkResponseService.getResponses(checkFilter));
      const targetLocations = _.flatten(_.map(responses, 'targetLocations'));
      this.listConfig.selectedLocationIds = _.uniq(targetLocations);

      this.isListAvailable = true;
      this.loadingService.disable();
    }
  }

}
