/* eslint-disable @typescript-eslint/naming-convention */
export interface IUpdateTime {
  table: string;
  newest: number;     // if a table will require many iterations with the backend to complete synchronizing, this will be -1 until that is complete.
  oldest: number;
}

export interface IDBRestore {
  table: string;
  dataIsArray?: boolean;
}

export interface IDBUpdate {
  table: string;
  data: any;
  times?: {
    newest: number;    // the timestamp for the most recent data update in the cache
    oldest: number;    // the earliest timestamp for data in the cache; 0 means all data
  };
  dataIsArray?: boolean;
  updateTimes?: boolean;
}

export interface IDBInitialize {
  dbname: string;
  reset?: boolean;
}

export interface IDBCommand {
  command: string;
  args: IDBUpdate | IDBInitialize | IDBRestore;
}


export const TableDefs = {
  updateTimes: '&table',
  responses: '&responseID, availableTime, completionTime, expiresTime, stateEntered,updatedAt',
  observations: '&observationID'
};

export const DBVersion = 9;
