import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {
  AccountsService,
  AccountTypes,
  ObservationService,
  Permission,
  TeamsService,
  UserdataService,
  UserService
} from '@services';
import { ItemListComponent } from '@shared/components/item-list/item-list.component';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { BaseItemList } from '../abstract-base-item-list/base-item-list';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends BaseItemList implements OnInit, OnChanges {

  @Input() isInfinityScrollEnabled: boolean;
  @Input() isMultiple = true;
  @Input() isSelectAllEnabled = true;
  @Input() isLoggedUserExcluded = false;
  @Input() excludeAccounts: AccountTypes[] = [];
  @Input() excludePermissions: Permission[] = [];
  @Input() isAssignPopUp?: boolean;
  @Input() selectedLocationIds?: string[] = [];
  @Output() onSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild(ItemListComponent) itemListComponent: ItemListComponent;

  public users: any[];
  public uuid: string;

  constructor(
    private accountsService: AccountsService,
    private userService: UserService,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private userdataService: UserdataService,
    private router: Router,
    private observationService: ObservationService
  ) {
    super();
  }

  ngOnInit() {
    this.getUserList();
  }

  ngOnChanges() {
    if (this.isAssignPopUp) {
      const curUrl = this.router.url.split('/');
      this.uuid = curUrl[curUrl.length - 1].split('?')[0];

      this.observationService.getObservationByUUID(this.uuid).then(
        data => {
          if (!this.users.length) {
            this.getUserList();
          }
          this.users = this.users.filter(user => !user.locations.length || user.locations.includes(data.locationID));
        }
      );
    }

    if (this.selectedLocationIds) {
      const userIDs = _.map(_.filter(this.users, 'checked'), 'userID');
      this.getUserList();

      if (userIDs.length) {
        setTimeout(() => {
          this.setSelectedUsers(userIDs);
        });
      }
    }
  }

  getUserList() {
    const accounts: any[] = this.accountsService.filterUserList([
      (user) => {
        // if there are no locations in the list, then this user has access to all locations
        if (user.locations.length === 0 || this.userdataService.locations.length === 0) {
          return false;
        }
        const overlap = _.intersection(this.userdataService.locations, user.locations);
        return !overlap.length;
      }
    ]);
    const users: any[] = _.orderBy(_.filter(accounts, (user: any) => this.isUserValid(user)), 'firstname');
    this.users = _.map(_.cloneDeep(users), (user) => {
      user.avatarUrl = this.userService.accountAvatar(user.userID, 64, false, false);
      let teamNames = '';

      _.each(user.groups, (val) => {
        teamNames += this.teamsService.teamNameByID(val) + ', ';
      });

      user.teamNames = teamNames.slice(0, -2);

      user.name = `${user.firstname} ${user.lastname}`;
      if (user.disabledAt || !user.active) {
        user.name += ' (' + this.translate.instant('SHARED.Deactivated') + ')';
      }
      user.description = ''; // user.teamNames;
      user.itemID = user.userID;

      return user;
    });

    this.users = this.filterByLocation(this.users);
  }

  public setSelectedUsers(ids: number[]): void {
    this.itemListComponent.setSelectedItems(ids);
  }

  public getSelectedUsers(): number[] {
    return this.itemListComponent.getSelectedItems();
  }

  public onUserSelected(): void {
    this.onSelected.emit(this.getSelectedUsers());
  }

  public init(): void {
    this.itemListComponent.resetCheckedItems();
    this.itemListComponent.clearSearch();
  }

  private isUserValid(user: any): boolean {
    let isValid: boolean = (user.disabledAt === 0 && user.active === 1);

    if (!_.isEmpty(this.excludeAccounts)) {
      isValid = isValid && !_.includes(this.excludeAccounts, user.type);
    }

    if (!_.isEmpty(this.excludePermissions)) {
      const userPermissions: string[] = _.keys(user.permissions);
      isValid = isValid && _.isEmpty(_.intersection(userPermissions, this.excludePermissions));
    }

    if (this.isLoggedUserExcluded) {
      isValid = isValid && user.userID !== this.userdataService.userID;
    }

    return isValid;
  }

}
