<div *ngIf="config.title" class="header">
  <button (click)="dismissPopup()" *ngIf="config.showCloseButton" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{config.title}}</h2>
</div>

<div class="body">
  <div class="description">{{config.description}}</div>
  <div *ngIf="config.subDescription" class="description sub">{{config.subDescription}}</div>
</div>

<div *ngIf="!config.hideActions" [ngClass]="config.hideCancelAction || config.hideSaveAction ? 'center-one-btn': ''"
     class="button-block">
  <button (click)="close()" *ngIf="!config.hideCancelAction"
          class="page-button button-link body-link">{{cancelBtnTitle}}</button>
  <button (click)="save()" *ngIf="!config.hideSaveAction"
          class="page-button button-link body-link">{{saveBtnTitle}}</button>
  <!-- <ion-button color="light" (click)="close()">{{"SHARED.No" | translate}}</ion-button>
  <ion-button color="light" (click)="save()">{{"SHARED.Yes" | translate}}</ion-button> -->
</div>
