<ion-content class="table-content">
  <app-dashboard-header (onSelected)="toggleFilterBody($event)" [filterComps]="filterComps" [filterObject]="filterObject"
                        [headerSetting]="headerSetting" [searchField]="searchField"
                        [showFilterBody]="showFilterBody"></app-dashboard-header>

  <div class="tabs-wrap">
    <div class="tabs">
      <div (click)="toggle(tab)" *ngFor="let tab of tabs"
           [hidden]="!((tab?.id!='workorder') || (tab?.id=='workorder' && workorderStatus))"
           [ngClass]="{'active-tab': tab.selected}" class="tab">
        {{tab.name}}<span class="tab-select-count">({{tab.count}})</span>
      </div>
    </div>
  </div>

  <div [hidden]="!(preSelected.id=='open')" class="table-container">
    <table class="display" id="open"></table>
  </div>

  <div *ngIf="workorderStatus" [hidden]="!(preSelected.id=='workorder')" class="table-container">
    <table class="display" id="workorder">
    </table>
  </div>

  <div [hidden]="!(preSelected.id=='fixed')" class="table-container">
    <table class="display" id="fixed">
    </table>
  </div>

  <div [hidden]="!(preSelected.id=='closed')" class="table-container">
    <table class="display" id="closed">
    </table>
  </div>
</ion-content>
