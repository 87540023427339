import {
  Directive,
  Optional,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  Host
} from '@angular/core';
import { NgControl, ControlContainer } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FORM_ERRORS } from './form-errors';
import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { merge, EMPTY, Observable } from 'rxjs';
import { ElementRef } from '@angular/core';
import { UserdataService } from '@services/userdata/userdata.service';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective {
  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  @Input() customErrors = {};

  constructor(
    private elementRef: ElementRef,
    private userData: UserdataService,
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors,
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl,
    protected translate: TranslateService) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  get control() {
    return this.controlDir.control;
  }

  ngOnInit() {
    merge(
      this.submit$,
      this.control.valueChanges
    ).pipe(
      untilDestroyed(this)).subscribe((v) => {
      const controlErrors = this.control.errors;
      const fcn = this.elementRef.nativeElement.getAttribute('formcontrolname');
      var error = false;
      let errorPlus = true;
      if (this.userData.userID != null) {
        if (fcn == 'username' || fcn == 'prefix' || fcn == 'username') {
          errorPlus = false;
        }
      }
      if (controlErrors && errorPlus) {
        error = true;
        if (document.querySelector(`#startSlideError`) == null) {
          const errorMessage = this.translate.instant('SHARED.Error_Missing');
          document.querySelector(`app-header`).insertAdjacentHTML('afterend', '<div id="startSlideError" class="toast-error">' + errorMessage + '</div>');
        }
        if (!document.querySelector(`input[formcontrolname="` + fcn + `"]`).closest('div').classList.contains('custom-error')) {
          document.querySelector(`input[formcontrolname="` + fcn + `"]`).closest('div').classList.add('custom-error');
        }
        const firstKey = Object.keys(controlErrors)[0];
        const getError = this.errors[firstKey];
        const text = this.customErrors[firstKey] || getError(controlErrors[firstKey]);
        this.setError(this.translate.instant(text));
        setTimeout(() => {
          if (document.querySelector(`#startSlideError .in`) == null) {
            document.querySelector(`#startSlideError`).classList.add('in');
          }
        }, 100);
      } else if (this.ref) {
        if (document.querySelector(`input[formcontrolname="` + fcn + `"]`).closest('div').classList.contains('custom-error')) {
          document.querySelector(`input[formcontrolname="` + fcn + `"]`).closest('div').classList.remove('custom-error');
        }
        this.setError(null);
      }
      var error = false;
      document.querySelectorAll('.is-danger').forEach(function (userItem) {
        if (userItem.innerHTML) {
          error = true;
        }
      });
      if (!error) {
        if (document.querySelector(`#startSlideError`) != null) {
          if (document.querySelector(`#startSlideError`) != null) {
            if (document.querySelector(`#startSlideError .in`) == null) {
              document.querySelector(`#startSlideError`).classList.remove('in');
            }
            setTimeout(() => {
              if (document.querySelector(`#startSlideError`) != null) {
                document.querySelector(`#startSlideError`).remove();
              }
            }, 2000);

          }
        }
      }
    });
  }

  setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy() {
  }

}
