import { ElementRef, Component, EventEmitter, Output } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';

import {
  AccountsService,
  MessagesService,
  TableService,
  TeamsService,
  UserdataService,
  UserService,
  UtilsService,
  DynamicComponentCreatorService
} from '@services';

import { TimeAgoComponent } from '@shared/components/time-ago/time-ago.component';

import * as _ from 'lodash';
import { ParticipantViewComponent } from '../participant-view/participant-view.component';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent {

  @Output() onClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  private tableAttr = {
    class: 'view-message',
    checkbox: true,
    lookupFunc: (id) => this.messagesService.getMessage(id),
    rowprop: 'data-mid',
    rowvaluefrom: 'messageID',
    test: (rec) => !this.messagesService.getMessage(rec.nextMessageID),
    rowClassFunc: (message) => {
      let selfMessage = '';
      const isUnread: boolean = this.messagesService.isUnreadThread(message.messageID, this.userData.userID);
      const statusRead = isUnread ? 'unread-class' : 'read-class';

      if (message.userID === this.userData.userID) {
        selfMessage = 'lightBlueBackground';
      }
      const returnClass = statusRead + ' ' + selfMessage;
      return returnClass;
    },
    columns: [
      {
        id: 'userID',
        title: this.translate.instant('COMMUNICATIONS.Messages_title'),
        fromProperty: 'userID',
        fromID: 'messageID',
        func: (messageID) => {
          const message = this.messagesService.getMessage(messageID);
          let f = '';
          let a = '';
          let toFrom = '';
          let participantsLink = '';
          // FOR ONE-ON-ONE COMMUNICATION
          if (message.recipients.length === 2) {
            const targetUserId: number = _.get(_.reject(message.recipients, {userID: this.userData.userID}), '[0].userID');

            a = this.userService.accountAvatar(targetUserId, 40, false, null);

            // if two people, have name of other person apart from app.userData.userID
            f = this.userService.getFullname(targetUserId); // we want another name when we're chatting
            // if (f.length > 15) {
            //   f = f.substring(0, 15) + '...';
            // }

          } else {
            a = 'assets/images/group_icon.svg';

            if (message.groups && message.groups.length > 0) {
              if (message.groups.length === 1) { // get team name
                f = `${this.teamsService.teamNameByID(message.groups[0])} ${this.translate.instant('SHARED.Team')}`;
              }
              if (message.groups.length > 1) {
                f = `${message.groups.length} ${this.translate.instant('SHARED.TEAMS')}`;
                participantsLink = `<div data-mid=${messageID} class='particpants-link js-show-participants'>${this.translate.instant('SHARED.View')}</div>`;
              }
            } else {
              if (message.userID !== this.userData.userID) {
                participantsLink = `<div data-mid=${messageID} class='particpants-link js-show-participants'>${message.recipients.length} ${this.translate.instant('COMMUNICATIONS.Participants')}</div>`;
                f = this.userService.getFullname(message.userID);
              } else {
                participantsLink = `<div data-mid=${messageID} class='particpants-link js-show-participants'>${this.translate.instant('SHARED.View')}</div>`;
                f = `${message.recipients.length} ${this.translate.instant('COMMUNICATIONS.Participants')}`;
              }
            }

          }
          if (message.userID === this.userData.userID) {
            toFrom = this.translate.instant('COMMUNICATIONS.Sent_To');
          } else {
            toFrom = this.translate.instant('COMMUNICATIONS.From');
          }

          let ret = '<div class="user-block">';
          ret += `<img class='image-vertical-center' src='${a}'>`;
          ret += `<div class="user-info">`;
          ret += `<div class='user-info-text'>${toFrom}</div>`;
          ret += `<div class="user-name">${f}</div>`; // the user name attached with the last message thread
          ret += participantsLink; // links for participants > 2
          ret += '</div></div>';
          return ret;
        },
        headerClass: 'table-header'
      },
      {
        id: 'created',
        title: this.translate.instant('COMMUNICATIONS.Messages_ACTIVITY'),
        fromProperty: 'created',
        func: (time) => this.dynamicComponentCreatorService.create(TimeAgoComponent, (componentInstance: TimeAgoComponent) => {
          componentInstance.time = time;
        }),
        cellprop: 'data-order',
        cellval: 'created',
        headerClass: 'table-header'
      },
      {
        id: 'message',
        title: this.translate.instant('SHARED.Messages_MESSAGE'),
        headerClass: 'table-header',
        class: 'text-message-wrap',
        func: (message, ref) => {
          let ret = this.markdown.compile(message);
          if (ref.hasOwnProperty('object') && ref.object.objectID) {
            ret = `<img class='image-text message-text' style='height: 12px !important; vertical-align: middle;' src='assets/icons/message_inline_camera.svg' /> <span class='image-text message-text'>${this.translate.instant('SHARED.Attachment')}</span>`;
          }
          return ret;
        }
      }
    ],
    onClickRow: (id): void => {
      this.onClicked.emit(id);

      this.menu.isEnabled('chat').then((isEnabled: boolean) => {
        if (!isEnabled) {
          this.menu.enable(true, 'chat');
        }
        this.menu.toggle('chat');
      });
    },
    onChecked: () => this.emitSelectedMessages(),
    onCheckedAll: () => this.emitSelectedMessages(),
    getTable: () => $('#messagesChatsTable').DataTable() // [azyulikov] figure out how to get table in the service
  };
  private elementRef: ElementRef = new ElementRef(document);

  constructor(
    private tableService: TableService,
    private messagesService: MessagesService,
    private userData: UserdataService,
    private userService: UserService,
    private teamsService: TeamsService,
    private utils: UtilsService,
    private menu: MenuController,
    private popoverController: PopoverController,
    private accountsService: AccountsService,
    private translate: TranslateService,
    private markdown: MarkdownService,
    private dynamicComponentCreatorService: DynamicComponentCreatorService
  ) {


  }

  public update(): void {
    if ($('#messagesChatsTable thead').length) {
      $(`#messagesChatsTable`).DataTable().destroy();
    }

    this.tableService.showTable(`#messagesChatsTable`, this.tableAttr, _.uniq(this.messagesService.messages));

    if ($('#messagesChatsTable thead').length) {

      const o = $('.table-container').offset().top;
      const h = $(window).height() - o - 110;

      const table = $('#messagesChatsTable').DataTable(<any>{
        scrollCollapse: true,
        language: {
          searchPlaceholder: this.translate.instant('SHARED.Search'),
          search: '',
          emptyTable: this.translate.instant('SHARED.emptyTable'),
          sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
        },
        scrollY: h + 'px',
        scrollX: true,
        paging: false,
        searching: true,
        info: false,
        retrieve: true,
        stateSave: true,
        columnDefs: [
          {
            orderable: false,
            className: 'select-checkbox',
            targets: 0,
            width: '20px'
          },
          {
            targets: [3],
            class: 'normal-wrap'
          }
        ],
        select: {
          style: 'multi',
          selector: 'td:first-child'
        },
        order: [[2, 'desc']],
        dom: 'frtip'
      });

      $('#messagesChatsTable').width('100%');

      table.columns.adjust().draw(false);
      this.tableService.handleResize();
    }
    $('#messagesChatsTable_filter input').addClass('messagesChatsTable_searchinput');
    this.elementRef.nativeElement.querySelector('#messagesChatsTable_filter input').addEventListener('input', _.debounce((event) => {
      setTimeout(() => {
        const searchValue = this.tableService.quoteEncoding(event.target.value);
        $('#search_close_messagesChatsTable_filter').remove();
        if (searchValue) {
          this.elementRef.nativeElement.querySelector('#messagesChatsTable_filter input').insertAdjacentHTML('afterend', `<ion-icon name="close-outline" id="search_close_messagesChatsTable_filter" class="custom_close_icon"></ion-icon>`);
          this.elementRef.nativeElement.querySelector('#search_close_messagesChatsTable_filter').addEventListener('click', (event) => {
            setTimeout(() => {
              this.clearSearch(event);
            });
          }, 300);
        } else {
          $('#search_close_messagesChatsTable_filter').remove();
        }
      });
    }, 300));

    $('.js-show-participants').on('click', (event) => {
      event.stopPropagation();

      const id: number = +event.currentTarget.getAttribute('data-mid');
      const message: any = this.messagesService.getMessage(id);
      let participants: any[] = [];

      if (message.groups && message.groups.length) {
        participants = _.map(message.groups, (group: string) => ({name: this.teamsService.teamNameByID(+group)}));
      } else {
        participants = _.map(message.recipients, (recipient) => {
          const user: any = this.accountsService.getAccount(recipient.userID);

          return {
            name: `${user.firstname} ${user.lastname}`,
            avatarUrl: this.userService.accountAvatar(user.userID, 64, false, false)
          };
        });
      }

      this.popoverController.create(<any>{
        component: ParticipantViewComponent,
        animated: false,
        componentProps: {participants}
      }).then((element: HTMLIonPopoverElement) => {
        element.present();
      });
    });
  }

  public initSizeTable(): void {
    window.setTimeout(() => {
      if ((<any>$.fn.DataTable).isDataTable(`#messagesChatsTable`)) {
        $('#messagesChatsTable').DataTable().columns.adjust().draw(false);
      }
      this.tableService.handleResize();
    });
  }

  clearSearch(e) {
    $('#messagesChatsTable').DataTable().search('').columns().search('').draw();
    this.update();

  }

  private emitSelectedMessages(): void {
    let ids: number[] = [];
    if ($('#messagesChatsTable .selected').length) {
      ids = _.map($('#messagesChatsTable .selected'), (selectedElement: any) => +selectedElement.getAttribute('data-mid'));
    }
    this.onSelected.emit(<number[]>_.filter(ids, Boolean));
  }
}
