import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ITab } from '@shared/components';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {
  ChatMenuComponent,
  MessagesComponent,
  NotificationMenuComponent,
  NotificationsComponent
} from '../../components';
import {
  LoadingService,
  MessagesService,
  MessageType,
  ResizeHandlerService,
  TableService,
  UserdataService,
  SubscriberService,
} from '@services';

import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { awaitHandler } from '@utils/awaitHandler';

@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
})
export class ListPage implements OnInit, OnDestroy {

  @ViewChild(ChatMenuComponent) chatMenuComponent: ChatMenuComponent;
  @ViewChild(MessagesComponent) messagesComponent: MessagesComponent;
  @ViewChild(NotificationMenuComponent) notificationMenuComponent: NotificationMenuComponent;
  @ViewChild(NotificationsComponent) notificationsComponent: NotificationsComponent;

  public selectedMessagesIds: number[] = [];
  public selectedNotificationsIds: number[] = [];

  public tabs: ITab[] = [
    {
      value: this.translate.instant('SHARED.Notifications'),
      postfix: `(${this.messageService.getNotificationsCount()})`,
      key: 'notifications',
      permission: () => this.subscriberService.usesModule('notifications')
    },
    {
      value: this.translate.instant('SHARED.Messages'),
      postfix: `(${this.messageService.getChatCount()})`,
      key: 'messages',
      permission: () => this.subscriberService.usesModule('messaging')
    }
  ];

  public currentTab: ITab = this.tabs[0];
  public messageThreadHidden = true;
  public notifEnabled = false;
  public messageEnabled = false;

  public removalConfig: any = {
    count: 0,
    type: 'Messages'
  };

  private messageTimeRefresher: number;
  private messagesSubscription: Subscription;
  private removedMessagesSubscription: Subscription;
  private notificationsSubscription: Subscription;
  private removedNotificationsSubscription: Subscription;
  private routeSubscription: Subscription;

  constructor(
    private menu: MenuController,
    private messageService: MessagesService,
    private loadingService: LoadingService,
    private resizeHandler: ResizeHandlerService,
    private tableService: TableService,
    private userdataService: UserdataService,
    private route: ActivatedRoute,
    private subscriberService: SubscriberService,
    private router: Router,
    private translate: TranslateService
  ) {
  }

  ionViewWillEnter() {
    this.resizeHandler.addResizeHandler('resizeList', () => this.tableService.handleResize());
    this.activeTabByType(this.route.snapshot.queryParamMap.get('type'));

    const uList = this.subscriberService.getFeature('message_enabled_users');
    if (uList && _.indexOf(uList, this.userdataService.userID) > -1) {
      this.messageThreadHidden = false;
    } else {
      this.messageThreadHidden = true;
    }

    this.notifEnabled = this.subscriberService.usesModule('notifications');
    this.messageEnabled = this.subscriberService.usesModule('messaging');

    // if notification is only enabled, we've to make it as current tab
    if (this.notifEnabled && !this.messageEnabled) {
      this.currentTab = this.tabs[0];
    }

  }

  ionViewWillLeave() {
    this.resizeHandler.removeResizeHandler('resizeList');
    this.stopRouteListener();
  }

  ionViewDidEnter() {
    this.messagesComponent.update();
    this.startRouteListener();
  }

  ngOnInit() {
    this.messageTimeRefresher = this.messageService.checkInterval();
    this.messageService.checkInterval(2500);

    this.messagesSubscription = this.messageService.getMessages(MessageType.MESSAGES).subscribe((ids: number[]) => {
      if (this.chatMenuComponent.isActive()) {
        this.chatMenuComponent.addMessagesByIds(ids);
      }
      this.messagesComponent.update();
      this.selectedMessagesIds = [];
      this.defineMessagesCount();
    });

    this.notificationsSubscription = this.messageService.getMessages(MessageType.NOTIFICATIONS).subscribe(() => {
      this.refreshNotificationTable();
    });

    this.removedMessagesSubscription = this.messageService.getMessages(MessageType.REMOVED_MESSAGES).subscribe((ids: any) => {
      _.each(ids, (id: number) => {
        this.chatMenuComponent.closeFor(id);
      });

      this.refreshMessageTable();
    });

    this.removedNotificationsSubscription = this.messageService.getMessages(MessageType.REMOVED_NOTIFICATIONS).subscribe((ids: any) => {
      _.each(ids, (id: number) => {
        this.notificationMenuComponent.closeFor(id);
      });

      this.refreshNotificationTable();
    });
  }

  ngOnDestroy(): void {
    this.messageService.checkInterval(this.messageTimeRefresher);

    this.messagesSubscription && this.messagesSubscription.unsubscribe();
    this.notificationsSubscription && this.notificationsSubscription.unsubscribe();
    this.removedMessagesSubscription && this.removedMessagesSubscription.unsubscribe();
    this.removedNotificationsSubscription && this.removedNotificationsSubscription.unsubscribe();
  }

  public open(): void {
    this.menu.isEnabled('userSelection').then((isEnabled: boolean) => {
      if (!isEnabled) {
        this.menu.enable(true, 'userSelection');
      }
      this.menu.toggle('userSelection');
    });
  }

  public setMenuData(id: number, type: 'chat' | 'notification'): void {
    if (type === 'chat') {
      this.chatMenuComponent.setData({
        type: 'currentChat',
        messageID: id
      });
    } else if (type === 'notification') {
      this.notificationMenuComponent.setId(+id);
      this.markNotificationAsRead(+id);
    }
  }

  public createChat(params: any): void {
    this.chatMenuComponent.setData({
      type: params.type,
      ids: params.ids
    });

    this.menu.isEnabled('chat').then((isEnabled: boolean) => {
      if (!isEnabled) {
        this.menu.enable(true, 'chat');
      }
      this.menu.toggle('chat');
    });
  }

  public refreshMessageTable(): void {
    this.messagesComponent.update();
    this.selectedMessagesIds = [];
    this.defineMessagesCount();
  }

  public onCheckedMessage(ids: number[]): void {
    this.syncHeightWithFooter(this.selectedMessagesIds.length, ids.length);
    this.selectedMessagesIds = ids;
    this.removalConfig.count = ids.length;
    this.removalConfig.type = 'Messages';
  }

  public onCheckedNotification(ids: number[]): void {
    this.syncHeightWithFooter(this.selectedNotificationsIds.length, ids.length);
    this.selectedNotificationsIds = ids;

    this.removalConfig.count = ids.length;
    this.removalConfig.type = 'Notifications';
  }

  public remove(): void {
    const ids: number[] = this.currentTab.key === 'messages' ? this.selectedMessagesIds : this.selectedNotificationsIds;
    const key: string = this.currentTab.key;

    this.loadingService.enable();
    this.messageService.changeRecipientState(ids, 'removed')
      .then(() => {
        this.loadingService.disable();

        if (key === 'messages') {
          this.selectedMessagesIds = [];
          this.messagesComponent.update();
          this.defineMessagesCount();
        } else {
          this.refreshNotificationTable();
        }
      }).catch(() => {
      this.loadingService.disable();
    });
  }

  public syncTabs(tab: ITab): void {
    if (tab.key === 'notifications') {
      if (this.selectedNotificationsIds.length === 0) {
        this.tableService.handleResize();
      }
      this.notificationsComponent.initSizeTable();

      this.removalConfig.count = this.selectedNotificationsIds.length;
      this.removalConfig.type = 'Notifications';
    } else {
      this.removalConfig.count = this.selectedMessagesIds.length;
      this.removalConfig.type = 'Messages';
      this.messagesComponent.initSizeTable();
    }

    this.router.navigate([], {relativeTo: this.route, queryParams: {type: tab.key}});
  }

  public refreshNotificationTable(): void {
    this.selectedNotificationsIds = [];
    this.notificationsComponent.update();
    this.defineNotificationsCount();
  }

  public downloadThread() {
    this.router.navigate(['/pages/communications-list/message-thread']);
  }

  private async markNotificationAsRead(notification) {
    const messageState: any = this.messageService.getRecipientInfo(notification, this.userdataService.userID);
    if (_.includes(['new', 'delivered'], _.get(messageState, 'state'))) {
      await awaitHandler(this.messageService.changeRecipientState(+notification, 'read'));
      this.refreshNotificationTable();
    }
  }

  private syncHeightWithFooter(previousCount, currentCount): void {
    if (previousCount === 0 && currentCount > 0) {
      this.tableService.handleResize(undefined, 100);
    }

    if (previousCount > 0 && currentCount === 0) {
      this.tableService.handleResize();
    }
  }

  private defineMessagesCount() {
    const tab: ITab = _.find(this.tabs, {key: 'messages'});
    tab.postfix = `(${this.messageService.getChatCount()})`;
  }

  private defineNotificationsCount() {
    const tab: ITab = _.find(this.tabs, {key: 'notifications'});
    tab.postfix = `(${this.messageService.getNotificationsCount()})`;
  }

  private activeTabByType(type: string): void {
    if (this.currentTab.key !== type) {
      this.currentTab = type === 'notifications' ? this.tabs[0] : this.tabs[1];
      this.syncTabs({value: this.currentTab.value, key: this.currentTab.key});
    }
  }

  private startRouteListener(): void {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const messageType: string = _.split(event.url, 'communications-list?type=')[1];

      if (messageType) {
        this.activeTabByType(messageType);
      }
    });
  }

  private stopRouteListener(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe();
  }

}
