import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { FoldersDataService, FormField, Module, PropertySubject } from '@services';
import { IFolder } from '@modules/management/modules/folders/model/folders.interfaces';
import { ContentListComponent } from '@modules/management/pages/details/content/components';
import { PropertyPickerComponent } from '@modules/management/modules/property-picker/components';
import {
  PropertyPickerConfig,
  SelectedProperty
} from '@modules/management/modules/property-picker/model/property-picker.interfaces';

import { each, find, has, map, reject } from 'lodash';

export enum FolderDataType {
  ASSET = 'asset',
  PROPERTY = 'property',
  NOTIFICATION_RULE = 'notification_rule',
  RELAY = 'relay'
}

@Injectable({
  providedIn: 'root'
})
export class FoldersService {

  constructor(
    private navController: NavController,
    private foldersDataService: FoldersDataService
  ) {}

  public goToFolder(link: string, folderId: number): void {
    this.navController.navigateForward([`pages/management/${link}`, {folderId}], { animated: false });
  }

  public filterDataByType(type: FolderDataType, folderData: IFolder) {
    if (type === FolderDataType.ASSET) {
      const parentProperties = this.getParentIds(folderData);

      if (parentProperties.length && folderData?.selectedProperties?.length) {
        folderData.selectedProperties = reject(folderData.selectedProperties, (property) => {
          if (property.propertyID) {
            return !!find(parentProperties, {propertyID: property.propertyID});
          } else if (property.propertyFolderID) {
            return !!find(parentProperties, {propertyFolderID: property.propertyFolderID});
          }
        });
      }
    }
  }

  public getAdditionFields(type: FolderDataType, folderData?: IFolder): FormField[] {
    if (type === FolderDataType.ASSET) {
      return [
        {
          type: 'customElement',
          name: 'selectedProperties',
          component: PropertyPickerComponent,
          module: Module.PROPERTIES,
          inputs: {
            pickerConfig: <PropertyPickerConfig>{
              type: PropertySubject.Asset
            },
            parentIds: this.getParentIds(folderData)
          }
        },
        {
          type: 'customElement',
          name: 'contentItems',
          component: ContentListComponent,
          module: Module.CONTENT
        }
      ];
    }
  }

  public parseFormData(type: FolderDataType, formData: any) {
    const fieldNames = map(this.getAdditionFields(type), 'name');

    each(fieldNames, (fieldName) => {
      if (has(formData, fieldName)) {
        try {
          formData[fieldName] = JSON.parse(formData[fieldName]);
        } catch (error) {}
      }
    });
  }

  private getParentIds(folderData: IFolder): SelectedProperty[] {
    let parentProperties: SelectedProperty[] = [];

    if (folderData?.parentID) {
      if (folderData?.parentAttributes?.selectedProperties) {
        parentProperties = folderData.parentAttributes.selectedProperties as SelectedProperty[];
      } else {
        const parentFolder = this.foldersDataService.getFolderByID(+folderData.parentID);
        parentProperties = [
          ...parentFolder?.attributes?.selectedProperties || [],
          ...parentFolder?.parentAttributes?.selectedProperties || []
        ] as SelectedProperty[];
      }
    }

    return parentProperties;
  }
}
