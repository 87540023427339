<ion-app [class.hop]="brand === 'hop'" [class.inteliforz]="brand === 'inteliforz'">
  <ion-router-outlet (activate)="isAppDataLoaded = true" id="main-content">
    <app-header *ngIf="isAppDataLoaded === false && isAppVersionChecked"></app-header>
    <ion-content color="primary">
      <div class="content">
        <img [src]="loaderImg" alt="Loader"/>
        <p>GATHERING DATA</p>
      </div>
    </ion-content>
  </ion-router-outlet>
</ion-app>
