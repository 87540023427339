import { OverlayEventDetail } from '@ionic/core';
import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ReorderList } from '@shared/components/reorder-list/reorder-list.interfaces';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import {
  FolderPickerConfig,
  FolderPickerEntities,
  IFolder
} from '@modules/management/modules/folders/model/folders.interfaces';
import { FoldersDataService, IProperty, PropertyService, PropertySubject } from '@services';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';
import { FolderPickerService } from '@modules/management/modules/folders/services/folder-picker.service';
import { ITreeViewModalConfig, TreeViewNode, TreeViewNodeIdType } from '@shared/modules/tree-viewer/models';
import {
  ReorderPickerFolder,
  ReorderPickerProperty,
  PropertyPickerConfig,
  SelectedProperty,
  ReorderPickerItem,
  PropertyPickerSelection
} from './../../model/property-picker.interfaces';
import {
  ViewModalTreePopupComponent
} from '@shared/modules/tree-viewer/view-modals/view-modal-popup/view-modal-popup.component';

import { clone, cloneDeep, filter, find, has, join, includes, map, split } from 'lodash';

@Component({
  selector: 'app-property-picker',
  templateUrl: './property-picker.component.html',
  styleUrls: ['./property-picker.component.scss'],
})
export class PropertyPickerComponent extends CustomFormComponent implements OnInit {
  @Input() parentIds: PropertyPickerSelection[];
  @Input() pickerConfig: PropertyPickerConfig;
  @ViewChild('propertyNameRef', { static: true }) propertyNameRef: TemplateRef<HTMLElement>;
  @ViewChild('propertyStateRef', { static: true }) propertyStateRef: TemplateRef<HTMLElement>;

  public title = {
    main: '',
    parent: '',
    child: ''
  };
  public reorderConfig: ReorderList<ReorderPickerItem> = {
    draggable: true,
    items: [],
    columns: []
  };
  public parentReorderConfig: ReorderList<ReorderPickerItem> = clone(this.reorderConfig);

  private selectedProperties: PropertyPickerSelection[];
  private propertyEntities: FolderPickerEntities = {
    folderItems: [],
    folders: {}
  };

  constructor(
    private popoverController: PopoverController,
    private folderPickerService: FolderPickerService,
    private propertyService: PropertyService,
    public foldersDataService: FoldersDataService
  ) {
    super();
  }

  ngOnInit() {
    this.defineTitle();
    this.getPropertyNodes();
    this.selectedProperties = cloneDeep(this.formValue);
    this.initReorderList();
  }

  public emitValue() {
    this.formValue = this.selectedProperties;
  }

  public folderPath(folderID: number) {
    return join(this.foldersDataService.getFolderPath(folderID), '/ ');
  }

  public onReorder(properties: ReorderPickerItem[]) {
    this.selectedProperties = map(properties, (property) => {
      if ((property as ReorderPickerProperty).propertyID) {
        return find(this.selectedProperties, { propertyID: (property as ReorderPickerProperty).propertyID });
      } else {
        return find(this.selectedProperties, { propertyFolderID: property.folderID });
      }
    });

    this.emitValue();
  }

  public selectProperties() {
    this.popoverController.create({
      component: ViewModalTreePopupComponent,
      animated: false,
      componentProps: {
        config: <ITreeViewModalConfig>{
          title: 'PROPERTY.Select_Property',
          nodes: this.getPropertyNodes(),
          multiselect: true
        }
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event: OverlayEventDetail<TreeViewNodeIdType[]>) => {
        if (event.data) {
          this.onSelectedProperties(event.data);
        }
      });
    });
  }

  public onStateChanged(value: boolean, property: IProperty | IFolder) {
    let selectedProperty: SelectedProperty;

    if ((property as IProperty).propertyID) {
      selectedProperty = find(this.selectedProperties,  { propertyID: (property as IProperty).propertyID });
    } else if (has(property, 'folderID')) {
      selectedProperty = find(this.selectedProperties,  { propertyFolderID: property.folderID });
    }

    if (selectedProperty) {
      selectedProperty.healthRelated = value ? 1 : 0;
    }

    this.emitValue();
  }

  private onSelectedProperties(ids: TreeViewNodeIdType[]) {
    this.selectedProperties = map(ids, (nodeId: TreeViewNodeIdType): PropertyPickerSelection => {
      const folderID = +split(nodeId as string, 'folder:')?.[1];

      if (isNaN(folderID)) {
        const property = this.propertyService.getPropertyById(nodeId as number);
        return { propertyID: nodeId as number, healthRelated: property.healthRelated };
      } else {
        return { propertyFolderID: folderID };
      }
    });

    this.emitValue();
    this.initReorderList();
  }

  private defineTitle() {
    if (this.pickerConfig.type === PropertySubject.Asset) {
      this.title.main = 'MGMT_LIST.Asset_Properties';

      if (this.parentIds?.length) {
        this.title.parent = 'MGMT_LIST.Asset_Properties_Inherited';
        this.title.child = 'MGMT_LIST.Asset_Specific_Properties';
      }
    }
  }

  private getPropertyNodes(): TreeViewNode[] {
    const properties = cloneDeep(filter(this.propertyService.getProperties(), (property) => {
      return !property.disabledAt && includes(property.subjects, this.pickerConfig.type) && property.active === 1;
    }));

    const config: FolderPickerConfig = {
      dataType: FolderDataType.PROPERTY,
      entities: this.propertyEntities,
      selectAllLabel: 'PROPERTY.All_Properties',
      selectedIds: this.formValue,
      parentIds: this.parentIds,
      getFolderItems: () => properties,
      getItemId: (property: IProperty) => property?.propertyID,
      getItemTitle: (property: IProperty) => property?.title,
      getFolderId: (folder) => folder?.folderID || (folder as SelectedProperty)?.propertyFolderID
    };
    return this.folderPickerService.getTreeNodes(config);
  }

  private initReorderList() {
    this.reorderConfig.columns = [
      {
        title: 'SHARED.Property',
        class: 'property-name',
        template: this.propertyNameRef
      },
      {
        title: 'PROPERTY.Track_State',
        class: 'property-state',
        template: this.propertyStateRef
      }
    ];
    this.parentReorderConfig.columns = clone(this.reorderConfig.columns);

    this.reorderConfig.items = this.defineReorderItems(this.selectedProperties);
    this.parentReorderConfig.items = this.defineReorderItems(this.parentIds);
  }

  private defineReorderItems(selectedProperties: SelectedProperty[]): ReorderPickerItem[] {
    return filter(map(selectedProperties, (selectedProperty) => {
      if (selectedProperty.propertyID) {
        return {
          ...this.propertyService.getPropertyById(selectedProperty.propertyID),
          healthRelated: selectedProperty.healthRelated
        };
      } else if (has(selectedProperty, 'propertyFolderID')) {
        if (selectedProperty.propertyFolderID === 0) {
          return {
            title: 'PROPERTY.All_Properties',
            folderID: 0,
            healthRelated: selectedProperty.healthRelated
          } as ReorderPickerFolder;
        }
        return {
          ...this.foldersDataService.getFolderByID(selectedProperty.propertyFolderID),
          healthRelated: selectedProperty.healthRelated
        };
      }
    }));
  }
}
