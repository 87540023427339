import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-changes-panel',
  templateUrl: './changes-panel.component.html',
  styleUrls: ['./changes-panel.component.scss'],
})
export class ChangesPanelComponent {

  @Input() changesCount = 0;

  @Output() onSave: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

}
