import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListPage, MessageThreadPage } from './pages';
import { MarkdownModule } from 'ngx-markdown';

const routes: Routes = [
  {
    path: '',
    component: ListPage
  },
  {
    path: 'message-thread',
    component: MessageThreadPage,
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    MarkdownModule.forChild()],
  exports: [RouterModule]
})
export class CommunicationsRoutingModule {
}
