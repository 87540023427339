<div class="images-container">
  <div (click)="presentModal(img)" *ngFor="let img of images; let i = index" class="galery-block" tabindex="0">
    <app-media-file [class]="img.class" [disableOpening]="true" [mediaType]="img.mediaType"
                    [url]="img.thumbnailUrl"></app-media-file>

    <div class="galery-block-text">
      <span [title]="img.fullName" class="body-copy-bold">
        {{img.title}}
      </span><br>
      <span class="detail">{{img.time}}</span>
    </div>
  </div>
</div>
