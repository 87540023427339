import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Location } from '@angular/common';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ModalPageDeactivator implements CanDeactivate<null> {

  constructor(
    private modalController: ModalController,
    protected location: Location
  ) {
  }

  canDeactivate(component: any, currentRoute: any): Promise<boolean> {
    return this.modalController.getTop().then((element: HTMLIonModalElement) => {
      const url: string = _.get(currentRoute, '_routerState.url', '');

      if (element && url) {
        history.pushState(null, null, url);
      }
      return !element;
    });
  }
}
