<div class="modal-table">
  <div class="header">
    <button (click)="close()" class="popup-close-button" tabindex="0"></button>
    <h2 class="title">{{ title | translate }}</h2>
  </div>

  <div class="body">
    <div class="table-container">
      <table #listTable class="display"></table>
    </div>
  </div>
</div>
