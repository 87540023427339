<div class="form-modal">
  <div class="modal-title form-divider-title headline-medium">{{ title }}</div>
  <div class="form-group">
    <div class="select selected-items" id="dataToIncludeModal">
      <div class="title">{{"SHARED.Select" | translate}}</div>
      <app-item-list
        (onSelected)="onItemsSelected()"
        [idProperty]="'id'"
        [isInfinityScrollEnabled]="false"
        [isMultiple]="true"
        [isSearchable]="false"
        [items]="options"></app-item-list>
    </div>
    <div class="arrange selected-items">
      <div class="title">{{"SHARED.Arrange" | translate}}</div>
      <app-item-list-drag-and-drop [items]="selectedItems"></app-item-list-drag-and-drop>
    </div>
  </div>
  <div class="btn-group">
    <ion-button (click)="close()" class="page-button" color="light">{{"SHARED.Cancel" | translate}}</ion-button>
    <ion-button (click)="applyForm()" [disabled]="!selectedItems || !selectedItems.length" class="page-button apply-btn"
                color="secondary">{{"SHARED.Apply" | translate}}</ion-button>
  </div>
</div>
