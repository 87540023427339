<div class="card-user">
  <img [src]="avatarUrl">

  <div class="card-details">
    <div class="card-details-header">
      <span class="card-user-name">{{ name }}</span>
      <span *ngIf="time" class="card-date"> | {{ time }}</span>
    </div>
    <div *ngIf="type === 'text'">{{ text }}</div>

    <audio *ngIf="type ==='audio'" class="observation-audio" controls="" preload="metadata">
      <source [src]="text" type="audio/mp4">
    </audio>
  </div>
</div>
