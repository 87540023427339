import { Directive, ElementRef, Input } from '@angular/core';

import { PermissionsService } from '@services';

@Directive({
  selector: '[appPermissionCanView]'
})
export class PermissionCanViewDirective {

  @Input('appPermissionCanView') appPermissionCanView: string;

  constructor(
    private elementRef: ElementRef,
    private permissionsService: PermissionsService
  ) {
  }

  ngOnInit(): void {
    if (!this.permissionsService.canView(this.appPermissionCanView)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }


}
