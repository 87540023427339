import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
  name: 'menuFilter'
})
export class MenuFilterPipe implements PipeTransform {

  transform(items: any, searchModel: string, type: string): any {
    let filteredItems: any = items;

    if (searchModel) {
      filteredItems = _.filter(filteredItems, (item: any) => {
        if (type === 'team') {
          return this.isTeamValid(item, searchModel);
        } else if (type === 'user') {
          return this.isUserValid(item, searchModel);
        }
      });
    }

    return filteredItems;
  }

  private isUserValid(user: any, searchModel: string): boolean {
    const firstName: string = _.replace(_.lowerCase(_.get(user, 'firstname')), / /g, '');
    const lastName: string = _.replace(_.lowerCase(_.get(user, 'lastname')), / /g, '');
    searchModel = _.replace(_.lowerCase(searchModel), / /g, '');

    return _.includes(firstName, searchModel) ||
      _.includes(lastName, searchModel) ||
      _.includes(firstName + lastName, searchModel) ||
      _.includes(lastName + firstName, searchModel);
  }

  private isTeamValid(team: any, searchModel: string): boolean {
    const name: string = _.replace(_.lowerCase(_.get(team, 'name')), / /g, '');
    searchModel = _.replace(_.lowerCase(searchModel), / /g, '');

    return _.includes(name, searchModel);
  }

}
