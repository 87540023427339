<ion-content>
  <section class="user-container">
    <div class="container-left custom-inputs">
      <div class="header">
        <img alt="Logo" src="assets/images/logo_corvex.png">
      </div>
      <form (keyup.enter)="onSubmit()" (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="wrap">
        <div *ngIf="showLogo" class="logo-block">
          <img [src]="theLogo" alt="Logo" class="default-logo">
        </div>
        <div class="form-group">
          <label>{{ "AUTH.Username" | translate }}<span class="required">*</span></label>
          <input [ngClass]="{'required-error': isInvalid('username')}" formControlName="username" id="username">
        </div>

        <div class="form-group">
          <label>{{ "AUTH.Password" | translate }}</label>
          <input autocomplete="new-password" formControlName="password" id="password" type="password">

          <img appHideShowPassword class="password-icon" src="assets/icons/General/Hidden.svg">
        </div>

        <div class="remember-me form-group">
          <label>{{ "AUTH.Remember_Me" | translate }}</label>
          <ion-toggle color="secondary" formControlName="rememberMe"></ion-toggle>
        </div>

        <div class='button-block centered'>
          <ion-button [disabled]="disableStatus" class="page-button forgot-button-style" color="secondary"
                      type="submit">{{ "AUTH.Sign_In" | translate }}</ion-button>
        </div>
        <div [appShowByModule]="feature.SECURITY_QUESTION_RECOVERY" class="forgot-password-username">
          <div (click)="goToForgotPassword()"
               class="forgot-password-link">{{"SHARED.Forgot_username/password?" | translate}}</div>
        </div>
        <div *ngIf="usesAuth0">
          <hr class='hr-line'>
          <div *ngIf="usesAuth0" class='button-block centered'>
            <ion-button (click)="popupAuthenticate()" class="page-button company-button forgot-button-style" color="light"
                        type="button">{{ "AUTH.SSO" | translate }}</ion-button>
          </div>
        </div>
      </form>
      <div class="footer" *ngIf="usesLoginFooter">
        <div class="body-copy text-center">
          <div [innerHTML]='theFooter | markdown'></div>
        </div>
      </div>
    </div>
    <div [ngStyle]='{"background-image": defaultBackground}' class="container-right">
      <img [src]="rightLogo" alt="Logo">
      <p *ngIf="isHOP" class="slogan">Operational Listening & Learning.™</p>
      <p *ngIf="isCorvex">Worker Powered.™</p>
    </div>
  </section>
</ion-content>
