import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';

import {
  AccountsService,
  LoadingService,
  MessagesService,
  TeamsService,
  UserdataService,
  UserService,
  UtilsService,
  PopoverService
} from '@services';
import { ParticipantViewComponent } from '../participant-view/participant-view.component';
import { IPopoverConfig } from '@shared/components';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

const ResizeObserver = require('resize-observer-polyfill').default;

@Component({
  selector: 'app-chat-menu',
  templateUrl: './chat-menu.component.html',
  styleUrls: ['./chat-menu.component.scss'],
})
export class ChatMenuComponent {

  @ViewChild('newMessageTextArea') newMessageTextArea: ElementRef;
  @Output() refreshTable: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('content') content: any;

  public viewItems: any[] = [];
  public user: any = {};
  public date: string;
  public newMessage = '';
  public avatarUrl: string;
  public fullName: string;
  public isViewAvailable = false;
  public isTeamChat = false;
  public isMultipleUsersChat = false;
  public messageLength = 2000;
  private newChatSelectedUserIDs: number[] = [];
  private newChatSelectedTeamIDs: number[] = [];
  private isNewChat = false;
  private scrollObserver: typeof ResizeObserver = new ResizeObserver(() => {
    this.scrollToBottom();
  });
  private isScrollListeningEnable = false;

  constructor(
    private menu: MenuController,
    private messagesService: MessagesService,
    private accountsService: AccountsService,
    private userService: UserService,
    private utils: UtilsService,
    private userData: UserdataService,
    private popoverController: PopoverController,
    private teamsService: TeamsService,
    private loadingService: LoadingService,
    private popoverService: PopoverService,
    private translate: TranslateService
  ) {
  }

  public closeFor(id: number): void {
    const lastMessageId: any = _.last(this.viewItems);
    if (id === lastMessageId.id) {
      this.menu.isOpen('chat').then((isOpen: boolean) => {
        if (isOpen) {
          this.close();
        }
      });
    }
  }

  public startListScrollListening(): void {
    if (this.isScrollListeningEnable === false && this.viewItems.length) {
      this.scrollObserver.observe(document.querySelector('#message-list'));
      this.isScrollListeningEnable = true;
    }
  }

  public stopListScrollListening(): void {
    this.scrollObserver.disconnect();
    this.isScrollListeningEnable = false;
  }

  public isActive(): boolean {
    return this.isScrollListeningEnable;
  }

  public close(): void {
    this.menu.close('chat');
  }

  public addMessagesByIds(ids: number[]): void {
    const messageIds: number[] = _.map(this.viewItems, 'id');
    let isNew = false;

    _.each(ids, (id: number) => {
      if (this.isCurrentChat(messageIds, id)) {
        this.messagesService.findAllMessageThread(id);
        const message: any = this.messagesService.getMessage(id);

        if (!_.includes(messageIds, id)) {
          isNew = true;
          const newMessage: any = this.createMessage(message);
          this.viewItems.push(newMessage);
        }
      }
    });

    if (isNew) {
      this.startListScrollListening();
      this.markMessagesAsRead();
    }
  }

  public setData(params: any): void {
    let teamCount = 0;
    let userCount = 0;

    this.newChatSelectedUserIDs = [];
    this.newChatSelectedTeamIDs = [];
    this.viewItems = [];
    this.user = {};
    this.date = null;
    this.newMessage = '';
    this.isNewChat = false;

    if (params.type === 'currentChat') {
      const message: any = this.messagesService.getMessage(params.messageID);
      const userID: number = message.userID;

      this.user = this.accountsService.getAccount(userID);
      this.avatarUrl = 'assets/images/group_icon.svg';
      this.date = this.utils.dateTimeFormat(message.created);

      if (message.recipients.length === 2) {
        const targetUserId: number = _.get(_.reject(message.recipients, {userID: this.userData.userID}), '[0].userID');
        this.defineUserInfoById(targetUserId);
      } else if (message.recipients.length > 2) {
        this.fullName = `${message.recipients.length} ${this.translate.instant('COMMUNICATIONS.Participants')}`;
        this.avatarUrl = 'assets/images/group_icon.svg';
      } else if (message.groups && message.groups.length) {
        const teamCount = message.groups && message.groups.length;
        this.avatarUrl = 'assets/images/group_icon.svg';
        if (teamCount > 1) {
          this.fullName = `${teamCount} ${this.translate.instant('SHARED.TEAMS')}`;
        } else {
          this.fullName = this.translate.instant('SHARED.Team');
        }
      } else {
        this.avatarUrl = this.userService.accountAvatar(this.user.userID, 64, false, false);
        this.fullName = this.userService.getFullname(this.user.userID);
      }

      const messageIds: number[] = this.messagesService.findAllMessageThread(message.messageID);

      this.viewItems = _.map(messageIds, (messageId: number) => {
        const message: any = this.messagesService.getMessage(messageId);
        return this.createMessage(message);
      });
      this.markMessagesAsRead();
      this.isViewAvailable = _.get(message, 'recipients.length', 0) > 2 || _.get(message, 'groups.length', 0) > 1;
      teamCount = message.groups && message.groups.length;
      userCount = message.recipients.length;
    } else {
      this.isNewChat = true;
      this.user = this.accountsService.getAccount(+this.userData.userID);
      this.avatarUrl = this.userService.accountAvatar(this.user.userID, 64, false, false);
      this.fullName = this.userService.getFullname(this.user.userID);
      this.date = this.utils.dateTimeFormat(moment().unix());

      if (params.type === 'user') {
        this.newChatSelectedUserIDs = params.ids;

        if (params.ids.length > 1) {
          this.fullName = `${params.ids.length + 1} ${this.translate.instant('COMMUNICATIONS.Participants')}`;
          this.avatarUrl = 'assets/images/group_icon.svg';
        } else {
          this.defineUserInfoById(params.ids[0]);
        }
      } else {
        this.newChatSelectedTeamIDs = params.ids;
        if (params.ids.length > 1) {
          this.fullName = `${params.ids.length} ${this.translate.instant('SHARED.TEAMS')}`;
        } else {
          this.fullName = `${this.teamsService.teamNameByID(params.ids[0])} ${this.translate.instant('SHARED.Team')}`;
        }
        this.avatarUrl = 'assets/images/group_icon.svg';
      }
    }

    this.isTeamChat = !!teamCount;

    if (this.isTeamChat) {
      this.isMultipleUsersChat = true;
    } else {
      this.isMultipleUsersChat = userCount > 2;
    }
  }

  public sendMessage(): void {
    this.newMessage = _.trim(this.newMessage);

    if (this.newMessage) {
      if (this.isNewChat) {
        this.addNewMessage();
      } else {
        const newMessageParams: any = {
          text: this.newMessage,
          previousMessageID: _.last(this.viewItems).id
        };

        this.newMessage = '';
        this.messagesService.replyToMessage(newMessageParams).then((messageText) => {
          const reqStatus = messageText.reqStatus;
          if (reqStatus !== 'OK') {
            this.messageLimitError();
          }
        });
      }
    }
  }

  public submitOnEnter(event: KeyboardEvent): void {
    if (event.keyCode === 13 && !event.shiftKey && this.newMessage) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  public viewParticipants(): void {
    const id: number = _.get(_.last(this.viewItems), 'id');
    if (id) {
      const message: any = this.messagesService.getMessage(id);
      let items: any[] = [];

      if (message.recipients.length > 2) {
        items = _.map(message.recipients, (recipient) => {
          const user: any = this.accountsService.getAccount(recipient.userID);

          return {
            name: `${user.firstname} ${user.lastname}`,
            avatarUrl: this.userService.accountAvatar(user.userID, 64, false, false)
          };
        });
      } else {
        items = _.map(message.groups, (group) => ({
          name: this.teamsService.teamNameByID(group)
        }));
      }

      this.popoverController.create(<any>{
        component: ParticipantViewComponent,
        animated: false,
        componentProps: {
          participants: items
        }
      }).then((element: HTMLIonPopoverElement) => {
        element.present();
      });
    }
  }

  public calcHeight(): void {
    setTimeout(() => {
      const minHeight = 61;
      const maxHeight = 120;

      this.newMessageTextArea.nativeElement.style.overflow = 'hidden';
      this.newMessageTextArea.nativeElement.style.height = 'auto';

      if (this.newMessageTextArea.nativeElement.scrollHeight === minHeight) {
        this.newMessageTextArea.nativeElement.setAttribute('rows', 1);
      } else {
        if (this.newMessageTextArea.nativeElement.scrollHeight <= maxHeight) {
          this.newMessageTextArea.nativeElement.style.height = `${this.newMessageTextArea.nativeElement.scrollHeight}px`;
        } else {
          this.newMessageTextArea.nativeElement.style.height = `${maxHeight}px`;
        }
      }
    });
  }

  public onClose(): void {
    this.avatarUrl = '';
  }

  public messageLimitError() {
    const params: IPopoverConfig = {
      title: this.translate.instant('COMMUNICATIONS.Message_Error'),
      description: this.translate.instant('COMMUNICATIONS.Message_Error_Description'),
      hideActions: true
    };
    this.popoverService.show(params);
  }

  private createMessage(message: any): any {
    const userName: string = this.userService.getFullname(message.userID);

    return {
      text: message.message,
      object: message.object,
      userName: message.userID === this.userData.userID ? null : userName,
      time: message.created * 1000,
      userAvatarUrl: this.userService.accountAvatar(message.userID, 64, null, false),
      id: message.messageID,
      groups: message.groups || []
    };
  }

  private addNewMessage(): void {
    const newMessageParams: any = {
      text: this.newMessage,
      users: _.map(this.newChatSelectedUserIDs, String) || [],
      groups: _.map(this.newChatSelectedTeamIDs, String) || []
    };

    this.newMessage = '';
    this.messagesService.createNewMessage(newMessageParams).then((message: any) => {
      const reqStatus = message.status;
      if (reqStatus !== 'OK') {
        this.messageLimitError();
      }
      this.refreshTable.emit();
      this.setData({
        type: 'currentChat',
        messageID: message.messageID
      });
      setTimeout(() => {
        this.startListScrollListening();
      });
    });
  }

  private isCurrentChat(currentMessageIds: number[], newMessageId: number): boolean {
    return newMessageId === _.last(this.messagesService.findAllMessageThread(_.last(currentMessageIds)));
  }

  private scrollToBottom(): void {
    this.content.scrollToBottom(350);
  }

  private markMessagesAsRead(): void {
    const lastMessage: any = _.last(this.viewItems);
    const messageIds: number[] = this.messagesService.findAllMessageThread(lastMessage.id);
    this.messagesService.setMessageThreadAsRead(messageIds);
    this.refreshTable.emit();
  }

  private defineUserInfoById(userId: number): void {
    this.fullName = this.userService.getFullname(userId);
    this.avatarUrl = this.userService.accountAvatar(userId, null, false, true);
  }
}
