import { Component, Input } from '@angular/core';

import { RiskWidget, RiskWidgetComponent } from './../risk-widget/risk-widget.component';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-risk-profile',
  templateUrl: './detail-risk-profile.component.html',
  styleUrls: ['./detail-risk-profile.component.scss'],
})
export class DetailRiskProfileComponent {

  public loggedWidget: RiskWidget = {
    title: this.translate.instant('SHARED.logged'),
    isDetailView: true,
    value: null
  };
  public assessedWidget: RiskWidget = {
    title: this.translate.instant('DASHPAGES.Assessed'),
    isDetailView: true,
    value: null
  };
  public remainingWidget: RiskWidget = {
    title: this.translate.instant('DASHPAGES.Remaining'),
    isDetailView: true,
    value: null
  };

  constructor(private translate: TranslateService) {
  }

  @Input() set config(data) {
    this.loggedWidget.value = RiskWidgetComponent.getRiskValueBy(data, ['severity', 'likelihood']);
    this.assessedWidget.value = RiskWidgetComponent.getRiskValueBy(data, ['severityOverride', 'likelihoodOverride']);
    this.remainingWidget.value = RiskWidgetComponent.getRiskValueBy(data, ['severityClosed', 'likelihoodClosed']);
  }

}
