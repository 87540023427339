<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ title | translate }}</h2>
</div>
<div class="body">
  <textarea [(ngModel)]="textMessage" autocomplete="off" class="textarea-styled" placeholder="{{placeHolder | translate}}"
            rows="10"></textarea>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="addNotes()" [disabled]="!textMessage" class="page-button"
              color="secondary">{{ "SHARED.Done" | translate }}</ion-button>
</div>
