import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BaseDetailsPage } from './../abstractDetails/abstractBaseDetails.page';
import {
  CertificationsService,
  FormBuilderService,
  FormField,
  ImageUploaderService,
  LoadingService,
  ObjectsService,
  PermissionsService,
  PopoverService,
  RolesService,
  SettingsService,
  ShiftService,
  SubscriberService,
  UserdataService,
  UserService,
  UtilsService,
  FilterElementService
} from '@services';
import { awaitHandler } from '@utils/awaitHandler';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-content',
  templateUrl: './content.page.html',
  styleUrls: ['./content.page.scss'],
})
export class ContentPage extends BaseDetailsPage {

  public title: string = this.translate.instant('MGMT_DETAILS.Add_Content');
  protected routeIdParameterName = 'id';
  protected newForm: any = {
    id: 'contentAddForm',
    cancelButton: 'contentAddCancel',
    saveButton: 'contentAddSave'
  };
  protected editForm: any = {
    id: 'contentEditForm',
    cancelButton: 'contentEditCancel',
    saveButton: 'contentEditSave',
    deleteButton: 'contentEditDelete'
  };
  private locations = this.userService.getUserLocations(this.userDataService.locations, false);
  private objectData: {
    description: string;
    mediaType: string;
    objectID: number;
    objectUUID: string;
    path: string;
    state: string;
    status: string;
    statustext: string;
    subtype: string;
    type: string;
  };
  private mediaConfig = {
    options: {
      formData: {
        type: 'content',
        subtype: null
      },
      acceptFiles: this.imageUploaderService.convertTypesToString({
        image: ['gif', 'jpeg', 'jpg', 'png'],
        application: ['pdf'],
        audio: ['mp4', 'm4a'],
        video: ['mp4']
      })
    },
    events: {
      onSuccess: (data) => {
        this.toggleFormControls();
        this.objectData = data;
        this.customValidate.validateFailed('success');

        if (!this.messageID) {
          const field = _.find(this.formConfig.fields, {name: 'translation_contentObject'});
          this.formBuilderService.updateFields(this.formConfig, [field], data, this.formId);
        }
      },
      onError: () => {
        this.toggleFormControls();
        this.customValidate.validateFailed('error');
      }
    }
  };
  protected formConfig: any = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: 'contentAdd',
    del: null,
    save: this.translate.instant('MGMT_DETAILS.Add_Content'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      {
        required: true,
        inputtype: 'verbatim',
        name: 'description',
        title: this.translate.instant('MGMT_DETAILS.Title'),
        type: 'text',
        size: 20
      },
      {
        inputtype: 'verbatim',
        name: 'translation_description',
        title: this.translate.instant('MGMT_DETAILS.Title_Translation'),
        type: 'textTranslations',
        size: 20,
      },
      {
        required: true,
        name: 'contentObject',
        title: this.translate.instant('LAYOUT.Content'),
        type: 'media',
        extensions: ['image', 'pdf', 'audio', 'video'],
        mediaConfig: _.cloneDeep(this.mediaConfig),
        multiple: false,
        addButton: this.translate.instant('SHARED.Upload_Image'),
        updateButton: this.translate.instant('SHARED.Replace_Image'),
        imageSize: '150px',
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['translation-object'],
              valueFunction: (val) => !val
            }
          ]
        }
      },
      {
        containerClass: 'translation-object',
        name: 'translation_contentObject',
        title: this.translate.instant('MGMT_DETAILS.Content_Translation'),
        type: 'mediaTranslations',
        extensions: ['image', 'pdf', 'audio', 'video'],
        mediaConfig: Object.assign({}, this.mediaConfig, {
          events: {
            onSuccess: () => this.toggleFormControls(),
            onError: () => this.toggleFormControls(),
            onSubmit: () => this.toggleFormControls(false),
            onRemove: async (id: number) => {
              if (!this.messageID) {
                this.loadingService.enable();
                await awaitHandler(this.objectsService.removeObject(id));
                this.loadingService.disable();
              }
            }
          }
        }),
        multiple: false,
        addButton: this.translate.instant('SHARED.Upload_Image'),
        updateButton: this.translate.instant('SHARED.Replace_Image'),
        imageSize: '150px'
      },
      {
        required: true,
        name: 'contentCategory',
        title: this.translate.instant('SHARED.Category'),
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.Choose_one'),
        valueProperty: 'messageID',
        options: _.reject(this.settingsService.getSettingSync('contentCategory'), 'disabledAt'),
        func: (assetType: any) => {
          let value: string = assetType.messageTitle;
          const currentLanguage: string = this.userDataService.getLanguage();

          if (currentLanguage !== this.subscriberService.getDefaultLanguage()) {
            const translatedValue: any = _.find(assetType.translations, ['language', currentLanguage]);

            if (translatedValue) {
              value = translatedValue.value;
            }
          }

          return value;
        }
      },
      {
        title: this.translate.instant('SHARED.Tags'),
        name: 'tags',
        type: 'selectmenu',
        multiple: true,
        tags: true,
        options: [],
        canClear: false,
        valueProperty: 'tagID',
        func: (item: any) => item.tag,
        placeholder: this.translate.instant('SHARED.Add_Tags')
      },
      {
        containerClass: 'type-selector',
        title: 'MGMT_DETAILS.Limit_Content_Access',
        type: 'subtitle'
      },
      {
        title: this.translate.instant('SHARED.Locations'),
        name: 'locations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Locations'),
        multiple: true,
        valueProperty: 'locationID',
        options: this.locations,
        func: (ref) => ref.name,
        onChange: () => this.onLocationChange()
      },
      {
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'teams',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: []
      },
      {
        name: 'shifts',
        title: this.translate.instant('SHARED.Shift'),
        type: 'selectmenu',
        placeholder: this.translate.instant('OTable.All_Shifts'),
        multiple: true,
        originalOrder: true,
        options: []
      },
      {
        name: 'roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'roleID',
        options: 'this.roleService.roles',
        test: (ref) => !_.get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        name: 'permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        multiple: true,
        options: _.cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return _.get(this.userDataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return _.get(this.userDataService.Permissions, 'sadmin') || _.get(this.userDataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        title: this.translate.instant('SHARED.Certifications'),
        name: 'certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.formBuilderService.certificationName(certification),
        test: (certification) => !_.get(certification, 'disabledAt')
      },
      {
        name: 'state',
        title: this.translate.instant('MGMT_DETAILS.Content_available'),
        type: 'flipswitch',
        value: 1,
        default: 1
      }
    ]
  };

  constructor(
    protected route: ActivatedRoute,
    protected formBuilderService: FormBuilderService,
    protected elementRef: ElementRef,
    protected popoverService: PopoverService,
    protected location: Location,
    protected loadingService: LoadingService,
    protected translate: TranslateService,
    private settingsService: SettingsService,
    private userDataService: UserdataService,
    private subscriberService: SubscriberService,
    private objectsService: ObjectsService,
    private userService: UserService,
    private roleService: RolesService,
    private permissionService: PermissionsService,
    private shifts: ShiftService,
    private certificationsService: CertificationsService,
    private utils: UtilsService,
    private imageUploaderService: ImageUploaderService,
    private filterService: FilterElementService
  ) {
    super(route, formBuilderService, elementRef, popoverService, location, loadingService, translate);
  }

  protected deleteHandler: any = () => this.objectsService.removeObject(this.objectData.objectID);

  protected updateHandler: any = async (formData: any) => {
    await awaitHandler(this.savePendingAttachments(formData));
    await this.settingsService.encodeTags(formData, 'tags');
    return this.updateObject(formData);
  };

  protected addHandler: any = async (formData: any) => {
    await this.settingsService.encodeTags(formData, 'tags');
    return this.settingsService.handleNewTagsByForm(formData, (data) => this.updateObject(data));
  };

  protected getData() {
    let data: any = {};
    const category: string = this.route.snapshot.paramMap.get('typeId');

    if (category) {
      data.contentCategory = +category;
    }

    if (this.messageID) {
      this.objectData = <any>this.objectsService.getCachedObjectById(+this.messageID, 'content');
      data = this.objectData;

      if (data.objectID) {
        data.contentObject = data.objectID;
      }

      if (!_.isNumber(data.state)) {
        data.state = data.state === 'active' ? 1 : 0;
      }

      this.utils.decodeTranslations(this.objectData, ['translation_description'], ['description', 'translation_contentObject']);
      this.decodeTranslationsObjects();
    }

    return data;
  }

  protected prepareFormConfig(): void {
    if (this.messageID) {
      this.title = this.translate.instant('MGMT_DETAILS.Edit_Content');

      this.formConfig.prefix = 'contentEdit';
      this.formConfig.save = this.translate.instant('SHARED.Save_Changes');
      this.formConfig.del = this.translate.instant('MGMT_DETAILS.Delete_Content');

      this.deletePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Delete_Content');
      this.deletePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Content_Delete_Popover_Description');

      this.updatePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Update_Content');
      this.updatePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Content_Update_Popover_Description');

      this.enableLocalMediaMode('contentObject');
      this.enableLocalMediaMode('translation_contentObject');
    }
  }

  protected async prepareDataAsync(): Promise<any> {
    const customTagsHandler: Promise<any> = _.isEmpty(this.settingsService.customTags.data) ?
      this.settingsService.getCustomTags() : Promise.resolve();
    const objectsHandler: Promise<any> = _.isEmpty(this.objectsService.getCachedObjectByAlias('content')) ?
      this.objectsService.getObjectList(['content'], false) : Promise.resolve();

    await this.loadingService.enable();
    await awaitHandler(Promise.all([customTagsHandler, objectsHandler]));
    this.loadingService.disable();

    const tagField: any = _.find(this.formConfig.fields, {name: 'tags'});
    tagField.options = _.sortBy(this.settingsService.customTags.data, 'tag');

    if (this.messageID) {
      this.objectData = <any>this.objectsService.getCachedObjectById(+this.messageID, 'content') || {};

      if (this.objectData.objectID) {
        const contentField: any = _.find(this.formConfig.fields, {name: 'contentObject'});
        contentField.mediaConfig.options.formData.updates = this.objectData.objectID;
      }
    }
  }

  protected onFinish(): void {
    this.onLocationChange();
  }

  private updateObject(formData) {
    this.prepareFormData(formData);
    return this.objectsService.updateObject(formData);
  }

  private enableLocalMediaMode(fieldName: string): void {
    const targetField = <FormField>_.find(this.formConfig.fields, {name: fieldName});
    targetField.mediaConfig.options.autoSubmit = false;
    targetField.mediaConfig.options.showCancel = true;
  }

  private prepareFormData(formData): any {
    const convertArrayToJSON = (key: string): void => {
      const items = _.map(_.flatten([formData[key]]), (arrayItem: string | number) => _.isNaN(+arrayItem) ? arrayItem : +arrayItem);
      formData[key] = JSON.stringify(_.filter(items));
    };

    this.utils.encodeTranslations(formData, ['translation_description'], ['description']);

    formData.type = 'content';
    formData.objectID = this.objectData.objectID;
    formData.objectUUID = this.objectData.objectUUID;
    formData.state = formData.state ? 'active' : 'inactive';
    _.each(['permissions', 'tags', 'locations', 'roles', 'certifications', 'shifts', 'teams'], (key) => convertArrayToJSON(key));
    delete formData.tagIDs;

    return formData;
  }

  private onLocationChange(): void {
    const locationField = <any>_.find(this.formConfig.fields, {name: 'locations'});
    let locations = _.filter(_.map(locationField.getValue(), Number));

    if (_.isEmpty(locations)) {
      locations = _.map(this.locations, 'locationID');
    }

    this.updateShiftsBy(locations);
    this.updateTeamsBy(locations);
  }

  private updateTeamsBy(locationsIds: number[]) {
    const fieldName = 'teams';
    const fieldId = `#${this.formConfig.prefix}${fieldName}`;
    const fieldConfig = <any>_.find(this.formConfig.fields, {name: fieldName});
    const selection: string[] | string = <string[] | string>fieldConfig.getValue();
    const fieldData: any = {};
    if (fieldConfig.multiple) {
      fieldData[fieldName] = _.isArray(selection) ? _.map(selection, Number) : [+selection];
    } else {
      fieldData[fieldName] = selection;
    }
    fieldConfig.options = this.filterService.buildTeamMenu(locationsIds, false).dropDownOptions;
    this.formBuilderService.replaceSelectOptionsWith(fieldId, this.formConfig, fieldConfig, fieldData);
  }

  private updateShiftsBy(locationsIds: number[]) {
    const fieldName = 'shifts';
    const fieldId = `#${this.formConfig.prefix}${fieldName}`;
    const fieldConfig = <any>_.find(this.formConfig.fields, {name: fieldName});
    const selectedIds = fieldConfig.getValue();

    const locations = _.map(locationsIds, (locationId) => this.userService.getLocation(locationId));
    fieldConfig.options = this.shifts.getSelectMenuObjectsByLocations(locations);
    this.formBuilderService.replaceSelectOptionsWith(fieldId, this.formConfig, fieldConfig, {shifts: selectedIds});
  }

  private decodeTranslationsObjects() {
    _.each(_.get(this.objectData, 'translations.objects'), (objectTranslation) => {
      if (objectTranslation.language) {
        this.objectData[`translation_contentObject_${objectTranslation.language}`] = objectTranslation.value;
      }
    });
  }

  private savePendingAttachments(formData) {
    const mediaFields = _.filter(this.formConfig.fields, (field) => _.includes(['media', 'mediaTranslations'], field.type));
    const imageUpdateHandlers: Promise<any>[] = [];

    _.each(mediaFields, (mediaField) => {
      if (mediaField?.mediaConfig?.startUpload && mediaField.mediaConfig.startUpload()) {
        imageUpdateHandlers.push(new Promise((resolve, reject) => {
          mediaField.mediaConfig.events.onSuccess = (data) => {
            if (mediaField.type === 'media') {
              formData.objectID = data.objectID;
            }
            resolve(true);
          };

          mediaField.mediaConfig.events.onError = () => reject();
        }));
      }

      if (mediaField.type === 'mediaTranslations') {
        const data = this.getData();
        const objectIds: number[] = _.map(data?.translations?.objects, 'value');

        if (mediaField.mediaConfig?.imageInstance?.selectedFiles === 0) {
          _.each(objectIds, (id) => {
            imageUpdateHandlers.push(this.objectsService.removeObject(id));
          });
        }
      }
    });

    return Promise.all(imageUpdateHandlers);
  }

}
