import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Feature, Module, SubscriberService } from '@services';
import * as _ from 'lodash';

export interface ISelectItem {
  key: string;
  text: string;
  onSelect: (oid: string) => void;
}

export interface ISelectList {
  name: string;
  values: ISelectItem[];
  onSelect?: (oid: string, item: ISelectItem) => void;
  class?: string;
  feature?: Feature;
  module?: Module;
  value?: string; // for ngModel binding
}

@Component({
  selector: 'app-detail-controls',
  templateUrl: './detail-controls.component.html',
  styleUrls: ['./detail-controls.component.scss'],
})
export class DetailControlsComponent {
  public buttonList: any = [];
  public selectList: ISelectList[] = [];
  public oid;
  public headerIsHidden: boolean;

  constructor(
    private route: ActivatedRoute,
    private subscriberService: SubscriberService
  ) {
    this.headerIsHidden = this.route.snapshot.queryParamMap.get('headerIsHidden') === 'true';
  }

  @Input() set headerData(data) {
    this.init(data);
  }

  public init(data) {
    this.oid = _.get(data, 'oid');
    this.initButtons(data);
    this.initSelects(data);
  }

  public trackBy(index, button) {
    return button?.name || index;
  }

  public initButtons(data) {
    this.buttonList = [];
    _.each(data.buttons, (butts) => {
      if (_.has(butts, 'module')) {
        if (this.subscriberService.usesModule(butts.module)) {
          this.buttonList.push(butts);
        }
      } else {
        this.buttonList.push(butts);
      }
    });
  }

  private initSelects(data) {
    this.selectList = [];
    _.each(data.selects, (selects) => {
      if (_.has(selects, 'module')) {
        if (this.subscriberService.usesModule(selects.module)) {
          this.selectList.push(selects);
        }
      } else {
        this.selectList.push(selects);
      }
    });
  }

  public onSelectChange(item: ISelectItem, select: ISelectList) {
    if (item.onSelect) {
      item.onSelect(this.oid);
    }
    if (select.onSelect) {
      select.onSelect(this.oid, item);
    }
    setTimeout(() => select.value = null);
  }
}
