import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {

  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() fieldName: string;
  @Input() enabledValue = 'on';
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  private value: string;

  constructor() {
  }

  @Input() getValue: () => string = () => this.value;

  ngOnInit() {
    this.value = this.isChecked ? this.enabledValue : '';
  }

  public onToggle(event: Event | any): void {
    this.value = event.target.checked ? this.enabledValue : '';
    this.onChange.emit(this.value);
  }

}
