import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { SettingsService } from '@services';
import { PdcaService } from '@services/pdca/pdca.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve<Promise<any>> {

  constructor(
    private settingsService: SettingsService,
    private pdcaService: PdcaService
  ) {}

  resolve() {
    const dataRequestHandler: Promise<any>[] = [];

    if (!this.settingsService.customTags.data.length) {
      dataRequestHandler.push(this.settingsService.getCustomTags());
    }

    if (_.isEmpty(this.pdcaService.getAll())) {
      dataRequestHandler.push(this.pdcaService.refresh());
    }

    return Promise.all(dataRequestHandler);
  }

}
