import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { CopyModalPopupComponent } from './../../view-modal/copy-modal-popup/copy-modal-popup.component';

export interface ICopyConfig {
  url: string; // 'pages/dashboard/condition-detail'
  description?: string;
  hideSplitter?: boolean;
}

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss'],
})
export class CopyLinkComponent {

  @Input() config: ICopyConfig;

  constructor(private popover: PopoverController) {}

  public open() {
    this.popover.create({
      component: CopyModalPopupComponent,
      animated: false,
      componentProps: {
        url: this.config.url,
        description: this.config.description
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

}
