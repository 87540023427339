<div>
  <ion-reorder-group (ionItemReorder)="onItemReorder($event)" [disabled]="!isReorderAvailable" class="groups-list">
    <app-question-form-group (onFinish)="onGroupFinish(index)"
                             (onRemoval)="removeItemByIndex(index)"
                             *ngFor="let group of groups; let index = index"
                             [group]="group"
                             [index]="index"
                             [isRemovalAvailable]="isRemovalAvailable" [isReorderAvailable]="isReorderAvailable"></app-question-form-group>
  </ion-reorder-group>

  <div *ngIf="!disableNewGroup" class="button-block">
    <ion-button (click)="addGroup()" class="page-button" color="secondary">{{addButtonName}}</ion-button>
    <ion-button (click)="showBulkAdding()" *ngIf="enableBulkAdding" class="page-button"
                color="light">{{ 'MGMT_DETAILS.Bulk_Add' | translate }}</ion-button>
  </div>
</div>
