import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { MenuController } from '@ionic/angular';

import {
  ResizeHandlerService,
  Select2Service,
  VersionCheckService,
  StorageService,
  FormBuilderService,
  TableService,
  SubscriberService
} from '@services';

import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isAppDataLoaded = false;
  public isAppVersionChecked: boolean;
  public brand = '';
  public loaderImg = '';

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private resizeHandler: ResizeHandlerService,
    private menu: MenuController,
    private select2Service: Select2Service,
    private versionCheckService: VersionCheckService,
    private translate: TranslateService,
    private storageService: StorageService,
    private formBuilder: FormBuilderService,
    private tableService: TableService,
    private subscriber: SubscriberService,
  ) {
    this.resizeHandler.init();
    this.select2Service.init();
    this.storageService.init();
    this.formBuilder.init();
    this.tableService.init();

    this.versionCheckService.startListening().then((isChecked: boolean) => {
      this.isAppVersionChecked = isChecked;
      this.initializeApp();
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if ($('.viewer-container').length === 0) {
      this.menu.close();
    }
  }

  ngOnInit(): void {
    const d = this.subscriber.brandInfo();
    this.brand = d.brand;
    this.loaderImg = d.loaderImg;
    if (d.brand === 'corvex' || d.brand === 'hop') {
      this.setGTagManager();
    }
  }

  async initializeApp() {
    this.translate.use('en');
    this.translate.reloadLang('es');
    this.translate.reloadLang('pt');
    this.translate.reloadLang('fr');
    // this.translate.setDefaultLang('es');
    // this.translate.setDefaultLang('en');
  }

  private setGTagManager() {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = '(function(w,d,s,l,i){'
      + 'var f=d.getElementsByTagName(s)'
      + '[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
      + '\'https://www.googletagmanager.com/gtag/js?id=\'+i;f.parentNode.insertBefore(j,f);})'
      + '(window,document,\'script\',\'dataLayer\',\'G-5CRX864MF4\');';
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }

}
