import { ChecksService } from '@services';
import { ICheck, IDeployment, IDeploymentTargets } from '..';
import { IObjectStringKeyMap } from '@shared/models';
import { DeploymentService } from '../../services';
import { NoSubstitutionTemplateLiteral } from 'typescript';
import * as _ from 'lodash';

export interface IResponseAnswer {
  elapsedTime: number;
  flaggedIssue: number;
  id: number;
  images: number[];
  locationID: number;
  observationID?: number;
  notes: any[];
  questionID: string;
  responder: number;
  response: string;
  responseValue: any;
  result: string;
  startTime: number;
  closingCategory?: string;
  closingTags?: number[];
}

export interface IResponse {
  instanceID: string;
  responseID: string;
  deploymentID: string;
  deploymentRevision: number;
  targetSignature: string;
  status?: string;
  anonymous?: string;
  owner?: number;
  updatedAt?: number;
  claimTime?: number;
  startTime?: number;
  availableTime: number;
  expiresTime: number;
  completionTime?: number;
  checkType?: number;
  checkID?: string;
  checkGroup: string;
  atLogin?: number;
  isGrouped?: number;
  questions?: string[];
  answers?: IObjectStringKeyMap<IResponseAnswer>;
  result?: string;
  issueCount?: number;
  observationCount?: number;
  presentationStyle?: string;
  assignedTo: any;
  deploymentAssignedTo?: any;
  targetZones?: (string | number)[];
  targetLocations?: (string | number)[];
  powerpoints?: number;
  target?: IDeploymentTargets
}

export class Response implements IResponse {
  get instanceID(): string {
    return this._theResponse.instanceID;
  }
  get responseID(): string {
    return this._theResponse.responseID;
  }
  get deploymentID(): string {
    return this._theResponse.deploymentID;
  }
  get deploymentRevision(): number {
    return this._theResponse.deploymentRevision;
  }

  get targetSignature(): string {
    return this._theResponse.targetSignature;
  }
  get status(): string {
    return this._theResponse.status;
  }
  get anonymous(): string {
    return this._theResponse?.anonymous ?? 'no';
  }
  get owner(): number {
    return this._theResponse?.owner ?? 0;
  }
  get claimTime(): number {
    return this._theResponse?.claimTime ?? 0;
  }
  get startTime(): number {
    return this._theResponse?.startTime ?? 0;
  }
  get completionTime(): number {
    return this._theResponse?.completionTime ?? 0;
  }
  get updatedAt(): number {
    return this._theResponse.updatedAt;
  }
  get availableTime(): number {
    return this._theResponse.availableTime;
  }
  get expiresTime(): number {
    return this._theResponse.expiresTime;
  }
  get checkID(): string {
    return this._theResponse?.checkID ?? this._theDeployment?.checkID;
  }
  get checkType(): number {
    return this._theCheck?.checkType ?? 0;
  }
  get checkGroup(): string {
    return this._theResponse.checkGroup;
  }
  get atLogin(): number {
    return this._theResponse?.atLogin ?? 0;
  }
  get isGrouped(): number {
    return this._theResponse?.isGrouped ?? 0;
  }
  get questions(): string[] {
    return this._theResponse?.questions;
  }
  get answers(): IObjectStringKeyMap<IResponseAnswer> {
    return this._theResponse?.answers ?? {};
  }
  get result(): string {
    return this._theResponse?.result ?? 'unknown';
  }
  get issueCount(): number {
    return this._theResponse?.issueCount ?? 0;
  }
  get observationCount(): number {
    return this._theResponse?.observationCount ?? 0;
  }
  get presentationStyle(): string {
    return this._theResponse.presentationStyle;
  }
  get assignedTo(): any {
    return this._theResponse?.assignedTo ?? {};
  }
  get deploymentAssignedTo(): any {
    return this._theDeployment?.assignedTo ?? {};
  }
  get targetZones(): (string | number)[] {
    return this._theResponse.targetZones;
  }
  get targetLocations(): (string | number)[] {
    return this._theResponse.targetLocations;
  }
  get target(): IDeploymentTargets {
    return this._theResponse?.target ?? this._theDeployment?.target;
  }
  get deployment(): IDeployment {
    return this._theDeployment;
  }
  get check(): ICheck {
    return this._theCheck;
  }

  // extended properties for indexing
  searchIndex?: string[];
  get deploymentFrequency(): string {
    return this._theDeployment?.frequency.type ?? '';
  }

  get deploymentTitle(): string {
    return this._theDeployment?.title ?? '';
  }

  get checkTitle(): string {
    return this._theCheck?.title ?? '';
  }

  get powerpoints(): number {
    return this._theResponse?.powerpoints ?? 0;
  }

  assignedToString?: string;
  expiresTimeString?: string;
  targetInfo?: string;

  constructor(
    private readonly _theResponse: IResponse,
    private readonly _theCheck: ICheck,
    private readonly _theDeployment: IDeployment
  ) {
  }
}

export interface IResponseCollection {
  [key: string]: Response;
}
