<ion-header no-border>
  <app-close-button [isModal]="true"></app-close-button>
</ion-header>

<ion-content>
  <div class="message-content">
    <div class="page-title">{{"SHARED.Send_Message" | translate}}</div>
    <div class="info-block">
      <div [class.team-image]="isTeam" class="image-block">
        <img [src]="avatarUrl" alt="User">
      </div>
      <div class="name">{{name}}</div>
    </div>
    <textarea [(ngModel)]="newMessage"></textarea>

    <div class="button-block">
      <ion-button (click)="close()" class="page-button" color="light">{{"SHARED.Cancel" | translate}}</ion-button>
      <ion-button (click)="sendMessage()" [class.disabled]="!newMessage" class="page-button"
                  color="secondary">{{"SHARED.Send_Message" | translate}}</ion-button>
    </div>
  </div>
</ion-content>
