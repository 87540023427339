import { Pipe, PipeTransform } from '@angular/core';

import * as Humanize from 'humanize-plus';

@Pipe({
  name: 'humanizeNumber'
})
export class HumanizeNumberPipe implements PipeTransform {

  transform(value: number, decimals: number = 0, roundUp: boolean = false, compact: boolean = true): number | string {
    if (roundUp) {
      value = Math.round(value);
    }

    if (Math.round(value).toString().length <= 3 && !compact) {
      return value.toFixed(decimals);
    } else {
      return Humanize.compactInteger(value, decimals);
    }
  }

}
