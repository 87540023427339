import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { ObservationService } from '@services/observations/observation.service';
import { Router } from '@angular/router';
import { LoadingService } from '@services/loading/loading.service';
import { Events } from '@services/events/events.service';
import { Location, PlatformLocation } from '@angular/common';
import { SettingsService } from '@services/settings/settings.service';
import { environment } from '@env';
import { UserdataService } from '@services/userdata/userdata.service';

import * as _ from 'lodash';
import { CheckResponseService } from '@services/checkResponse/check-response.service';
import { awaitHandler } from '@utils/awaitHandler';

@Injectable({
  providedIn: 'root'
})
export class PopupObservationService {

  private dataNavMap = {
    condition: 'pages/dashboard/condition-detail/',
    behavior: 'pages/dashboard/coaching-detail/',
    compliment: 'pages/dashboard/compliment-detail/',
    pi: 'pages/dashboard/process-detail/',
    quality: 'pages/dashboard/quality-detail/'
  };

  private dataTableMap = {
    condition: 'pages/dashboard/condition-table',
    behavior: 'pages/dashboard/coaching-table',
    compliment: 'pages/dashboard/compliment-table',
    pi: 'pages/dashboard/process-table',
    quality: 'pages/dashboard/quality-table'
  };

  constructor(
    private logger: NGXLogger,
    private comms: CommsService,
    private observations: ObservationService,
    private router: Router,
    private loaderService: LoadingService,
    private events: Events,
    private plocation: PlatformLocation,
    private settingsService: SettingsService,
    private userService: UserdataService,
    private location: Location,
    private checkResp: CheckResponseService
  ) {
  }

  public shareLink(oid) {
    let baseHref: string = environment.baseHref;
    if (_.last(baseHref) !== '/') {
      baseHref += '/';
    }
    return `${(<any>this.plocation).location.origin}${baseHref}pages/dashboard/observation/${oid}`;
  }

  public shareExternal(oid) {
    let baseHref: string = environment.baseHref;
    if (_.last(baseHref) !== '/') {
      baseHref += '/';
    }
    return `${(<any>this.plocation).location.origin}${baseHref}pages/dashboard/external-detail/${oid}?headerIsHidden=true`;
  }

  public categoryChange(requestObject) {
    this.comms.sendMessage(requestObject, false, false).then((res) => {
      if (res.reqStatus === 'OK') {
        this.observations.observations.data[res.result.observationID] = res.result;
        this.events.publish('ccs:noteUpdate', true);
      }

    });
  }

  public closeObs(requestObject): Promise<any> {
    return this.comms.sendMessage(requestObject, false, false).then((res: any) => {
      if (res.reqStatus === 'OK') {
        this.observations.observations.data[res.result.observationID] = res.result;
        this.events.publish('ccs:archiveUpdate', true);
      }

      return res;
    });
  }

  public getObsCats(oid) {
    const observation = this.observations.observations.data[oid];
    return observation.categories;
  }

  public getCategories(oid) {
    const observation = this.observations.observations.data[oid];
    const type: string = this.observations.getProperty(observation, 'type');

    // we have to switch between cats and qual cats depending on the type of observation!!!!
    if (type === 'condition') {
      return this.settingsService.getSettingSync('category', observation.locationID, true);
    } else if (type === 'quality') {
      return this.settingsService.getSettingSync('quality', observation.locationID, true);
    } else if (type === 'pi') {
      return this.settingsService.getSettingSync('pi', observation.locationID, true);
    } else if (type === 'ai') {
      return this.settingsService.getSettingSync('ai', observation.locationID, true);
    }
  }

  public boolSavingValue(oid) {
    const observation = this.observations.observations.data[oid];
    const type = this.observations.getProperty(observation, 'type');
    const subtype = this.observations.getProperty(observation, 'subtype');
    return (type === 'pi' && subtype === 'general');
  }

  public getImplementedBy(oid) {
    const observation = this.observations.observations.data[oid];
    const type = this.observations.getProperty(observation, 'type');
    const subtype = this.observations.getProperty(observation, 'subtype');
    if (type === 'pi' && subtype === 'general' && !observation.ownerID) {
      return this.userService.userID;
    }
  }


  public cancelWorkOrder(oid): void {
    this.comms.sendMessage({
      cmd: 'updateObservation',
      observationID: oid,
      ownerID: 0,
      state: 'escalated',
      workorder: ''
    }, false, false).then((res) => {
      this.observations.observations.data[oid] = res.result;
      delete this.observations.observations.workorder[oid];
      this.events.publish('ccs:archiveUpdate', true);
      this.location.back();
    });
  }

  public archive(oid): void {
    this.comms.sendMessage({
      cmd: 'updateObservation',
      observationID: oid,
      state: 'archived'
    }, false, false).then((res) => {
      if (res.reqStatus === 'OK') {
        delete this.observations.observations.data[oid];
        this.events.publish('ccs:archiveUpdate', true);
        this.location.back();
      }
    });
  }

  public async toggleState(observationID: number, isAddressed: boolean = true, disableBackNavigation?: boolean) {
    const state = isAddressed ? 'fixed' : 'new';
    await this.loaderService.enable();
    const [response] = await awaitHandler(this.comms.sendMessage({
      cmd: 'updateObservation',
      observationID,
      state
    }, false, false));
    this.loaderService.disable();

    if (response.reqStatus === 'OK' && !disableBackNavigation) {
      this.location.back();
    }
  }

  public async sendMessage(requestObject) {
    // send note only if the text is not empty
    if (requestObject.message.length > 0) {
      await this.loaderService.enable();
      this.comms.sendMessage(requestObject, false, false).then(() => {
        this.loaderService.disable();
      }).catch(() => {
        this.loaderService.disable();
      });
    } else {
      alert('Cannot submit empty message');
    }
  }

  public updateSeverity(sev, oid) {
    this.comms.sendMessage({
      cmd: 'updateObservation',
      observationID: oid,
      severityOverride: sev
    }, false, false).then((res) => {
      if (res.reqStatus === 'OK' && res.result) {
        this.observations.observations.data[oid] = res.result;
      }
    });
  }

  public updateLikelihood(lik, oid) {
    this.comms.sendMessage({
      cmd: 'updateObservation',
      observationID: oid,
      likelihoodOverride: lik
    }, false, false).then((res) => {
      if (res.reqStatus === 'OK' && res.result) {
        this.observations.observations.data[oid] = res.result;
      }
    });
  }

  public generateLink(oid) {
    alert('generate link for this ' + oid);
  }


  public zoneChange(zoneID, oid) {
    if (zoneID.indexOf(':') >= 0) {
      zoneID = '0';
    }
    // this.observations.showLoaderWithText('Zone Updated', 1500);
    // update observation
    this.loaderService.enable('Zone Updated');
    this.comms.sendMessage({
      cmd: 'updateObservation',
      observationID: oid,
      zoneID
    }, false, false).then((res) => {
      if (res.reqStatus === 'OK') {
        this.observations.observations.data[oid] = res.result;
      }
    });
    this.loaderService.disable();
  }


  createWorkOrder(updateObject: any, workOrder?: any) {
    updateObject.cmd = 'updateObservation';
    updateObject.state = 'workorder';
    this.comms.sendMessage(updateObject, false, true).then(data => {
      if (data.reqStatus === 'OK') {
        this.observations.observations.data[updateObject.observationID] = data.result;
        this.events.publish('ccs:archiveUpdate', true);
        // nav to parent table
        if (workOrder) {
          this.events.publish('ccs:assignUpdate', true);
        } else {
          this.location.back();
        }
      }
    });
  }

  addNote(oid, noteType, message) {
    // 4. If escalate/move notes
    this.loaderService.enable();
    const requestBody: any = {
      cmd: 'updateObservation',
      observationID: oid
    };
    const noteObject = {
      type: 'text',
      value: message,
      subtype: noteType
    };
    requestBody.note = JSON.stringify(noteObject);
    this.comms.sendMessage(requestBody, false, true).then(data => {
      if (data.reqStatus === 'OK') {
        this.observations.observations.data[oid] = data.result;
        this.events.publish('ccs:noteUpdate', true);
        this.loaderService.disable();
      }
    }).catch(err => {
      this.logger.log(err);
      this.loaderService.disable();
    });
  }

  addIssueNote(oid, message) {
    // 4. If escalate/move notes
    this.loaderService.enable();
    const requestBody: any = {
      cmd: 'addResponseIssueNote',
      issueID: oid
    };
    const noteObject = {
      type: 'text',
      value: message,
    };
    requestBody.note = JSON.stringify(noteObject);
    this.comms.sendMessage(requestBody, false, true).then(async data => {
      if (data.reqStatus === 'OK') {
        try {
          this.events.publish('ccs:noteUpdate', true);
          this.loaderService.disable();
        } catch (error) {
          this.loaderService.disable();
          this.logger.log(error);
        }
      }
    });
  }


  /**
   *
   *
   * @param updateObject - object that contains observation
   * ID [required] and userID, groupID, attachement and notes as a part of observation update
   * @returns promise
   * @memberof ObservationService
   */
  moveEscalateObservation(updateObject: any) {
    // get the reference of observationobject
    return new Promise((resolve, reject) => {
      // if not valid observation ID, abort
      if (!Number.isInteger(Number(updateObject.observationID))) {
        return false;
      }

      this.observations.getObservation(updateObject.observationID).then(observationObject => {
        const requestBody: any = {
          cmd: 'updateObservation',
          observationID: updateObject.observationID,
          state: 'escalated'
        };

        // 1. for moving observation to a team
        if (updateObject.groupID) {
          requestBody.groupID = updateObject.groupID;
          requestBody.ownerID = 0;
          requestBody.reassign = 1;
        }

        // 2. assigning observstion to specific user
        if (updateObject.ownerID) {
          requestBody.ownerID = updateObject.ownerID;
          requestBody.reassign = 1;
        }

        // 3. If new attachements (audio, photo, video)
        if (updateObject.attachments) {
          const newAttachment: any = [];
          updateObject.attachments.forEach(element => {
            newAttachment.push(element);
          });
          if (observationObject.attachments && observationObject.attachments.length > 0) {
            observationObject.attachments.forEach(element => {
              newAttachment.push(element.objectID);
            });
          }
          requestBody.attachments = JSON.stringify(newAttachment);
        }

        // 4. If escalate/move notes
        if (updateObject.textMessage) {
          const noteObject = {
            type: 'text',
            value: updateObject.textMessage
          };
          requestBody.note = JSON.stringify(noteObject);
        }

        // 5. if the observation is workorder, then it should not be escalated.
        if (observationObject.state === 'workorder') {
          requestBody.state = 'workorder';
        }

        // if no owner or group id is assigned then this is just escalation within a team, set ownerID as 0.
        if (!updateObject.ownerID && !updateObject.groupID) {
          requestBody.ownerID = 0;
        }

        // making the call to the backend to update
        this.loaderService.enable();
        this.comms.sendMessage(requestBody, false, true).then(data => {
          if (data.reqStatus === 'OK') {
            this.observations.observations.data[observationObject.observationID] = data.result;
            this.events.publish('ccs:assignUpdate', true);
            this.loaderService.disable();
          }
          resolve(true);
        }).catch((err) => {
          this.loaderService.disable();
          reject(err);
        });

      });
    });
  }


  assignCheckResponse(reqObject) {
    return new Promise((resolve, reject) => {
      this.comms.sendMessage(reqObject, false, true).then(async data => {
        if (data.reqStatus === 'OK') {
          // let's insert the response into checkResponse data set
          try {
            await this.checkResp.updateCache(data, false);
            this.events.publish('ccs:assignCheckUser', true);
            resolve(data);
          } catch (err) {
            this.logger.log(err);
          }
        } else {
          resolve(true);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

}
