<ion-menu (click)="menu.close()" (ionWillOpen)="checkAvailability()" contentId="main-content" menuId="sideMenu" side="end"
          swipeGesture="false" type="overlay">
  <ion-toolbar>
    <div class="menu-content">
      <ion-list class="menu-list">
        <div (click)="onClickMenuItem(menuItem, $event)" *ngFor="let menuItem of menuItems"
             [class.menu-item]="isMenuItemAvailable(menuItem)">
          <ng-container *ngIf="isMenuItemAvailable(menuItem)">
            <div [class.active-item]="menuItem.isActive" [class.collapsed-item]="menuItem.children" [class.open-item]="menuItem.isOpen"
                 class="item-block">
              <div class="item-image {{menuItem.class}}">
                <img
                  [src]="'assets/icons/' + menuItem.icon + (menuItem.isActive ? '_orange.svg' : menuItem.isOpen ? '_blue.svg' : '.svg')">
              </div>
              <div class="item-text">{{menuItem.title | translate}}</div>
            </div>

            <div *ngIf="menuItem.isOpen" [@menuChildren]>
              <ng-container *ngFor="let child of menuItem.children">
                <div (click)="onClickMenuChildItem(child, $event)" *ngIf="child.isAvailable" [class.active-item]="child.isActive"
                     class="item-block child">
                  <div class="item-text">{{child.title | translate}}</div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ion-list>
    </div>
  </ion-toolbar>
</ion-menu>
