<div [class.active-panel]="removalPanelConfig.count || changeDetectionPanel.changedCount" class="check-build-content">
  <ion-reorder-group (ionItemReorder)="onItemReorder($event)" class="groups-list" disabled="false">
    <app-question-group (onChecked)="onQuestionChecked($event)"
                        (onGroupRowReorder)="onGroupRowReorder($event)"
                        (onNameChange)="onNameChange($event)"
                        (onRemove)="onRemoveGroup($event)"
                        (onRowReorder)="onRowReorder($event)"
                        *ngFor="let group of groups; trackBy: trackByFunction"
                        [checkId]="checkId"
                        [groupItem]="group"
                        [groups]="groups"
                        [isGroupControlsVisible]="groups.length > 1"></app-question-group>
  </ion-reorder-group>

  <ion-button (click)="createGroup()" class="page-button"
              color="secondary">{{ 'MGMT_DETAILS.Add_Group' | translate }}</ion-button>

  <div class="button-block">
    <ion-button (click)="openChecksPage()" class="page-button"
                color="light">{{ 'SHARED.Cancel' | translate }}</ion-button>
    <ion-button (click)="saveDeploy()" [disabled]="!questions.length" class="page-button"
                color="secondary">{{ 'MGMT_DETAILS.Save_Deploy' | translate }}</ion-button>
  </div>
</div>

<div class="footer-block">
  <app-changes-panel (onCancel)="changeDetectionPanel.onCancel()"
                     (onSave)="changeDetectionPanel.handleUpdate()"
                     [changesCount]="changeDetectionPanel.changedCount"></app-changes-panel>
</div>

<div *ngIf="removalPanelConfig.count" class="footer-block">
  <app-removal-panel (onRemove)="onRemoveQuestion()"
                     [removeButtonTitle]="removalPanelConfig.removeButtonTitle"
                     [subTitle]="removalPanelConfig.subTitle"
                     [title]="removalPanelConfig.title">
    <ion-button (click)="onDuplicateQuestion()" class="page-button"
                color="secondary">{{ 'MGMT_DETAILS.Duplicate_Question_Item' | translate }}</ion-button>
  </app-removal-panel>
</div>
