import { Injectable } from '@angular/core';
import { IResponse } from '@modules/management/pages/details/check/models';

import PromiseWorker from 'promise-worker';

@Injectable({
  providedIn: 'root' }) export class WebWorkerService {

  private worker: PromiseWorker;
  private stringWorker: PromiseWorker;
  private dbWorker: PromiseWorker;

  constructor() {
  }

  public create() {
    if (typeof Worker !== 'undefined' && !this.worker) {
      const worker = new Worker(new URL('./../../workers/compress.worker', import.meta.url));
      this.worker = new PromiseWorker(worker);
      const stringWorker = new Worker(new URL('./../../workers/string.compress.worker', import.meta.url));
      this.stringWorker = new PromiseWorker(stringWorker);
      const dbWorker = new Worker(new URL('./../../workers/localdb.worker', import.meta.url));
      this.dbWorker = new PromiseWorker(dbWorker);
    }
  }

  public compressData(data: any, key?: string): Promise<any> {
    if (this.worker) {
      return this.worker.postMessage({data, key});
    } else {
      Promise.resolve(null);
    }
  }

  public compressString(data: string, key?: string): Promise<any> {
    if (this.stringWorker) {
      return this.stringWorker.postMessage({data, key});
    } else {
      Promise.resolve(null);
    }
  }

  public initDB(dbname: string): Promise<any> {
    if (this.dbWorker) {
      return this.dbWorker.postMessage({ command: 'init', args: { dbname }});
    } else {
      Promise.resolve(null);
    }
  }

  public restoreDB(table: string): Promise<IResponse[]> {
    if (this.dbWorker) {
      return this.dbWorker.postMessage({ command: 'restore', args: { table }});
    } else {
      return Promise.resolve(null);
    }
  }

  public updateDB(args: any): Promise<any> {
    if (this.dbWorker) {
      return this.dbWorker.postMessage({ command: 'update', args});
    } else {
      Promise.resolve(null);
    }
  }
}
