import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TableService } from '@services';
import { ObservationDashboardUIService } from '@services/observationDashboardUI/observation-dashboard-ui.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fullhealth-table',
  templateUrl: './fullhealth-table.component.html',
  styleUrls: ['./fullhealth-table.component.scss'],
})

export class FullhealthTableComponent implements OnInit {
  @Input() activeZoneHealth: any = {};
  @Input() activeTableData: any = [];
  public name = '';
  private tableId = 'fullHealthTable';
  private tableHeaderMap = {
    zoneCondition: this.translate.instant('DASHBOARD.FULLHEALTH_TABLE_zone0'),
    zoneQuality: this.translate.instant('DASHBOARD.FULLHEALTH_TABLE_zone1'),
    zonePI: this.translate.instant('DASHBOARD.FULLHEALTH_TABLE_zone2'),
    zoneCompliment: this.translate.instant('DASHBOARD.FULLHEALTH_TABLE_zone3'),
    zoneCoaching: this.translate.instant('DASHBOARD.FULLHEALTH_TABLE_zone4')
  };

  constructor(private popOver: PopoverController,
              private dashboardUiService: ObservationDashboardUIService,
              private tableService: TableService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.name = this.tableHeaderMap[this.activeZoneHealth.id];
    this.buildTableElements();
  }

  public close(): void {
    this.popOver.dismiss();
  }

  private buildTableElements() {
    // now let's build that table.
    const tableElement = this.dashboardUiService.buildZoneTableAttrs(this.activeZoneHealth);
    if (($.fn.dataTable.isDataTable(`#${this.tableId}`))) {
      $(`#${this.tableId}`).DataTable().clear().draw();
      $(`#${this.tableId}`).DataTable().destroy();
    }
    if (this.activeTableData.length > 0) {
      this.tableService.showTable(`#${this.tableId}`, tableElement, this.activeTableData);
      this.dashboardUiService.initDataTable(`#${this.tableId}`);
      $(`#${this.tableId}` + ' td').css({'text-align': 'left !important'});
      $(`#${this.tableId}`).removeClass('no-footer');
      $(`#${this.tableId}` + ' thead th .sort-icon').removeClass('sort-icon');

    }
  }


}


