<ion-header no-border>
  <app-close-button #closeButton (onClicked)="back()"></app-close-button>
</ion-header>

<ion-content>
  <div class="header-content">
    <div>
      <span (click)="closeButton.back()" class="body-link">{{checkName}}</span><span class="spacer">/</span><span
      class="headline-large">{{"SHARED.Target" | translate}}</span>
    </div>
    <div class="filter-block left-spacer">
      <select2
        (valueChanged)="onTargetChange($event)"
        [data]="targetConfig.data | selectLangPipe"
        [options]="targetConfig.options"
        [value]="targetConfig.value"></select2>
    </div>
  </div>
  <!-- time range-->
  <div class="filter-block float-right">
    <span class="descriptor upcase">{{filterConfig.selectConfig.label  | translate}} &nbsp;</span>
    <select2 (valueChanged)="onPeriodChange($event)"
             [data]="filterConfig.selectConfig.config.data | selectLangPipe"
             [options]="filterConfig.selectConfig.config.options"
             [value]="filterConfig.selectConfig.config.value"
             appSelect2Parent></select2>
  </div>
  <!-- time range end-->
  <app-tabs (currentTabChange)="onTabChange($event)" [(currentTab)]="currentTab" [tabs]="tabs"></app-tabs>

  <div class="dashboard-table-content">
    <div [hidden]="currentTab && currentTab.key !== 'complete'" class="table-container">
      <table class="display" id="complete"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'logged'" class="table-container">
      <table class="display" id="logged"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'corrective'" class="table-container">
      <table class="display" id="corrective"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'skipped'" class="table-container">
      <table class="display" id="skipped"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'missed'" class="table-container">
      <table class="display" id="missed"></table>
    </div>
  </div>
</ion-content>
