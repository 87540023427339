import { Injectable } from '@angular/core';

import * as _ from 'lodash';

export enum NotificationSource {
  ErgoTempPlugin = 'plugin:ergo-temp',
  Observation = 'observation',
  Beacon = 'beacon',
  System = 'system'
}

export enum NotificationPriority {
  Low = 'low',
  Lower = 'normal',
  Warning = 'warning',
  Critical = 'critical',
  Emergency = 'emergency',
  Resolved = 'resolved',
  Pending = 'pending',
  Negative = 'negative',
  Positive = 'positive',
  Neutral = 'neutral'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() {
  }

  public getIcon(notification: any): string {
    let iconUrl = 'assets/icons/notifications/notification-general.svg';
    const source: NotificationSource | string = _.get(notification, 'source', 'observation');

    if (source === NotificationSource.ErgoTempPlugin) {
      const iconSource = 'assets/images/plugins/temperature-sensor';

      if (notification.priority === NotificationPriority.Low) {
        iconUrl = `${iconSource}/notification-plugin-wt-minimal.svg`;
      } else if (notification.priority === NotificationPriority.Lower) {
        iconUrl = `${iconSource}/notification-plugin-wt-lower.svg`;
      } else if (notification.priority === NotificationPriority.Warning) {
        iconUrl = `${iconSource}/notification-plugin-wt-moderate.svg`;
      } else if (notification.priority === NotificationPriority.Critical) {
        iconUrl = `${iconSource}/notification-plugin-wt-high.svg`;
      } else if (notification.priority === NotificationPriority.Emergency) {
        iconUrl = `${iconSource}/notification-plugin-wt-extreme.svg`;
      }
    } else if (source === NotificationSource.Beacon) {
      iconUrl = 'assets/icons/notification-site.svg';
    } else if (source === NotificationSource.Observation || source === '') {
      const iconSource = 'assets/icons/notifications';

      if (_.includes(['condition', 'behavior'], notification.observationType)) {
        iconUrl = `${iconSource}/notification-safety.svg`;
      } else if (_.includes(['quality', 'pi'], notification.observationType)) {
        iconUrl = `${iconSource}/notification-opex.svg`;
      } else if (notification.observationType === 'compliment') {
        iconUrl = `${iconSource}/notification-thumbs-up.svg`;
      }
    }

    return iconUrl;
  }

}
