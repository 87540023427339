import { Injectable } from '@angular/core';

import { CommsService } from './../comms/comms.service';
import { awaitHandler } from '@utils/awaitHandler';

@Injectable()
export abstract class BaseService {

  constructor(protected commsService: CommsService) {
  }

  public abstract refresh(): Promise<any>;

  protected async handleRequest(requestParams: { [key: string]: any }, refreshCallback?: () => Promise<any>): Promise<any> {
    const [response] = await awaitHandler(this.commsService.sendMessage(requestParams));
    if (response && response.reqStatus === 'OK') {
      await (refreshCallback && refreshCallback() || this.refresh());
    }

    return response;
  }
}
