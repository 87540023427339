import { Component, OnInit, Input } from '@angular/core';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Feature } from '@services/subscriber/subscriber.service';
import { CommsService, LoadingService, SubscriberService } from '@services';
import { awaitHandler } from '@utils/awaitHandler';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-workorder-popup',
  templateUrl: './workorder-popup.component.html',
  styleUrls: ['./workorder-popup.component.scss'],
})
export class WorkorderPopupComponent implements OnInit {
  @Input() observationID: Number;
  @Input() workOrder: any;
  public workOrderNumber = '';
  public workRequestTitle = '';
  public priority = 'NONE';
  public description = '';
  public usesMaintainX = false;

  constructor(private popOver: PopoverController,
              private loading: LoadingService,
              private logger: NGXLogger,
              private subscriberService: SubscriberService,
              private popOps: PopupObservationService,
              private comms: CommsService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.workOrder) {
      this.workOrderNumber = this.workOrder;
    }
    if (this.subscriberService.usesFeature(Feature.OBSERVATION_MAINTAINX)) {
      this.usesMaintainX = true;
    }
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public async createWorkOrder(): Promise<void> {
    this.popOver.dismiss();
    const requestObj: any = {
      observationID: this.observationID,
      workorder: this.workOrderNumber
    };
    if (this.usesMaintainX) {
      const reqObj: any = {
        cmd: 'createWorkRequest',
        observationID: this.observationID,
        locationID: this.subscriberService.locationID(),
        priority: this.priority,
        description: this.description
      };
      if (this.workRequestTitle) {
        reqObj.title = this.workRequestTitle;
      }
      const [data, err] = await awaitHandler(this.comms.sendMessage(reqObj, false, false));
      this.loading.enable('Request Sent', 1500);
      if (data && data.reqStatus !== 'OK') {
        this.logger.error(err);
      } else {
        requestObj.workorder = data.result.workRequestID;
        requestObj.workorderStatus = 'submitted';
      }
    }

    this.popOps.createWorkOrder(requestObj, this.workOrder);
  }

}
