import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class Select2Service {

  constructor() {
  }

  public init(): void {
    (<any>$).fn.select2.defaults.set('sorter', (data) => _.sortBy(data, (sortItem: any) => {
      if (sortItem.children) {
        sortItem.children = _.sortBy(sortItem.children, [child => _.lowerCase(child.text)]);
      }
      return _.lowerCase(sortItem.text);
    }));
  }
}
