import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-observation-title',
  templateUrl: './observation-title.component.html',
  styleUrls: ['./observation-title.component.scss'],
})
export class ObservationTitleComponent implements OnInit {

  @Input() title: string;
  @Input() id: number | string;
  @Input() isHidden: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
