import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import {
  HistoryItemRelatedType,
  IObservationHistoryItem,
  ObservationDetailService
} from '@services/observationDetail/observation-detail.service';
import { MessagePopupComponent } from './../message-popup/message-popup.component';
import { PdcaService } from '@services/pdca/pdca.service';
import { UserService } from '@services';

import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
  selector: 'app-observation-history',
  templateUrl: './observation-history.component.html',
  styleUrls: ['./observation-history.component.scss'],
})
export class ObservationHistoryComponent {
  @Input() title = 'SHARED.History';
  @Input() set observationId(id: number) {
    if (id) {
      this.init(id);
    }
  }

  public history: IObservationHistoryItem[] = [];

  constructor(
    private popover: PopoverController,
    private router: Router,
    private pdcaService: PdcaService,
    private userService: UserService,
    private translateService: TranslateService,
    private observationDetailService: ObservationDetailService
  ) {
  }

  public init(id: number) {
    const history = this.observationDetailService.getHistory(id);
    this.history = this.setRelatedData(history);
  }

  public setRelatedData(history: IObservationHistoryItem[]): IObservationHistoryItem[] {
    _.each(history, (historyItem) => {
      if (this.isPdca(historyItem)) {
        const pdca = this.pdcaService.getById(+historyItem.relatedItem);
        if (pdca) {
          historyItem.linkTitle = this.userService.translateItem(pdca, 'title');
          historyItem.linkRoute = '/pages/dashboard/pdca-list/pdca-detail/' + historyItem.relatedItem;
        } else {
          historyItem.linkTitle = `(${this.translateService.instant('SHARED.Deleted')})`;
        }
      }
    });

    return history;
  }

  public sendMessageTo(historyItem: IObservationHistoryItem) {
    this.popover.create(<any>{
      component: MessagePopupComponent,
      animated: false,
      componentProps: {
        userObject: historyItem,
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public isPdca(historyItem: IObservationHistoryItem) {
    return historyItem.relatedType === HistoryItemRelatedType.pdca;
  }

  public navigateToLink(historyItem: IObservationHistoryItem) {
    if (historyItem.linkRoute) {
      this.router.navigate([historyItem.linkRoute]);
    }
  }
}
