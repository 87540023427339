<div class="header">
  <button (click)="close()" class="popup-close-button"></button>
</div>

<div class="body">
  <div class="page-title">{{ 'MGMT_DETAILS.Add_Bulk_Options' | translate }}</div>
  <div class="description">{{ 'MGMT_DETAILS.Add_Bulk_Options_Description' | translate }}</div>

  <select2 (valueChanged)="onSelect($event)"
           [data]="selectConfig.data"
           [options]="selectConfig.options"
           appSelect2Parent></select2>
</div>

<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="addOptions()" class="page-button"
              color="secondary">{{ 'MGMT_DETAILS.Add_Options' | translate }}</ion-button>
</div>
