<ion-content class="table-content">
  <app-dashboard-header (onSelected)="toggleFilterBody($event)" [filterComps]="filterComps" [filterObject]="filterObject"
                        [headerSetting]="headerSetting" [searchField]="searchField"
                        [showFilterBody]="showFilterBody">
    <ion-button (click)='createObservation()' class="page-button" color="secondary"
                preSearchContent>{{ 'DASHPAGES.Create' | translate }}</ion-button>
  </app-dashboard-header>

  <div class="tabs-wrap">
    <div class="tabs">
      <div (click)="toggle(tab)" *ngFor="let tab of tabs" [ngClass]="{'active-tab': tab.selected}" class="tab">
        {{tab.name}}<span class="tab-select-count">({{tab.count}})</span>
      </div>
    </div>
  </div>

  <div [hidden]="!(preSelected?.id=='qualityOpen')" class="table-container">
    <table class="display" id="qualityOpen"></table>
  </div>
  <div [hidden]="!(preSelected?.id=='qualityReceiving')" class="table-container">
    <table class="display" id="qualityReceiving"></table>
  </div>
  <div [hidden]="!(preSelected?.id=='qualityFixed')" class="table-container">
    <table class="display" id="qualityFixed">
    </table>
  </div>

  <div [hidden]="!(preSelected?.id=='qualityClosed')" class="table-container">
    <table class="display" id="qualityClosed">
    </table>
  </div>
</ion-content>
