import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingPromisedInstances: any[] = [];
  private isLoadingQueueStarted = false;
  private readonly newLoadingPromisedInstanceSubject: Subject<any> = new Subject<any>();

  constructor(private loadingController: LoadingController) {
    this.newLoadingPromisedInstanceSubject.asObservable().subscribe((loadingPromisedInstance: any) => {
      this.loadingPromisedInstances.push(loadingPromisedInstance);

      if (!this.isLoadingQueueStarted) {
        this.startLoadingQueue();
      }
    });
  }

  public enable(message: string = '', duration: number = 30000): Promise<void> {
    return new Promise((resolve: any) => {

      if (document.visibilityState === 'visible') {
        const loadingPromisedInstance = () => this.loadingController.getTop().then((element: any) => {
          if (!element) {
            return this.loadingController.create({
              spinner: null,
              duration,
              message,
              cssClass: 'custom-loading',
              showBackdrop: false
            }).then((loading: HTMLIonLoadingElement) => loading.present().then(() => resolve()));
          } else {
            return resolve();
          }
        });

        this.newLoadingPromisedInstanceSubject.next(loadingPromisedInstance);
      } else {
        return resolve();
      }
    });
  }

  public disable(): Promise<void> {
    return new Promise((resolve: any) => {

      const loadingPromisedInstance = () => this.loadingController.dismiss()
        .then(() => resolve())
        .catch(() => resolve());

      this.newLoadingPromisedInstanceSubject.next(loadingPromisedInstance);
    });
  }

  private async startLoadingQueue(): Promise<void> {
    this.isLoadingQueueStarted = true;

    for (const loadingPromisedInstance of this.loadingPromisedInstances) {
      await loadingPromisedInstance();
    }
    this.isLoadingQueueStarted = false;
    this.loadingPromisedInstances = [];
  }

}
