import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { UserListComponent } from './../user-list/user-list.component';
import { TeamListComponent } from './../team-list/team-list.component';
import { ITab } from './../tabs/tabs.component';
import { AccountTypes, Permission } from '@services';
import { TranslateService } from '@ngx-translate/core';

export enum ListMenuViewType {
  User,
  Team,
  MultipleUserTeam
}

export enum ListMenuType {
  User = 'user',
  Team = 'team'
}

export enum ListMenuSelectMode {
  Single,
  Multiple
}

export interface IListMenuConfig {
  type: ListMenuViewType;
  selectMode: ListMenuSelectMode;
  isLoggedUserExcluded?: boolean;
  isSelectAllEnabled?: boolean;
  isInfinityScrollEnabled?: boolean;
  excludeAccounts?: AccountTypes[];
  excludePermissions?: Permission[];
  isAssignPopUp?: boolean;
  selectedLocationIds?: String[];
}

export interface IListMenuSelected {
  type: ListMenuType;
  items: any[];
}

@Component({
  selector: 'app-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.scss']
})
export class ListMenuComponent {

  @Input() config: IListMenuConfig = <IListMenuConfig>{};
  @Output() onSelected: EventEmitter<IListMenuSelected> = new EventEmitter<IListMenuSelected>();
  @Output() onChangedTab: EventEmitter<ListMenuType> = new EventEmitter<ListMenuType>();
  @ViewChild(UserListComponent) userListComponent: UserListComponent;
  @ViewChild(TeamListComponent) teamListComponent: TeamListComponent;
  public listMenuViewType = ListMenuViewType;
  public listMenuType = ListMenuType;
  public listMenuSelectMode = ListMenuSelectMode;
  public tabs: ITab[] = [
    {value: this.translate.instant('SHARED.USER'), key: <string>ListMenuType.User},
    {value: this.translate.instant('SHARED.Team'), key: <string>ListMenuType.Team}
  ];
  public activeTab: ITab = this.tabs[0];

  constructor(
    private translate: TranslateService,
  ) {
  }

  public emitSelection(items: number[]): void {
    const selectedData: IListMenuSelected = {
      items,
      type: this.getOutputTypeMenu()
    };
    this.onSelected.emit(selectedData);
  }

  public reset(): void {
    this.userListComponent && this.userListComponent.init();
    this.teamListComponent && this.teamListComponent.init();
  }

  public onChangeTab(tab: ITab): void {
    this.onChangedTab.emit(<ListMenuType>tab.key);
  }

  public setSelectedUsers(ids: number[]): void {
    if (this.activeTab.key === <string>ListMenuType.User) {
      this.userListComponent.setSelectedUsers(ids);
    }
  }

  public getSelectedUsers(): number[] {
    return this.activeTab.key === <string>ListMenuType.User ? this.userListComponent.getSelectedUsers() : [];
  }

  private getOutputTypeMenu(): ListMenuType {
    let type: ListMenuType;

    switch (this.config.type) {
      case ListMenuViewType.User:
        type = ListMenuType.User;
        break;
      case ListMenuViewType.Team:
        type = ListMenuType.Team;
        break;
      case ListMenuViewType.MultipleUserTeam:
        type = <ListMenuType>this.activeTab.key;
        break;
    }

    return type;
  }

}
