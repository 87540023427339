<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{"COMMUNICATIONS.Participants" | translate}}</h2>
</div>
<div class="body">
  <ion-item *ngFor="let participant of participants | orderBy: 'name'">
    <ion-avatar *ngIf="participant.avatarUrl">
      <img [src]="participant.avatarUrl" alt="User">
    </ion-avatar>
    <div class="info">
      <div class="item-info name">{{participant.name}}</div>
    </div>
  </ion-item>
</div>
