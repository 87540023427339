<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{title | translate  }}</h2>
</div>
<div class="body">
  <ion-toolbar>
    <app-list-menu (onChangedTab)="onChangeTab($event)"
                   (onSelected)="onSelect($event)"
                   *ngIf="isListAvailable"
                   [config]="listConfig"></app-list-menu>
  </ion-toolbar>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="assignObs()" [class.disabled]="!isSaveAvailable()" class="page-button"
              color="secondary">{{ "SHARED.Assign" | translate }}</ion-button>
</div>
