import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { AssetsService, RolesService } from '@services';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DeploymentDataResolverService implements Resolve<Promise<any>> {

  constructor(
    private assetsService: AssetsService,
    private roleService: RolesService
  ) {
  }

  resolve() {
    const assetsHandler: Promise<any> = _.isEmpty(this.assetsService.asset.data) ? this.assetsService.refresh() : Promise.resolve();
    const rolesHandler: Promise<any> = _.isEmpty(this.roleService.roles.data) ? this.roleService.refresh() : Promise.resolve();

    return Promise.all([assetsHandler, rolesHandler]);
  }

}
