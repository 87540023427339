import { Pipe, PipeTransform } from '@angular/core';
import { Module, SubscriberService, UserService } from '@services';


@Pipe({
  name: 'permission',
  pure: false
})
export class PermissionPipe implements PipeTransform {
  constructor(
    private subscriberService: SubscriberService,
    private userService: UserService
  ) {
  }

  transform(module: Module, locationID?: number): boolean {
    const subscriptionFeatureEnabled = this.subscriberService.usesModule(module, true);
    let locationFeatureEnabled = true;

    if (locationID) {
      const location = this.userService.getLocation(locationID);
      locationFeatureEnabled = location.features[module];
    }

    return subscriptionFeatureEnabled && locationFeatureEnabled;
  }
}
