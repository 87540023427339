import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { SettingsService } from '@services';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataQuestionResolverService implements Resolve<Promise<any>> {

  constructor(private settingsService: SettingsService) {
  }

  resolve() {
    return _.isEmpty(this.settingsService.customTags.data) ? this.settingsService.getCustomTags() : Promise.resolve();
  }

}
