import { NGXLogger } from 'ngx-logger';
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { AuthService } from '@services/auth/auth.service';
import { LoadingService } from '@services/loading/loading.service';
import { ObservationService } from '@services/observations/observation.service';
import { HeartbeatService } from '@services/heartbeat/heartbeat.service';
import { MessagesService } from '@services/messages/messages.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { Events } from '@services/events/events.service';
import { CacheService } from '@services/cache/cache.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { CheckResponseService } from '@services/checkResponse/check-response.service';
import { HierarchyGroupingService } from '@services/hierarchyGrouping/hierarchy-grouping.service';
import { CollectionsService } from '@services/collections/collections.service';
import * as _ from 'lodash';
import { decompressSync, strFromU8 } from 'fflate';
import { FeedbackService } from '@services/feedback/feedback.service';
import { PoolService } from '@services/pool/pool.service';
import { PdcaService } from '@services/pdca/pdca.service';
import { TasksService } from '@services/tasks/tasks.service';
import { StorageService } from '@services/storage/storage.service';
import { DisclaimerService } from '@services/disclaimer/disclaimer.service';
import { LocalDBService } from '@services/localdb.service.ts/localdb';
import { SubscriberService, Module } from '@services/subscriber/subscriber.service';

@Injectable({
  providedIn: 'root'
})
export class DataRestoreService {

  constructor(
    private logger: NGXLogger,
    private authService: AuthService,
    private loadingService: LoadingService,
    private observations: ObservationService,
    private heartbeat: HeartbeatService,
    private messagesService: MessagesService,
    private userData: UserdataService,
    private events: Events,
    private cacheService: CacheService,
    private accountsService: AccountsService,
    private storage: Storage,
    private storageService: StorageService,
    private responses: CheckResponseService,
    private hierarchyService: HierarchyGroupingService,
    private collectionsService: CollectionsService,
    private feedback: FeedbackService,
    private pools: PoolService,
    private pdcaService: PdcaService,
    private tasksService: TasksService,
    private localdb: LocalDBService,
    private subscriber: SubscriberService
  ) {
  }

  public sync(): Promise<any> {
    return new Promise((resolve) => {
      if (!this.heartbeat.isRunning() && !this.cacheService.usesSSE) {
        this.logger.log('Starting data restore');
        // this.loadingService.enable();
        // @@@ MOVE THIS?
        this.userData.fetch()
          .then(async (res) => {
            if (res) {
              // the user was logged in and we have their preferences initialized now
              this.userData.enableLanguage();
              /// ensure the indexed db is open
              await this.localdb.initialize(this.subscriber.subscriberID(), this.userData.userID);
              await this.getCachedData();
              // this will force a cache load with the correct collection of tables according to the backend
              const dataUpdateHandlers = [
                this.cacheService.fetchAll(),
                this.accountsService.refresh(),
                // lots of things use collections...
                this.collectionsService.refresh(),
                // restore a day of responses so we are responsive in the default case
              ];
                if (this.subscriber.usesModule(Module.FEEDBACK)) {
                  dataUpdateHandlers.push(this.feedback.refresh());
                }
                if (this.subscriber.usesModule(Module.EXTERNAL_DATA_MANAGEMENT)) {
                  dataUpdateHandlers.push( this.pools.refresh() );
                }
                if (this.subscriber.usesModule(Module.PDCA)) {
                  dataUpdateHandlers.push(
                  this.pdcaService.refresh(),
                  this.tasksService.refresh(),
                  this.tasksService.getTaskStates(),
                  this.tasksService.getTaskTypes(),
                  );
                }

              Promise.all(dataUpdateHandlers)
                .then(() => {
                  this.logger.log('data restore complete');

                  // now enable SSE if we are using it
                  if (this.cacheService.listenForEvents()) {
                    this.heartbeat.doHeartbeat(true);
                  } else {
                    this.heartbeat.doHeartbeat();
                  }
                  this.observations.restoreAndUpdate();
                  this.messagesService.startPolling();
                  this.loadingService.disable();
                  this.userData.updatePermissions();
                  this.authService.emitEvent();
                  this.events.publish('ccs:syncUpdate', true);
                  resolve(true);
                });
            }
          });
      } else {
        this.authService.emitEvent();
        this.events.publish('ccs:syncUpdate', true);
        resolve(true);
      }
    });
  }

  private async getCachedData() {
    const storageKeys = await this.storageService.getIonicStorageKeys();
    const dataKeys = [
      {
        key: 'fetchAll',
        callback: (data) => {
          if (data) {
            this.cacheService.updateCache({result: data}, false);
          }
        }
      },
      // {
      //   key: 'responses',
      //   callback: (data) => {
      //     this.responses.responses = data;
      //     this.responses.lastStorageTime = data.lastRequest;
      //   }
      // },
      {
        key: 'accounts',
        callback: (data) => {
          this.accountsService.accounts = data;
        }
      },
      // {
      //   key: 'observations',
      //   callback: (data) => {
      //     this.observations.rebuildCache(data);
      //     this.observations.observations.lastRequest = data.lastRequest;
      //   }
      // },
    ];

    for (const dataKey of dataKeys) {
      if (_.includes(storageKeys, dataKey.key)) {
        this.logger.debug(`restoring cached ${dataKey.key}`);
        const cachedData: any = await this.storageService.getIonicStorageItem(dataKey.key);
        this.logger.debug(`decompressing cached ${dataKey.key}`);
        let data: any = {};
        if (typeof (cachedData) === 'object' && _.has(cachedData, '_splitField') && _.has(cachedData, '_buckets')) {
          // recombine the split data along with the other components
          _.each(cachedData, (value, key) => {
            if (!key.match(/^_/)) {
              data[key] = value;
            }
          });
          if (cachedData._isObject) {
            data[cachedData._splitField] = {};
          } else {
            data[cachedData._splitField] = [];
          }
          _.each(cachedData._buckets, item => {
            const a = JSON.parse(strFromU8(decompressSync(item)));
            if (cachedData._isObject) {
              // use the key field to repopulate the object
              _.each(a, val => {
                data[cachedData._splitField][val[cachedData._keyField]] = val;
              });
            } else {
              // we can just push array values in
              data[cachedData._splitField].push(...a);
            }
          });
        } else {
          const decompressedCachedData = decompressSync(cachedData);
          data = JSON.parse(strFromU8(decompressedCachedData));
        }

        if (data) {
          this.logger.debug(`inserting cached ${dataKey.key}`);
          try {
            dataKey.callback(data);
          } catch (err) {
            this.logger.log(err);
          }
        }
      }
    }
  }
}
