<div (click)="showCarousel()" *ngIf="router.url!=='/pages/login/preparing' && carousel" [@slideInOut]
     class="new-stuff-available">
  <div class="wrap">
    <img alt="New Icon" height="34" src="assets/new-badge.svg" width="31">
    <p><strong>{{ "LAYOUT.carouselText" | translate }}</strong>{{ "LAYOUT.carouselText_Next" | translate }}</p>
  </div>
  <div (click)="clickCloseCarousel($event);" class="close-icon">
    <img alt="Close" src="assets/close.svg">
  </div>
</div>

<ng-container *ngIf="!config.isHidden">
  <ion-header no-border>
    <ion-toolbar (click)="menu.close()">
      <div class="toolbar-content {{brand}}">
        <div (click)="goToHomePage()" [title]="appVersion" class="logo"><img [src]="logoSrc"/></div>
        <div *ngIf="isLoggedIn && isUserInfoAvailable" class="info-panel">
          <div *ngIf="userName" class="user-info">
            <span class="user-name">
              <span [class.link-active]="isSupervisor" [routerLink]="['/pages/profile']"
                    class="name-link">{{userName}}</span>
              <span *ngIf="isMessageBlockAvailable()" class="message-block">
                <span *ngIf="isMessagesAvailable()" [queryParams]="{ type: 'messages' }"
                      [routerLink]="['/pages/communications-list']" class="count-icon message"></span>
                <span *ngIf="isNotificationsAvailable()" [queryParams]="{ type: 'notifications' }"
                      [routerLink]="['/pages/communications-list']" class="count-icon notification"></span>
                <span (click)="navWorkNotes()" *ngIf="isWorkNotesAvailable()" class="count-icon work-note"></span>
              </span>
            </span>
            <span class="user-team">{{subscriberName}}</span>
            <a (click)="logout()" class="logout-action">{{ "SHARED.Sign_Out" | translate }}</a>
          </div>
          <div [class.disabled-menu]="!isMenuAvailable" class="menu" (click)="toggleMenu()">
            <div class="title">{{ "LAYOUT.Menu" | translate }}</div>
          </div>
        </div>
      </div>
    </ion-toolbar>
  </ion-header>

  <app-menu #menu></app-menu>
  <app-filter-menu></app-filter-menu>
</ng-container>
