<ion-content>
  <app-dashboard-header (onSelected)="toggleFilterBody($event)" [filterComps]="filterComps" [filterObject]="dashboardUiService.locationDashboardFilter"
                        [headerSetting]="headerSetting"
                        [showFilterBody]="showFilterBody"></app-dashboard-header>
  <div class="location-blocks">
    <div class="left-number-block">
      <div *ngIf="activeTab.key === 'condition'">
        <div class="descriptor">{{ "DASHPAGES.LOCATION_descriptor0" | translate }}</div>
        <div (click)="navToCondition()" *ngIf="locationData.counts"
             class="dash-number-large-link decorator">{{locationData.counts.unsafe.count}}</div>
        <div class="dotted-border"></div>
        <div class="descriptor">{{ "SHARED.LOCATION_descriptor1" | translate }}</div>
        <div *ngIf="locationData.counts" class="dash-number-small">
          {{locationData.counts.unsafe.timeOpen}}</div>
      </div>
      <div *ngIf="activeTab.key === 'quality'">
        <div class="descriptor">{{ "DASHPAGES.LOCATION_descriptor2" | translate }}</div>
        <div (click)="navToQuality()"
             class="dash-number-large-link decorator">{{locationData.counts?.quality.count}}</div>
        <div class="dotted-border"></div>
        <div class="descriptor">{{ "SHARED.LOCATION_descriptor1" | translate }}</div>
        <div class="dash-number-small">{{locationData.counts?.quality.timeOpen}}</div>
      </div>
      <div *ngIf="tabs.length > 1" class="tabs">
        <div (click)="toggleTab(tab)" *ngFor="let tab of tabs" [ngClass]="tabClass(tab)" class="tab">{{tab.title}}</div>
      </div>
    </div>
    <div class="right-zone-block">
      <div class="right-zone-block-header">
        <div class="headline-medium-wrap">
          <span class="headline-medium">{{ "DASHPAGES.LOCATION_descriptor3" | translate }}</span> <span
          (click)="viewAllZoneHealth()" class="body-link">{{ "SHARED.View_All" | translate }}</span>
        </div>
        <div class="right-zone-block-select">
          <ng-select (change)="zoneHealthChange($event)"
                     [(ngModel)]="activeZoneHealth"
                     [clearable]="false"
                     [closeOnSelect]="true"
                     [multiple]="false"
                     [searchable]="false"
                     class="custom-select">
            <ng-option *ngFor="let health of zoneHealthDropDown" [value]="health">
              <span class="select-value {{ health.key }}">{{ health.text | translate }}</span>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="table-container location-zone-table">
        <table class="display" id="zoneHealthTable"></table>
      </div>
    </div>
  </div>

  <!-- CHART -->
  <div class="chart-block-wrap">
    <div class="descriptor">{{ "SHARED.Observations" | translate }}</div>
    <div class="chart-container chart-values">
      <canvas #locationLineCanvas [hidden]="!locationLineData" id="locationLineCanvas"></canvas>
      <p [hidden]="locationLineData" class="no-data no-data-available">{{ "SHARED.No_Data" | translate }}</p>
    </div>
    <div class="chart-block" id="trendLegend">
      <div *ngIf="lineChartLabel">
        <span *ngFor="let label of lineChartLabel" class="linechart-label">
          <span class="checkbox-label-crossbrowser">
            <input (click)="lineChartLegend(label.index)" [value]="label.label" checked type="checkbox">
            <label></label>
          </span>
        <span [ngStyle]="{'border-color': label.borderColor, 'background-color': label.backgroundColor}"
              class="colored-block"></span>
        <span class="body-copy-bold">{{label.label}}</span>
        </span>
      </div>
    </div>
  </div>
  <!-- END CHART-->

  <!-- COACHING -->
  <div *ngIf="(module.BEHAVIOR | permission: locationID) || (module.COMPLIMENT | permission: locationID)"
       class="coaching-block">
    <div class="coaching-block-left">
      <div [class.disabled]="!(module.BEHAVIOR | permission: locationID)" class="coaching-block-number">
        <div class="descriptor">
          {{ "DASHPAGES.LOCATION_descriptor5" | translate }}
        </div>
        <div *ngIf="locationData.counts && (module.BEHAVIOR | permission: locationID)" class="dash-number-medium">
          {{locationData.counts.coaching.count}}
        </div>
      </div>
      <div [class.disabled]="!(module.BEHAVIOR | permission: locationID)"
           class="dotted-border-right coaching-block-border-margin"></div>
      <div class="coaching-block-barchart">
        <div *ngIf="(module.BEHAVIOR | permission: locationID)" class="chart-container">
          <canvas #locationBehaviorBar [hidden]="!behaviorChart" id="locationBehaviorBar"></canvas>
          <p [hidden]="behaviorChart" class="no-data no-data-available">{{ "SHARED.No_Data" | translate }}</p>
        </div>
      </div>
    </div>
    <div [class.disabled]="!(module.COMPLIMENT | permission: locationID)" class="coaching-block-right">
      <div class="coaching-block-number">
        <div class="descriptor">
          {{ "DASHPAGES.LOCATION_descriptor6" | translate }}
        </div>
        <div *ngIf="locationData.counts && (module.COMPLIMENT | permission: locationID)" class="dash-number-medium">
          {{locationData.counts.compliment.count}}
        </div>
        <img alt="Thumbs Up" src="assets/icons/landingThumbsup.svg">
      </div>
    </div>
  </div>
  <!--END COACHING -->

  <!-- Removed because conflicted with thumbsup leaderboard in leaderboard dashboar -->
  <!-- <div class="compliment-block">
    <div class="descriptor">
      {{ "DASHPAGES.LOCATION_descriptor7" | translate }}
    </div>
    <div class="compliment-block-info" *ngIf="locationData.counts">
      <div [hidden]="!complimentAvaiable" *ngFor="let user of locationData.counts.compliment.topCompliment" class="image-block">
        <img [src]="user.imgSrc" [class]="user.class" alt="User">
        <div class="body-copy-bold" [title]="user.fullName">{{user.compactName}}</div>
        <div class="dash-number-small">{{user.count}}</div>
      </div>
      <p [hidden]="complimentAvaiable" class="no-data no-data-available">{{ "SHARED.No_Data" | translate }}</p>
    </div>
  </div> -->
</ion-content>
