<ion-content class="table-content">
  <app-dashboard-header (onSelected)="toggleFilterBody($event)" [filterComps]="filterComps" [filterObject]="filterObject"
                        [headerSetting]="headerSetting" [searchField]="searchField"
                        [showFilterBody]="showFilterBody"></app-dashboard-header>

  <div class="tabs-wrap">
    <div class="tabs">
      <div (click)="toggle(tab)" *ngFor="let tab of tabs" [ngClass]="{'active-tab': tab.selected}" class="tab">
        {{tab.name}}<span class="tab-select-count">({{tab.count}})</span>
      </div>
    </div>
  </div>

  <div [hidden]="!(preSelected.id=='caOpen')" class="table-container">
    <table class="display" id="caOpen"></table>
  </div>

  <div [hidden]="!(preSelected.id=='caWorkorder')" class="table-container">
    <table class="display" id="caWorkorder">
    </table>
  </div>

  <!-- <div [hidden]="!(preSelected.id=='caFixed')" class="table-container">
    <table class="display" id="caFixed">
    </table>
  </div> -->

  <div [hidden]="!(preSelected.id=='caClosed')" class="table-container">
    <table class="display" id="caClosed">
    </table>
  </div>

</ion-content>
