import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';

import {
  FormBuilderService,
  LoadingService,
  PopoverService,
  ZoneService,
  UserdataService,
  ObservationService
} from '@services';
import { Events } from '@services/events/events.service';
import { BaseDetailsPage } from '../../../management/pages/details/abstractDetails/abstractBaseDetails.page';
import { ReportService } from '../../services/report.service';
import { ReportingFormBuilderService } from '@modules/reporting/services/reporting-form-builder.service';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-form-group',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})

export class FormGroupComponent extends BaseDetailsPage {

  @Input() last: boolean;
  @Input() type: string;
  @Input() newForm: any;
  @Input() editForm: any = {};
  @Input() reportID;
  @Output() onSaveReport = new EventEmitter();
  @Output() onSaveAsReport = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() changeStep = new EventEmitter();
  @Output() onEvent = new EventEmitter();
  public reportData: any;
  public isPreviewReportVisible = false;
  public title: string = this.translate.instant('SHARED.Add_Report');
  public previewReportButtonId: string;
  public formPrefixId: string;
  public formConfig: any;
  private zones = this.zoneService.getGroupedZonesByLocations(this.userdataService.locations);
  private customDateRange: any = {};
  private reportType = 'observation';
  private additionalFormData: any = {};

  constructor(
    protected route: ActivatedRoute,
    protected formBuilderService: FormBuilderService,
    protected elementRef: ElementRef,
    protected popoverService: PopoverService,
    protected location: Location,
    protected loadingService: LoadingService,
    private reportService: ReportService,
    private zoneService: ZoneService,
    protected router: Router,
    private userdataService: UserdataService,
    protected translate: TranslateService,
    private observationService: ObservationService,
    private events: Events,
    private modalController: ModalController,
    private reportingFormBuilderService: ReportingFormBuilderService
  ) {
    super(route, formBuilderService, elementRef, popoverService, location, loadingService, translate);
    this.disableUpdatingDataWhileBack = true;

    this.duplicateErrorPopoverConfig.title = this.translate.instant('SHARED.Something_Went_Wrong');
    this.duplicateErrorPopoverConfig.description = this.translate.instant('SHARED.Failed_To_Update');

    if (_.isEmpty(this.observationService.observations.data)) {
      this.loadingService.enable();
      this.events.subscribe('observationUpdate', this.observationListener);
    }
  }

  @Input() set inputFormConfig(data) {
    this.formConfig = data;

    if (this.type) {
      this.formConfig.fields = _.filter(this.formConfig.fields, (field: any) => !field.containerClass || _.includes(field.containerClass, this.type));
    }
    this.ionViewDidEnter();
  }

  public cancel(): any {
    this.modalController.getTop().then((modal: any) => {
      modal ? this.modalController.dismiss() : this.onCancel.emit();
    });
  }

  public onSave(): void {
    this.onSaveReport.emit();
  }

  public onSaveAsEvent(): void {
    this.onEvent.emit();
  }

  public onSaveAs(): void {
    this.onSaveAsReport.emit();
  }

  public onChangeStep(n: any): void {
    this.changeStep.emit(n);
  }

  protected save() {
    if (this.reportID) {
      this.onSaveAsEvent();
    } else {
      this.onSave();
    }
  }

  protected updateHandler(formData: any) {
    this.replaceMessageIdWith('reportID', formData);
    Object.assign(formData, _.pickBy(this.additionalFormData, _.identity));
    if (formData.timespan === 'custom') {
      Object.assign(formData, this.customDateRange);
    }
    return this.reportService.update(formData);
  }

  protected deleteHandler: any = () => this.reportService.delete(this.messageID);

  protected addHandler: any = (formData: any) => this.reportService.add(formData);

  protected getData(): any {
    if (this.messageID || this.reportID) {
      const reportData: any = _.find(this.reportService.reports.data, <any>{reportID: +this.messageID});

      _.forEach(reportData.selectors, (ref, name) => {
        reportData[name] = ref;
      });

      if (this.copyMode) {
        reportData.name = this.translate.instant('REPORTING.Copy_of') + reportData.name;
      }

      if (reportData.type === 'zone') {
        reportData.zonePrimaryField = reportData.selectors.primary;
        reportData.zoneSecondaryField = reportData.selectors.secondary;
        reportData.zoneReportColumns = reportData.selectors.extraColumns;
      } else if (reportData.type === 'worker') {
        // reportData.workerDetailReportColumns = reportData.selectors.extraColumns;
        reportData.workerPrimaryField = reportData.selectors.primary;
        reportData.workerSecondaryField = reportData.selectors.secondary;
        reportData.workerReportColumns = reportData.selectors.extraColumns;
        reportData.workerGraphPrimary = reportData.selectors.graphPrimary;
        reportData.workerGraphSecondary = reportData.selectors.graphSecondary;
      } else if (reportData.type === 'obsdets') {
        reportData.obsdetsPrimaryField = reportData.selectors.primary;
        reportData.obsdetsSecondaryField = reportData.selectors.secondary;
        reportData.observationReportColumns = reportData.selectors.extraColumns;
      } else if (reportData.type === 'observation') {
        reportData.observationPrimaryField = reportData.selectors.primary;
        reportData.observationSecondaryField = reportData.selectors.secondary;
        reportData.observationReportColumns = reportData.selectors.extraColumns;
        reportData.observationGraphPrimary = reportData.selectors.graphPrimary;
        reportData.observationGraphSecondary = reportData.selectors.graphSecondary;
      } else if (reportData.type === 'check') {
        reportData.checkPrimaryField = reportData.selectors.primary;
        reportData.checkSecondaryField = reportData.selectors.secondary;
        reportData.checkReportColumns = reportData.selectors.extraColumns;
        reportData.checkGraphPrimary = reportData.selectors.graphPrimary;
        reportData.checkGraphSecondary = reportData.selectors.graphSecondary;
      } else if (reportData.type === 'checkDetail') {
        reportData.checkDetailReportColumns = reportData.selectors.extraColumns;
      } else if (reportData.type === 'PPESummary') {
        reportData.PPESummaryPrimaryField = reportData.selectors.primary;
        reportData.PPESummarySecondaryField = reportData.selectors.secondary;
        reportData.PPESummaryReportColumns = reportData.selectors.extraColumns;
        reportData.PPESummaryGraphPrimary = reportData.selectors.graphPrimary;
        reportData.PPESummaryGraphSecondary = reportData.selectors.graphSecondary;
      } else if (reportData.type === 'obsDetail') {
        reportData.obsDetailReportColumns = reportData.selectors.extraColumns;
      }

      _.forEach(reportData.recipientSelectors, (ref, name) => {
        reportData[name] = ref;
      });

      const periodField: any = _.find(this.formConfig.fields, {name: 'period'});
      if (periodField) {
        periodField.options = this.reportService.buildPeriodList(reportData.timespan);
      }

      if (_.get(reportData, 'locations.length')) {
        const zoneFieldConfig: any = _.find(this.formConfig.fields, {name: 'zones'});
        if (zoneFieldConfig) {
          zoneFieldConfig.options = this.zoneService.getGroupedZonesByLocations(reportData.locations);
        }
      }
      return reportData;
    } else {
      return {};
    }
  }

  protected prepareDataAsync(): Promise<any> {
    return Promise.resolve();
  }

  protected prepareFormConfig(): void {
    this.formConfig.enableChangeDetectionMode = false;

    if (this.reportID) {
      this.messageID = this.reportID;
      this.formConfig.step = 'Modal';
    }

    if (this.messageID && this.copyMode) {
      this.title = this.translate.instant('REPORTING.Copy_Report');
      this.formConfig.prefix = 'reportAddMenu';
      this.formConfig.save = this.translate.instant('SHARED.Save_Copy');
    } else if (this.messageID) {
      const reportData = this.getData();
      this.title = this.translate.instant('REPORTING.Edit_Report');
      this.formConfig.prefix = 'reportEditMenu' + this.formConfig.step;
      this.formConfig.save = this.translate.instant('SHARED.Save');
      this.formConfig.cancel = this.translate.instant('SHARED.Cancel');
      this.updatePopoverConfig.title = this.translate.instant('REPORTING.Update_Report');
      this.updatePopoverConfig.description = this.translate.instant('REPORTING.Update_confirm');
      if (reportData.creatorID !== this.userdataService.userID) {
        this.updatePopoverConfig.hideSaveAction = true;
        this.updatePopoverConfig.description = this.translate.instant('REPORTING.Update_cant_save');
      }
    }

    if (this.formConfig.step === 'Modal') {
      this.skipPopup = true;
      this.updatePopoverConfig.hideSaveAction = false;
      this.updatePopoverConfig.description = this.translate.instant('REPORTING.Update_confirm');
    }

    if (this.reportID) {
      this.formConfig.save = this.translate.instant('REPORTING.Save_Report');
    }

    this.formPrefixId = `#${this.formConfig.prefix}`;
  }

  protected onFinish(): void {
    let activeStep = 1;

    if (this.messageID) {
      const reportData: any = _.find(this.reportService.reports.data, <any>{reportID: +this.messageID});
      this.reportingFormBuilderService.defineObstypeVisibilityByValue(this.formConfig, reportData.obstype);

      if (reportData.timespan === 'custom') {
        this.customDateRange.startTime = reportData.startTime;
        this.customDateRange.endTime = reportData.endTime;
      }
      this.reportType = reportData.type;
    }

    if (!this.reportID) {
      this.previewReportButtonId = this.messageID && !this.copyMode ? '#reportEditFormStep' + this.formConfig.step + ' .form-divider-title' : '#reportAddFormStep' + this.formConfig.step + ' .form-divider-title';
      this.elementRef.nativeElement.querySelector(this.previewReportButtonId)
        .addEventListener('click', () => {
          activeStep = this.formConfig.step;
          this.onChangeStep(activeStep);
        });
    }

    if (this.formConfig.extraControl) {
      this.previewReportButtonId = this.messageID && !this.copyMode ? '#reportEditMenu' + this.formConfig.step + 'ExtraControl' : '#' + this.formConfig.prefix + 'ExtraControl';
      this.elementRef.nativeElement.querySelector(this.previewReportButtonId)
        .addEventListener('click', () => {
          if (this.messageID) {
            this.onSaveAs();
          } else {
            const formId: string = this.messageID && !this.copyMode ? '#' + this.editForm.id : '#' + this.newForm.id;
            if ((<any>$(formId)).valid()) {
              this.reportData = this.formBuilderService.getFormData($(formId));
              this.reportData.selectors = this.reportService._encodeSelectors(this.reportData);
              if (this.reportData.timespan === 'custom') {
                const customDateRange = {
                  startTime: moment(this.customDateRange.startTime).startOf('day').valueOf(),
                  endTime: moment(this.customDateRange.endTime).endOf('day').valueOf()
                };
                Object.assign(this.reportData, customDateRange);
              }
              activeStep = this.formConfig.step + 1;
              this.onChangeStep(activeStep);
              this.isPreviewReportVisible = true;
            } else {
              this.isPreviewReportVisible = false;
            }
          }
        });

      if (this.messageID && this.formConfig.step === 1) {
        this.syncFields();
      }
    }
  }

  private observationListener = () => {
    this.reportData = _.find(_.cloneDeep(this.reportService.reports.data), <any>{reportID: +this.messageID});
    this.events.unsubscribe('observationUpdate', this.observationListener);
    this.loadingService.disable();
  };

  private syncFields(): void {
    this.reportingFormBuilderService.updateGraphFields(this.type, this.formConfig);
    this.reportingFormBuilderService.syncGraphSecondaryField(this.type, this.formConfig);
  }

}
