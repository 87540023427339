import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss'],
})
export class TreeviewComponent {
  @Input() items = [];
  @Input() isChildren: boolean;
  @Input() @HostBinding('class.disabled') public disabled: boolean;
  @Output() onCheck: EventEmitter<void> = new EventEmitter<void>();
  public hiddenItemsMap = {};

  public applyChanges(item, value: boolean) {
    this.applyCheck(item, value);
    this.onCheck.emit();
  }

  public applyCheck(folder, value: boolean) {
    folder.checked = value;
    _.each(folder.children, (child) => this.applyCheck(child, value));
  }

}
