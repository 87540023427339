import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-archive-popup',
  templateUrl: './archive-popup.component.html',
  styleUrls: ['./archive-popup.component.scss'],
})
export class ArchivePopupComponent implements OnInit {
  @Input() observationID: Number;

  constructor(private popOver: PopoverController,
              private popOps: PopupObservationService,
              private router: Router) {
  }

  ngOnInit() {
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public save(): void {
    this.popOps.archive(this.observationID);
    this.popOver.dismiss();
  }

}
