<div class="group-content">
  <div [hidden]="!isGroupControlsVisible" class="group-controls">
    <form #nameForm></form>
    <ion-reorder>
      <div class="reorder-icon"></div>
    </ion-reorder>
  </div>

  <div class="table-container table-block">
    <table [id]="tableId" class="display"></table>

    <div class="button-block">
      <ion-button (click)="openQuestion()" class="page-button"
                  color="secondary">{{ "MGMT_DETAILS.Add_Question_Item" | translate }}</ion-button>
      <ion-button (click)="removeGroup()" *ngIf="isGroupControlsVisible" class="page-button"
                  color="light">{{ "MGMT_DETAILS.Delete_Group" | translate }}</ion-button>
    </div>
  </div>
</div>
