import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

const openTypes = ['escalated', 'new', 'workorder'];


export interface StatisicalTool {
  mean: number;
  standardDeviation: number;
}

@Injectable({
  providedIn: 'root'
})
export class SafetyIndexService {

  public riskIndex: number = null;

  private statisticObject: StatisicalTool;

  constructor(
    private logger: NGXLogger,
  ) {
  }


  /**
   * calculates the average risk index of the observations provided
   *
   * @param observationBucket - list of observations, ex openObservations, closedObservations
   */
  public calculateHistoricRiskByState(observationBucket: any): number {
    const tempScoreArray: any = [];
    this.statisticObject = this.calcMeanAndDeviation(observationBucket);
    _.forEach(observationBucket, (object: any) => {
      tempScoreArray.push(this.calculateRiskScorePerObservation(object, this.statisticObject));
    });
    this.riskIndex = _.mean(tempScoreArray);
    return this.riskIndex;
  }


  /**
   * @param calculateRiskScore - observationObject
   * Calculates the risk value unsafe Condition
   */
  private calculateRiskScorePerObservation(observationObject: any, statisticalObject: any): number {
    if (!observationObject) {
      return null;
    }

    // check if valid observation type, can only caclulate for unsafe condition now.
    if (observationObject.type === 'condition') {
      if (_.includes(openTypes, observationObject.state)) {
        observationObject.safetyIndex = this.calculateOpenRisk(observationObject, statisticalObject);
        return observationObject.safetyIndex;
      } else {
        observationObject.safetyIndex = this.calculateHistoricRisk(observationObject, statisticalObject);
        return observationObject.safetyIndex;
      }
    } else {
      return null;
    }
  }


  /**
   * calculates the risk index for historic unsafe conditions
   *
   * @param observationObject - unsafe observation Object
   * @param statisticalObject - mean and standardDeviation from related bucket
   */
  private calculateHistoricRisk(observationObject: any, statisticalObject: any): number {
    /***  BE works  historic safety Index Calculation ***/
    return null;
  }


  /**
   * calculates the risk index for open unsafe conditions
   *
   * @param observationObject - unsafe observation object
   * @param statisticalObject - mean and standardDeviation of open unsafe bucket
   */
  private calculateOpenRisk(observationObject: any, statisticalObject: any): number {
    // 1. Log of duration
    const duration = observationObject.lnDuration;
    const created = moment(new Date(observationObject.created * 1000)).fromNow();

    // 2. Hazard muliplier
    const s = observationObject.severityOverride ? observationObject.severityOverride : observationObject.severity;
    const l = observationObject.likelihoodOverride ? observationObject.likelihoodOverride : observationObject.likelihood;

    const hazard = (s + l) / 2;

    // 3. Time Open Response Rate
    const responseRate = (duration - statisticalObject.mean) / statisticalObject.standardDeviation;

    // 4. Non normalized risk value
    let rawRiskValue = hazard;
    if (responseRate > 0) {
      rawRiskValue = hazard + responseRate * hazard;
    }

    if (rawRiskValue > 100) {
      rawRiskValue = 100; // normalize the upper limit
    }

    // this.logger.log('duration :' + created + ' hazard: ' + hazard + ' rRate: ' + responseRate + ' rawRisk:' + rawRiskValue);
    // this.logger.log('...................................................');
    // 5. TO:DO calclate more from BE works documentation
    // observationObject.safetyIndex = rawRiskValue;

    observationObject.safetyIndex = hazard;
    // return rawRiskValue;
    return hazard;
  }


  /**
   * calculates the natural log of time differences
   *
   * @param historyArray - array of activities
   */
  private calcDurationLn(historyArray: any): number {
    // if has created and fixed then its historic, else its open
    const startObj = _.find(historyArray, ['activity', 'created']);
    const endObj = _.find(historyArray, ['activity', 'fixed']);
    const startTime = moment(startObj.time * 1000);
    let endTime: any;
    if (!endObj) {
      endTime = moment();
    } else {
      endTime = moment(endObj.time * 1000);
    }
    return Math.log(endTime.diff(startTime));
  }


  /**
   *  calculates the average and standard deviation of ln(duration) for each bucket
   *
   * @param observationBucket - list of observations by state/type ex: open, closed, fixed
   */
  private calcMeanAndDeviation(observationBucket: any): StatisicalTool {

    // calculate duration ln for each observation
    _.forEach(observationBucket, (object: any) => {
      if (object && object.history) {
        object.lnDuration = this.calcDurationLn(object.history);
      }

    });

    // calculate average and Sd for each bucket
    const durationArray = _.map(observationBucket, 'lnDuration');
    const avg = _.mean(durationArray);
    const sd = Math.sqrt(_.sum(_.map(durationArray, (i) => Math.pow((i - avg), 2))) / durationArray.length);
    return {
      mean: avg,
      standardDeviation: sd
    };
  }
}
