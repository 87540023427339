<ion-header no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content class="custom-inputs">

  <!-- <div class="close-observation-container">
   <div class="page-title">{{"title"}}</div>
    <form [id]="formId" [name]="formId"></form>
  </div> -->

  <div class="medium">
    <div class="page-title">{{title}}</div>
    <form id="resolveObservationForm" name="resolveObservationForm"></form>
  </div>

</ion-content>
