import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingService } from '@services';

import * as _ from 'lodash';

interface IFooterNavigationItem {
  disabled: boolean;
  data?: any;
  qparam?: any;
}

interface IFooterNavigationConfig {
  prev: IFooterNavigationItem;
  next: IFooterNavigationItem;
  name?: string;
  nav?: string;
  activeTab?: string;
}

@Component({
  selector: 'app-detail-footer',
  templateUrl: './detail-footer.component.html',
  styleUrls: ['./detail-footer.component.scss'],
})
export class DetailFooterComponent {

  public footerData: IFooterNavigationConfig;

  constructor(
    private router: Router,
    private loadingService: LoadingService
  ) {
  }

  @Input('footerData') set data(footerData: any) {
    if (_.isEmpty(footerData)) {
      this.footerData = {
        prev: {disabled: true},
        next: {disabled: true}
      };
    } else {
      this.footerData = footerData;
    }
  }

  public openBy(navigationConfig: IFooterNavigationItem) {
    if (navigationConfig?.data) {
      const queryParams = _.assign({tableNav: true}, navigationConfig.qparam);
      this.router.navigate([navigationConfig.data], {queryParams, replaceUrl: true});
    }
  }

  public async openMainPage() {
    // await this.loadingService.enable();
    this.router.navigate([this.footerData.nav], {
      queryParams: {activeTab: this.footerData.activeTab},
      replaceUrl: true
    });
  }
}
