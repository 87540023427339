import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AccountsService, CommsService, LoadingService, TeamsService, UserService } from '@services';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent {

  @Input() isTeam: boolean;

  public currentId: number;
  public avatarUrl: string;
  public name: string;
  public newMessage = '';

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private accountService: AccountsService,
    private commsService: CommsService,
    private loadingService: LoadingService,
    private teamsService: TeamsService
  ) {
  }

  @Input()
  set id(id: number) {
    this.currentId = id;

    if (this.isTeam) {
      this.name = this.teamsService.teamNameByID(this.currentId);
      this.avatarUrl = 'assets/icons/groups_white.svg';
    } else {
      this.avatarUrl = this.userService.accountAvatar(this.currentId, null, false, true);
      this.name = this.accountService.username(this.currentId);
    }
  }

  public close(): void {
    this.modalController.dismiss();
  }

  public sendMessage(): void {
    const requestParams: any = {
      cmd: 'addMessage',
      type: 'message',
      message: this.newMessage,
      [this.isTeam ? 'groups' : 'users']: [this.currentId]
    };

    this.loadingService.enable();

    this.commsService.sendMessage(requestParams, false, false).then(() => {
      this.loadingService.disable();
      this.close();
    }).catch(() => {
      this.loadingService.disable();
    });
  }

}
