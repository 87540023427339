<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <div *ngIf="!usesMaintainX" class="title">{{ "ODetail.Work_Order_Required" | translate }}</div>
  <div *ngIf="usesMaintainX" class="title">{{ "ODetail.Send_Work_Request" | translate }}</div>
</div>
<div *ngIf="!usesMaintainX" class="body">
  <p>{{ "DASHBOARD.WORKORDER_POPUP_body" | translate }}</p>
  <input [(ngModel)]="workOrderNumber" id="worknumberInputFirst" type="text" value="">
</div>
<div *ngIf="usesMaintainX" class="body">
  <ion-item>
    <ion-label>Priority</ion-label>
    <ion-select [(ngModel)]="priority" interface="alert">
      <ion-select-option value="NONE">None</ion-select-option>
      <ion-select-option value="LOW">Low</ion-select-option>
      <ion-select-option value="MEDIUM">Medium</ion-select-option>
      <ion-select-option value="HIGH">High</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position='stacked'>{{'SHARED.Work_Request_Title' | translate}}</ion-label>
    <ion-input [(ngModel)]="workRequestTitle" required="true" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">{{'SHARED.Work_Request_Description' | translate }}</ion-label>
    <ion-textarea #wrDescription [(ngModel)]="description" placeholder="{{'SHARED.Work_Request_Description' | translate}}"
                  rows="6"></ion-textarea>
  </ion-item>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="createWorkOrder()" *ngIf="!usesMaintainX" [disabled]="!workOrderNumber" class="page-button"
              color="secondary">{{ "SHARED.Save" | translate }}</ion-button>
  <ion-button (click)="createWorkOrder()" *ngIf="usesMaintainX" [disabled]="!workRequestTitle" class="page-button"
              color="secondary">{{ "SHARED.Create" | translate }}
  </ion-button>
</div>
