import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { TreeViewNode, TreeViewNodeIdType } from './../../models';

import { each } from 'lodash';
@Component({
  selector: 'app-tree-viewer [nodes]',
  templateUrl: './tree-viewer.component.html',
  styleUrls: ['./tree-viewer.component.scss'],
})
export class TreeViewerComponent {
  @Input() nodes: TreeViewNode[] = [];
  @Input() isChildren: boolean;
  @Input() @HostBinding('class.multiselect') multiselect: boolean;
  @Input() @HostBinding('class.disabled') public disabled: boolean;

  @Output() onChange: EventEmitter<TreeViewNodeIdType[]> = new EventEmitter<TreeViewNodeIdType[]>();
  @Output() onResetSelection: EventEmitter<void> = new EventEmitter<void>();

  public hiddenItemsMap: { [nodeId: string]: boolean } = {};
  ngOnInit() {
    this.predefineNodes();
  }
  public onCheckboxChanged(node: TreeViewNode, value: boolean) {
    this.toggleNode(node, value);
    this.onChildSelected();
  }
  public toggleSelect(node: TreeViewNode): void {
    const value = !node.checked;

    if (this.multiselect) {
      this.onCheckboxChanged(node, value);
    } else {
      this.resetSelection();
      node.checked = value;
      this.onChildSelected();
    }
  }
  public resetSelection() {
    if (this.isChildren) {
      this.onResetSelection.emit();
    } else {
      each(this.nodes, (node) => {
        this.resetNode(node);
      });
    }
  }
  public onChildSelected() {
    if (this.isChildren) {
      this.onChange.emit([]);
    } else {
      this.onChange.emit(this.getSelectedIds());
    }
  }
  public getSelectedIds(nodes = this.nodes): TreeViewNodeIdType[] {
    const ids: TreeViewNodeIdType[] = [];

    each(nodes, (node) => {
      if (node.checked) {
        ids.push(node.id);
      } else if (node?.nodes?.length) {
        ids.push(...this.getSelectedIds(node.nodes));
      }
    });

    return ids;
  }
  private toggleNode(node: TreeViewNode, value: boolean) {
    node.checked = value;

    each(node.nodes, (childNode) => {
      this.toggleNode(childNode, value);
    });
  }
  private predefineNodes() {
    each(this.nodes, (node) => {
      if (node.checked) {
        this.toggleNode(node, true);
      }
    });
  }
  private resetNode(node: TreeViewNode) {
    node.checked = false;

    each(node?.nodes, (childNode) => {
      this.resetNode(childNode);
    });
  }
}
