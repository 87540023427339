import { Component, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';

import {
  FilterElementService,
  IHeaderSetting,
  LoadingService,
  Module,
  ResizeHandlerService,
  TableService
} from '@services';
import { Events } from '@services/events/events.service';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ca-table',
  templateUrl: './ca-table.page.html',
  styleUrls: ['./ca-table.page.scss'],
})

export class CaTablePage {

  public headerSetting: IHeaderSetting = {
    title: this.translate.instant('SHARED.Corrective_Actions'),
    filter: {
      showFilter: true,
      isActive: false
    },
    showSearchField: true,
    selectDropDown: {
      dataNavMenuKey: Module.OBSERVATIONS,
      option: {
        minimumResultsForSearch: Infinity,
        placeholder: this.translate.instant('SHARED.COACHING_TABLE_placeholder'),
        width: '15em',
        closeOnSelect: true,
        sorter: (data) => data
      },
      defaultVal: null
    }
  };

  // prepare tabs
  public tabs: any = [
    {
      name: this.translate.instant('SHARED.Open'),
      selected: true,
      active: true,
      count: 0,
      id: 'caOpen'
    },
    {
      name: this.translate.instant('SHARED.Work_Order'),
      selected: false,
      active: true,
      count: 0,
      id: 'caWorkorder'
    },
    // {
    //   name: this.translate.instant('SHARED.Marked_as_Fixed'),
    //   selected: false,
    //   active: true,
    //   count: 0,
    //   id: 'caFixed'
    // },
    {
      name: this.translate.instant('DASHPAGES.resolved'),
      selected: false,
      active: true,
      count: 0,
      id: 'caClosed'
    }
  ];

  public preSelected: any = _.first(this.tabs);
  public filterObject = this.observationTableService.filterObject;
  public filterComps: any = [];
  public showFilterBody = false;
  public searchField: any = '';
  private filterString: string = null;
  private tableData: any = {};
  private menuItem: any = ['location', 'team', 'user', 'zone', 'sl', 'timespan'];
  private clearFilter = false;
  private isNeededTableRefreshing: any = {};

  constructor(
    private observationTableService: ObservationTableService,
    private events: Events,
    private zone: NgZone,
    private filterElem: FilterElementService,
    private resizeHandler: ResizeHandlerService,
    private tableService: TableService,
    private route: ActivatedRoute,
    private location: PlatformLocation,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {
  }

  ionViewWillLeave() {
    this.resizeHandler.removeResizeHandler('conditionTable');
    this.events.unsubscribe('ccs:filterObject', this.filterListener);
    this.events.unsubscribe('ccs:clearMenu', this.clearMenuListener);
    this.events.unsubscribe('ccs:observationUpdate', this.observationUpdateListener);
    this.events.unsubscribe('ccs:searchString', this.searchStringListener);

    if (this.clearFilter) {
      this.observationTableService.clearFilterObject();
    }
  }

  ionViewDidEnter() {
    // setting pre-selected first
    this.loadingService.enable().finally(() => {
      let clearSearch: string = this.route.snapshot.queryParamMap.get('clearSearch');
      this.clearFilter = this.route.snapshot.queryParamMap.get('clearFilter') === 'true';
      if (this.clearFilter) {
        this.observationTableService.clearFilterObject();
        this.clearFilter = false;
      }

      this.preSelected = _.find(this.tabs, ['selected', true]);
      this.location.onPopState(() => {
        clearSearch = null;
      });

      if (clearSearch) {
        this.searchField = '';
        this.observationTableService.filterObject.searchString = null;
        $('.observation-search-field').val(null);
      } else {
        this.searchField = this.observationTableService.filterObject.searchString;
        $('.observation-search-field').val(this.searchField);
      }

      this.events.subscribe('ccs:observationUpdate', this.observationUpdateListener);
      this.events.subscribe('ccs:filterObject', this.filterListener);
      this.events.subscribe('ccs:searchString', this.searchStringListener);
      this.events.subscribe('ccs:clearMenu', this.clearMenuListener);

      this.prepData();
      this.preSelected = _.find(this.tabs, ['selected', true]);

      const activeType: string = this.route.snapshot.queryParamMap.get('activeTab');
      const activeTab = _.find(this.tabs, {id: activeType});

      if (activeTab) {
        this.toggle(activeTab, false);
        this.preSelected = activeTab;
      } else {
        this.buildTableElements(this.preSelected);
        this.markTablesForNextUpdate();
      }

      this.filterComps = this.filterElem.buildFilterObjects(this.menuItem, this.observationTableService.filterObject);
      this.headerSetting.filter.isActive = this.observationTableService.filterValuesAvailable(this.filterComps);
      this.resizeHandler.addResizeHandler('conditionTable', () => this.tableService.handleResize('.dataTables_scrollBody:visible:last', 50, null));
      this.loadingService.disable();
    });
  }

  public toggleFilterBody(val) {
    this.showFilterBody = val;
  }

  public toggle(tab, isTabChanging: boolean = true) {
    // find selected first
    const prevObj = _.find(this.tabs, ['selected', true]);
    prevObj.selected = false;
    this.preSelected = _.find(this.tabs, ['id', tab.id]);
    this.preSelected.selected = true;
    this.buildTableElements(tab, isTabChanging);

    if (prevObj.id !== tab.id) {
      this.observationTableService.updateRouterActiveTab(tab.id);
    }
  }

  private searchStringListener = (data) => {
    this.observationTableService.filterObject = data; // get filter elem
    this.prepData();
    this.buildTableElements(this.preSelected, false, true, true);
    this.markTablesForNextUpdate();
  };

  private observationUpdateListener = (data) => {
    if (data) {
      this.prepData();
      this.buildTableElements(this.preSelected);
      this.markTablesForNextUpdate();
    }
  };

  private filterListener = (data) => {
    this.observationTableService.filterObject = data; // get filter elem
  };

  private clearMenuListener = (data) => {
    if (data) {
      this.zone.run(() => {
        // tslint:disable-next-line:max-line-length
        this.filterComps = this.filterElem.buildFilterObjects(this.menuItem, this.observationTableService.filterObject);
        this.filterObject = this.observationTableService.filterObject;
        let checkFilterActive = false;
        _.each(this.filterComps, comp => {
          if (comp.value && comp.value.length > 0) {
            checkFilterActive = true;
            return false;
          }
        });
        this.headerSetting.filter.isActive = checkFilterActive;
        this.prepData();

        setTimeout(() => {
          this.buildTableElements(this.preSelected);
          this.markTablesForNextUpdate();
        });
      });
    }
  };

  private prepData() {
    // need to update end time to capture new incoming observaitons
    if (this.observationTableService.filterObject.endTime && this.observationTableService.filterObject.timespan !== 'custom') {
      const endTimeNow = Date.now();
      this.observationTableService.filterObject.endTime = endTimeNow;
    }

    if (!this.observationTableService.filterObject.timespan) {
      this.observationTableService.filterObject.timespan = 'all';
    }
    const rawData = this.observationTableService.getObservation('ca');
    this.tableData = {}; // empty out before collection

    // collect open stuff
    if (rawData.rows.new || rawData.rows.escalated) {
      if (rawData.rows.new) {
        if (rawData.rows.escalated) { // if both new and escalated exist,put them in one
          this.tableData.caOpen = _.concat(rawData.rows.new.items, rawData.rows.escalated.items);
        } else { // if only new is present
          this.tableData.caOpen = rawData.rows.new.items;
        }
      } else { // if only escalated is present
        this.tableData.caOpen = rawData.rows.escalated.items;
      }
    } else {
      this.tableData.caOpen = [];
    }

    // collect workorder, this is optional
    if (rawData.rows.workorder) {
      this.tableData.caWorkorder = rawData.rows.workorder.items;
    } else {
      this.tableData.caWorkorder = [];
    }

    // collect fixed
    if (rawData.rows.fixed) {
      this.tableData.fixed = rawData.rows.fixed.items;
    } else {
      this.tableData.fixed = [];
    }

    // collected closed
    if (rawData.rows.resolved) {
      this.tableData.caClosed = rawData.rows.resolved.items;
    } else {
      this.tableData.caClosed = [];
    }

    // if (!_.isEmpty(rawData.rows)) {
    //   this.tabs = this.observationTableService.fillCount(this.tabs, this.tableData);
    // }
    this.tabs = this.observationTableService.fillCount(this.tabs, this.tableData);
    this.filterString = this.observationTableService.filterObject.searchString;
  }

  private buildTableElements(tab, isTabChanging?: boolean, recalculate?: boolean, disableLoading?: boolean) {
    this.observationTableService.buildTableByTab({
      tab,
      tableData: this.tableData,
      filterString: this.filterString,
      loadingState: this.isNeededTableRefreshing,
      isTabChanging,
      recalculate,
      disableLoading
    });
  }

  private markTablesForNextUpdate() {
    _.each(this.tabs, (tab) => {
      this.isNeededTableRefreshing[tab.id] = true;
    });
  }

}
