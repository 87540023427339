import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { HierarchyGroupingService } from '@services/hierarchyGrouping/hierarchy-grouping.service';
import { UserService } from '@services/user/user.service';
import { TreeviewComponent } from '@shared/components';
import { UserdataService } from '@services/userdata/userdata.service';
import { AccountsService, Module, Permission, PermissionsService, SubscriberService } from '@services';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as uuid from 'uuid';

interface FolderItem {
  id: string;
  locationId: number;
  type: 'location' | 'folder';
  folderId: string;
  text: string;
  children: FolderItem[];
  locations: number[];
  checked: boolean;
  isValid: boolean;
}

@Component({
  selector: 'app-manage-location',
  templateUrl: './manage-location.component.html',
  styleUrls: ['./manage-location.component.scss']
})
export class ManageLocationComponent implements OnInit {
  @ViewChild('treeviewRef') treeviewComponent: TreeviewComponent;
  @Input() checkboxValue;
  public folders: FolderItem[] = [];
  public selectedFolders: (string | number)[] = [];
  public isCheckedAll: boolean;

  private userLocations = [];
  private loggedUserLocationSelections: (string | number)[] = [];

  constructor(
    private translate: TranslateService,
    private hierarchyGroupingService: HierarchyGroupingService,
    private popoverCtrl: PopoverController,
    private userService: UserService,
    private userdataService: UserdataService,
    private permissionsService: PermissionsService,
    private accountService: AccountsService,
    private subscriber: SubscriberService
  ) {}

  ngOnInit() {
    this.isCheckedAll = _.includes(this.checkboxValue, `node:${0}`);
    this.defineCurrentUserLocationSelections();
    this.defineFolders();
  }

  public close(): void {
    this.popoverCtrl.dismiss('');
  }

  public onGroupSelect() {
    this.selectedFolders = [];

    if (this.isCheckedAll) {
      this.selectedFolders = ['node:0'];
    } else {
      this.defineSelectedLocations();
    }
  }

  public toggleAll(value: boolean) {
    this.isCheckedAll = value;
    _.each(this.folders, (item) => this.treeviewComponent.applyCheck(item, value));
    this.onGroupSelect();
  }

  public save() {
    this.popoverCtrl.dismiss(this.selectedFolders);
  }

  private addTreeItemsByLocations() {
    const treeItems = [];
    this.userLocations = this.userService.getUserLocations(this.userdataService.locations, false);

    _.each(this.userLocations, (location) => {
      if (location.folderID === 0) {
        treeItems.push({
          id: uuid.v4(),
          locationId: location.locationID,
          type: 'location',
          text: location.name,
          checked: this.isCheckedAll || _.includes(this.checkboxValue, location.locationID),
          isValid: _.includes(this.loggedUserLocationSelections, location.locationID)
        });
      }
    });

    return treeItems;
  }

  private addTreeItemsByFolders() {
    const folders = this.hierarchyGroupingService.getFolders();
    this.hierarchyGroupingService.translateData(folders);

    return _.map(folders, (folder) => this.addTreeItemsByFolder(folder, this.isCheckedAll));
  }

  private addTreeItemsByFolder(folder, parentChecked?: boolean) {
    const folderChildren = _.values(folder.children);
    const locationChildren = [];
    const isChecked = parentChecked || _.includes(this.checkboxValue, `node:${folder.folderID}`);

    _.each(folder.locations, (locationId) => {
      if (locationId) {
        const locInfo = _.find(this.userLocations, { locationID: locationId });

        if (locInfo) {
          locationChildren.push({
            id: uuid.v4(),
            locationId,
            type: 'location',
            folderId: folder.folderID,
            text: locInfo.name,
            children: [],
            locations: [],
            checked: isChecked || _.includes(this.checkboxValue, locationId),
            isValid: _.includes(this.loggedUserLocationSelections, locationId)
          });
        }
      }
    });

    return {
      id: uuid.v4(),
      folderId: folder.folderID,
      type: 'folder',
      text: folder.title,
      isValid: _.includes(this.loggedUserLocationSelections, `node:${folder.folderID}`),
      children: [
        ...locationChildren,
        ..._.map(folderChildren, (child) => this.addTreeItemsByFolder(child, isChecked)),
      ],
      checked: isChecked
    };
  }

  private isLocationHierarchy(type) {
    return this.subscriber.usesModule(Module.LOCATION_HIERARCHY) && (!_.includes(['observation', 'observer', 'coordinator'], type));
  }

  private defineCurrentUserLocationSelections() {
    const currentUser = this.accountService.getByID(+this.userdataService.userID);
    this.loggedUserLocationSelections = currentUser.locations;

    if (this.isLocationHierarchy(currentUser.type)) {
      if (currentUser.nodeSelections.length) {
        this.loggedUserLocationSelections = currentUser.nodeSelections;
      } else if (currentUser.locations.length === 0) {
        this.loggedUserLocationSelections = ['node:0'];
      }
    }
  }

  private defineFolders() {
    this.folders = [
      ...this.addTreeItemsByLocations(),
      ...this.addTreeItemsByFolders()
    ];

    if (!this.permissionsService.canView(Permission.SuperAdmin)) {
      this.folders = <FolderItem[]>_.filter(this.folders, (folder) => this.isChildChecked(folder));
    }
  }

  private isChildChecked(folder) {
    return folder.isValid || this.isChildrenChecked(folder.children);
  }

  private isChildrenChecked(folders) {
    return _.some(folders, (folder) => this.isChildChecked(folder));
  }

  private defineSelectedLocations(items = this.folders) {
    _.each(items, (item) => {
      if (item.checked) {
        if (item.type === 'folder') {
          this.selectedFolders.push(`node:${item.folderId}`);
        } else {
          this.selectedFolders.push(item.locationId);
        }
      } else {
        if (item.type === 'folder') {
          this.defineSelectedLocations(item.children);
        }
      }
    });
  }

}
