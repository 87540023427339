import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { Events } from '@services/events/events.service';
import { BaseService } from './../abstract-base-service/abstract-base-service.service';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CertificationsService extends BaseService {

  public filterObject: any = {};

  certifications: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private userdata: UserdataService,
    private subscriber: SubscriberService,
    protected translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.certifications.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.certifications.lastRequest}`);
      return Promise.resolve(this.certifications.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getCertifications',
          includeDisabled: 1,
          lastHash: this.certifications.lastHash,
          lastRequest: this.certifications.lastRequest,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.certifications.lastHash = data.result.datahash;
            this.updateCache(data);
          }
          resolve(this.certifications.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public clearCache() {
    this.certifications.lastHash = null;
    this.certifications.lastRequest = null;
    this.certifications.data = null;
  }

  public updateCache(data) {
    this.certifications.lastRequest = data.result.timestamp;
    this.certifications.data = data.result.certifications;
    this.events.publish('ccs:certificationsUpdate');
  }

  public handleAddCertification(fData) {
    fData.cmd = 'addCertification';
    fData.sendTime = Date.now();
    return this.handleRequest(this.encode(fData));
  }

  public handleUpdateCertification(fData) {
    fData.cmd = 'updateCertification';
    fData.sendTime = Date.now();
    return this.handleRequest(this.encode(fData));
  }

  public handleDeleteCertification(fData) {
    fData.cmd = 'deleteCertification';
    return this.handleRequest(fData);
  }

  public certificationNameFromType(type: number, includeDescription: boolean = true, translate: boolean = true) {
    const gRef = this.getCertificationByID(type);
    if (gRef) {
      return this.certificationName(gRef, includeDescription);
    } else {
      return `Unknown ("${type}")`;
    }
  }

  public getCertification(messageID: string | number, translate: boolean = true, mapTranslations: boolean = false) {
    const ref = this.certifications;
    if (!ref) {
      return;
    }
    const r: any = _.find(ref.data, <any>{certificationID: +messageID});
    if (r && (r.hasOwnProperty('ntranslations') || r.hasOwnProperty('dtranslations'))) {
      if (translate) {
        const l = this.userdata.getLanguage();
        if (l !== this.subscriber.getDefaultLanguage()) {
          let t = _.find(r.ntranslations, ['language', l]);
          if (t) {
            r.name = t.value;
          }
          t = _.find(r.dtranslations, ['language', l]);
          if (t) {
            r.description = t.value;
          }
        }
      }
      if (mapTranslations) {
        _.each(r.ntranslations, (ref) => {
          r[`ntranslations_${ref.language}`] = ref.value;
        });
        _.each(r.dtranslations, (ref) => {
          r[`dtranslations_${ref.language}`] = ref.value;
        });
      }
    }
    return r;
  }

  public getCertificationByID(certID) {
    let gRef = null;
    const cert = parseInt(certID);
    $.each(this.certifications.data, (i, ref) => {
      if (ref.certificationID === cert) {
        gRef = ref;
        return false;
      }
    });
    return gRef;
  }

  public certificationName(certItem, includeDescription: boolean = true, translate: boolean = true) {
    let ret = certItem.name;
    let desc = certItem.description;
    if (this.translate != undefined) {
      const ntranslations = certItem.ntranslations;
      const dtranslations = certItem.dtranslations;
      const lang = this.translate.getDefaultLang();
      if (ntranslations) {
        ntranslations.forEach(element => {
          if (element.language == lang) {
            ret = element.value;
          }
        });
      }
      if (dtranslations) {
        dtranslations.forEach(element => {
          if (element.language == lang) {
            desc = element.value;
          }
        });
      }
    }

    if (includeDescription && desc) {
      if (ret) {
        ret += ' - ';
      }
      ret += desc;
    }
    return ret;
  }

  public encode(fData) {

    // roll up any translations
    const l = this.subscriber.getLanguages(true);
    if (l && l.length) {
      const nt = [];
      const dt = [];
      _.each(l, (lang: string) => {
        const n = `ntranslations_${lang}`;
        if (fData.hasOwnProperty(n)) {
          nt.push({language: lang, value: fData[n]});
        }
        const d = `dtranslations_${lang}`;
        if (fData.hasOwnProperty(d)) {
          dt.push({language: lang, value: fData[d]});
        }
      });
      if (nt.length) {
        fData.ntranslations = JSON.stringify(nt);
      }
      if (dt.length) {
        fData.dtranslations = JSON.stringify(dt);
      }
    }
    return fData;
  }

  public getCertificationsByName(name: string) {
    return _.find(this.certifications.data, <any>{name});
  }
}
