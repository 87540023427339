<div *ngFor="let node of nodes | orderBy: 'label'">
  <div class="node">
    <div *ngIf="isChildren"
         (click)="hiddenItemsMap[node.id] = !hiddenItemsMap[node.id]"
         [class.clickable]="node?.nodes?.length"
         class="tree-icon">
    </div>
    <ion-checkbox *ngIf="multiselect" (ngModelChange)="onCheckboxChanged(node, $event)" [(ngModel)]="node.checked"></ion-checkbox>
    <div class="node-label clickable"
         (click)="toggleSelect(node)"
         [class.selected]="!multiselect && node.checked">
      <div *ngIf="node?.nodes?.length || node?.showFolderIcon" class="folder-icon"></div>
      <ion-label>{{ node.label | translate }}</ion-label>
    </div>
  </div>

  <app-tree-viewer *ngIf="node?.nodes?.length"
                   [disabled]="node.checked"
                   [hidden]="hiddenItemsMap[node.id]"
                   [class.children]="isChildren"
                   [multiselect]="multiselect"
                   [isChildren]="true"
                   (onResetSelection)="resetSelection()"
                   (onChange)="onChildSelected()"
                   [nodes]="node.nodes">
  </app-tree-viewer>
</div>
