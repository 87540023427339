import { Directive, ElementRef, Input } from '@angular/core';

import { SubscriberService } from '@services/subscriber/subscriber.service';

import * as _ from 'lodash';

@Directive({
  selector: '[appShowByModule]'
})
export class ShowByModuleDirective {

  constructor(
    private elementRef: ElementRef,
    private subscriberService: SubscriberService
  ) {
  }

  @Input('appShowByModule') set module(targetModules: string | string[]) {
    const isAvailable: boolean = _.some(_.flatten([targetModules]), (module) => this.subscriberService.usesFeature(module));

    if (!isAvailable) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}
