import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import * as _ from 'lodash';
import { NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';

export interface IGaleryImage {
  url: string;
  thumbnailUrl?: string;
  altText?: string;
  title?: string;
  extUrl?: string;
  extUrlTarget?: string;
  time?: string;
  class?: string;
  fullName?: string;
  mediaType?: string;
  isPending?: boolean;
}

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {

  @ViewChild(NgxGalleryComponent, {static: true}) ngxGalleryComponent: NgxGalleryComponent;

  public galleryOptions: NgxGalleryOptions[] = [
    <NgxGalleryOptions>{
      previewKeyboardNavigation: true,
      previewZoom: true,
      previewZoomMax: 2,
      previewZoomMin: 1,
      previewZoomStep: 1,
      thumbnailsMargin: 0,
      thumbnails: false,
      image: false,
      previewCloseOnEsc: true,
      previewCloseOnClick: true
    }
  ];
  public galleryImages: NgxGalleryImage[];

  private images: IGaleryImage[] = [];
  private index: number;
  private previousImage: IGaleryImage;

  constructor(
    private modalController: ModalController,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
    let zoomed = false;
    this.ngxGalleryComponent.imagesReady.subscribe(() => {
      setTimeout(() => {
        this.ngxGalleryComponent.openPreview(this.index);
      });
      setTimeout(() => {
        const img = $('.ngx-gallery-preview-img');
        const handler = (evt) => {
          if (evt.type === 'mouseup') {
            if (zoomed) {
              $('.fa-search-minus').click();
              zoomed = false;
            } else {
              $('.fa-search-plus').click();
              zoomed = true;
            }
          } // else { drag }
          img.off('mouseup mousemove', handler);
        };
        img.on('mousedown', (evt) => {
          img.on('mouseup mousemove', handler);
        });
      });
    });

    this.ngxGalleryComponent.previewClose.subscribe(() => {
      this.modalController.dismiss();
    });

    this.galleryImages = <NgxGalleryImage[]>_.map(this.images, (image: IGaleryImage) => ({big: this.isVideo(image) ? image.thumbnailUrl : image.url}));
  }

  public previewChange(data): void {
    const currentImage: IGaleryImage = this.images[data.index];
    let delay = 650;

    if (this.isVideo(this.previousImage)) {
      this.getElement('.gallery-video').classList.add('hidden-gallery-item');
    }

    if (!this.previousImage && this.isVideo(currentImage)) {
      delay = 0;
    }

    setTimeout(() => {
      const imageElement: HTMLImageElement = <HTMLImageElement>this.getElement('img');

      if (this.isVideo(currentImage)) {
        if (this.isVideo(this.previousImage)) {
          this.getElement('.gallery-video')?.remove();
        }

        imageElement.classList.add('not-active');
        imageElement.after(this.createVideoElement(currentImage.url));
      } else {
        imageElement.classList.remove('not-active');
        this.getElement('.gallery-video')?.remove();
      }

      this.previousImage = currentImage;
    }, delay);
  }

  private isVideo(image: IGaleryImage): boolean {
    return _.includes(image?.mediaType, 'video/');
  }

  private createVideoElement(url: string): HTMLVideoElement {
    const video: HTMLVideoElement = document.createElement('video');

    video.src = url;
    video.autoplay = true;
    video.controls = true;
    video.poster = 'assets/images/no-image-placeholder.png';
    video.classList.add('gallery-video');

    video.addEventListener('click', (event) => {
      event.stopPropagation();
    });

    return video;
  }

  private getElement(selector: string): HTMLElement {
    return this.elementRef.nativeElement.querySelector(`.ngx-gallery-active ${selector}`);
  }
}
