<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ "DASHBOARD.ARCHIVE_POPUP_title" | translate }}</h2>
</div>
<div class="body">
  <div class="description">{{ "DASHBOARD.ARCHIVE_POPUP_description" | translate }}</div>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.No" | translate }}</ion-button>
  <ion-button (click)="save()" class="page-button" color="secondary">{{ "SHARED.Yes" | translate }}</ion-button>
</div>
