<ion-menu (ionWillOpen)="reset()" contentId="main-content" menuId="userSelection" side="end" swipeGesture="false"
          type="overlay">
  <ion-toolbar>
    <app-list-menu (onChangedTab)="onChangeTab($event)" (onSelected)="onSelect($event)"
                   [config]="listConfig"></app-list-menu>
  </ion-toolbar>

  <div class="footer">
    <ion-button (click)="createChat()" [class.disabled]="!isSaveAvailable()" class="page-button"
                color="secondary">{{"SHARED.Next" | translate}}</ion-button>
  </div>
</ion-menu>
