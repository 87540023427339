import { Injectable } from '@angular/core';

import { PopoverService } from '@services/popover/popover.service';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ResizeHandlerService {

  private resizeHandlers: any = {};
  private isInitialized: boolean;

  constructor(protected popoverService: PopoverService,
              private platform: Platform,
              protected translate: TranslateService) {
  }

  public init(): void {
    if (!this.isInitialized) {
      $(window).on('resize', _.debounce((ev) => this.handleResize(ev), 300));
      this.isInitialized = true;
    }
  }

  public addResizeHandler(name, ref) {
    this.removeResizeHandler(name);
    this.resizeHandlers[name] = ref;
  }

  public removeResizeHandler(name) {
    $.each(this.resizeHandlers, (handlerName, ref) => {
      if (handlerName === name) {
        delete this.resizeHandlers[handlerName];
      }
    });
  }

  public handleResize(ev) {
    if (window.innerHeight < 500 || window.innerWidth < 600) {
      this.showPopover();
    } else {
      this.popoverService.hide();
      _.each(this.resizeHandlers, (callback: any) => {
        if (_.isFunction(callback) && !callback(ev)) {
          this.showPopover();
        }
      });
    }
  }

  private showPopover(): void {
    if (!this.platform.is('tablet') && !this.platform.is('mobile')) {
      this.popoverService.show({
        description: this.translate.instant('COMMONT_SERVICE.resize'),
        hideActions: true
      });
    }
  }

}
