import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import {TranslateService} from '@ngx-translate/core';
import {PlatformLocation} from '@angular/common';
import {environment} from '@env';
import * as _ from 'lodash';

@Component({
  selector: 'app-copylink-popup',
  templateUrl: './copylink-popup.component.html',
  styleUrls: ['./copylink-popup.component.scss'],
})

export class CopyLinkReportPopupComponent implements OnInit {
  @Input() reportID: string;

  public fullUrl = '';

  constructor(
    private popOver: PopoverController,
    private pLocation: PlatformLocation,
  ) {}

  ngOnInit() {
    this.fullUrl = this.getReportUrl();
  }

  public getReportUrl(): string {
    let baseHref: string = environment.baseHref;
    if (_.last(baseHref) !== '/') {
      baseHref += '/';
    }
    return `${(<any>this.pLocation).location.origin}${baseHref}pages/reporting/${this.reportID}`
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public copyLink(): void {
    const copyText: any = document.getElementById('deepUrl');
    copyText.select();
    document.execCommand('Copy');
    this.close();
  }
}
