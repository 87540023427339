import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSelect2TextClearing]'
})
export class Select2TextClearingDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    $(this.elementRef.nativeElement).find('select').on('select2:select select2:selecting', (event: any) => {
      $(event.target).parent().find('.select2-search__field').val('').trigger('input');
    });
  }

}
