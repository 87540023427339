import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export enum Permission {
  Corvex = 'corvex',
  SuperAdmin = 'sadmin',
  Admin = 'admin',
  Manager = 'manager',
  Supervisor = 'supervisor',
  Helper = 'helper',
  Worker = 'worker',
  Reporting = 'reporting'
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  public blockedPermissions: string[] = ['helper', 'worker'];
  public userLevel = 99;
  public permissions: any = {
    lastRequest: 0,
    data: [
      {
        id: Permission.Corvex,
        description: 'COMMONT_SERVICE.Subscriber_Admin',
        type: 'boolean'
      },
      {
        id: Permission.SuperAdmin,
        description: 'SHARED.Global_Administration',
        type: 'boolean'
      },
      {
        id: Permission.Admin,
        description: 'SHARED.Administration',
        type: 'boolean'
      },
      {
        id: Permission.Manager,
        description: 'SHARED.Manager',
        type: 'boolean'
      },
      {
        id: Permission.Supervisor,
        description: 'SHARED.Supervisor',
        type: 'boolean'
      },
      {
        id: Permission.Helper,
        description: 'SHARED.Helper',
        type: 'boolean'
      },
      {
        id: Permission.Worker,
        description: 'SHARED.Worker',
        type: 'boolean'
      }
    ]
  };

  constructor(
    protected translate: TranslateService
  ) {
  }

  /**
   * Update the user's highest access level
   *
   * @param perms an object with the user's various permissions
   */
  public updateAccessLevel(perms: any) {
    // reset the user's level
    this.userLevel = this.accessLevel(perms);
    return;
  }

  public accessLevel(perms: any): number {
    let level = 99;
    if (perms) {
      for (let i = this.permissions.data.length - 1; i > -1; i--) {
        const item = this.permissions.data[i];
        if (_.get(perms, item.id, 0)) {
          level = i;
        }
      }
    }
    return level;
  }

  /**
   * Determine if a user has access to a specific level
   *
   * @param level An access level to check against
   */
  public canView(level: string): boolean {
    const checkLevel = _.findIndex(this.permissions.data, {id: level});
    if (checkLevel < this.userLevel) {
      return false;
    } else {
      return true;
    }
  }

  public getPermissionWithoutStaff = () => {
    const permission: any = [];
    this.permissions.data.map((item: any) => {
      if (item.id !== Permission.Corvex) {
        permission.push(item);
      }
    });
    return permission;
  };
}
