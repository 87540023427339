import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-tab-tables',
  templateUrl: './tab-tables.component.html',
  styleUrls: ['./tab-tables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabTablesComponent implements OnInit {

  @Input() tabTableData: any;
  @Input() tabs: any;

  public isOpen = true;
  public isHistory: boolean;
  public obsType = 'open';

  constructor(private changeRef: ChangeDetectorRef,
              private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.changeRef.markForCheck();
    this.logger.log('see what we got', this.tabTableData);
  }


  toggle(stringVal) {
    if (stringVal === 'open') {
      this.isOpen = true;
      this.isHistory = false;
      this.obsType = 'open';
    } else {
      this.isOpen = false;
      this.isHistory = true;
      this.obsType = 'history';
    }

  }


}
