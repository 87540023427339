import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LoadingService } from './../loading/loading.service';
import { MediaViewerComponent } from '@shared/components/media-viewer/media-viewer.component';
import { CommsService } from '@services/comms/comms.service';
import { ImageGalleryComponent } from '@shared/components';

import * as _ from 'lodash';

declare let Viewer: any;

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(
    private loadingService: LoadingService,
    private modalController: ModalController,
    private commsService: CommsService
  ) {
  }

  public show(img: HTMLImageElement) {
    const viewer = new (<any>Viewer)(img, {
      transition: true,
      navbar: false,
      toolbar: false,
      title: false
    });

    img.addEventListener('hidden', () => viewer.destroy());
    viewer.show();

    $('.viewer-close')
      .removeClass('viewer-close viewer-button')
      .addClass('viewerClose viewer-button');

    return viewer;
  }

  public showByUrl(src: string): void {
    const imageElement: HTMLImageElement = new Image();

    imageElement.src = src;
    this.loadingService.enable();

    imageElement.onload = () => {
      this.loadingService.disable();
      this.show(imageElement);
    };

    imageElement.onerror = () => {
      this.loadingService.disable();
    };
  }

  public showByType(url: string, type: string): void {
    if (_.includes(type, 'image/')) {
      this.showByUrl(url);
    } else if (type === 'application/pdf') {
      window.open(url, '_blank');
    } else {
      type = _.includes(type, 'video/') ? 'video' : 'audio';

      this.modalController.create(<any>{
        component: MediaViewerComponent,
        cssClass: 'media-screen-modal',
        componentProps: {URL: url, type}
      }).then((element: HTMLIonModalElement) => {
        element.present();
      });
    }
  }

  public openGallery(attachments: { objectID: number; mediaType: string }[], index: number = 0) {
    attachments = this.filterAttachments(attachments);
    this.modalController.create({
      component: ImageGalleryComponent,
      componentProps: {
        images: _.map(attachments, (attachmentsItem) => ({
          url: this.commsService.objectURI(attachmentsItem.objectID, false),
          thumbnailUrl: this.commsService.objectURI(attachmentsItem.objectID, true),
          mediaType: attachmentsItem.mediaType
        })),
        index
      },
      cssClass: 'image-gallery-modal'
    }).then((modal) => {
      modal.present();
    });
  }

  public filterAttachments(attachments) {
    return _.filter(attachments, (attachmentsItem) => _.includes(['video', 'image'], _.split(attachmentsItem?.mediaType, '/')[0]) && attachmentsItem?.objectID as boolean);
  }

}
