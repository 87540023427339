<ion-content>
  <app-dashboard-header [headerSetting]="headerSetting">
    <app-date-selector (onSelectDate)="onSelectDate($event)"></app-date-selector>
  </app-dashboard-header>

  <div class="content-block">
    <div class="wide chart-widgets">
      <app-chart-widget (click)="renderTable(config.id)" *ngFor="let config of widgetConfigs"
                        [class.active-widget]="currentActivatedTableId === config.id" [config]="config"></app-chart-widget>
    </div>
    <div *ngIf="widgetConfigs.length === 0 && isWidgetLoaded" class="empty-result">
      <div class="title">{{ "DASHPAGES.HOME_title" | translate }}</div>
      <p>{{ "DASHPAGES.HOME_text" | translate }} <a
        href="mailto:support@corvexsafety.com">{{ "DASHPAGES.HOME_text_next" | translate }} </a>
      </p>
    </div>
  </div>

  <div [id]="tableWrapperId" class="table-content"></div>

  <app-auto-page-refresher (onUpdate)="onUpdatePageEvent()" [frequency]="60000"></app-auto-page-refresher>
</ion-content>
