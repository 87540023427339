import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DatePickerPopupComponent } from './../date-picker-popup/date-picker-popup.component';
import { UtilsService } from '@services/utils/utils.service';
import { Select2Component } from '@shared/components/ng2-select2/ng2-select2.component';
import { Select2OptionData } from '@shared/components/ng2-select2/ng2-select2.interface';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SubscriberService } from '@services';

export enum Select2Type {
  Today = 'today',
  PastDay = 'pastDay',
  PastWeek = 'pastWeek',
  Past2Week = 'past2Week',
  PastMonth = 'pastMonth',
  Past7Days = '7days',
  Past14Days = '14days',
  Past30Days = '30days',
  Past60Days = '60days',
  Past90Days = '90days',
  Past365Days = '365days',
  ThisWeek = 'thisweek',
  LastWeek = 'lastweek',
  ThisMonth = 'thismonth',
  LastMonth = 'lastmonth',
  ThisFiscalQuarter = 'thisfiscalquarter',
  ThisQuarter = 'thisquarter',
  LastFiscalQuarter = 'lastfiscalquarter',
  LastQuarter = 'lastquarter',
  ThisFiscalYear = 'thisfiscalyear',
  ThisYear = 'thisyear',
  LastFiscalYear = 'lastfiscalyear',
  LastYear = 'lastyear',
  Custom = 'custom',
  All = 'all'
}

interface Select2OptionDataExtended extends Select2OptionData {
  periods?: string[];
  id: any;
  text: any;
  enabled?: () => boolean;
}

export interface DatePickerConfig {
  value?: Select2Type;
  options?: Select2Options;
  data?: Select2OptionDataExtended[];
  types?: Select2Type[];
  range?: {
    startTime: number;
    endTime: number;
  };
}

export interface DatePickerObject {
  type: Select2Type;
  range: {
    startTime: number;
    endTime: number;
  };
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent extends CustomFormComponent implements AfterViewInit {

  @ViewChild(Select2Component) selectComponent: Select2Component;
  @Output() onSelect: EventEmitter<DatePickerObject> = new EventEmitter<DatePickerObject>();
  @Input() fieldName: string;
  public config: DatePickerConfig = {
    options: {
      minimumResultsForSearch: Infinity,
      sorter: (data) => data
    },
    data: []
  };
  public currentModel: Select2Type = Select2Type.All;
  private range: { startTime: number; endTime: number } = {startTime: null, endTime: null};
  private readonly types: Select2OptionDataExtended[] = [
    {
      id: Select2Type.ThisWeek,
      text: this.translate.instant('SHARED.This_Week'),
      periods: ['days']
    },
    {
      id: Select2Type.LastWeek,
      text: this.translate.instant('SHARED.Last_Week'),
      periods: ['days']
    },
    {
      id: Select2Type.ThisMonth,
      text: this.translate.instant('SHARED.This_Month'),
      periods: ['weeks']
    },
    {
      id: Select2Type.LastMonth,
      text: this.translate.instant('SHARED.Last_Month'),
      periods: ['weeks']
    },
    {
      id: Select2Type.ThisFiscalQuarter,
      text: this.translate.instant('SHARED.This_Fiscal_Quarter'),
      periods: ['months'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: Select2Type.ThisQuarter,
      text: this.translate.instant('SHARED.This_Quarter'),
      periods: ['months']
    },
    {
      id: Select2Type.LastFiscalQuarter,
      text: this.translate.instant('SHARED.Last_Fiscal_Quarter'),
      periods: ['months'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: Select2Type.LastQuarter,
      text: this.translate.instant('SHARED.Last_Quarter'),
      periods: ['months']
    },
    {
      id: Select2Type.ThisFiscalYear,
      text: this.translate.instant('SHARED.This_Fiscal_Year'),
      periods: ['months'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: Select2Type.ThisYear,
      text: this.translate.instant('SHARED.This_Year'),
      periods: ['months']
    },
    {
      id: Select2Type.LastFiscalYear,
      text: this.translate.instant('SHARED.Last_Fiscal_Year'),
      periods: ['months'],
      enabled: () => this.subscriber.isFiscalPeriodEnabled()
    },
    {
      id: Select2Type.LastYear,
      text: this.translate.instant('SHARED.Last_Year'),
      periods: ['months']
    },
    {
      id: Select2Type.Custom,
      text: this.translate.instant('SHARED.Custom'),
      periods: []
    },
    {
      id: Select2Type.Today,
      text: this.translate.instant('SHARED.Today'),
      periods: ['days']
    },
    {
      id: Select2Type.PastDay,
      text: this.translate.instant('SHARED.Yesterday'),
      periods: ['days']
    },
    {
      id: Select2Type.PastWeek,
      text: this.translate.instant('SHARED.7_Days'),
      periods: ['days']
    },
    {
      id: Select2Type.Past2Week,
      text: this.translate.instant('SHARED.14_Days'),
      periods: ['days']
    },
    {
      id: Select2Type.PastMonth,
      text: this.translate.instant('SHARED.30_Days'),
      periods: ['weeks']
    },
    {
      id: Select2Type.Past7Days,
      text: this.translate.instant('SHARED.7_Days'),
      periods: ['days']
    },
    {
      id: Select2Type.Past14Days,
      text: this.translate.instant('SHARED.14_Days'),
      periods: ['weeks']
    },
    {
      id: Select2Type.Past30Days,
      text: this.translate.instant('SHARED.30_Days'),
      periods: ['weeks']
    },
    {
      id: Select2Type.Past60Days,
      text: this.translate.instant('SHARED.60_Days'),
      periods: ['weeks']
    },
    {
      id: Select2Type.Past90Days,
      text: this.translate.instant('SHARED.90_Days'),
      periods: ['months']
    },
    {
      id: Select2Type.Past365Days,
      text: this.translate.instant('SHARED.365_days'),
      periods: ['months']
    },
    {
      id: Select2Type.All,
      text: this.translate.instant('SHARED.ALL_TIME'),
      periods: ['months']
    }
  ];

  constructor(
    private translate: TranslateService,
    private popoverController: PopoverController,
    private utilsService: UtilsService,
    private subscriber: SubscriberService
  ) {
    super();
    this.filterFiscalYearTimeSpans();
  }

  private filterFiscalYearTimeSpans() {
    _.remove(this.types, (span) => span.enabled && !span.enabled());
  }

  @Input() set dateConfig(config: DatePickerConfig) {
    if (config) {
      _.assign(this.config, config);

      this.defineData();
      this.updateDateRangeValueBy(this.config.value, this.config.range);
    }
  }

  ngAfterViewInit() {
    this.defineData();

    setTimeout(() => {
      this.initOpenListener();
    });
  }

  public onChange(selection: { value: Select2Type }) {
    this.config.value = selection.value;

    if (selection.value === Select2Type.Custom) {
      this.popoverController.create({
        component: DatePickerPopupComponent,
        animated: false,
        componentProps: {
          filterObject: this.range,
          silentMode: true
        }
      }).then((element: HTMLIonPopoverElement) => {
        element.present();

        element.onDidDismiss().then((result) => {
          if (result.data) {
            if (!this.range.startTime && !this.range.endTime) {
              this.range = {
                startTime: moment().startOf('day').valueOf(),
                endTime: moment().endOf('day').valueOf()
              };
            }

            this.formValue = {
              type: selection.value,
              range: _.clone(this.range)
            };
            this.onSelect.emit(this.formValue);

            this.syncCustomSelection(selection.value, this.range);
          }
        });
      });
    } else {
      this.formValue = {
        type: selection.value,
        range: this.utilsService.timespan(selection.value)
      };
      this.onSelect.emit(this.formValue);

      this.syncCustomSelection(selection.value);
    }
  }

  public syncCustomSelection(value: string, filterObject?: { startTime: number; endTime: number }): void {
    this.updateDateRangeValueBy(value, filterObject);
    this.selectComponent.value = value;

    this.selectComponent.ngOnDestroy();
    this.selectComponent.ngOnInit();
    this.selectComponent.ngAfterViewInit();
  }

  private updateDateRangeValueBy(value: string, range?: { startTime: number; endTime: number }) {
    const customSelection: any = _.find(this.config.data, {id: Select2Type.Custom});

    if (customSelection) {
      if (value === Select2Type.Custom && range) {
        const format = 'MM/DD/YYYY';
        customSelection.text = `${moment(range.startTime).format(format)} - ${moment(range.endTime).format(format)}`;
      } else {
        customSelection.text = this.translate.instant('SHARED.Custom');
      }
    }
  }

  private initOpenListener() {
    $(this.selectComponent.selector.nativeElement).off('select2:open').on('select2:open', () => {
      $('.select2-results').off('mouseup').on('mouseup', () => {
        if (this.selectComponent.value === Select2Type.Custom) {
          this.onChange({value: this.selectComponent.value});
        }
      });
    });
  }

  private defineData() {
    if (_.isEmpty(this.config.data)) {
      const types = this.config.types || [
        Select2Type.ThisWeek,
        Select2Type.LastWeek,
        Select2Type.ThisMonth,
        Select2Type.LastMonth,
        Select2Type.ThisFiscalQuarter,
        Select2Type.ThisQuarter,
        Select2Type.LastFiscalQuarter,
        Select2Type.LastQuarter,
        Select2Type.ThisFiscalYear,
        Select2Type.ThisYear,
        Select2Type.LastFiscalYear,
        Select2Type.LastYear,
        Select2Type.Custom
      ];

      this.config.data = <any>_.filter(_.map(types, (type: Select2Type) => _.find(this.types, {id: type})));
    }

    if (_.isNull(this.range.startTime) && _.isNull(this.range.endTime) && this.config.range) {
      this.range = _.clone(this.config.range);
    }

    this.defineValue();
  }

  private defineValue(): void {
    if (this.config.value) {
      this.currentModel = this.config.value;
    }

    if (!_.find(this.config.data, {id: this.currentModel})) {
      const selectedByDefault = _.find(this.config.data, {id: 'all'}) || _.first(this.config.data) || {};
      const firstSelectDataId: Select2Type = _.get(selectedByDefault, 'id');

      if (firstSelectDataId) {
        this.currentModel = firstSelectDataId;
      }
    }

    this.config.value = this.currentModel;
    const range = this.config.value === Select2Type.Custom && this.config.range ? _.clone(this.config.range) : this.utilsService.timespan(this.currentModel);
    this.formValue = {type: this.currentModel, range};
  }

}
