import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import {
  IPopoverConfig,
  PopoverTemplateComponent
} from '@shared/components/popoverTemplate/popover-template.component';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {

  private isActive: boolean;

  constructor(private popoverController: PopoverController) {
  }

  public show(config: IPopoverConfig): Promise<void> {
    if (!this.isActive) {
      return this.popoverController.create({
        component: PopoverTemplateComponent,
        animated: false,
        componentProps: {config}
      }).then((element: HTMLIonPopoverElement) => {
        this.isActive = true;
        element.onDidDismiss().then(() => {
          this.isActive = false;
        });
        return element.present();
      });
    } else {
      return Promise.resolve();
    }
  }

  public hide(): void {
    if (this.isActive) {
      this.popoverController.dismiss();
      this.isActive = false;
    }
  }
}
