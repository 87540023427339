import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-removal-panel',
  templateUrl: './removal-panel.component.html',
  styleUrls: ['./removal-panel.component.scss'],
})
export class RemovalPanelComponent {

  @Input() title: string;
  @Input() removeButtonTitle: string;
  @Input() subTitle: string;
  @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();
  @Input() titleMask: string;

  constructor(private translate: TranslateService) {
  }

  @Input('count') set count(value: number) {
    if (_.isNumber(value) && this.titleMask) {
      this.title = `${value} ${this.translate.instant('SHARED.Selected')}`;
      this.subTitle = `${this.translate.instant(this.titleMask)}:`;
    }
  }

  public remove(): void {
    this.onRemove.emit();
  }

}
