import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import {
  FormBuilderService, SettingsService, UserdataService,
  SubscriberService, CommsService, ChecksService, CheckResponseService,
  LoadingService
} from '@services';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Events } from '@services/events/events.service';

@Component({
  selector: 'app-unable-to-perform-reason',
  templateUrl: './unable-to-perform-reason.page.html',
  styleUrls: ['./unable-to-perform-reason.page.scss'],
})
export class UnableToPerformReasonPage implements OnInit {

  public tableElementArr: any = [];
  private responseID: string;
  private bulkResponseArr: any;
  private bulkReq = false;
  private formId = 'reasonEditForm';
  private saveButtonId = 'reasonEditSave';
  private cancelButtonId = 'reasonEditCancel';
  private formConfig: any = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: 'reasonEdit',
    save: this.translate.instant('MGMT_DETAILS.Update_Status'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      {
        title: this.translate.instant('SHARED.Reason'),
        name: 'reason',
        type: 'selectmenu',
        required: true,
        containerClass: 'observation beacon proximity',
        multiple: false,
        placeholder: this.translate.instant('MGMT_DETAILS.Choose_Reason'),
        options: this.settingsService.getSettingSync('checkReason'),
        valueProperty: 'messageID',
        test: (ref) => !_.get(ref, 'disabledAt'),
        func: (assetType: any) => {
          let value: string = assetType.messageTitle;

          const currentLanguage: string = this.userDataService.getLanguage();
          if (currentLanguage !== this.subscriberService.getDefaultLanguage()) {
            const translatedValue: any = _.find(assetType.translations, ['language', currentLanguage]);

            if (translatedValue) {
              value = translatedValue.value;
            }
          }

          return value;
        },
        originalOrder: true
      },
      {
        name: 'notes',
        inputtype: 'verbatim',
        title: this.translate.instant('MGMT_DETAILS.Notes'),
        type: 'textarea',
        size: 100,
      },
    ]
  };

  constructor(private activatedRoute: ActivatedRoute,
              private logger: NGXLogger,
              private checkDetailService: CheckDetailService,
              private formBuilderService: FormBuilderService,
              private translate: TranslateService,
              private location: Location,
              private elementRef: ElementRef,
              private settingsService: SettingsService,
              private userDataService: UserdataService,
              private subscriberService: SubscriberService,
              private comms: CommsService,
              private checksService: ChecksService,
              private router: Router,
              private events: Events,
              private loadingService: LoadingService,
              private checkResp: CheckResponseService) {
  }


  ngOnInit() {
    const data = {
      reason: null,
      notes: ''
    };
    this.formBuilderService.showForm(`#${this.formId}`, this.formConfig, data);
    this.elementRef.nativeElement.querySelector(`#${this.cancelButtonId}`)
      .addEventListener('click', () => {
        this.cancel();
      });
    this.elementRef.nativeElement.querySelector(`#${this.saveButtonId}`)
      .addEventListener('click', () => {
        this.saveReason();
      });
  }

  public skipResponse(rid: string, reasonCode: number, skipNotes): Promise<boolean | string> {
    return new Promise((resolve, reject) => {
      this.comms.sendMessage({
        cmd: 'skipResponse',
        responseID: rid,
        skipReason: reasonCode,
        skipNotes: JSON.stringify([skipNotes])
      }, false, false)
        .then(async (data) => {
          if (data && data.reqStatus === 'OK') {
            this.loadingService.enable();
            this.events.publish('ccs:refreshCheckStatus', true);
            await this.checksService.refresh();
            resolve(true);
            this.loadingService.disable();
            if (this.activatedRoute.snapshot.queryParamMap.get('destination')) {
              let d = this.activatedRoute.snapshot.queryParamMap.get('destination');
              this.router.navigate([d]);
            } else {
              this.router.navigate(['/pages/dashboard/supervisor-dashboard']);
            }
          } else {
            reject(data.statusText);
          }
        })
        .catch(err => {
          this.logger.error('skipResponse call failed: ', err);
          resolve(false);
        });
    });
  }

  public isFormChanged(): boolean {
    return this.formBuilderService.isFormChanged(`#${this.formId}`);
  }

  async ionViewWillEnter() {
    await this.loadingService.enable();
    const elemObj = [];
    this.responseID = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.activatedRoute.snapshot.queryParamMap.get('bulkReq')) {
      this.bulkReq = true;
      this.bulkResponseArr = this.checkDetailService.unableCheckResponseArr;

      const bulkResponseData = await Promise.all(_.map(this.bulkResponseArr, (id) => {
        return this.checkDetailService.getTopComponentData(id);
      }));

      _.each(bulkResponseData, (headerData) => {
        const tableElement = _.filter(headerData.headerElements, data => {
          if (data.name === 'SHARED.ASSIGNED_TO') {
            if (_.isArray(data.data)) {
              const nameArr = [];
              _.each(data.data, nameObj => {
                nameArr.push(nameObj.data + ' ');
              });
              data.data = nameArr;
            }
          }
          if (data.name !== 'SHARED.Deployment_Name') {
            return true;
          }
        });
        elemObj.push(tableElement);
      });
    } else {
      const headerData = await this.checkDetailService.getTopComponentData(this.responseID);
      const tableElement = _.filter(headerData.headerElements, data => {
        if (data.name === 'SHARED.ASSIGNED_TO') {
          const nameArr = [];
          if (_.isArray(data.data)) {
            _.each(data.data, nameObj => {
              nameArr.push(nameObj.data + ' ');
            });
            data.data = nameArr;
          }
        }
        if (data.name !== 'SHARED.Deployment_Name') {
          return true;
        }
      });
      elemObj.push(tableElement);
    }
    this.loadingService.disable();
    this.tableElementArr = elemObj;
  }

  private async saveReason() {
    const formData: any = this.formBuilderService.getFormData($('#reasonEditForm'));
    // this should go to main supervisor task board after this
    const noteObj = {
      type: 'text',
      value: formData.notes
    };
    if (!this.bulkReq) {
      this.skipResponse(this.responseID, +formData.reason, noteObj);
    } else {
      this.loadingService.enable();
      const reqObj = {
        cmd: 'skipResponse',
        responses: JSON.stringify(this.bulkResponseArr),
        skipReason: +formData.reason,
        skipNotes: JSON.stringify([noteObj])
      };
      try {
        await this.comms.sendMessage(reqObj, false, false);
        this.events.publish('ccs:refreshCheckStatus', true);
        this.loadingService.disable();
        if (this.activatedRoute.snapshot.queryParamMap.get('destination')) {
          this.router.navigate([this.activatedRoute.snapshot.queryParamMap.get('destination')]);
        } else {
          this.router.navigate(['/pages/dashboard/supervisor-dashboard']);
        }
      } catch (err) {
        this.logger.log(err);
        this.loadingService.disable();
      }
    }
  }

  private cancel(): void {
    this.location.back();
  }

}
