<ion-header no-border>
  <app-close-button (onClicked)="back()"></app-close-button>
</ion-header>

<ion-content>
  <div class="container">
    <ngu-carousel #myCarousel [dataSource]="carouselTileItems$ | async" [inputs]="carouselTileConfig"
                  class="custom-carousel">
      <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate">
        <div class="tile-image">
          <img alt="Slide Image" src="{{item.image}}">
        </div>
        <div class="tile-content">
          <h3>{{item.header}}</h3>
          <p>{{item.description}}</p>
        </div>
        <ul NguCarouselPoint class="ul-my-point">
          <li (click)="myCarousel.moveTo(i)"
              *ngFor="let point of myCarousel.pointNumbers; let i = index" [class.active]="point==myCarousel.activePoint"></li>
        </ul>
        <button (click)="clickNextFun(0)" *ngIf="i < 3; else elseBlock"
                class="primary-button">{{ "SHARED.Next" | translate }}</button>
        <ng-template #elseBlock>
          <button (click)="clickNextFun(1)" class="primary-button">{{ "SHARED.I_M_DONE" | translate }}</button>
        </ng-template>
      </ngu-tile>
      <button NguCarouselNext [style.opacity]="myCarousel.isLast ? 0.5:1" hidden id="buttonNextClick"></button>
    </ngu-carousel>

  </div>
</ion-content>
