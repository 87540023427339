<app-close-button [isModal]="true"></app-close-button>

<video *ngIf="type === 'video'; else audioBlock" controls>
  <source [src]="URL">
</video>

<ng-template #audioBlock>
  <audio controls preload="metadata">
    <source [src]="URL" type="audio/mp4">
  </audio>
</ng-template>
