import { Component, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { CheckResponseService, ChecksService, LoadingService, SettingsService, ViewerService } from '@services';
import { NotePopupComponent } from '@modules/observation/components';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { AbstractObservationDetailPage } from '@modules/observation/pages/abstract-observation-detail-page/abstract-observation-detail-page';
import { QuestionService } from '@modules/management/pages/details/check/services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { split } from 'lodash';
import { Observation } from '@services/observations/observation.interfaces';
import { IResponse } from '@modules/management/pages/details/check/models';

@Component({
  selector: 'app-ca-detail',
  templateUrl: './ca-detail.page.html',
  styleUrls: ['./ca-detail.page.scss'],
})

export class CaDetailPage extends AbstractObservationDetailPage implements OnDestroy {

  // THESE DATA ARE DISPLAYED ON FRONT END
  public uuid: string = null;
  public oid: number = null; // oid
  public bottomComponentData: any; // bottom back/front nav, table nav
  public headerData: any = {}; // data for top component, loc, zone, user etc
  public closingData: any = {}; // data for closing component
  public images = []; // observation images
  public notes: any = {}; // observation notes, audio + test
  public footerButtons = []; // button displayed on footer
  public bottomNav = false;
  public footerData: any = {};
  public headerIsHidden: string;
  public caDetail: any = {};
  public state: any = '';
  public tags: number[] = [];
  public navFromSuperBoard = false;
  public superTaskBack: any;
  public tagsObjs: any = [];
  public categoryObjs: any = [];
  private navFromMainSuperPage: boolean;
  public observationObject: Observation = {} as Observation;
  private targetSignature: string;
  private isImageLoading = false;
  private hasOwner = false;  // to check if there is owner already or not for back nav.
  private flagObject: any = {};
  private checkType = '';
  private response: IResponse;

  constructor(
    private observationDetailService: ObservationDetailService,
    private popover: PopoverController,
    private loadingService: LoadingService,
    private viewer: ViewerService,
    private router: Router,
    private checkDetailService: CheckDetailService,
    private questionService: QuestionService,
    private checksService: ChecksService,
    private checkResponseService: CheckResponseService,
    private translate: TranslateService,
    protected injector: Injector,
    private settingsService: SettingsService
  ) {
    super(injector);
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.noteListener);
    this.events.unsubscribe('ccs:assignUpdate', this.assignListener);
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:checkResponseUpdate', this.checkObservationListener);

    this.events.unsubscribe('ccs:observationUpdate', this.updateObservationListener);
  }

  async ionViewWillEnter() {
    // get observations
    this.uuid = this.activatedRoute.snapshot.paramMap.get('id');
    this.headerIsHidden = this.activatedRoute.snapshot.queryParamMap.get('headerIsHidden');
    this.navFromSuperBoard = this.activatedRoute.snapshot.queryParamMap.get('superTaskBoard') === 'true'; // from check result table
    this.navFromMainSuperPage = this.activatedRoute.snapshot.queryParamMap.get('supervisorTaskBoard') === 'true'; // from open observations.
    // passing where to return after resolving into detail service function ( could be main ca tables, open observations, or superBoard)
    if (this.navFromMainSuperPage) {
      this.observationDetailService.resolveFromSupervisor = true;
    }
    this.checkType = this.activatedRoute.snapshot.queryParamMap.get('checkType');
    this.targetSignature = this.activatedRoute.snapshot.queryParamMap.get('targetSignature');
    if (this.activatedRoute.snapshot.queryParamMap.get('tableNav') === 'true') {
      this.bottomNav = true;
    }

    this.events.subscribe('ccs:noteUpdate', this.noteListener);

    this.events.subscribe('ccs:assignUpdate', this.assignListener);

    this.events.subscribe('ccs:observationUpdate', this.updateObservationListener);

    // to make sure this observation actually exists, lets make a call for this observation.
    this.updateObservationListener();
    this.loadingService.disable();

  }

  async prepareDataSet() {
    // get question and answer stuff
    if (!this.flagObject) {
      return;
    }
    // find the question
    const questionOptions = this.questionService.getQuestionOptions(this.flagObject.questionID);
    const questionObject = this.questionService.getQuestion(this.flagObject.questionID);
    if (this.checkDetailService.isUUID(this.flagObject.response)) {
      const questionOption = questionOptions[this.flagObject.response];
      this.caDetail.answer = this.checkDetailService.translateData(questionOption);
    } else {
      if (this.flagObject.responseValue && this.flagObject.responseValue.length) {
        this.caDetail.answer = this.flagObject.responseValue[0].value;
      } else {
        let rString = this.flagObject.response;
        if (this.flagObject.response == 'pass') {
          rString = this.translate.instant('MGMT_DETAILS.Pass');
        } else if (this.flagObject.response == 'fail') {
          rString = this.translate.instant('MGMT_DETAILS.Fail');
        }
        this.caDetail.answer = rString;
      }
    }

    const checkData = this.checksService.getCheck(this.flagObject.checkID);
    // get additional headerData
    // check if we have target and types already, if not, push.
    if (!_.find(this.headerData.headerElements, {name: 'OTable.TARGET'})) {
      this.headerData.headerElements.push(this.checkDetailService.targetFullColumn(this.response || {}));
    }

    if (!_.find(this.headerData.headerElements, {name: 'SHARED.TYPE'})) {
      this.headerData.headerElements.push(this.checkDetailService.type(checkData.checkType));
    }

    this.caDetail.question = this.checkDetailService.translateData(questionObject);

    this.caDetail.name = this.checkDetailService.translateData(checkData);
    this.caDetail.deployment = await this.checkResponseService.getDeploymentByResponseID(this.flagObject.responseID);
    this.notes = this.observationDetailService.getCaNotes(this.oid);
  }

  public addOpenNote(): void {
    this.popover.create({
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'created'
      }
    } as any).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addClosedNote(): void {
    this.popover.create({
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'resolved'
      }
    } as any).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public zoomThis(src: string): void {
    if (!this.isImageLoading) {
      this.isImageLoading = true;
      this.loadingService.enable();

      const img: HTMLImageElement = new Image();
      img.src = src.replace('/thumb', '');
      img.onload = () => {
        this.loadingService.disable();
        this.isImageLoading = false;

        this.viewer.show(img);
      };
    }
  }

  public addFixIdea() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'fixIdea'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addGeneralIdea() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'generalIdea'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private checkObservationListener = async () => {
    this.loadingService.disable();
    this.flagObject = await this.checkResponseService.getResponseByObservationID(this.oid);
    await this.prepareDataSet();
  };

  private noteListener = (data) => {
    if (data) {
      this.notes = this.observationDetailService.getCaNotes(this.oid);
    }
  };

  private assignListener = (data) => {
    if (data) {
      // go back to main page after assigining.
      this.headerData = this.observationDetailService.getTopComponentData(this.oid);
      const checkData = this.checksService.getCheck(this.flagObject.checkID);
      // get additional headerData
      this.headerData.headerElements.push(this.checkDetailService.type(checkData.checkType));
      this.headerData.headerElements.push(this.checkDetailService.targetFull(this.flagObject));
    }
  };

  private updateObservationListener = async () => {
    const data = await this.observationService.getObservationByUUID(this.uuid);
    if (data && data.observationID) {
      this.observationObject = data;
      this.oid = data.observationID;
      this.tags = JSON.parse(data.caTags);
      this.categoryObjs = this.collectCategories(data.caCategory);
      this.state = data.state;
      this.observationDetailService.checkObservationType(this.oid, 'ca');
      const theResponse: any = await this.checkResponseService.getResponseAsync(data.responseID);
      this.response = theResponse;
      if (!_.isEmpty(theResponse.answers)) {
        const r: any = _.find(theResponse.answers, {observationID: this.oid});
        if (r) {
          r.responseID = data.responseID;
          r.checkID = theResponse.checkID;
          r.checkType = theResponse.checkType;
          this.flagObject = r;
        }
      }
      this.headerData = this.observationDetailService.getTopComponentData(this.oid);
      this.images = this.observationDetailService.getImages(this.oid);
      // get Ca details
      // get additional headerData
      // if obs type resolved, add resolveduration too
      if (this.state === 'resolved') {
        this.headerData.headerElements.push(this.checkDetailService.resolvedDuration(data.history));
      }
      await this.prepareDataSet();
      if (this.headerData.buttonPanel) {
        this.footerButtons = this.headerData.buttons;
      }

      if (data.ownerID) {
        this.hasOwner = true;
      }

      // if coming from table then only show prev-back nav.
      // blue nav bar depending whether its coming from the table list.
      if (this.bottomNav) {
        if (this.navFromSuperBoard) {
          // we have to set footer data nav based on the OIDS on check-result-table
          this.footerData = this.observationDetailService.getFooterData(this.oid);
          // if we are coming fro supervisor task board, we need back nav to board, and app-close on the top
          this.footerData.nav = `pages/dashboard/check-result-table/${this.checkType}/${this.targetSignature}`;
          this.footerData.activeTab = 'corrective';
          const queryParamsNav: any = {
            superTaskBoard: true,
            checkType: this.checkType,
            targetSignature: this.targetSignature,
            headerIsHidden: true
          };
          if (!this.footerData.next.disabled) {
            this.footerData.next.qparam = queryParamsNav;
          }
          if (!this.footerData.prev.disabled) {
            this.footerData.prev.qparam = queryParamsNav;
          }

          this.footerData.name = 'ODetail.All_Corrective_Actions';
          const queryParams: any = {
            activeTab: 'corrective'
          };
          this.observationDetailService.resolveToResultTable = true;
          this.observationDetailService.resolveFromCheckResultTable = () => this.router.navigate([`pages/dashboard/check-result-table/${this.checkType}/${this.targetSignature}`], {queryParams});
          this.superTaskBack = () => this.router.navigate([`pages/dashboard/check-result-table/${this.checkType}/${this.targetSignature}`], {queryParams});
        } else {
          this.checkFooterData();
        }

      }
    } else {
      this.observationDetailService.checkObservationType(null, 'ca');
    }
  };

  private collectCategories(data) {
    const catsList = split(data, ':');
    let item: any;
    if (catsList[0] === 'condition') {
      item = this.settingsService.getItem('categories', catsList[1], true);
    }

    if (catsList[0] === 'pi') {
      item = this.settingsService.getItem('piCats', catsList[1], true);
    }


    if (catsList[0] === 'quality') {
      item = this.settingsService.getItem('qualityCats', catsList[1], true);
    }


    if (item && item.messageTitle) {
      return item.messageTitle;
    }
  }

}
