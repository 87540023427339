import { Injectable } from '@angular/core';

import { TableColumn } from '@services/table/table.service';
import { environment } from '@env';

import * as uuid from 'uuid';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataTableNavigationService {

  public currentIdsList: string[] = [];
  public lastActiveUrl: string;

  constructor() {
  }

  public registerCustomSortProvider(dataTableOptions: DataTables.Settings): void {
    const customSortKey: string = uuid.v4();

    $.fn.dataTable.ext.afnSortData[customSortKey] = (settings) => {
      let dataItems = _.map(_.cloneDeep(settings.aoData), (dataItem) => {
        const data = dataItem._aData;
        data.idx = dataItem.idx;

        if (settings?.oInit?.rowvaluefrom) {
          data.dataMid = dataItem._aData[settings.oInit.rowvaluefrom];
        } else if (dataItem.nTr) {
          data.dataMid = dataItem.nTr.getAttribute('data-mid');
        }

        return data;
      });

      dataItems = this.sortTableData(dataItems, settings.aaSorting, dataTableOptions.columns);
      settings.aiDisplayMaster = _.map(dataItems, 'idx');
      this.currentIdsList = _.map(dataItems, 'dataMid');

      return settings;
    };

    this.addCustomSortField(dataTableOptions, customSortKey);
  }

  public getCachedOrder(tableId: string, pageUrl: string): any[] {
    let baseHref: string = environment.baseHref;

    if (_.includes(baseHref, '/')) {
      baseHref = _.replace(baseHref, /\//g, '');
      if (baseHref) {
        baseHref += '/';
      }
    }

    return _.get(JSON.parse(localStorage.getItem(`DataTables_${tableId}_/${baseHref}${pageUrl}`)), 'order', []);
  }

  public sortTableData(dataItems: any[], order: any[], columns?: DataTables.ColumnSettings[] | TableColumn[]) {
    if (order.length && dataItems.length) {
      if (columns?.length) {
        dataItems = _.orderBy(dataItems, this.getOrderFieldsByColumns(dataItems, order, columns), this.getOrder(order));
      } else {
        dataItems = _.orderBy(dataItems, this.getOrderFields(order), this.getOrder(order));
      }
    }

    return dataItems;
  }

  public getNextId(currentId: number | string, ids: number[] | string[]): number | string {
    const index = _.indexOf(ids, currentId);
    return index === ids.length - 1 ? null : ids[index + 1];
  }

  public getPreviousId(currentId: number | string, ids: number[] | string[]): number | string {
    const index = _.indexOf(ids, currentId);
    return index === 0 ? null : ids[index - 1];
  }

  public getOrderFieldsByColumns(dataItems: any[], order: any[], columns: DataTables.ColumnSettings[]): any[] {
    const sorts: any[] = [];
    const isDataTableColumn = this.isDataTableColumn(columns[0]);
    const sortKeyMap = {
      fieldValue: isDataTableColumn ? 'data' : 'id',
      fieldValueRender: isDataTableColumn ? 'render' : 'func',
    };

    _.map(order, (orderItem: any) => {
      const sortField: any = _.get(columns[orderItem[0]], sortKeyMap.fieldValue);
      const sortFieldRender: any = _.get(columns[orderItem[0]], sortKeyMap.fieldValueRender);
      const cellVal = _.get(columns[orderItem[0]], 'cellval');

      if (_.isString(sortField)) {
        const dataItem: any = _.find(dataItems, `${sortField}`);

        if (_.isString(_.get(dataItem, sortField)) || _.isNumber(_.get(dataItem, sortField))) {
          sorts.push((item) => this.getOrderItemValue(item[sortField]));
        } else if (sortFieldRender) {
          sorts.push((item) => this.getOrderItemValue(sortFieldRender(item[sortField], isDataTableColumn ? 'sort' : item)));
        } else if (cellVal) {
          if (_.isString(cellVal)) {
            sorts.push((item) => this.getOrderItemValue(item[cellVal]));
          } else {
            sorts.push((item) => this.getOrderItemValue(cellVal(item[sortField], item)));
          }
        }
      } else {
        const dataItem = _.find(dataItems, (dataItem) => sortField(dataItem));
        const data = sortField(dataItem);

        if (_.isString(data) || _.isNumber(data)) {
          sorts.push((item) => this.getOrderItemValue(sortField(item)));
        } else {
          sorts.push((item) => this.getOrderItemValue(sortFieldRender(item, isDataTableColumn ? 'sort' : item)));
        }
      }
    });

    return sorts;
  }

  public getOrder(order): any[] {
    return _.map(order, (orderItem) => orderItem[1]);
  }

  public getOrderItemValue(value: any): any {
    value = _.isNumber(value) ? value : _.trim(value);

    if (_.isString(value)) {
      return _.toLower(value);
    }

    if (_.isNull(value)) {
      return -1;
    }

    return value;
  }

  private addCustomSortField(dataTableOptions: DataTables.Settings, customSortKey: string): void {
    const customSortField = {orderDataType: customSortKey, targets: '_all'};
    const existingCustomSortField = _.find(dataTableOptions.columnDefs, 'orderDataType');

    if (!dataTableOptions.columnDefs) {
      dataTableOptions.columnDefs = [];
    }

    if (existingCustomSortField?.orderDataType) {
      existingCustomSortField.orderDataType = customSortKey;
    } else {
      dataTableOptions.columnDefs.push(customSortField);
    }
  }

  private isDataTableColumn(column: DataTables.ColumnSettings | TableColumn): boolean {
    return _.has(column, 'data');
  }

  private getOrderFields(order) {
    return _.map(order, (orderItem) => (item) => {
      const itemValue = item[orderItem[0]];
      const orderValue = _.get(itemValue, '@data-order') || itemValue;

      return _.isFinite(+orderValue) ? +orderValue : orderValue;
    });
  }

}
