<div class="footer-wrapper">
  <div *ngIf="footerData?.name" class="footer-content">
    <span (click)="openBy(footerData.prev)" [class.disabled]="footerData.prev.disabled" class="footer-item">
      <img alt="" src="assets/icons/greater_white.svg">
      <span>{{ "SHARED.Previous" | translate }}</span>
    </span>

    <span (click)="openMainPage()" class="footer-item">
      <img alt="" src="assets/icons/hamburger_white.svg">
      <span>{{footerData.name | translate}}</span>
    </span>

    <span (click)="openBy(footerData.next)" [class.disabled]="footerData.next.disabled" class="footer-item">
      <span>{{ "SHARED.Next" | translate }}</span>
    <img alt="" src="assets/icons/less_white.svg">
    </span>
  </div>
</div>
