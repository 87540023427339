import { Injectable } from '@angular/core';

import { ISelectMenuItem } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { CommsService, Module, SubscriberService } from '@services';

import * as _ from 'lodash';
import { filter, includes, map } from 'lodash';
import { IPropertyMeta } from '@services/assets/asset.interfaces';

export enum LimitType {
  Healthy = 'healthy',
  Strained = 'strained'
}

export enum ThresholdType {
  Toggle = 'boolean',
  Selector = 'selector',
  Ascending = 'ascendingNumeric',
  Descending = 'descendingNumeric'
}

export enum PropertyMethod {
  Latest = 'latest',
  Highest = 'highest',
  Lowest = 'lowest',
  Cumulative = 'cumulative',
  Average = 'average'
}

export interface IThresholdLimit {
  type: LimitType;
  limit: number;
}

export interface IThresholds {
  type: ThresholdType;
  limits: IThresholdLimit[];
}

export enum PropertyStyle {
  Numeric = 'number',
  Text = 'text',
  TextArea = 'textarea',
  Note = 'note',
  Flipswitch = 'flipswitch',
  Duration = 'duration',
  TimeStamp = 'timestamp',
  Measurement = 'measurement',
  Counter = 'counter',
  UpDown = 'upDown',
  UpStrainedDown = 'upStrainedDown'
}

export enum PropertyCategory {
  Text = 'text',
  Numeric = 'number',
  InService = 'asset:inService',
  MissedCheck = 'check:missed',
  SkippedCheck = 'check:skipped',
  CompletedCheck = 'check:completed',
  FailedCheck = 'check:failed',
  PassedCheck = 'check:passed',
  OpenObservations = 'observation:open',
  UnassignedObservations = 'observation:unassigned',
  Zone = 'zone',
}

export enum PropertySource {
  Manual = 'manual',
  Hybrid = 'hybrid',
  Derived = 'derived',
  OverTime = 'overTime',
  Pull = 'pull',
  Push = 'push'
}

export enum PropertySubject {
  Asset = 'asset',
  Worker = 'worker',
  Zone = 'zone',
  Location = 'location'
}

export interface IPropertyEvent {
  time: number;
  userID: number;
  subject: PropertySubject;
  subjectID: string;
  activity: string;
  value: string;
  id: number;
  propertyID: number;
}
export interface IPropertyEventActivity {
  from: string;
  id: number;
  type: string;
}

export type PropertyEventActivity = IPropertyEventActivity | string;

export interface IPropertyHistoryEntry {
  time: number;
  userID: number;
  activity: string;
  notes: string;
}

export interface IProperty {
  propertyID: number;
  lastUpdate: number;
  addedBy: number;
  addedAt: number;
  active: number;
  healthRelated: number;
  thresholds: IThresholds;
  canAggregate: number;
  subjects: PropertySubject[];
  source: PropertySource;
  method: PropertyMethod;
  timespan: number;
  category: PropertyCategory;
  uniqueName: string;
  style: PropertyStyle;
  folderID: number;
  title: string;
  description: string;
  events: IPropertyEvent[];
  history: IPropertyHistoryEntry[];
  disabledAt: number;
  disabledBy: number;
}

export interface IPropertyEventRequestsParams {
  propertyID?: number;
  uniqueName?: string;
  subject?: PropertySubject;
  subjectID?: number;
  activity?: string;
  value?: string;
  events?: IPropertyEventRequestParams[];
}

export interface IPropertyEventRequestParams {
  value: string;
  subject: PropertySubject;
  subjectID: number;
  activity: string;
}

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private properties = {
    lastRequest: null,
    data: {} as IObjectStringKeyMap<IProperty>
  };

  constructor(
    private commsService: CommsService,
    private events: Events,
    private subscriberService: SubscriberService
  ) {}

  public refresh(): Promise<IObjectStringKeyMap<IProperty>> {
    const requestData: any = {
      cmd: 'getProperties',
      lastRequest: this.properties.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.updateCache(response);
      }

      return this.properties.data;
    });
  }

  public updateCache(data) {
    this.properties.data = data.result.properties;
    this.properties.lastRequest = data.result.timestamp;
    this.events.publish('ccs:propertiesUpdate', true);
  }

  public getPropertiesAsList(folderID: number = 0, includeInactive: boolean = false, includeDisabled: boolean = false, sifter?: (item: IProperty) => boolean ): IProperty[] {
    const theList: IProperty[] = [];

    _.each(this.properties.data, (item) => {
      if (!_.isUndefined(folderID) && (item.folderID !== folderID)) {
        return;
      }

      if (!includeInactive && !item.active) {
        return;
      }
      if (!includeDisabled && item.disabledAt) {
        return;
      }
      if (!_.isUndefined(sifter) && !sifter(item)) {
        return;
      }
      theList.push(item);
    });

    return theList;
  }

  public getPropertiesByIds(ids: IPropertyMeta[]): IProperty[] {
    return filter(map(ids, (id) => this.getPropertyById(id.propertyID)));
  }
  public getProperties(): IObjectStringKeyMap<IProperty> {
    return this.properties.data;
  }

  public getPropertyEvents(propertyID: number, subjectID: number, subject: PropertySubject): Promise<IPropertyEvent[]> {
    const requestData = {
      cmd: 'getPropertyEvents',
      propertyID,
      subjectID,
      subject
    };
    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        return response?.result?.events || [];
      }
    });
  }

  public getPropertyById(id: number): IProperty {
    return _.cloneDeep(this.properties.data[id]);
  }

  public addProperty(data: any): Promise<any> {
    const requestData = {
      cmd: 'addProperty',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public async updateProperty(data: any): Promise<any> {
    const requestData = {
      cmd: 'updateProperty',
      ...data
    };
    const result = await this.commsService.sendMessage(requestData, false, false);
    await this.refresh();
    return result;
  }

  public deleteProperty(ids: number[] | number | string): Promise<any> {
    const requestData = {
      cmd: 'deleteProperty',
      properties: _.flatten([ids])
    };

    return this.commsService.sendMessage(requestData, false, false);
  }

  public capturePropertyEvents(params: IPropertyEventRequestsParams): Promise<any> {
    const requestData = {
      cmd: 'capturePropertyEvents',
      sendTime: Date.now(),
      ...params
    };

    return this.commsService.sendMessage(requestData, false, false);
  }

  public getLimitTypeList(): ISelectMenuItem[] {
    return [
      { id: LimitType.Healthy, description: 'PROPERTY.Healthy'},
      { id: LimitType.Strained, description: 'PROPERTY.Strained'},
    ];
  }
  public getThresholdTypeList(category?: PropertyCategory): ISelectMenuItem[] {
    const ret = [];
    if (category === PropertyCategory.InService) {
      ret.push(
        { id: ThresholdType.Selector, description: 'PROPERTY.Selector'},
      );
    } else {
    ret.push (
      { id: ThresholdType.Toggle, description: 'PROPERTY.Boolean'},
      { id: ThresholdType.Ascending, description: 'PROPERTY.Ascending'},
      { id: ThresholdType.Descending, description: 'PROPERTY.Descending'},
    );
    }
    return ret;
  }

  public getCalculationMethodList(limit?: PropertySource): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
      ret.push(
      { id: PropertyMethod.Latest, description: 'PROPERTY.Latest'},
      { id: PropertyMethod.Highest, description: 'PROPERTY.Highest'},
      { id: PropertyMethod.Lowest, description: 'PROPERTY.Lowest'},
      { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
      { id: PropertyMethod.Average, description: 'PROPERTY.Average'},
      );
    } else {
      if (limit === PropertySource.Derived) {
        ret.push(
          { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        );
      } else if (limit === PropertySource.OverTime) {
        ret.push(
          { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        );
      } else {
      ret.push(
        { id: PropertyMethod.Latest, description: 'PROPERTY.Latest'},
        { id: PropertyMethod.Highest, description: 'PROPERTY.Highest'},
        { id: PropertyMethod.Lowest, description: 'PROPERTY.Lowest'},
        { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        { id: PropertyMethod.Average, description: 'PROPERTY.Average'},
      );
      }
    }
    return ret;
  }

  public getSourceList(limit?: string): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
      ret.push(
        { id: PropertySource.Manual, description: 'PROPERTY.Manual' },
        { id: PropertySource.Hybrid, description: 'PROPERTY.Hybrid' },
        { id: PropertySource.Derived, description: 'PROPERTY.Derived' },
        { id: PropertySource.OverTime, description: 'PROPERTY.OverTime' },
        { id: PropertySource.Pull, description: 'PROPERTY.Pull' },
        { id: PropertySource.Push, description: 'PROPERTY.Push' },
      );
    } else {
      if (limit.match(/^check:/)) {
        ret.push(
          { id: PropertySource.OverTime, description: 'PROPERTY.OverTime' },
        );
      } else if (limit.match(/^observation:/)) {
        ret.push(
          { id: PropertySource.Derived, description: 'PROPERTY.Derived' },
        );
      } else if (limit === PropertyCategory.Zone) {
        ret.push({ id: PropertySource.Push, description: 'PROPERTY.Push' });
      } else {
        ret.push(
          { id: PropertySource.Manual, description: 'PROPERTY.Manual' },
          { id: PropertySource.Hybrid, description: 'PROPERTY.Hybrid' },
          { id: PropertySource.Push, description: 'PROPERTY.Push' }
        );
      }
    }

    return ret;
  }
  public getStyleList(limit?: string): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
    ret.push (
      { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      { id: PropertyStyle.Text, description: 'PROPERTY.Text' },
      { id: PropertyStyle.TextArea, description: 'PROPERTY.TextArea' },
      { id: PropertyStyle.Note, description: 'PROPERTY.Note' },
      { id: PropertyStyle.Flipswitch, description: 'PROPERTY.Flipswitch' },
      { id: PropertyStyle.Duration, description: 'PROPERTY.Duration' },
      { id: PropertyStyle.TimeStamp, description: 'PROPERTY.TimeStamp' },
      { id: PropertyStyle.Measurement, description: 'PROPERTY.Measurement' },
      { id: PropertyStyle.Counter, description: 'PROPERTY.Counter' },
    );
    } else if (limit.match(/^check:/)) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      );
    } else if (limit.match(/^observation:/)) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      );
    } else if (limit === PropertyCategory.InService) {
      ret.push (
        { id: PropertyStyle.UpDown, description: 'PROPERTY.UpDown' },
        { id: PropertyStyle.UpStrainedDown, description: 'PROPERTY.UpStrainedDown' },
      );
    } else if (limit === PropertyCategory.Numeric) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
        { id: PropertyStyle.Measurement, description: 'PROPERTY.Measurement' },
        { id: PropertyStyle.Counter, description: 'PROPERTY.Counter' },
      );
    } else if (limit === PropertyCategory.Text) {
      ret.push (
        { id: PropertyStyle.Text, description: 'PROPERTY.Text' },
        { id: PropertyStyle.TextArea, description: 'PROPERTY.TextArea' },
        { id: PropertyStyle.Note, description: 'PROPERTY.Note' },
      );
    }
    return ret;
  }

  public getCategoryList(value?: string[] | string): ISelectMenuItem[] {
    if (_.isUndefined(value)) {
      value = [];
    } else if ( !_.isArray(value)) {
      value = [ value ];
    }

    const cats = [];
    if (!value.length || _.indexOf(value, PropertySubject.Asset) > -1) {
      cats.push( { id: PropertyCategory.InService, description: 'PROPERTY.InService'} );
    }


    // cats.push( // TODO [azyulikov] will be implemented in next release
    //   { id: PropertyCategory.Text, description: 'PROPERTY.Text'},
    // );

    cats.push({ id: PropertyCategory.Numeric, description: 'PROPERTY.Numeric'} );

    if (this.subscriberService.usesModule(Module.CHECKS)) {
      cats.push(
      { id: PropertyCategory.CompletedCheck, description: 'PROPERTY.CompletedCheck'},
      { id: PropertyCategory.MissedCheck, description: 'PROPERTY.MissedCheck'},
      { id: PropertyCategory.SkippedCheck, description: 'PROPERTY.SkippedCheck'},
      { id: PropertyCategory.PassedCheck, description: 'PROPERTY.PassedCheck'},
      { id: PropertyCategory.FailedCheck, description: 'PROPERTY.FailedCheck'},
      );
    }

    if (this.subscriberService.usesModule(Module.OBSERVATIONS)) {
      cats.push(
      { id: PropertyCategory.OpenObservations, description: 'PROPERTY.OpenObservations'},
      { id: PropertyCategory.UnassignedObservations, description: 'PROPERTY.UnassignedObservations'});
    }

    cats.push({
      id: PropertyCategory.Zone,
      description: 'SHARED.Zone'
    });

    return cats;
  }

  public getSubjectList(): ISelectMenuItem[] {
    const subjects = [
      {
        id: PropertySubject.Asset,
        description: 'SHARED.Asset'
      },
      // {
      //   id: PropertySubject.Worker, // TODO [azyulikov] will be implemented in next release
      //   description: 'SHARED.User'
      // },
      // {
      //   id: PropertySubject.Zone,
      //   description: 'SHARED.Zone'
      // },
      // {
      //   id: PropertySubject.Location,
      //   description: 'SHARED.Location'
      // }
    ];
    return subjects;
  }

  public getCategoryName(category: PropertyCategory): string {
    let ret = 'SHARED.None';

    switch (category) {
      case PropertyCategory.Text: {
        ret = 'PROPERTY.Text';
        break;
      }
      case PropertyCategory.Numeric: {
        ret = 'PROPERTY.Numeric';
        break;
      }
      case PropertyCategory.InService: {
        ret = 'PROPERTY.InService';
        break;
      }
      case PropertyCategory.MissedCheck: {
        ret = 'PROPERTY.MissedCheck';
        break;
      }
      case PropertyCategory.SkippedCheck: {
        ret = 'PROPERTY.SkippedCheck';
        break;
      }
      case PropertyCategory.CompletedCheck: {
        ret = 'PROPERTY.CompletedCheck';
        break;
      }
      case PropertyCategory.FailedCheck: {
        ret = 'PROPERTY.FailedCheck';
        break;
      }
      case PropertyCategory.PassedCheck: {
        ret = 'PROPERTY.PassedCheck';
        break;
      }
      case PropertyCategory.OpenObservations: {
        ret = 'PROPERTY.OpenObservations';
        break;
      }
      case PropertyCategory.UnassignedObservations: {
        ret = 'PROPERTY.UnassignedObservations';
        break;
      }
      case PropertyCategory.Zone: {
        ret = 'PROPERTY.Zone';
        break;
      }
    }
    return ret;
  }

  public isEventHistoryAvailable(property: IProperty): boolean {
    return includes([PropertySource.Manual, PropertySource.Derived], property.source);
  }
}
