import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ReorderList, ReorderListItem } from './reorder-list.interfaces';
import { filter } from 'lodash';

@Component({
  selector: 'app-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss'],
})
export class ReorderListComponent {
  @Input() @HostBinding('class.not-clickable') disabled: boolean;
  @Input() config: ReorderList<ReorderListItem>;
  @Output() onReorder: EventEmitter<ReorderListItem[]> = new EventEmitter<ReorderListItem[]>();
  @Output() onClick: EventEmitter<ReorderListItem> = new EventEmitter<ReorderListItem>();
  @Output() onChecked: EventEmitter<ReorderListItem[]> = new EventEmitter<ReorderListItem[]>();

  public doReorder(event): void {
    event.detail.complete(this.config.items);
    this.onReorder.emit(this.config.items);
  }

  public toggleSelection(): void {
    this.onChecked.emit(filter(this.config.items, 'selected'));
  }

}
