import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPage, PreparingPage } from './pages';
import { ObtainConsentPage } from './pages/obtain-consent/obtain-consent.page';
import { SsoPage } from './pages/sso/sso.page';

const routes: Routes = [
  {
    path: '',
    component: LoginPage,
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  },
  {
    path: 'preparing',
    component: PreparingPage
  },
  {
    path: 'obtain-consent',
    data: {
      headerConfig: {
        isHidden: true
      }
    },
    component: ObtainConsentPage
  },
  {
    path: 'sso',
    data: {
      headerConfig: {
        isHidden: true
      }
    },
    component: SsoPage
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
}
