import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';
import { LoginPage, ObtainConsentPage, PreparingPage, SsoPage } from './pages';
import { ControlErrorsDirective } from '../../control-errors.directive';
import { ControlErrorComponent } from '../../control-error/control-error.component';
import { ControlErrorContainerDirective } from '../../control-error-container.directive';
import { FormSubmitDirective } from '../../form-submit.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    SsoPage,
    LoginPage,
    PreparingPage,
    ObtainConsentPage,
    ControlErrorsDirective,
    ControlErrorComponent,
    ControlErrorContainerDirective,
    FormSubmitDirective
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild(),
    MarkdownModule.forChild(),

  ],
  entryComponents: [ControlErrorComponent]
})
export class LoginModule {
}
