<ion-header *ngIf="headerIsHidden" no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content fullscreen>
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>
    <app-detail-controls [headerData]="headerData"></app-detail-controls>

    <!--<app-closing-notes *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-notes>-->
    <app-closing-details [scrollRef]="historyRef" *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-details>

    <!-- IMAGES BLOCK-->
    <div class="detail-page-section">

      <div class="headline-medium">{{ "DASHPAGES.QUALITY_DETAIL_des1" | translate }}</div>

      <image-gallery-modal [inputImages]="images"></image-gallery-modal>

      <!-- quality info block -->
      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col size="3">
              <div class="descriptor">{{ "DASHPAGES.QUALITY_DETAIL_des2" | translate }}</div>
              <div class="adjustment-grid-item">
                <div class="adjustment-grid-value">{{qualityDetail.qualType}}</div>
              </div>
            </ion-col>
            <ion-col class="col-border-left">
              <div *ngFor="let field of qualityDetail.fields" class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ field.name }}</div>
                <div *ngFor="let v of field.values" class="adjustment-grid-item">
                  <img *ngIf="field.multiple && field.values.length > 1" alt="Tick" class="adjustment-icon-tick" src="assets/images/tick_icon.svg">
                  <span class="adjustment-grid-value">{{v}}</span>
                </div>
              </div>
              <ion-button (click)="editQualityField()" [appAccountCanViewExcept]="['viewer']" color="secondary" class="page-button smaller">
                {{ "SHARED.Edit" | translate }}
              </ion-button>
            </ion-col>
            <ion-col *ngIf='qualityDetail.subtype !== "receiving"' class="col-border-left" size="3">
              <div class="descriptor">{{ "DASHPAGES.QUALITY_DETAIL_des0" | translate }}</div>
              <div class="adjustment-grid-item">
                <div class="adjustment-grid-value">{{qualityDetail.fixType}}</div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <!-- quality info block end-->

      <div>
        <hr class="hr-dashed">
      </div>
    </div>
    <!-- IMAGES BLOCK END-->


    <!-- NOTE BLOCK-->
    <div class="detail-page-section">
      <div class="headline-medium">{{ "SHARED.Observation_Notes" | translate }}</div>

      <div class="section-grid">
        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-quality" id="noteColorCode">
              {{ "SHARED.Open" | translate }}
            </span>
            <ion-button (click)="addOpenNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                        color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>

          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.openNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img">
                  <img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p><span class="body-copy-bold">{{note.name}}</span> | <span class="detail">{{note.time}}</span></p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <!--FIXED OBSERVATION NOTES-->
        <div *ngIf="notes.displayClosedNotes" class="section-grid-right">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-fixed">
              {{notes.noteStatus}}
            </span>
            <ion-button (click)="addClosedNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                        color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.closedNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img">
                  <img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p><span class="body-copy-bold">{{note.name}}</span> | <span>{{note.time}}</span></p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr class="hr-dashed">

    </div>
    <!-- NOTE BLOCK END-->

    <!--OBSERTVATION HISTORY-->
    <div #historyRef>
      <app-observation-history [observationId]="oid" [title]="'SHARED.Observation_History'"></app-observation-history>
    </div>

    <!--OBSERTVATION HISTORY END-->
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->
  </div>
</ion-content>

<ion-footer *ngIf="bottomNav" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
