<div id="reportViewContent">
  <div class="report-view-has-chart" id="reportViewChartContainer">
    <div class="no-data-msg chart-data" id="noChartData">{{ emptyTableMsg | translate}}</div>
    <canvas id="reportViewChart"></canvas>
  </div>
  <hr class="form-divider"/>
  <div class="report-view-table-wrapper">
    <table class="display table-group" [id]="tableId"></table>
    <div class="no-data-msg" id="noReportData">{{ emptyTableMsg | translate}}</div>
  </div>
</div>
