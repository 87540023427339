<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ "SHARED.Send_Message" | translate }}</h2>
</div>
<div class="body">
  <div class="message-recepient" id="toMessage">
    <span>{{ "DASHBOARD.MESSAGE_POPUP_To" | translate }}</span>
    <img *ngIf="userObject.userImg" [src]="userObject.userImg" alt="User">
    <span class="body-copy-bold">{{userObject.name}}</span>
  </div>
  <textarea [(ngModel)]="textMessage" autocomplete="off"
            class="textarea-styled" placeholder="{{'DASHBOARD.MESSAGE_POPUP_placeholder' | translate}}"></textarea>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="sendMessage()" [disabled]="!textMessage" class="page-button"
              color="secondary">{{ "SHARED.Send_Message" | translate }}
  </ion-button>
</div>
