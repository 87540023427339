import { PopoverController } from '@ionic/angular';
import { Component, Input, ViewChild } from '@angular/core';

import { TreeViewerComponent } from './../../components';
import { TreeViewNodeIdType, ITreeViewModalConfig } from './../../models';

@Component({
  selector: 'app-view-modal-popup',
  templateUrl: './view-modal-popup.component.html',
  styleUrls: ['./view-modal-popup.component.scss'],
})
export class ViewModalTreePopupComponent {
  @ViewChild(TreeViewerComponent) treeViewerComponent: TreeViewerComponent;
  @Input() config: ITreeViewModalConfig;
  public selectedIds: TreeViewNodeIdType[] = [];

  constructor(private popoverController: PopoverController) {}
  ionViewDidEnter() {
    this.selectedIds = this.treeViewerComponent.getSelectedIds();
  }
  public close() {
    this.popoverController.dismiss();
  }
  public onNodeSelected(ids: TreeViewNodeIdType[]) {
    this.selectedIds = ids;
  }
  public save() {
    this.popoverController.dismiss(this.selectedIds);
  }
}
