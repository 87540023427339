import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unknown-observation',
  templateUrl: './unknown-observation.page.html',
  styleUrls: ['./unknown-observation.page.scss'],
})
export class UnknownObservationPage implements OnInit {

  public id: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) {
      this.router.navigate(['/pages/dashboard/main']);
    }
  }

}
