<ion-header no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="content">
    <div class="title-block">
      <div class="value">{{"COMMUNICATIONS.PAGES_LIST_Worker_Messages" | translate}}</div>
      <!-- <div class="sign">#</div>
      <div class="id">{{observationId}}</div> -->
    </div>

    <!-- FORM COMPONENTS -->

    <div class="adjustment-grid">
      <div class="descriptor top-margin">{{"SHARED.RANGE" | translate}}</div>
      <ion-grid>
        <ion-row>
          <ion-col size-md="3">

            <input [(ngModel)]="startDate" autocomplete="off" class="pull-left form-input" max="9999-12-31" type="date">

          </ion-col>
          <ion-col size-md="1">
            <div class="body-copy top-space full">
              {{"COMMUNICATIONS.TO" | translate}}
            </div>
          </ion-col>
          <ion-col size-md="3">

            <input [(ngModel)]="endDate" autocomplete="off" class="form-input" max="9999-12-31" type="date">

          </ion-col>
          <ion-col size-md="4">
            <ion-button (click)="save()" [disabled]="!nextActive()" class="page-button button-centered"
                        color="secondary">{{"SHARED.Save_to_Excel" | translate}}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <!-- END FORM COMPONENTS-->
    <div [hidden]="true" class="table-container message-thread-table">
      <table class="display" id="messageTable">
      </table>
    </div>

  </div>
