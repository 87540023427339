import { Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-tooltip',
  templateUrl: './check-tooltip.component.html',
  styleUrls: ['./check-tooltip.component.scss'],
})
export class CheckTooltipComponent implements OnDestroy {

  public tooltipObject = {
    color: '',
    value: '',
    text: ''
  };
  public isActive: boolean;
  @HostBinding('style.top.px') private positionTop: number;
  @HostBinding('style.left.px') private positionLeft: number;
  private isAdded: boolean;

  constructor(
    private element: ElementRef,
    private translate: TranslateService
  ) {
  }

  ngOnDestroy() {
    this.element.nativeElement.remove();
  }

  public show(event: JQuery.MouseOverEvent<HTMLElement>) {
    this.isActive = true;

    const perct = event.target.dataset.percentage;
    const blkColor = event.target.dataset.color;
    const length = event.target.dataset.length;
    const bodyText = blkColor === 'success' ? this.translate.instant('SHARED.Positive') : this.translate.instant('SHARED.Negative');

    this.tooltipObject = {
      value: `${(+perct).toFixed(0)}%`,
      text: `${bodyText}: ${length}`,
      color: blkColor
    };

    if (!this.isAdded) {
      this.isAdded = true;
      document.querySelector('body').appendChild(this.element.nativeElement);
    }

    this.setPosition(event);
  }

  public hide() {
    this.isActive = false;
  }

  public setPosition(event: JQuery.MouseOverEvent<HTMLElement> | any) {
    const {pageY: top, pageX: left} = event;

    this.positionTop = top - 130;
    this.positionLeft = left - 60;
  }
}
