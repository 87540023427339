<div class="text-translations-block">
  <div class="collapsible-block">
    <div (click)="isActive = !isActive" [class.active-block]="isActive"
         class="text-translations-content collapsible-item">{{ title | translate }}</div>
    <div *ngIf="isActive" class="text-translations text-translations-content">
      <div *ngFor="let field of fields" class="ui-field-contain">
        <label class="form-indent">{{ translationMap[field.language] || 'SHARED.Unknown' | translate }}</label>
        <input (ngModelChange)="emitInputChange()" [(ngModel)]="field.value"
               [name]="name ? 'translation_' + name + '_' + field.language : ''">
      </div>
    </div>
  </div>
</div>
