import { Injectable, Input } from '@angular/core';

import * as _ from 'lodash';

@Injectable()
export abstract class BaseItemList {

  @Input() selectedLocationIds?: string[] = [];

  protected filterByLocation(items: any[]): any[] {
    if (this.selectedLocationIds?.length && this.selectedLocationIds[0] !== 'all') {
      const selectedLocationIds: string[] = _.map(this.selectedLocationIds, String);

      return _.filter(items, (item) => {
        const itemLocations: string[] = _.map(item.locations, String);
        const emptyItemLocations = itemLocations.length === 0;
        const matchedLocations = _.intersection(selectedLocationIds, itemLocations).length === selectedLocationIds.length;

        return matchedLocations || emptyItemLocations;
      });
    } else {
      return items;
    }
  }

}
