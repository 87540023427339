import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss'],
})
export class MediaViewerComponent {

  @Input() URL: string;
  @Input() type: 'video' | 'audio';

  constructor(
    private elementRef: ElementRef,
    private modalController: ModalController
  ) {
  }

  @HostListener('click', ['$event']) onClick(e) {
    if (this.elementRef.nativeElement.isEqualNode(e.target)) {
      this.modalController.dismiss();
    }
  }

}
