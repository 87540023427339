import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
})
export class MessagePopupComponent implements OnInit {
  @Input() userObject: any;
  @Input() msgType: string;
  public textMessage = '';

  constructor(private popOver: PopoverController,
              private popOps: PopupObservationService,
  ) {
  }

  ngOnInit() {
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public sendMessage(): void {
    this.popOver.dismiss();
    const requestObject: any = {
      cmd: 'addMessage',
      type: 'message',
      message: this.textMessage,
    };
    if (this.userObject.team) {
      requestObject.groups = [this.userObject.userID];
    } else {
      requestObject.users = [this.userObject.userID];
    }
    this.popOps.sendMessage(requestObject);
  }

}
