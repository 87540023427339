import { Injectable } from '@angular/core';

import { AccountsService, GeartypesService, ShiftService, TeamsService, UserService, UtilsService, } from '@services';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PPEModelService {

  columnOptions = [
    {
      id: 'registrationCount',
      description: this.translate.instant('REPORTING.Service_Number_of_Registrations'),
      label: this.translate.instant('REPORTING.Service_Number_of_Registrations'),
      units: 'Registrations',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let count_regs = 0;
        _.forEach(collection, (ref, key) => {
          count_regs += ref.registrationCount;
        });
        return count_regs;
      }
    },
    {
      id: 'registrationDuration',
      description: this.translate.instant('REPORTING.Service_Total_Time_Registered'),
      label: this.translate.instant('REPORTING.Service_Total_Time_Registered'),
      units: 'TimeRegistered',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let registrationTime = 0;
        _.forEach(collection, (ref, key) => {
          registrationTime += ref.registrationDuration;
        });
        if (registrationTime) {
          return this.utils.secsToDuration(registrationTime);
        } else {
          return 0;
        }
      }
    },
    {
      id: 'average_registrationDuration',
      description: this.translate.instant('REPORTING.Service_Average_Time_Registered'),
      label: this.translate.instant('REPORTING.Service_Average_Time_Registered'),
      units: 'AverageTimeRegistered',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let registrationTime = 0;
        _.forEach(collection, (ref, key) => {
          registrationTime += ref.registrationDuration;
        });
        if (registrationTime) {
          return this.utils.secsToDuration(registrationTime / collection.length);
        } else {
          return 0;
        }
      }
    },
    {
      id: 'registrationCost',
      description: this.translate.instant('REPORTING.Service_Total_Cost'),
      label: this.translate.instant('REPORTING.Service_Total_Cost'),
      units: 'TotalCost',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let registrationCost = 0;
        _.forEach(collection, (ref, key) => {
          registrationCost += ref.registrationCost;
        });
        return registrationCost;
      }
    },
    {
      id: 'average_registrationCost',
      description: this.translate.instant('REPORTING.Service_Average_Cost'),
      label: this.translate.instant('REPORTING.Service_Average_Cost'),
      units: 'AverageCost',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let registrationCost = 0;
        _.forEach(collection, (ref, key) => {
          registrationCost += ref.registrationCost;
        });
        return _.round((registrationCost / collection.length), 2);
      }
    },
    {
      id: 'wearDuration',
      description: this.translate.instant('REPORTING.Service_Total_Time_in_Use'),
      label: this.translate.instant('REPORTING.Service_Total_Time_in_Use'),
      units: 'TotalTimeInUse',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let wearTime = 0;
        _.forEach(collection, (ref, key) => {
          wearTime += ref.wearDuration;
        });
        if (wearTime) {
          return this.utils.secsToDuration(wearTime);
        } else {
          return 0;
        }
      }
    },
    {
      id: 'average_wearDuration',
      description: this.translate.instant('REPORTING.Service_Average_Time_in_Use'),
      label: this.translate.instant('REPORTING.Service_Average_Time_in_Use'),
      units: 'AverageTimeInUse',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let wearTime = 0;
        _.forEach(collection, (ref, key) => {
          wearTime += ref.wearDuration;
        });
        if (wearTime) {
          return this.utils.secsToDuration(wearTime / collection.length);
        } else {
          return 0;
        }
      }
    },
    {
      id: 'deregistrationCount',
      description: this.translate.instant('REPORTING.Service_Number_of_Deregistrations'),
      label: this.translate.instant('REPORTING.Service_Number_of_Deregistrations'),
      units: 'NumberOfDeregistrations',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        let deregistrationCount = 0;
        _.forEach(collection, (ref, key) => {
          deregistrationCount += ref.deregistrationCount;
        });
        return deregistrationCount;
      }
    }
  ];
  fieldOptions = [
    {
      id: 'PPEType',
      description: this.translate.instant('SHARED.PPE_Type'),
      label: this.translate.instant('SHARED.PPE_Type'),
      fieldName: 'type',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const ppe_types = this.geartypesService.gearTypes.data;
        const ppe_type = _.find(ppe_types, ['id', val]);
        return [val, ppe_type.description];
      }
    },
    {
      id: 'PPEName',
      description: this.translate.instant('SHARED.PPE_Name'),
      label: this.translate.instant('SHARED.PPE_Name'),
      fieldName: 'name',
      fieldType: 'string',
      fieldFunc: (val, ref) => [val, val]
    },
    {
      id: 'Location',
      description: this.translate.instant('SHARED.Location'),
      label: this.translate.instant('SHARED.Location'),
      fieldName: 'locationID',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const loc = this.userService.findLocation(val);
        let locName = this.translate.instant('DASHPAGES.NA');
        if (loc && loc.name) {
          locName = loc.name;
        }
        return [val, locName];
      }
    },
    {
      id: 'Team',
      description: this.translate.instant('SHARED.Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'groupID',
      fieldType: 'string',
      fieldFunc: (val, ref) => [val, this.teamsService.teamNameByID(val)]
    },
  ];
  fieldOptions_temporarily = [
    {
      id: 'PPEType',
      description: this.translate.instant('SHARED.PPE_Type'),
      label: this.translate.instant('SHARED.PPE_Type'),
      fieldName: 'type',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        const ppe_types = this.geartypesService.gearTypes.data;
        const ppe_type = _.find(ppe_types, ['id', val]);
        return [val, ppe_type.description];
      }
    },
    {
      id: 'PPEName',
      description: this.translate.instant('SHARED.PPE_Name'),
      label: this.translate.instant('SHARED.PPE_Name'),
      fieldName: 'name',
      fieldType: 'string',
      fieldFunc: (val, ref) => [val, val]
    },
  ];

  constructor(
    private translate: TranslateService,
    private utils: UtilsService,
    private userService: UserService,
    private accountsService: AccountsService,
    private shift: ShiftService,
    private teamsService: TeamsService,
    private geartypesService: GeartypesService
  ) {
  }

  public getColumns() {
    const columns = _.filter(_.cloneDeep(this.columnOptions), (opt) => opt.showWhen);
    return columns;
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }

  public getFieldOptions_temporarily() {
    return this.fieldOptions_temporarily;
  }

}
