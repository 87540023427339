import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReportLinkGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id: string = next.paramMap.get('id');
    return this.navigateToReportPage(id);
  }

  private navigateToMainPage(): boolean {
    this.router.navigate(['pages/dashboard/main']);
    return false;
  }

  public navigateToReportPage(reportID): Promise<boolean> {
    return this.router.navigate(['pages/reporting/view', {reportID}]).then(() => {
      return false;
    }).catch(() => this.navigateToMainPage());
  }

  public parseUrlId(url: string): string {
    return _.get(_.split(url, '/reporting/'), '[1]');
  }

}
