import { Injectable } from '@angular/core';
import { CommsService } from '@services';
import { awaitHandler } from '@utils/awaitHandler';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  /**
   *
   *
   * @type {*}
   * @memberof SearchService
   * saves the filter used in explore and learn page.
   */
  public exploreFilterObject: any = {};

  constructor(private comms: CommsService) {
  }

  /**
   *
   * @param req search parameters from API
   * @returns list of related search results.
   */
  public simpleSearch(req) {
    req.cmd = 'simpleSearch';
    req.sendTime = Date.now();
    return this.comms.sendMessage(req, false, false);
  }

  public async getSearchSize(req: any): Promise<number> {
    req.cmd = 'simpleSearch';
    req.limit = 1;
    req.sendTime = Date.now();
    req.terms = ['*:*'];
    const [result] = await awaitHandler(this.comms.sendMessage(req, false, false));
    if (result?.reqStatus === 'OK') {
      // we got a result
      return result.result.poolCount;
    } else {
      return 0;
    }
  }

}
