import { Component, Input } from '@angular/core';

import { CommsService, LoadingService, SettingsService } from '@services';
import { awaitHandler } from '@utils/awaitHandler';

import * as _ from 'lodash';

@Component({
  selector: 'app-observation-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent {

  @Input() removalHandler: (ids: number[]) => Promise<any>;
  public tags: { id: number; title: string }[] = [];

  constructor(
    private settingsService: SettingsService,
    private comms: CommsService,
    private loadingService: LoadingService
  ) {
  }

  @Input() set ids(values: number[]) {
    if (values) {
      this.init(values);
    }
  }

  public async removeTag(tagId: number) {
    await this.loadingService.enable();

    const filteredTags = _.reject(this.tags, {id: tagId});
    const [response] = await awaitHandler(this.removalHandler(_.map(filteredTags, 'id')));

    if (response?.reqStatus === 'OK' && response?.result) {
      this.tags = filteredTags;
    }

    this.loadingService.disable();
  }

  private init(ids: number[]) {
    this.tags = _.filter(_.map(ids, (id: number) => {
      const filterParameters: { [key: string]: any } = {tagID: +id, active: 1, disabledAt: 0};
      const tagObject = _.find(this.settingsService.customTags.data, filterParameters);

      return {id, title: tagObject?.tag};
    }), 'title');
  }

}
