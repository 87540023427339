<ion-menu (ionDidClose)="onClose()" (ionWillClose)="onClose()" class="add-reports-bar" contentId="main-content" menuId="addReports"
          side="end" swipeGesture="false" type="overlay">
  <ion-content class="custom-inputs">
    <div (deferLoad)="onFormsLoad()" *ngIf="isAvailable && formGroups.length" class="medium">
      <ng-container *ngFor="let formGroup of formGroups; let index = index; let last = last">
        <app-form-group (changeStep)="changeStep($event)"
                        (onCancel)="cancel()"
                        (onSaveAsReport)="onSaveAsReport()"
                        (onSaveReport)="onSaveReport($event)"
                        [editForm]="formGroup.editForm"
                        [inputFormConfig]="formGroup.formConfig"
                        [last]="last"
                        [newForm]="formGroup.newForm"
                        [ngClass]="activeStep === index + 1 ? 'active' : 'not-active'"
                        [type]="formsData.type"></app-form-group>
      </ng-container>
    </div>
  </ion-content>
</ion-menu>

<span (click)="openAddReports()" *ngIf="isAvailable" [class.active]="menuOpen" class="open-steps"></span>
