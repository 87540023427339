import { Directive, ElementRef, Input } from '@angular/core';

import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { SettingsService } from '@services';

import * as _ from 'lodash';

@Directive({
  selector: '[appMarkNote]'
})
export class MarkNoteDirective {

  constructor(
    private observationDetailService: ObservationDetailService,
    private elementRef: ElementRef,
    private settingsService: SettingsService
  ) {
  }

  @Input() set last(value: boolean) {
    if (value) {
      setTimeout(() => {
        const tags: string[] = _.map(this.settingsService.customTags.data, (customTagItem: any) => _.trim(customTagItem.tag));
        this.observationDetailService.markNotes(tags, this.elementRef.nativeElement.parentElement);
      });
    }
  }

}
