<ion-header no-border>
  <app-close-button (onClicked)="cancel()"></app-close-button>
</ion-header>

<ion-content class="custom-inputs">
  <div class="medium">
    <div class="page-title">{{title}}</div>

    <form id="contentAddForm" name="contentAddForm"></form>
    <form id="contentEditForm" name="contentEditForm"></form>
  </div>
</ion-content>
