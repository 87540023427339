import { Injectable } from '@angular/core';
import { LocationChangeEvent, LocationChangeListener, PathLocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HistoryBlockingLocationStrategyService extends PathLocationStrategy {

  private historyBlocked = false;

  public stopNextPopstatePropagation(): void {
    this.historyBlocked = true;
  }

  public unblockHistory(): void {
    this.historyBlocked = false;
  }

  onPopState(fn: LocationChangeListener): void {
    const wrappedFn: LocationChangeListener = (event: LocationChangeEvent) => {
      if (this.historyBlocked) {
        this.unblockHistory();

        return () => {
        };
      }

      return fn(event);
    };
    super.onPopState(wrappedFn);
  }

  replaceState(state: any, title: string, url: string, queryParams: string): void {
    if (this.historyBlocked) {
      return;
    }

    return super.replaceState(state, title, url, queryParams);
  }
}
