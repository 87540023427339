import { Injectable } from '@angular/core';

import { FoldersDataService } from '@services';
import { TreeViewNode } from '@shared/modules/tree-viewer/models';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';
import {
  FolderPickerConfig,
  FolderPickerEntities,
  FolderPickerEntityFolder,
  FolderPickerItem, FolderPickerSelection
} from '@modules/management/modules/folders/model/folders.interfaces';

import { cloneDeep, each, filter, includes, map } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FolderPickerService {
  static readonly folderIdPrefix: string = 'folder:';
  private pickerConfig: FolderPickerConfig;
  constructor(private foldersDataService: FoldersDataService) {}

  public getTreeNodes(config: FolderPickerConfig): TreeViewNode[] {
    this.pickerConfig = config;
    const nodeId: number = 0;
    const folderItems = config?.getFolderItems && config.getFolderItems() || [];
    const rootFolders = this.getRootFolders(config.entities, config.parentIds, folderItems, config.dataType);
    const nodes = this.buildNodeTree(config.selectedIds, rootFolders);
    const treeNodes: TreeViewNode[] = [];

    if (nodes?.length) {
      if (this.pickerConfig.selectAllLabel) {
        treeNodes.push({
          id: `${FolderPickerService.folderIdPrefix}${nodeId}`,
          label: this.pickerConfig.selectAllLabel,
          nodes,
          checked: this.isChecked(config.selectedIds, nodeId, true)
        });
      } else {
        treeNodes.push(...nodes);
      }
    }

    return treeNodes;
  }

  private getRootFolders(entities: FolderPickerEntities, rejectedIds: FolderPickerSelection[], items: FolderPickerItem[], dataType: FolderDataType): FolderPickerEntityFolder[] {
    entities.folderItems = items;
    entities.folders = cloneDeep(this.foldersDataService.getFolders([dataType]));

    const rootFolders = this.buildFolderTree(entities, rejectedIds);
    this.defineFolderItems(entities, rejectedIds);

    return rootFolders;
  }

  private buildFolderTree(entities: FolderPickerEntities, rejectedIds: FolderPickerSelection[]): FolderPickerEntityFolder[] {
    const rootFolders: FolderPickerEntityFolder[] = [];
    const rejectAll = this.pickerConfig.getFolderId(rejectedIds?.[0]) === 0;

    if (!rejectAll) {
      each(entities.folders, (folder) => {
        const isRejected = includes(map(rejectedIds, (id) => this.pickerConfig.getFolderId(id)), folder.folderID);

        if (!isRejected) {
          const parentFolder = entities.folders[folder.parentID];

          if (parentFolder) {
            if (!parentFolder.children) {
              parentFolder.children = [];
            }

            parentFolder.children.push(folder);
          } else {
            rootFolders.push(folder);
          }
        }
      });
    }

    return rootFolders;
  }

  private defineFolderItems(entities: FolderPickerEntities, rejectedIds: FolderPickerSelection[]) {
    each(entities.folderItems, (folderItem) => {
      const folder = entities.folders[folderItem.folderID];
      const isRejected = includes(map(rejectedIds, (id) => this.pickerConfig.getItemId(id)), this.pickerConfig.getItemId(folderItem));

      if (folder && !isRejected) {
        if (!folder.folderItems) {
          folder.folderItems = [];
        }

        folder.folderItems.push(folderItem);
      }
    });
  }

  private isChecked(values: FolderPickerSelection[], id: number, isFolder: boolean = false): boolean {
    let ids: (string | number)[] = [];

    if (isFolder) {
      ids = map(values, (value) => this.pickerConfig.getFolderId(value));
    } else {
      ids = filter(map(values, (value) => this.pickerConfig.getItemId(value)));
    }

    return includes(ids, id);
  }

  private buildNodeTree(selectedFolderItems: FolderPickerSelection[], folders: FolderPickerEntityFolder[]): TreeViewNode[] {
    return filter(map(folders, (folder) => {
      let nodes = this.buildNodeTree(selectedFolderItems, folder.children) || [];

      each(folder?.folderItems, (folderItem) => {
        const itemId = this.pickerConfig.getItemId(folderItem);

        nodes.push({
          id: itemId,
          label: this.pickerConfig.getItemTitle(folderItem),
          checked: this.isChecked(selectedFolderItems, itemId)
        });
      });

      return {
        id: `${FolderPickerService.folderIdPrefix}${folder.folderID}`,
        label: folder.title,
        checked: this.isChecked(selectedFolderItems, folder.folderID, true),
        nodes,
        showFolderIcon: true
      };
    }));
  }
}
