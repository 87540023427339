import { Component, Input, OnInit } from '@angular/core';
import { SubscriberService } from '@services';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {

  @Input() isFinishing = false;
  public brand = '';


  constructor(
    private subscriber: SubscriberService
  ) {
  }

  ngOnInit() {
    const d = this.subscriber.brandInfo();
    this.brand = d.brand;
  }

}
