import { ElementRef, Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, interval } from 'rxjs';
import { startWith, take, map } from 'rxjs/operators';
import { NguCarouselConfig } from '@ngu/carousel';
import { slider } from './app-carousel-animation';
import { UserdataService } from '@services/userdata/userdata.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@services';

@Component({
  selector: 'app-app-carousel',
  templateUrl: './app-carousel.page.html',
  styleUrls: ['./app-carousel.page.scss'],
  animations: [slider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppCarousel implements OnInit {
  @Input() name: string;
  images = [
    'assets/carousel/web1.png',
    'assets/carousel/web2.png',
    'assets/carousel/web3.png',
    'assets/carousel/web4.png',
  ];
  dataHeader = [
    {
      header: this.translate.instant('DASHPAGES.APP_CAROUSEL_header0'),
      description: this.translate.instant('DASHPAGES.APP_CAROUSEL_description0')
    },
    {
      header: this.translate.instant('DASHPAGES.APP_CAROUSEL_header1'),
      description: this.translate.instant('DASHPAGES.APP_CAROUSEL_description1')
    },
    {
      header: this.translate.instant('DASHPAGES.APP_CAROUSEL_header2'),
      description: this.translate.instant('DASHPAGES.APP_CAROUSEL_description2')
    },
    {
      header: this.translate.instant('DASHPAGES.APP_CAROUSEL_header3'),
      description: this.translate.instant('DASHPAGES.APP_CAROUSEL_description3')
    }
  ];


  public carouselTileItems$: Observable<number[]>;
  public carouselTileConfig: NguCarouselConfig = {
    grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
    point: {
      visible: true
    },
    touch: true,
    loop: true,
    animation: 'lazy'
  };
  tempData: any[];

  constructor(private userData: UserdataService, private cdr: ChangeDetectorRef, private location: Location, private elementRef: ElementRef, private translate: TranslateService) {
  }

  ngOnInit() {
    this.tempData = [];
    this.userData.showCarousel = false;
    this.carouselTileItems$ = interval(0).pipe(
      startWith(-1),
      take(4),
      map(val => {
        const data = (this.tempData = [
          ...this.tempData,
          {
            image: this.images[val + 1],
            header: this.dataHeader[val + 1].header,
            description: this.dataHeader[val + 1].description
          }
        ]);
        return data;
      })
    );
  }

  public back(): void {
    if (this.userData.userID != null) {
      StorageService.setItem('carouselItem' + this.userData.userID, this.userData.userID);
    }
    this.location.back();
  }

  public clickNextFun(event): void {
    let itemList;
    if (event == 1) {
      if (this.userData.userID != null) {
        StorageService.setItem('carouselItem' + this.userData.userID, this.userData.userID);
      }
      this.location.back();
    }
    this.elementRef.nativeElement.querySelector(`#buttonNextClick`).dispatchEvent(new Event('click'));
  }

}
