import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DataTableOptions, TableService } from '@services';

import * as uuid from 'uuid';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnChanges {
  @Input() tableId = `dataTable_${uuid.v4()}`;
  @Input() dataTableOptions: DataTableOptions;
  @Input() selectedIndex: number;

  private table: DataTables.Api;

  constructor(private tableService: TableService) {
    this.tableService.defineDefaults();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.render();
    })
  }

  render() {
    this.table = this.tableService.showDataTable(`#${this.tableId}`, this.dataTableOptions);

    if (this.selectedIndex > -1) {
      (this.table.row(`:eq(${this.selectedIndex})`) as any).select();
    }
  }

  public getTable() {
    return this.table;
  }
}
