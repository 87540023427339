import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { CommsService, LoadingService, ObservationService } from '@services';
import { RangeInputConfig } from '@shared/components';
import { IObjectStringKeyMap } from '@shared/models';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import { QualityFieldsComponent } from '@modules/observation/components';
import { Select2OptionData } from '@shared/components/ng2-select2/ng2-select2.interface';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-changing-type',
  templateUrl: './changing-type.page.html',
  styleUrls: ['./changing-type.page.scss'],
})
export class ChangingTypePage implements OnInit {

  @ViewChild(QualityFieldsComponent) qualityFieldsComponent: QualityFieldsComponent;

  public note: string;
  public currentType: string;
  public typeTitle: string;
  public observationId: string;
  public options: any = {
    minimumResultsForSearch: Infinity,
    placeholder: this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_placeholder'),
    sorter: (data) => data
  };
  public rangeOptions: any[] = [
    {
      id: 'condition',
      text: this.translate.instant('SHARED.Unsafe_Condition')
    },
    {
      id: 'quality',
      text: this.translate.instant('SHARED.CHANGING_TYPE_TS_quality')
    },
    {
      id: 'pi',
      text: this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_pi')
    }
  ];
  public viewRangeOptions: any[] = [];
  public selectedRange: string;
  public severityRangeConfig: RangeInputConfig = {
    title: this.translate.instant('SHARED.Severity'),
    currentValue: 50
  };
  public liklihoodRangeConfig: RangeInputConfig = {
    title: this.translate.instant('SHARED.Likelihood'),
    currentValue: 50
  };
  public qualityIssues: IObjectStringKeyMap<string>[] = [
    {
      key: 'receiving',
      value: this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_receiving')
    },
    {
      key: 'production',
      value: this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_production')
    }
  ];
  public fixTypeOptions: IObjectStringKeyMap<string>[] = [
    {
      key: 'dropped',
      value: this.translate.instant('SHARED.Scrapped')
    },
    {
      key: 'fixed',
      value: this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_fixed')
    }
  ];
  public subType: string;
  public observation: any;
  private previousType: string;
  private observationTypes: any = {};
  private isLoading: boolean;
  private fixTypeValue: string;
  private isBackNavigation: boolean;
  private dataTableMap = {
    condition: 'pages/dashboard/condition-table',
    behavior: 'pages/dashboard/coaching-table',
    compliment: 'pages/dashboard/compliment-table',
    pi: 'pages/dashboard/process-table',
    quality: 'pages/dashboard/quality-table'
  };

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private observationsService: ObservationService,
    private commsService: CommsService,
    private loadingService: LoadingService,
    private observationDetailService: ObservationDetailService,
    private observationTableService: ObservationTableService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.observationTypes = _.fromPairs(_.map(this.rangeOptions, (rangeOption: any) => [rangeOption.id, rangeOption.text]));
    this.observationId = this.route.snapshot.paramMap.get('id');
    this.observation = this.observationsService.observations.data[this.observationId];
    this.previousType = this.observationsService.getProperty(this.observation, 'type');
    this.isBackNavigation = this.route.snapshot.queryParamMap.get('isBackNavigation') === 'true';
  }

  ionViewWillEnter() {
    this.init();
  }

  public back(): void {
    this.location.back();
  }

  public onTypeChange(selectedType: any): void {
    this.currentType = selectedType.value;

    this.subType = '';
    this.fixTypeValue = '';
  }

  public onRangeValueChanged(value: number, type: 'severity' | 'liklihood'): void {
    if (type === 'severity') {
      this.severityRangeConfig.currentValue = value;
    } else {
      this.liklihoodRangeConfig.currentValue = value;
    }
  }

  public isFormInvalid(): boolean {
    let isInvalid = false;

    if (this.currentType === 'quality') {
      if (this.observation.state === 'fixed') {
        isInvalid = (this.subType === 'production' && !this.fixTypeValue) || !this.subType;
      } else {
        isInvalid = !_.get(this.qualityFieldsComponent, 'nextActivated') || !this.subType;
      }
    }

    return _.isEmpty(this.currentType) || isInvalid || this.isLoading;
  }

  public onIssueChange(value: any): void {
    this.subType = value.detail.value;
    this.fixTypeValue = '';
  }

  public onFixTypeChange(value: any): void {
    this.fixTypeValue = value.detail.value;
  }

  public save(): void {
    const params: any = {
      cmd: 'updateObservation',
      observationID: this.observationId,
      typeOverride: this.currentType
    };

    if (this.note) {
      params.note = JSON.stringify({
        value: this.note,
        type: 'text',
        subtype: 'created'
      });
    }

    if (this.currentType === 'condition') {
      params.severityOverride = this.severityRangeConfig.currentValue;
      params.likelihoodOverride = this.liklihoodRangeConfig.currentValue;
    }

    if (this.currentType === 'quality') {
      params.subtypeOverride = this.subType;

      if (this.fixTypeValue) {
        params.state = this.fixTypeValue;
      } else {
        params.value = JSON.stringify(_.map(this.qualityFieldsComponent.customQualityField.data, data => {
          if (data.value !== '') {
            return {
              name: data.name,
              uuid: data.uuid,
              value: data.value
            };
          }
        }));
      }
    }

    if (this.currentType === 'pi') {
      params.subtypeOverride = 'general';
      params.state = 'escalated';
    }

    this.setLoadingState(true);
    this.commsService.sendMessage(params).then((res) => {
      this.observationsService.observations.data[this.observationId] = res.result;
      this.observationsService.observations.data[this.observationId].type = res.result.typeOverride;
      this.observationsService.observations.data[this.observationId].type = res.result.subtypeOverride;
      this.setLoadingState(false);

      if (this.isBackNavigation) {
        window.history.go(-2);
      } else {
        const observations: any = this.observationTableService.getNearestObservationsById(+this.observationId);
        const observationsIds = _.map(observations, 'observationID');
        this.observationDetailService.navigateToDetail(res.result, _.sortBy(observationsIds), true);
      }
    }).catch(() => {
      this.setLoadingState(false);
    });
  }

  private init(): void {
    this.note = '';
    this.observation = this.observationsService.observations.data[this.observationId];

    if (this.observationId && this.observation) {
      const observationType: string = this.observationsService.getProperty(this.observation, 'type');
      this.typeTitle = this.observationTypes[observationType];
      this.viewRangeOptions = <Select2OptionData[]>_.reject(this.rangeOptions, <any>{id: observationType});
      this.selectedRange = null;
    } else {
      this.back();
    }
  }

  private setLoadingState(isEnabled: boolean): void {
    this.isLoading = isEnabled;
    isEnabled ? this.loadingService.enable() : this.loadingService.disable();
  }

}
