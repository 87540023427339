<ion-header no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content>
  <div class="wide title-block">
    <div class="value">{{ "MGMT_DETAILS.Unable_To_Perform" | translate }}</div>
    <!-- <div class="id">{{responseID}}</div> -->
  </div>

  <!-- top grid-->
  <div class="wide" *ngIf="tableElementArr?.[0]?.length">
    <ion-row>
      <ion-col *ngFor="let elem of tableElementArr[0]">
        <div class="column-header pad-left">
          {{elem.name | translate}}
        </div>
      </ion-col>
    </ion-row>
    <ion-grid class="wide-grid">
      <ion-row *ngFor="let tableElement of tableElementArr" class="row-bg">
        <ion-col *ngFor="let elem of tableElement">
          <div class="table-body text pad-left">
            {{elem.data}}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <!-- top grid end-->

  <div class="wide">
    <form id="reasonEditForm" name="reasonEditForm"></form>
  </div>
</ion-content>
