import { Directive, ElementRef, Input } from '@angular/core';

import { SubscriberService } from '@services';

import * as _ from 'lodash';

@Directive({
  selector: '[showByBrand]'
})
export class ShowByBrandDirective {

  constructor(
    private elementRef: ElementRef,
    private subscriberService: SubscriberService
  ) {
  }

  @Input('showByBrand') set brands(brands: string | string[]) {
    const currentBrand = this.subscriberService.brandInfo();

    if (!_.includes(_.flatten([brands]), currentBrand?.brand)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}
