import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastController: ToastController,
    private translate: TranslateService
  ) { }

  async show(message = 'SHARED.Update_Success', prefix?: string, params?: Object) {
    let msg = this.translate.instant(message);
    if (prefix) {
      msg = `${this.translate.instant(prefix)}: ${msg}`
    }

    const toast = await this.toastController.create(_.assign({
      message: msg,
      cssClass: 'custom-toast',
      duration: 3000,
      position: 'middle',
      buttons: [{
        text: '×',
        role: 'cancel'
      }]
    } as ToastOptions, params));
    await toast.present();
  }

  async showUpdatedSuccess(prefix?: string, params?: Object) {
    this.show('SHARED.Update_Success', prefix, params);
  }

  async showAddedSuccess(prefix?: string, params?: Object) {
    this.show('SHARED.Added_success', prefix, params);
  }

  async showDeletedSuccess(prefix?: string, params?: Object) {
    this.show('SHARED.Deleted_success', prefix, params);
  }
}
