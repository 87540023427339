<div *ngIf="parentContentItems.length" class="form-divider-title headline-small">{{ 'MGMT_DETAILS.Inherited_Content' | translate }}</div>
<div class="parent-list">
  <ion-item (click)="openDetailPage(contentItem)" *ngFor="let contentItem of parentContentItems | orderBy: currentOrderByObject.parentOrder"
            lines="full">
    <img [src]="contentItem.iconName">
    <div class="description">{{ contentItem | translateBy: 'description' }}</div>
  </ion-item>
</div>

<div class="form-divider-title headline-small">{{ 'MGMT_DETAILS.Attached_Content' | translate }}</div>

<div *ngIf="prompt && !contentItems.length" class='text-center'>{{ prompt }}</div>

<app-reorder-list (onChecked)="toggleSelection()" (onClick)="openDetailPage($event)" (onReorder)="doReorder()" [config]="reorderConfig"></app-reorder-list>

<div class="add-block">
  <ion-button (click)="addContent()" class="page-button"
              color="secondary">{{ "MGMT_DETAILS.Select_Add_Content" | translate }}</ion-button>
</div>

<ion-footer *ngIf="panelConfig.count" no-border>
  <app-removal-panel (onRemove)="onRemove()" [removeButtonTitle]="panelConfig.removeTitle" [subTitle]="panelConfig.subTitle"
                     [title]="panelConfig.title"></app-removal-panel>
</ion-footer>


<ng-template let-content="item" #contentTypeRef>
  <img [src]="content.iconName">
</ng-template>

<ng-template let-content="item" #contentNameRef>
  <div class="name">{{ content | translateBy: 'description' }}</div>
</ng-template>

<ng-template let-content="item" #contentRemoveRef>
  <ion-button (click)="removeById(content.objectID)" class="page-button" color="secondary" slot="end">{{ "SHARED.Remove" | translate }}</ion-button>
</ng-template>
