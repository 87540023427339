import { Component, HostListener, Input } from '@angular/core';

import { ViewerService } from '@services';

import * as _ from 'lodash';

@Component({
  selector: 'app-table-attachments-field',
  templateUrl: './table-attachments-field.component.html',
  styleUrls: ['./table-attachments-field.component.scss'],
})
export class TableAttachmentsFieldComponent {

  public attachments: any[];
  public clickable = true;

  constructor(private viewerService: ViewerService) {
  }

  @Input('attachments') set values(attachments: any[]) {
    this.attachments = this.viewerService.filterAttachments(attachments);
  }

  @HostListener('click', ['$event']) onClick(event) {
    event.stopPropagation();

    if (this.attachments?.length && this.clickable) {
      this.viewerService.openGallery(this.attachments);
    }
  }

}
