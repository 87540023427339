import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';

import { SubscriberService } from '@services/subscriber/subscriber.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { Events } from '@services/events/events.service';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SiteAwarenessMessagingService extends BaseService {

  messages: any = {
    data: [],
    lastRequest: null,
    lastHash: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private userdata: UserdataService,
    private subscriber: SubscriberService,
    private translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.messages.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.messages.lastRequest}`);
      return Promise.resolve(this.messages.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getSafetyMessages',
          includeDisabled: 1,
          lastHash: this.messages.lastHash,
          lastRequest: this.messages.lastRequest,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
            this.messages.lastHash = data.result.datahash;
          }
          this.logger.log(this.messages);
          resolve(this.messages.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public updateCache(data) {
    this.messages.lastRequest = data.result.timestamp;
    const lang = this.translate.getDefaultLang();
    data.result.messages.forEach(elementOne => {
      elementOne.translations.forEach(element => {
        if (element.language === lang) {
          this.logger.log(element.value);
          elementOne.message = element.value;
        }
      });
    });
    this.messages.data = data.result.messages;
    this.events.publish('ccs:siteAwarenessMessagingUpdate');
  }

  public clearCache() {
    this.messages.lastHash = null;
    this.messages.lastRequest = null;
    this.messages.data = null;
  }

  public handleAddMessage(fData) {
    fData.cmd = 'addSafetyMessage';
    fData.sendTime = Date.now();
    return this.handleRequest(this.encodeMessage(fData));
  }


  public encodeMessage(messageData) {
    const r: any = {};
    Object.keys(messageData).forEach((key) => {
      if (key === 'locations') {
        const v = messageData[key];
        const o = [];
        if (Array.isArray(v)) {
          $.each(v, (i, item) => {
            o.push(+item);
          });
        } else {
          o[0] = +v;
        }
        r[key] = JSON.stringify(o);
      } else {
        r[key] = messageData[key];
      }
    });
    // roll up any translations
    const l = this.subscriber.getLanguages(true);
    if (l && l.length) {
      const t = [];
      _.each(l, (lang: string) => {
        const n = `translations_${lang}`;
        if (messageData.hasOwnProperty(n)) {
          t.push({language: lang, value: messageData[n]});
        }
      });
      if (t.length) {
        r.translations = JSON.stringify(t);
      }
    }
    r.active = r.active || 0;

    return r;
  }

  /**
   *
   * @param filters array of functions that are called with the user recored as a parameter.  The function returns true if the item should be filtered out of the user list.
   */
  public filterMessageList(filters: any) {
    const r = [];
    _.each(this.messages.data, (ref) => {
      let matched = true;
      if (Array.isArray(filters)) {
        _.each(filters, (test) => {
          if (test(ref)) {
            matched = false;
          }
        });
      }
      if (matched) {
        r.push(ref);
      }
    });
    return r;
  }

  public getMessageList(locations) {
    return this.filterMessageList([
      (message) => {
        // if there are no locations in the list, then this user has access to all locations
        if (message.locations.length === 0 || locations.length === 0) {
          return false;
        }
        const overlap = _.intersection(locations, message.locations);
        return !overlap.length;
      }
    ]);
  }

  public handleUpdateMessage(fData): Promise<any> {
    const cmdOpts = _.cloneDeep(fData);
    cmdOpts.cmd = 'updateSafetyMessage';
    if (!cmdOpts.hasOwnProperty('locations')) {
      cmdOpts.locations = [];
    }
    cmdOpts.sendTime = Date.now();

    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(this.encodeMessage(cmdOpts), false, true)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.refresh()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }

  public oldhandleUpdateMessage(fData) {
    return this.commsService.sendMessage(this.encodeMessage(fData));
  }

  public handleDeleteMessage(fData) {
    fData.cmd = 'deleteSafetyMessage';
    return this.handleRequest(fData);
  }

  public getItem(messageID: string | number, translate: boolean = true, mapTranslations: boolean = false) {
    const ref = this.messages;
    if (!ref) {
      return;
    }
    const r: any = _.find(ref.data, <any>{messageID: +messageID});
    if (r != undefined && r.hasOwnProperty('translations')) {
      if (translate) {
        const l = this.userdata.getLanguage();
        if (l !== this.subscriber.getDefaultLanguage()) {
          const t = _.find(r.translations, ['language', l]);
          if (t) {
            r.messageTitle = t.value;
          }
        }
      }
      if (mapTranslations) {
        _.each(r.translations, (ref) => {
          r[`translations_${ref.language}`] = ref.value;
        });
      }
    }
    return r;
  }

}
