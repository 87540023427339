import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-observation-card',
  templateUrl: './observation-card.component.html',
  styleUrls: ['./observation-card.component.scss'],
})
export class ObservationCardComponent {
  @Input() avatarUrl: string;
  @Input() name: string;
  @Input() time: string;
  @Input() text: string;
  @Input() type: string;
}
