<ion-header no-border>
  <app-close-button (onClicked)="back()"></app-close-button>
</ion-header>

<ion-content>
  <div class="wide padded-bottom">
    <app-detail-header [headerData]="headerData"></app-detail-header>
    <hr class="hr-dashed top-bottom-padding">
    <div class="headline-medium">{{ (isPositiveAnswer ? 'DASHPAGES.Passed_Check' : "DASHPAGES.Failed_Check") | translate }}</div>
    <!-- Check Detail Block -->
    <div class="detail-page-section">
      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col size="4">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "SHARED.Deployment_Name" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div class="adjustment-grid-value">{{caDetail.deployment}}</div>
                </div>
              </div>
            </ion-col>
            <ion-col class="col-border-left" size="4">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "DASHPAGES.question-item" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div class="adjustment-grid-value">
                    {{caDetail.question}}
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col class="col-border-left" size="4">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "DASHPAGES.check-answer" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div [ngClass]="isPositiveAnswer ? 'green-block' : 'red-block'">
                    {{ caDetail.answer }}
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <!-- Check Detail Block End-->


    <!-- IMAGES BLOCK-->
    <div *ngIf="images.length" class="detail-page-section">
      <div class="headline-medium">{{ (isPositiveAnswer ? 'DASHPAGES.Validation_Inputs' : "DASHPAGES.issue-input") | translate }}</div>
      <image-gallery-modal [inputImages]="images"></image-gallery-modal>
      <hr class="hr-dashed">
    </div>
    <!-- IMAGES BLOCK END-->

    <!-- NOTE BLOCK-->
    <div *ngIf="notes" class="detail-page-section">
      <div class="headline-medium">{{ (isPositiveAnswer ? 'DASHPAGES.Validation_Notes' : "DASHPAGES.Issue_Notes") | translate }}</div>

      <div class="section-grid">

        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead" [ngClass]="isPositiveAnswer ? 'subhead-fixed' : 'subhead-logged'">
              {{ "SHARED.Logged" | translate }}
            </span>
            <ion-button (click)="addOpenNote()"
                        *ngIf="!isPositiveAnswer"
                        [appAccountCanViewExcept]="['viewer']"
                        class="page-button smaller border-margin" color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list">
              <li *ngFor="let note of notes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img"><img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p>
                    <span class="observation-u-name">{{note.name}}</span> |
                    <span>{{note.time}}</span>
                  </p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data" class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <hr class="hr-dashed">
      </div>
    </div>
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->
  </div>
</ion-content>

<ion-footer *ngIf="footerData" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
