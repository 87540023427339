import { Pipe, PipeTransform } from '@angular/core';

import { UserService } from '@services/user/user.service';

@Pipe({
  name: 'translateBy'
})
export class TranslateByPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(object: any, property: string): string {
    return this.userService.translateItem(object, property);
  }

}
