import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export abstract class CustomFormComponent {
  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();
  public required: boolean

  private _formValue;

  get formValue() {
    return this._formValue;
  }

  set formValue(value) {
    this._formValue = value;
    this.onChanged.emit(value);
  }
}
