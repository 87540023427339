import { Component, Input, OnInit } from '@angular/core';

import { IListBaseTab } from '@modules/management/pages/list/abstractList/abstractBaseList.page';
import { TableAttributes, TableService } from '@services';

import { extend, cloneDeep, each } from 'lodash';

export interface TabsTablesConfig {
  tabs: IListBaseTab[];
  currentTab?: IListBaseTab;
  disableTabsCount?: boolean;
  tableConfig: {
    baseTableIdPrefix: string;
    attr: TableAttributes | any;
    dataTableOptions: DataTables.Settings | any;
    getDataByTab(tabKey: string);
    getColumnsByTab?(tabKey: string);
  },
  searchConfigReference?: { searchString?: string };
}

@Component({
  selector: 'app-tables-by-tabs',
  templateUrl: './tables-by-tabs.component.html',
  styleUrls: ['./tables-by-tabs.component.scss'],
})
export class TablesByTabsComponent implements OnInit {
  @Input() config: TabsTablesConfig;

  constructor(
    private tableService: TableService
  ) {}

  ngOnInit() {
    if (!this.config.currentTab) {
      this.config.currentTab = this.config.tabs[0];
    }
  }

  public refresh() {
    this.showTablesByTabs();
  }

  public onChangeTab(tab: IListBaseTab) {
    this.getCountOfTables();

    setTimeout(() => {
      this.adjustColumns(tab);
    });
  }

  public applySearch(search: string) {
    each(this.config.tabs, (tab) => {
      const table = this.getTableInstanceByIdTabKey(tab.key);
      table.search(search || '').draw();
      this.tableService.highlightSearchResult(this.getTableSelectorByTabKey(tab.key), search);
    });

    this.getCountOfTables();
  }

  private getCountOfTables() {
    if (!this.config.disableTabsCount) {
      each(this.config.tabs, (tab) => {
        const table = this.getTableInstanceByIdTabKey(tab.key);
        tab.postfix = `(${table.rows({search: 'applied'}).count()})`;
      });
    }
  }

  private showTablesByTabs() {
    each(this.config.tabs, (tab) => {
      this.showTable(tab);
    });

    setTimeout(() => {
      this.getCountOfTables();
    });
  }

  private showTable(tab: IListBaseTab) {
    const tableIdPostfix = tab.key;
    const tableId = this.getTableSelectorByTabKey(tableIdPostfix);
    const data = this.config.tableConfig.getDataByTab(tableIdPostfix);
    const options = cloneDeep(this.config.tableConfig.dataTableOptions);
    const tableAttr = cloneDeep(this.config.tableConfig.attr);

    if (this.config.tableConfig.getColumnsByTab) {
      tableAttr.columns = this.config.tableConfig.getColumnsByTab(tableIdPostfix);
    }

    if (tab.emptyText) {
      extend(options, {
        language: {
          emptyTable: tab.emptyText
        }
      });
    }

    const table = this.tableService.showTable(tableId, tableAttr as TableAttributes, data, options);

    if (this.config.searchConfigReference) {
      this.config.searchConfigReference.searchString = table.search();
    }
  }

  private getTableSelectorByTabKey(key: string) {
    return `#${this.config.tableConfig.baseTableIdPrefix}${key}`;
  }

  private getTableInstanceByIdTabKey(key: string) {
    const tableSelector = this.getTableSelectorByTabKey(key);
    return $(tableSelector).DataTable();
  }

  private adjustColumns(tab: IListBaseTab) {
    const table = this.getTableInstanceByIdTabKey(tab.key);
    table.columns.adjust().draw(true);
  }

}
