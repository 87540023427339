<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ "SHARED.Copy_Link" | translate }}</h2>
</div>
<div class="body">
  <p>{{ "DASHBOARD.COPY_LINK_POPUP_body" | translate }}</p>
  <input [(ngModel)]="fullUrl" id="deepUrl" type="text" value="">
</div>
<div class="button-block ion-justify-content-center">
  <ion-button (click)="copyLink()" class="page-button" color="secondary">{{ "SHARED.Copy" | translate }}</ion-button>
</div>
