<div class="header-wrapper">
  <ng-container *ngIf="headerSetting">

    <div *ngIf="!headerSetting.showBreadCrumb" class="header-navigation">
      <div class="dropdown">
        <button (click)="openDashboardMenu()"
                [ngClass]="headerSetting.hideSelectionArrow ? 'hide-dropdown' : 'show-dropdown'"
                class="headline-large dropbtn-arrow" id="dropdownBtn">
          <ng-container *ngIf="getCurrentDashboardTitle">
            {{ getCurrentDashboardTitle | translate }}
          </ng-container>
          <ng-container *ngIf="!getCurrentDashboardTitle">
            {{headerSetting.title | translate}}
          </ng-container>
          <img *ngIf="!headerSetting.hideSelectionArrow" alt="Arrow" src="assets/icons/arrow_down.svg">
        </button>

        <div #dashboardMenuDropdown *ngIf="icons && icons.length" class="dropdown-content">
          <div class="dashboard-menu-item">
            <div class="dashboard-menu-item-title">
              <img alt="Dashboard" src="assets/icons/dashboard.svg">
              <span>{{headerSetting.title | translate}}</span>
            </div>
          </div>
          <span (click)="navigateTo(icon.routeUrl)" *ngFor="let icon of icons"
                [class.active]="!icon.routeUrl" [hidden]="icon.isAvailable && icon.isAvailable() === false"
                class="sub-menu-item">{{ icon.label | translate }}</span>
        </div>
        <div #dashboardMenuDropdown *ngIf="!headerSetting.hideSelectionArrow && observationIcons && observationIcons.length"
             class="dropdown-content">
          <div class="dashboard-menu-item">
            <div *ngIf="!headerSetting.type" class="dashboard-menu-item-title">
              <img alt="Shield" height="19px" src="assets/icons/shield.svg">
              <span>{{ "SHARED.Observations" | translate}}</span>
            </div>
            <div *ngIf="headerSetting.type" class="dashboard-menu-item-title">
              <img alt="Shield" height="20px" src="assets/icons/active_learning.svg">
              <span>{{ "SHARED.Active_Learning" | translate}}</span>
            </div>
          </div>
          <span (click)="navigateToObservationPage(icon.routeUrl)" *ngFor="let icon of observationIcons" [class.active]="icon.disabled"
                class="sub-menu-item">{{ icon.label }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="headerSetting.showBreadCrumb" class="breadcrumb-navigation">
      <div (click)="breadCrumbNav()" class="body-link text-align">{{headerSetting.breadCrumbObject.backNavTitle}}</div>
      <div class="divider">/</div>
      <div class="headline-large">{{headerSetting.breadCrumbObject.pageTitle}}</div>
    </div>
    <ion-button (click)="openFilter()"
                *ngIf="headerSetting.filter && headerSetting.filter.showFilter"
                [color]="hasAnyFilter() ? 'primary' : 'light'"
                [ngClass]="headerSetting.filter.customClass"
                class="page-button">
      {{ hasAnyFilter() ? Adjust_Filters : Add_Filters }}
    </ion-button>
    <ng-content select="[preSearchContent]"></ng-content>
    <span (click)="customClick($event)" *ngIf="headerSetting.customButton && isCustomButtonActive"
          [ngClass]="headerSetting.customButton.class"
          class='page-button'>{{ headerSetting.customButton.title }}</span>

    <div *ngIf="!headerSetting.disableSearchField" class="data-tables-filter-block">
      <div [hidden]="!headerSetting.showSearchField" class="dataTables_filter data-tables-filter-block-inner"
           tabindex="0">
        <label>
          <input (ngModelChange)="filterString()" [(ngModel)]="searchField"
                 aria-controls="open" autocomplete="off" class="spaced observation-search-field"
                 placeholder="{{'SHARED.Search' | translate}}" type="search">
          <ion-icon (click)="clearSearch()" *ngIf="searchField" class="custom_close_icon" name="close"></ion-icon>
        </label>
      </div>
    </div>

    <ng-content></ng-content>
  </ng-container>
</div>

<app-filter (onRemoveItem)="buildFilterComponents($event)" (onSelected)="closeFilterBody($event)" [filterComps]="filterComps"
            [filterObject]="filterObject" [showFilterBody]="showFilterBody">
</app-filter>
