import { Component, Input, OnChanges } from '@angular/core';

import { AssetsService } from '@services';
import { Asset } from '@services/assets/asset.interfaces';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { Observation, ObservationStatusCode } from '@services/observations/observation.interfaces';

@Component({
  selector: 'app-asset-status-panel',
  templateUrl: './asset-status-panel.component.html',
  styleUrls: ['./asset-status-panel.component.scss'],
})
export class AssetStatusPanelComponent implements OnChanges {
  @Input() observation: Observation;
  public asset: Asset;
  public loggedStatusCode: ObservationStatusCode;
  public fixedStatusCode: ObservationStatusCode;
  public isInitialized: boolean;

  constructor(
    private assetService: AssetsService,
    private observationDetailService: ObservationDetailService
  ) {}

  ngOnChanges() {
    if (this.observation) {
      this.init();
    }
  }

  public init() {
    if (this.observation.subjectType === 'asset' && this.observation.subjectID) {
      this.asset = this.assetService.getAssetById(this.observation.subjectID)?.[0];
      this.loggedStatusCode = this.observationDetailService.getAIStatus(this.observation);
      this.fixedStatusCode = this.observationDetailService.getAIStatus(this.observation, true);
      this.isInitialized = true;
    }
  }
}
