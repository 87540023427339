<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 *ngIf="!usesMaintainX" class="title">{{ "SHARED.CANCELWORKORDER_POPUP_title" | translate }}</h2>
  <h2 *ngIf="usesMaintainX" class="title">{{ "SHARED.CANCELWORKREQUEST_POPUP_title" | translate }}</h2>
</div>
<div class="body">
  <div *ngIf="!usesMaintainX" class="description">{{ "DASHBOARD.CANCELWORKORDER_POPUP_description" | translate }}</div>
  <div *ngIf="usesMaintainX" class="description">{{ "DASHBOARD.CANCELWORKREQUEST_POPUP_description" | translate }}</div>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.No" | translate }}</ion-button>
  <ion-button (click)="cancelWO()" class="page-button" color="secondary">{{ "SHARED.Yes" | translate }}</ion-button>
</div>
