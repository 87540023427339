<div class="group-block">
  <div *ngIf="group.title" class="title-block">
    <div class="title">
      {{group.title}}
      <div (click)="onRemove()" *ngIf="isRemovalAvailable" class="remove-button"></div>
    </div>
    <ion-reorder *ngIf="isReorderAvailable">
      <div class="reorder-icon"></div>
    </ion-reorder>
  </div>

  <form #form [id]="formId"></form>
</div>
