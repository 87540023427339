import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { ActiveFiltersComponent } from './components/active-filters/active-filters.component';
import { SelectFiltersFormComponent } from '@modules/reporting/components';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FilterMenuComponent,
    ActiveFiltersComponent,
    SelectFiltersFormComponent
  ],
  exports: [
    FilterMenuComponent,
    ActiveFiltersComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ]
})
export class MenuFilterModule {
}
