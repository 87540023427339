<ion-list lines="full">
  <ion-reorder-group (ionItemReorder)="doReorder($event)" *ngIf="viewItems && viewItems.length" class="groups-list"
                     disabled="false">
    <div *ngFor="let item of viewItems; trackBy: trackBy" class="list-item">
      <ion-reorder>
        <ion-item>
          <div class="info">
            <div class="item-info name list-label">{{item.name}}</div>
            <div class="item-info description">{{item.description}}</div>
          </div>
          <div class="reorder-icon"></div>
        </ion-item>
      </ion-reorder>
    </div>
  </ion-reorder-group>
  <div *ngIf="!viewItems || !viewItems.length" class="empty-list">
    {{"SHARED.Empty_Data_To_Include" | translate}}
  </div>
</ion-list>
