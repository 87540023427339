import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

import { HistoryBlockingLocationStrategyService } from '@services/historyBlockingLocationStrategy/history-blocking-location-strategy.service';
import { Events } from '@services/events/events.service';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionDeactivator implements CanDeactivate<null> {

  constructor(
    private events: Events,
    private alertController: AlertController,
    private translateService: TranslateService,
    private location: Location,
    private locationStrategy: HistoryBlockingLocationStrategyService
  ) {
  }

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean> | boolean {
    const isFormChanged: boolean = _.get(component, 'isFormChanged') && component.isFormChanged();
    const skipChangeDetection: boolean = _.includes(nextState.url, 'skipChangeDetection=true');

    if (isFormChanged && !skipChangeDetection) {
      return this.showAlert(currentRoute, currentState, nextState);
    }

    return true;
  }

  private showAlert(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean> {
    let canDeactivate = false;
    const isHeaderHidden: boolean = _.get(currentRoute, 'data.headerConfig.isHidden');
    this.updateHeaderParams(currentState);

    return new Promise(async (resolve) => {
      const params: any = {
        header: this.translateService.instant('SHARED.Are_You_Sure'),
        message: this.translateService.instant('SHARED.Change_Detection_Message'),
        cssClass: 'custom-alert',
        buttons: [
          {text: this.translateService.instant('SHARED.Cancel')},
          {
            text: this.translateService.instant('SHARED.Yes_Sure'),
            handler: () => {
              canDeactivate = true;
              resolve(canDeactivate);
              this.updateHeaderParams(nextState);
            }
          }
        ]
      };

      const alert: HTMLIonAlertElement = await this.alertController.create(params);
      alert.present();

      alert.onDidDismiss().then(() => {
        if (!canDeactivate && isHeaderHidden) {
          this.stopNextPopstatePropagation();
        }
      });
    });
  }

  private updateHeaderParams(routerStateSnapshot: RouterStateSnapshot) {
    this.events.publish('ccs:updateHeaderParams', routerStateSnapshot);
  }

  private stopNextPopstatePropagation(): void {
    this.locationStrategy.stopNextPopstatePropagation();
    this.location.forward();
  }
}
