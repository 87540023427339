import { NGXLogger } from 'ngx-logger';
import { Component, OnDestroy } from '@angular/core';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QuestionService } from '@modules/management/pages/details/check/services';
import { ChecksService, LoadingService, ViewerService, CheckResponseService } from '@services';
import { PopoverController } from '@ionic/angular';
import { Events } from '@services/events/events.service';
import { NotePopupComponent, MessagePopupComponent } from '@modules/observation/components';
import { DeploymentService } from '@modules/management/pages/details/check/services/deployment/deployment.service';

import * as _ from 'lodash';
import {Location} from '@angular/common';

@Component({
  selector: 'app-issue-detail',
  templateUrl: './issue-detail.page.html',
  styleUrls: ['./issue-detail.page.scss'],
})
export class IssueDetailPage implements OnDestroy {

  public headerData: any = {};
  public flagID: number;
  public flagObject: any = {};
  public isPositiveAnswer: boolean;
  public caDetail: any = {};
  public images: any = [];
  public notes: any = [];
  public footerData;
  private isImageLoading = false;
  private rid = '';
  private questionId;
  private responseObject: any;

  constructor(
    private checkDetailService: CheckDetailService,
    private logger: NGXLogger,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private questionService: QuestionService,
    private checksService: ChecksService,
    private checkResponseService: CheckResponseService,
    private popover: PopoverController,
    protected events: Events,
    private loadingService: LoadingService,
    private viewer: ViewerService,
    private router: Router,
    private deployment: DeploymentService,
    private location: Location
  ) {
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.noteListener);
  }

  async ionViewWillEnter() {
    this.flagID = +this.activatedRoute.snapshot.paramMap.get('fid');
    this.rid = this.activatedRoute.snapshot.paramMap.get('rid');
    this.questionId = this.activatedRoute.snapshot.paramMap.get('questionId');

    this.responseObject = await this.checkResponseService.getResponseAsync(this.rid);

    if (this.flagID) {
      this.headerData = await this.checkDetailService.getTopComponentData(this.rid, 'logged');
      await this.loadingService.enable();
      try {
        const testObj = await this.checkResponseService.getFlagIssue([this.flagID]);
        this.flagObject = testObj[this.flagID];
      } catch (error) {
        this.logger.log(error);
      }

      await this.prepareDataSet();
      this.loadingService.disable();
    } else if (this.questionId) {
      this.defineAnswer();
    }
    //   this.flagObject = this.checkDetailService.getIssueByIssueId(this.flagID);
    this.events.subscribe('ccs:noteUpdate', this.noteListener);
  }

  async prepareDataSet() {
    // get question and answer stuff
    const questionOptions = this.questionService.getQuestionOptions(this.flagObject.questionID);
    const questionObject = this.questionService.getQuestion(this.flagObject.questionID);
    const respValue = this.responseObject.answers[this.flagObject.questionID].response;
    if (this.checkDetailService.isUUID(respValue)) {
      const questionOption = questionOptions[respValue];
      this.caDetail.answer = this.checkDetailService.translateData(questionOption);
    } else {
      let rString = respValue;
      if (respValue === 'pass') {
        rString = this.translate.instant('MGMT_DETAILS.Pass');
      } else if (respValue === 'fail') {
        rString = this.translate.instant('MGMT_DETAILS.Fail');
      }
      this.caDetail.answer = rString;
    }
    const checkData = this.checksService.getCheck(this.flagObject.checkID);
    this.headerData.headerElements.push(this.checkDetailService.type(checkData.checkType));
    this.headerData.headerElements.push(this.checkDetailService.targetFullColumn(this.flagObject));
    this.caDetail.question = this.checkDetailService.translateData(questionObject);
    this.caDetail.name = this.checkDetailService.translateData(checkData);
    const deploymentName = this.deployment.getDeployment(this.flagObject.deploymentID);
    this.caDetail.deployment = deploymentName ? deploymentName.title : '';
    this.images = this.checkDetailService.getImages(this.flagObject);
    this.notes = this.checkDetailService.getNotes(this.flagObject);

    if (!this.activatedRoute.snapshot.queryParamMap.get('footerIsHidden')) {
      this.footerData = await this.checkDetailService.getFooterComponent(this.rid, 'logged', this.flagID);
    }
  }

  private async defineAnswer() {
    const currentAnswer = this.responseObject.answers[this.questionId];
    this.isPositiveAnswer = currentAnswer.result === 'positive';
    currentAnswer.created = currentAnswer.startTime;
    currentAnswer.creator = currentAnswer.responder;
    _.each(currentAnswer.notes, (note) => {
      note.createdAt ??= currentAnswer.startTime;
      note.userID ??= currentAnswer.responder;
    });

    const questionOptions = this.questionService.getQuestionOptions(this.questionId);
    const questionObject = this.questionService.getQuestion(this.questionId);
    const respValue = this.responseObject.answers[this.questionId].response;

    this.headerData = await this.checkDetailService.getTopComponentData(this.rid, 'validation');

    if (this.checkDetailService.isUUID(respValue)) {
      const questionOption = questionOptions[respValue];
      this.caDetail.answer = this.checkDetailService.translateData(questionOption);
    } else {
      let rString = respValue;
      if (respValue === 'pass') {
        rString = this.translate.instant('MGMT_DETAILS.Pass');
      } else if (respValue === 'fail') {
        rString = this.translate.instant('MGMT_DETAILS.Fail');
      }
      this.caDetail.answer = rString;
    }
    const checkData = this.checksService.getCheck(this.responseObject.checkID);
    this.caDetail.question = this.checkDetailService.translateData(questionObject);
    this.caDetail.name = this.checkDetailService.translateData(checkData);
    this.caDetail.deployment = this.deployment.getDeployment(this.responseObject.deploymentID)?.title || '';
    this.images = this.checkDetailService.getImages(currentAnswer);
    this.notes = this.checkDetailService.getNotes(currentAnswer);
  }

  public addOpenNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        flagID: this.flagID,
        msgType: 'text'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public zoomThis(src: string): void {
    if (!this.isImageLoading) {
      this.isImageLoading = true;
      this.loadingService.enable();

      const img: HTMLImageElement = new Image();
      img.src = src.replace('/thumb', '');
      img.onload = () => {
        this.loadingService.disable();
        this.isImageLoading = false;

        this.viewer.show(img);
      };
    }
  }

  back() {
    if (_.get(this.activatedRoute.snapshot.queryParams, 'backNavigation')) {
      this.location.back();
    } else {
      this.router.navigate([this.footerData.nav]);
    }
  }

  private noteListener = async (data) => {
    if (data) {
      try {
        const testObj = await this.checkResponseService.getFlagIssue([this.flagID]);
        this.flagObject = testObj[this.flagID];
        this.notes = this.checkDetailService.getNotes(this.flagObject);
      } catch (error) {
        this.logger.log(error);
      }
    }
  };

}

