<div class="component-title headline-small">{{gridObject.title | translate}}</div>
<ion-grid>
  <ion-row>
    <ion-col class="column-header column-header-height" size-md="6">
      {{"SUB_Settings.Action" | translate}}
    </ion-col>
    <ion-col class="column-header column-header-height" push="2" size-md="3">
      {{"SUB_Settings.Point_Value" | translate}}
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid lines="full">
  <ion-row *ngFor="let item of gridObject.fieldValues" class="row-styled">
    <ion-col class="body-copy" size-md="6">
      {{item.name | translate}}
    </ion-col>
    <ion-col class="height-adjust" push="2" size-md="3">
      <select2 (valueChanged)="dropDownChanged($event, item)" [data]="dropOption" [options]="options" [value]="item.value"
               appSelect2Parent class="table-content dashboard-dropdown">
      </select2>
    </ion-col>
  </ion-row>
</ion-grid>
