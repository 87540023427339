import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-compliance-details-table-popup',
  templateUrl: './compliance-details-table-popup.component.html',
  styleUrls: ['./compliance-details-table-popup.component.scss'],
})
export class ComplianceDetailsTablePopupComponent {

  public title: string;

  constructor(private popoverCtrl: PopoverController) {
  }

  public close(): void {
    this.popoverCtrl.dismiss();
  }

}
