import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppVersionGuard } from '@services/appVersionGuard/app-version.guard';
import { LocationStrategy } from '@angular/common';
import { HistoryBlockingLocationStrategyService } from '@services/historyBlockingLocationStrategy/history-blocking-location-strategy.service';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AppVersionGuard]
  },
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {provide: LocationStrategy, useClass: HistoryBlockingLocationStrategyService} // TODO [azyulikov] remove when https://github.com/angular/angular/issues/13586 is fixed
  ]
})
export class AppRoutingModule {
}
