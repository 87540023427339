import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { CommsService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenLeaderboardDeactivateGuard implements CanDeactivate<null> {

  constructor(private commsService: CommsService) {
  }

  canDeactivate(component: any, currentRoute: any): Observable<boolean> | Promise<boolean> | boolean {
    this.commsService.token = null;
    return true;
  }
}
