import { trigger, state, style, transition, animate } from '@angular/animations';

export function Collapse(duration: number = 300, triggerId: string = 'collapse') {
  return trigger(triggerId, [
    state('*', style({'overflow-y': 'hidden'})),
    state('void', style({'overflow-y': 'hidden'})),
    transition('* => void', [
      style({height: '*'}),
      animate(duration, style({height: 0}))
    ]),
    transition('void => *', [
      style({height: '0'}),
      animate(duration, style({height: '*'}))
    ])
  ]);
}
