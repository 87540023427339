<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ "SHARED.CREATE_POPUP_title" | translate }}</h2>
</div>
<div class="body">
  <div *ngIf="observationType === 'quality'">
      <ion-list>
    <ion-radio-group (ionChange)="selectSubtype($event)">
      <ion-list-header>
        <ion-label>{{ "DASHBOARD.QUALITY_SUBTYPE_POPUP" | translate }}</ion-label>
      </ion-list-header>
      <ion-item *ngFor="let entry of subtypes" class="checkbox-color-filled">
        <ion-label>{{entry.description}}</ion-label>
        <ion-radio [value]="entry.id" slot="start"></ion-radio>
      </ion-item>
    </ion-radio-group>
    </ion-list>
  </div>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="startCreate()" [disabled]="isFormInvalid()" class="page-button"
              color="secondary">{{ "SHARED.CREATE_POPUP_title" | translate }}</ion-button>
</div>
