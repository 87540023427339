<div class="panel" *ngIf="isInitialized">
  <div class="panel-item">
    <div class="panel-label">{{ 'MGMT_DETAILS.Asset_Name' | translate }}</div>
    <div class="asset-link" [routerLink]="['/pages/dashboard/asset/' + asset?.assetID, { backNavigation: true }]">{{ asset?.name }}</div>
  </div>

  <div class="panel-item">
    <div class="panel-label">{{ 'SHARED.Status_Logged' | translate }}</div>
    <div class="asset-status observation-circle"
         [ngClass]="loggedStatusCode?.type"
         *ngIf="loggedStatusCode?.type">{{loggedStatusCode?.title | translate}}</div>
  </div>

  <div class="panel-item">
    <div class="panel-label">{{ 'SHARED.Status_Fixed' | translate }}</div>
    <div class="asset-status observation-circle"
         [ngClass]="fixedStatusCode?.type"
         *ngIf="fixedStatusCode?.type">{{fixedStatusCode.title | translate}}</div>
  </div>
</div>
