import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SubscriberService } from '@services';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import { Feature } from '@services/subscriber/subscriber.service';

@Component({
  selector: 'app-cancelworkorder-popup',
  templateUrl: './cancelworkorder-popup.component.html',
  styleUrls: ['./cancelworkorder-popup.component.scss'],
})

export class CancelworkorderPopupComponent implements OnInit {
  @Input() observationID: Number;
  @Input() workorder: string;
  public usesMaintainX = false;

  constructor(private popOver: PopoverController,
              private subscriber: SubscriberService,
              private popOps: PopupObservationService) {
  }

  ngOnInit() {
    if (this.subscriber.usesFeature(Feature.OBSERVATION_MAINTAINX)) {
      this.usesMaintainX = true;
    }
  }

  public close(): void {
    this.popOver.dismiss();
  }

  public cancelWO(): void {
    this.popOps.cancelWorkOrder(this.observationID);
    this.popOver.dismiss();
  }
}
