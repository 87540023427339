<div class="header">
  <button (click)="close()" class="popup-close-button"></button>
  <h2 class="title">{{ "SHARED.Copy_Link" | translate }}</h2>
</div>

<div class="body">
  <p>{{ (description || 'SHARED.Copy_Link') | translate }}</p>
  <input #textRef [ngModel]="url" type="text">
</div>

<div class="button-block ion-justify-content-center">
  <ion-button (click)="copyLink()" class="page-button" color="secondary">{{ "SHARED.Copy" | translate }}</ion-button>
</div>
