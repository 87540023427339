import { Component, Input } from '@angular/core';

import { DashboardChartServiceService } from '@services/dashboardChartService/dashboard-chart-service.service';

export interface IChartWidgetConfig {
  id: string;
  gdata: any;
  pct: string;
  title: string;
  type: string;
}

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
})
export class ChartWidgetComponent {

  public widgetConfig: IChartWidgetConfig;

  constructor(private dashboardChartService: DashboardChartServiceService) {
  }

  @Input() set config(config: IChartWidgetConfig) {
    this.widgetConfig = config;

    setTimeout(() => {
      this.renderWidget();
    });
  }

  public renderWidget() {
    const {id, title, gdata, pct} = this.widgetConfig;

    if (this.widgetConfig.type === 'arc') {
      this.dashboardChartService.arc(`#${id}`, title, gdata, pct);
    } else {
      this.dashboardChartService.horizontalBar(`#${id}`, title, gdata, pct);
    }
  }

}
