import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent extends CustomFormComponent {
  @Input() value: string | number = 0;
  @ViewChild('currencyMask', {static: true}) itemListComponent: ElementRef;

  public currencyMaskOptions: any = {
    align: 'left',
    allowNegative: false,
    prefix: '$'
  };

  constructor() {
    super();
  }

  public onChange() {
    this.formValue = this.value;
  }
}
