<div *ngIf="history?.activity" [appAccountCanViewExcept]="['viewer']" class="detail-address-controls-as-viewer">
  <div class="button-block">
    <span class="address-text">{{'MGMT_DETAILS.Move_To_Addressed_Desc' | translate: {date: history.time} }}</span>
    <button (click)="messageUser(history)" class="grid-box-underline button-link">{{history.name}}</button>

    <ng-container *ngIf="scrollTarget">
      <span class="splitter">|</span> &nbsp;<a (click)="scrollTo()"
                                               class="link">{{ 'SHARED.View_All_History' | translate }}</a>
    </ng-container>
  </div>
</div>
