import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IMenuIcon } from '@services/observationDashboardUI/observation-dashboard-ui.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-icon-menu',
  templateUrl: './icon-menu.component.html',
  styleUrls: ['./icon-menu.component.scss'],
})
export class IconMenuComponent implements OnInit {

  public selectedIcon: IMenuIcon;

  @Input() icons: IMenuIcon[];
  @Output() onSelected: EventEmitter<IMenuIcon> = new EventEmitter<IMenuIcon>();

  constructor() {
  }

  ngOnInit() {
    this.selectedIcon = _.find(this.icons, ['selected', true]);
  }

  public clicked(value: IMenuIcon) {
    if (this.selectedIcon) {
      this.selectedIcon.selected = false;
    }
    this.selectedIcon = value;
    this.selectedIcon.selected = true;
    this.onSelected.emit(this.selectedIcon);
  }

  public calcStyle(i) {
    const menuLength = this.icons.length;
    if (i === 0) {
      return 'col-left';
    } else if (i + 1 === menuLength) {
      return 'col-right';
    } else {
      return 'col';
    }


  }

}
