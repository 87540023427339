import { Component, Injector, OnDestroy } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { LoadingService, ObservationService } from '@services';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { NotePopupComponent } from '@modules/observation/components';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import { AbstractObservationDetailPage } from '@modules/observation/pages/abstract-observation-detail-page/abstract-observation-detail-page';
import { Events } from '@services/events/events.service';

@Component({
  selector: 'app-compliment-detail',
  templateUrl: './compliment-detail.page.html',
  styleUrls: ['./compliment-detail.page.scss', './../../directives/mark-note.scss'],
})
export class ComplimentDetailPage extends AbstractObservationDetailPage implements OnDestroy {

  // THESE DATA ARE DISPLAYED ON FRONT END
  public uuid: string = null;
  public oid: number = null; // oid
  public bottomComponentData: any; // bottom back/front nav, table nav
  public headerData: any = {}; // data for top component, loc, zone, user etc
  public closingData: any = {}; // data for closing component
  public images = []; // observation images
  public notes: any = {}; // observation notes, audio + test
  public footerButtons = []; // button displayed on footer
  public bottomNav = false;
  public footerData: any = {};
  public headerIsHidden: string;

  constructor(
    private observationDetailService: ObservationDetailService,
    private popover: PopoverController,
    private loadingService: LoadingService,
    private location: Location,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.noteSubscriber);
  }

  ionViewDidEnter() {
    this.loadingService.disable();
  }

  ionViewWillEnter() {
    // get observations
    this.uuid = this.activatedRoute.snapshot.paramMap.get('id');
    this.headerIsHidden = this.activatedRoute.snapshot.queryParamMap.get('headerIsHidden');
    if (this.activatedRoute.snapshot.queryParamMap.get('tableNav') === 'true') {
      this.bottomNav = true;
    }

    this.events.subscribe('ccs:noteUpdate', this.noteSubscriber);

    this.events.subscribe('ccs:observationUpdate', this.updateObservationListener);

    // to make sure this observation actually exists, lets make a call for this observation.
    this.updateObservationListener();

  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:observationUpdate', this.updateObservationListener);
  }

  public addOpenNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'comment'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private noteSubscriber = (data) => {
    if (data) {
      this.notes = this.observationDetailService.getBbsNotes(this.oid);
    }
  };

  private updateObservationListener = () => {
    this.observationService.getObservationByUUID(this.uuid).then(data => {
      if (data && data.observationID) {
        this.oid = data.observationID;
        this.headerData = this.observationDetailService.getTopComponentData(this.oid);
        this.notes = this.observationDetailService.getBbsNotes(this.oid);
        if (this.headerData.buttonPanel) {
          this.footerButtons = this.headerData.buttons;
        }

        // if coming from table then only show prev-back nav.
        // blue nav bar depending whether its coming from the table list.
        if (this.bottomNav) {
          this.checkFooterData();
        }
      } else {
        this.location.back();
      }
    }).catch(() => {
      this.location.back();
    });
  };
}

