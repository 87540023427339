import { Component, OnInit, Input } from '@angular/core';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import { PopoverController } from '@ionic/angular';
import { NotePopupComponent } from '../note-popup/note-popup.component';
import { CategoryPopupComponent } from '../category-popup/category-popup.component';

@Component({
  selector: 'app-closing-notes',
  templateUrl: './closing-notes.component.html',
  styleUrls: ['./closing-notes.component.scss'],
})
export class ClosingNotesComponent implements OnInit {

  @Input() closingData: any;

  constructor(private popOps: PopupObservationService,
              private popover: PopoverController) {
  }

  ngOnInit() {
  }

  closingCategory() {
    this.popover.create(<any>{
      component: CategoryPopupComponent,
      animated: false,
      componentProps: {
        observationID: this.closingData.observationID,
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }


  closingNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.closingData.observationID,
        msgType: 'closingNotes'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  scroll(id) {
    const el: any = document.getElementById(id);
    el.scrollIntoView({behavior: 'smooth'});
  }

}
