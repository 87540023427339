import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { ObjectsService } from '@services';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { ItemListComponent } from '@shared/components';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-filters-form-modal',
  templateUrl: './select-filters-form-modal.component.html',
  styleUrls: ['./select-filters-form-modal.component.scss'],
})
export class SelectFiltersFormModalComponent extends CustomFormComponent implements OnInit {

  @Input() options: any[];

  @Output() onSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild(ItemListComponent) itemListComponent: ItemListComponent;

  public selectedItems: any[] = [];
  public selectedItemsIDs: string[];
  public title: string = this.translate.instant('SHARED.Select_Column_Data');
  public subTitle: string = this.translate.instant('SHARED.Select_Column_Data_Subtitle');

  constructor(
    private objectsService: ObjectsService,
    private popoverController: PopoverController,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    setTimeout(() => {
      let opts = _.map(this.options, (item) => item.id);
      _.each(opts, (item: any) => {
        const option = _.find(this.options, {id: item});
        option.checked = false;
        option.hidden = false;
      });
      _.each(this.selectedItems, (item: any) => {
        const option = _.find(this.options, {id: item});

        if (option) {
          option.checked = true;
          option.hidden = true;
          opts = _.remove(opts, (item) => item !== option.id);
        }
      });
    });
  }

  public onItemsSelected(): void {
    this.onSelected.emit(this.getSelectedItems());
  }

  public getSelectedItems(): any[] {
    this.selectedItems = _.cloneDeep(this.itemListComponent.getSelectedByID());
    this.selectedItemsIDs = _.map(this.selectedItems, 'id');
    return this.selectedItemsIDs;
  }

  public close() {
    this.popoverController.dismiss();
  }

  public applyForm() {
    this.popoverController.dismiss(this.selectedItemsIDs);
  }

}
