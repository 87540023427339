import { OverlayEventDetail } from '@ionic/core';
import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

import { Asset } from '@services/assets/asset.interfaces';
import { AssetsService, FoldersDataService } from '@services';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';
import { ITreeViewModalConfig, TreeViewNodeIdType } from '@shared/modules/tree-viewer/models';
import {
  FolderPickerConfig,
  FolderPickerEntities,
  FolderPickerItem,
  FolderPickerSelection
} from '@modules/management/modules/folders/model/folders.interfaces';
import { FolderPickerService } from '@modules/management/modules/folders/services/folder-picker.service';
import { ViewModalTreePopupComponent } from '@shared/modules/tree-viewer/view-modals/view-modal-popup/view-modal-popup.component';

import { join } from 'lodash';

import { filter, map, reject, split } from 'lodash';

@Component({
  selector: 'app-folder-picker',
  templateUrl: './folder-picker.component.html',
  styleUrls: ['./folder-picker.component.scss'],
})
export class FolderPickerComponent extends CustomFormComponent implements OnInit {
  @Input() type: FolderDataType;
  public selectedItems: { label: string; isFolder?: boolean }[] = [];
  public getSourceItems: () => FolderPickerItem[];

  private folderEntities: FolderPickerEntities = {};
  private selectedEntities: FolderPickerSelection[] = [];

  constructor(
    private assetsService: AssetsService,
    private folderPickerService: FolderPickerService,
    private popoverController: PopoverController,
    private foldersDataService: FoldersDataService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedEntities = this.formValue;
    this.defineSelectedItems();
  }

  public showTree() {
    this.popoverController.create({
      component: ViewModalTreePopupComponent,
      animated: false,
      componentProps: {
        config: <ITreeViewModalConfig>{
          title: 'MGMT_DETAILS.Asset_Targets',
          nodes: this.folderPickerService.getTreeNodes(this.getTreeConfigByType()),
          multiselect: true
        }
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event: OverlayEventDetail<TreeViewNodeIdType[]>) => {
        if (event.data) {
          this.onSelection(event.data);
          this.emitChanges();
        }
      });
    });
  }

  private emitChanges() {
    this.formValue = this.selectedEntities;
  }

  public resetSelection() {
    this.onSelection([]);
    this.emitChanges();
  }

  private getTreeConfigByType(): FolderPickerConfig {
    if (this.type === FolderDataType.ASSET) {
      const sourceData = this.getSourceItems && this.getSourceItems();
      const assets: Asset[] | FolderPickerItem[] = sourceData || reject(this.assetsService.getLocalAssets(), 'disabledAt');

      return {
        dataType: FolderDataType.ASSET,
        entities: this.folderEntities,
        selectAllLabel: 'MGMT_LIST.All_Assets',
        selectedIds: this.selectedEntities,
        parentIds: [],
        getFolderItems: () => assets,
        getItemId: (asset: Asset) => asset?.assetID,
        getItemTitle: (asset: Asset) => asset?.name,
        getFolderId: (folder) => folder?.folderID
      };
    }
  }

  private onSelection(data: TreeViewNodeIdType[]) {
    if (this.type === FolderDataType.ASSET) {
      this.selectedEntities = map(data, (nodeId): FolderPickerSelection => {
        const folderID = +split(nodeId as string, FolderPickerService.folderIdPrefix)?.[1];

        if (isNaN(folderID)) {
          return { assetID: +nodeId };
        } else {
          return { folderID };
        }
      });

      this.defineSelectedItems();
    }
  }

  private defineSelectedItems() {
    this.selectedItems = filter(map(this.selectedEntities, (selectedEntity) => {
      let selectedItem = { label: '', isFolder: false };

      if (selectedEntity.assetID) {
        selectedItem.label = this.assetsService.getAssetById(selectedEntity.assetID)?.[0]?.name;
      } else if (selectedEntity.folderID) {
        if (this.foldersDataService.isActive(selectedEntity.folderID)) {
          selectedItem.label = join(this.foldersDataService.getFolderPath(selectedEntity.folderID, true), '/ ');
          selectedItem.isFolder = true;
        } else {
          // this item shouldn't be here - it is not selectable
          return;
        }
      } else {
        selectedItem.label = 'MGMT_LIST.All_Assets';
        selectedItem.isFolder = true;
      }

      return selectedItem;
    }), 'label');
  }
}
