import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export interface IPopoverConfig {
  title?: string;
  description: string;
  subDescription?: string;
  hideActions?: boolean;
  hideSaveAction?: boolean;
  hideCancelAction?: boolean;
  showCloseButton?: boolean;
  saveTitle?: string;
  cancelTitle?: string;

  onSave?(): any;

  onCancel?(): any;
}

@Component({
  selector: 'app-popover-template',
  templateUrl: './popover-template.component.html',
  styleUrls: ['./popover-template.component.scss'],
})
export class PopoverTemplateComponent implements OnInit {
  @Input() config: IPopoverConfig;

  public cancelBtnTitle = this.translate.instant('SHARED.Cancel');
  public saveBtnTitle = this.translate.instant('SHARED.Yes_Sure');

  constructor(
    public translate: TranslateService,
    private popoverCtrl: PopoverController
  ) {
  }

  ngOnInit() {
    this.cancelBtnTitle = this.config.cancelTitle || this.cancelBtnTitle;
    this.saveBtnTitle = this.config.saveTitle || this.saveBtnTitle;
  }

  public close(): void {
    this.config.onCancel && this.config.onCancel();
    this.dismissPopup();
  }

  public dismissPopup(): void {
    this.popoverCtrl.dismiss();
  }

  public save(): void {
    this.dismissPopup();
    this.config.onSave();
  }
}
