import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHideShowPassword]',
})

export class HideShowPasswordDirective {
  private _shown = false;

  constructor(
    private elementref: ElementRef
  ) {
  }

  @HostListener('click', ['$event']) onClick(event) {
    this.toggle();
  }

  toggle() {
    this._shown = !this._shown;
    const parent = this.elementref.nativeElement.parentNode;
    if (this._shown) {
      this.elementref.nativeElement.setAttribute('src', 'assets/icons/General/Visible.svg');
      parent.querySelector('input').setAttribute('type', 'url');
    } else {
      this.elementref.nativeElement.setAttribute('src', 'assets/icons/General/Hidden.svg');
      parent.querySelector('input').setAttribute('type', 'password');
    }
  }
}
