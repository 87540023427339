import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { UserdataService } from '@services/userdata/userdata.service';

import * as _ from 'lodash';

export interface UpdateObjectParams {
  objectUUID: string;
  objectID: number;
  mediaType?: string;
  description?: string;
  type?: string;
  subtype?: string;
  state?: string;
  isPublic?: number;
}

export interface ObjectItem {
  contentCategory: number;
  createdAt: number;
  creatorID: number;
  creatorShift: number;
  description: string;
  disabledAt: number;
  disabledBy: number;
  isPublic: number;
  lastUpdate: number;
  mediaType: string;
  objectID: number;
  objectUUID: string;
  state: string;
  subtype: string;
  tags: number[];
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {

  public filterObject: any = {};
  private objects: { [key: string]: any[] } = {};

  constructor(
    private comms: CommsService,
    private subscriber: SubscriberService,
    private userdataService: UserdataService
  ) {
  }

  /**
   *
   * @param objectID  the ID of the object from the object_map table
   * @param thumbnail whether or not to request the thumbnail version
   *
   */
  public URI(objectID: number, thumbnail: boolean = false, oldStyle: boolean = false) {
    let cmd = '';
    const token = this.comms.token || 'none';
    let ret = '';

    if (oldStyle) {
      cmd = this.comms.serviceURL.replace('corvex.cgi', 'getObject.cgi');

      ret = cmd +
        '?cmd=getObject&token=' + this.comms.token +
        '&subscriberID=' + this.subscriber.subInfo.subscriberID +
        '&objectID=' + objectID;

      if (thumbnail) {
        ret += '&thumb=1';
      }
    } else {
      cmd = this.comms.serviceURL.replace('api/v2', '');
      ret = `${cmd}objects/${this.subscriber.subInfo.subscriberID}/${token}/${objectID}`;
      if (thumbnail) {
        ret += '/thumb';
      }
    }
    return ret;
  }

  public removeObject(id) {
    const fData = {cmd: 'deleteObject', objectID: id};
    return this.comms.sendMessage(fData);
  }

  public getObjectList(types, includeDisabled, filters?: any) {
    const fData = {
      cmd: 'getObjectList',
      types: JSON.stringify(types || []),
      includeDisabled,
      ...filters
    };

    return this.comms.sendMessage(fData).then((data) => {
      const objects = _.get(data, 'result.objects') || [];
      _.each(types, (type) => {
        this.objects[type] = _.filter(objects, {type});
      });
      return objects;
    });
  }

  public updateObject(params: UpdateObjectParams): Promise<any> {
    const requestParams = {
      cmd: 'updateObject',
      ...params
    };

    return this.comms.sendMessage(requestParams);
  }

  public getCachedObjectById(id: number, alias?: string, translated?: boolean): ObjectItem {
    const objects = alias ? this.objects[alias] : _.flatten(_.values(this.objects));
    let object = _.clone(_.find(objects || [], ['objectID', id]));

    if (translated) {
      const translatedLanguage: any = _.findLast(_.get(object, 'translations.objects'), {language: this.userdataService.getLanguage()});

      if (translatedLanguage) {
        object = this.getCachedObjectById(translatedLanguage.value, alias);
      }
    }

    return object;
  }

  public getCachedObjectByIds(ids: number[], onlyActive?: boolean): ObjectItem[] {
    const objects: ObjectItem[] = _.flatten(_.values(this.objects));
    const items = [];

    _.each(_.uniq(ids), (id) => {
      const item = _.find(objects, <any>{objectID: id});

      if (onlyActive && _.get(item, 'state') === 'active' || !onlyActive) {
        items.push(item);
      }
    });

    return _.cloneDeep(items);
  }

  public getCachedObjectByAlias(alias: string): ObjectItem[] {
    return _.cloneDeep(this.objects[alias]) || [];
  }

  public getIconByType(mediaType: string): string {
    const objectType = this.getObjectType(mediaType);
    const iconMap = {
      pdf: 'pdf.svg',
      image: 'image.svg',
      video: 'video.svg',
      audio: 'audio.svg'
    };

    return iconMap[objectType] ? `assets/icons/media_types/${iconMap[objectType]}` : null;
  }

  public getObjectType(mediaType: string) {
    let type: 'image' | 'pdf' | 'video' | 'audio' = 'image';

    if (_.includes(mediaType, 'application/pdf')) {
      type = 'pdf';
    } else if (_.includes(mediaType, 'video/')) {
      type = 'video';
    } else if (_.includes(mediaType, 'audio/')) {
      type = 'audio';
    }

    return type;
  }

  public filterData(data) {
    if (_.isEmpty(this.filterObject)) {
      return data;
    } else {
      return _.filter(data, (dataItem) => this.checkFilter(dataItem));
    }
  }

  private checkFilter(dataItem: any): boolean {
    const keyMap = {
      permissions: 'permissionLevel',
      locations: 'locations',
      roles: 'roles',
      shifts: 'shift',
      certifications: 'certifications'
    };

    return _.every(keyMap, (filterKey: string, dataKey: string) => {
      const filter = this.filterObject[filterKey];
      const dataByKey = dataItem[dataKey];
      const matched = _.intersection(filter, dataByKey).length > 0;
      const dataIsEmpty = dataByKey.length === 0;

      return filter?.length ? (dataIsEmpty || matched) : true;
    });
  }
}
